<script setup lang="ts">
</script>

<template>
  <header class="px-2 bg-submit-925">
    <div class="container mx-auto py-6">
      <h1 class="font-display text-white text-3xl font-bold">
        <slot name="title" />
      </h1>
      <h2 class="text-sm text-gray-300">
        <slot name="subtitle" />
      </h2>
    </div>
  </header>
</template>

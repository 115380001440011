<script setup lang="ts">
import D from "@/composables/TimeDisplay"

defineProps(["item", "length", "index"])

function convertToAnchor(inputString: string, url: string) {
  const regex = /post:\s*(.+)/
  return inputString.replace(regex, (match, p1) => {
    return `post: <a class="underline" href="${url}">${p1}</a>`
  })
}

function removePost(inputString: string) {
  // remove everything after post: in the string, keep post:
  const regex = /post:\s*(.+)/
  return inputString.replace(regex, "post:")
}

function getTitle(inputString: string) {
  // get everyting after post: in the string
  const regex = /post:\s*(.+)/
  return inputString.match(regex)?.[1]
}
</script>

<template>
  <li class="group">
    <div class="relative pb-8 group-last:pb-0">
      <span v-if="index !== length - 1" class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-300 dark:bg-gray-800" aria-hidden="true" />
      <div class="relative flex items-start space-x-3">
        <template v-if="item.type === 'joined'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg class="h-3 w-3 fill-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              {{ item.activityString }}
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'left'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg class="h-3 w-3 fill-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM472 200H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H472c-13.3 0-24-10.7-24-24s10.7-24 24-24z"/></svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              {{ item.activityString }}
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'posted'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg class="h-3 w-3 fill-gray-500" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zM232 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"/></svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'deleted'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    item.moderation ? 'fill-red-500' : 'fill-gray-500',
                    'h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm175 63c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              {{ item.activityString }}
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
            <p v-if="item.moderation" class="mt-0.5 text-sm text-gray-400 dark:text-gray-500">Moderation Action: Originally posted by <a :href="`/${item.referenceUsername}`" class="underline">{{ item.referenceUsername }}</a></p>
            <p class="mt-0.5 text-gray-400 dark:text-gray-500 text-sm" v-if="item.moderation && item.referenceReason">{{ item.referenceReason }}</p>
          </div>
        </template>
        <template v-if="item.type === 'commented'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg class="h-3 w-3 fill-gray-500" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM232 328V264H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V152c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'updated'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg class="h-3 w-3 fill-gray-500" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'locked'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                      item.moderation ? 'fill-red-500' : 'fill-gray-500',
                      'h-3 w-3'
                    ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
            <p v-if="item.moderation" class="mt-0.5 text-sm text-gray-400 dark:text-gray-500">Moderation Action: Originally posted by <a :href="`/${item.referenceUsername}`" class="underline">{{ item.referenceUsername }}</a></p>
            <p class="mt-0.5 text-gray-400 dark:text-gray-500 text-sm" v-if="item.moderation && item.referenceReason">{{ item.referenceReason }}</p>
          </div>
        </template>
        <template v-if="item.type === 'deletedComment'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    item.moderation ? 'fill-red-500' : 'fill-gray-500',
                    'h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM175 159c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
            <p v-if="item.moderation" class="mt-0.5 text-sm text-gray-400 dark:text-gray-500">Moderation Action: Comment posted by <a :href="`/${item.referenceUsername}`" class="underline">{{ item.referenceUsername }}</a></p>
            <p class="mt-0.5 text-gray-400 dark:text-gray-500 text-sm" v-if="item.moderation && item.referenceReason">{{ item.referenceReason }}</p>
          </div>
        </template>
        <template v-if="item.type === 'warning'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    item.moderation ? 'fill-red-500' : 'fill-gray-500',
                    'h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
            <p v-if="item.moderation" class="mt-0.5 text-sm text-gray-400 dark:text-gray-500">Moderation Action: Warning for <a :href="`/${item.referenceUsername}`">{{ item.referenceUsername }}</a></p>
            <p class="mt-0.5 text-gray-400 dark:text-gray-500 text-sm" v-if="item.moderation && item.referenceReason">{{ item.referenceReason }}</p>
          </div>
        </template>
        <template v-if="item.type === 'timeout'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    item.moderation ? 'fill-red-500' : 'fill-gray-500',
                    'h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
            <p v-if="item.moderation" class="mt-0.5 text-sm text-gray-400 dark:text-gray-500">Moderation Action: Timeout for <a :href="`/${item.referenceUsername}`">{{ item.referenceUsername }}</a></p>
            <p class="mt-0.5 text-gray-400 dark:text-gray-500 text-sm" v-if="item.moderation && item.referenceReason">{{ item.referenceReason }}</p>
          </div>
        </template>
        <template v-if="item.type === 'kick'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    item.moderation ? 'fill-red-500' : 'fill-gray-500',
                    'h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path d="M0 32C0 14.3 14.3 0 32 0H320c17.7 0 32 14.3 32 32V64c0 17.7-14.3 32-32 32H0V32zM0 457.4V448H512v9.4c0 14.5-5.8 28.4-16 38.6s-24.1 16-38.6 16H442.5c-17 0-33.3-6.7-45.3-18.7l-7.6-7.6c-3.1-3.1-8.2-3.1-11.3 0l-7.6 7.6c-12 12-28.3 18.7-45.3 18.7h-11c-17 0-33.3-6.7-45.3-18.7l-7.6-7.6c-3.1-3.1-8.2-3.1-11.3 0l-7.6 7.6c-12 12-28.3 18.7-45.3 18.7h-11c-17 0-33.3-6.7-45.3-18.7l-7.6-7.6c-3.1-3.1-8.2-3.1-11.3 0l-7.6 7.6c-12 12-28.3 18.7-45.3 18.7H54.6c-14.5 0-28.4-5.8-38.6-16S0 471.9 0 457.4zM0 416V128H320v32H272c-8.8 0-16 7.2-16 16s7.2 16 16 16h48v32H272c-8.8 0-16 7.2-16 16s7.2 16 16 16h48l119.3 29.8C482 296.5 512 334.9 512 379v37H0z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
            <p v-if="item.moderation" class="mt-0.5 text-sm text-gray-400 dark:text-gray-500">Moderation Action: Kicked <a :href="`/${item.referenceUsername}`">{{ item.referenceUsername }}</a></p>
            <p class="mt-0.5 text-gray-400 dark:text-gray-500 text-sm" v-if="item.moderation && item.referenceReason">{{ item.referenceReason }}</p>
          </div>
        </template>
        <template v-if="item.type === 'ban'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    item.moderation ? 'fill-red-500' : 'fill-gray-500',
                    'h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 640 512"
                >
                  <path d="M283.9 378.6l18.3-60.1c18-4.1 34.2-16 43.1-33.8l64-128c10.5-21.1 8.4-45.2-3.7-63.6l52.7-76.6c3.7-5.4 10.4-8 16.7-6.5s11.2 6.7 12.3 13.1l16.2 104.1 105.1-7.4c6.5-.5 12.7 3.1 15.5 9s1.8 12.9-2.6 17.8L550.1 224l71.3 77.5c4.4 4.8 5.5 11.9 2.6 17.8s-9 9.5-15.5 9l-105.1-7.4L487.3 425c-1 6.5-5.9 11.7-12.3 13.1s-13-1.1-16.7-6.5l-59.7-86.7-91.4 52.2c-5.7 3.3-12.8 2.7-17.9-1.4s-7.2-10.9-5.3-17.2zm28.3-101.7c-9.3 10.9-25.2 14.4-38.6 7.7l-65.9-32.9 0 0L122 208.8l0 0L17.7 156.6C1.9 148.7-4.5 129.5 3.4 113.7l40-80C48.8 22.8 59.9 16 72 16H192c5 0 9.9 1.2 14.3 3.4l78.2 39.1 81.8 40.9c15.8 7.9 22.2 27.1 14.3 42.9l-64 128c-1.2 2.4-2.7 4.6-4.4 6.6zM107.6 237.4l85.9 42.9L90.9 485.5c-11.9 23.7-40.7 33.3-64.4 21.5S-6.8 466.2 5.1 442.5L107.6 237.4z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
            <p v-if="item.moderation" class="mt-0.5 text-sm text-gray-400 dark:text-gray-500">Moderation Action: Ban for <a :href="`/${item.referenceUsername}`">{{ item.referenceUsername }}</a></p>
            <p class="mt-0.5 text-gray-400 dark:text-gray-500 text-sm" v-if="item.moderation && item.referenceReason">{{ item.referenceReason }}</p>
          </div>
        </template>
        <template v-if="item.type === 'acknowledged'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'h-3 w-3 fill-gray-500'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'edited'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'h-3 w-3 fill-gray-500'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'created'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'h-3 w-3 dark:fill-gold-500 fill-gold-700'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path d="M325.8 152.3c1.3 4.6 5.5 7.7 10.2 7.7s8.9-3.1 10.2-7.7L360 104l48.3-13.8c4.6-1.3 7.7-5.5 7.7-10.2s-3.1-8.9-7.7-10.2L360 56 346.2 7.7C344.9 3.1 340.7 0 336 0s-8.9 3.1-10.2 7.7L312 56 263.7 69.8c-4.6 1.3-7.7 5.5-7.7 10.2s3.1 8.9 7.7 10.2L312 104l13.8 48.3zm-112.4 5.1c-8.8-17.9-34.3-17.9-43.1 0l-46.3 94L20.5 266.5C.9 269.3-7 293.5 7.2 307.4l74.9 73.2L64.5 483.9c-3.4 19.6 17.2 34.6 34.8 25.3l92.6-48.8 92.6 48.8c17.6 9.3 38.2-5.7 34.8-25.3L301.6 380.6l74.9-73.2c14.2-13.9 6.4-38.1-13.3-40.9L259.7 251.4l-46.3-94zm215.4 85.8l11 38.6c1 3.6 4.4 6.2 8.2 6.2s7.1-2.5 8.2-6.2l11-38.6 38.6-11c3.6-1 6.2-4.4 6.2-8.2s-2.5-7.1-6.2-8.2l-38.6-11-11-38.6c-1-3.6-4.4-6.2-8.2-6.2s-7.1 2.5-8.2 6.2l-11 38.6-38.6 11c-3.6 1-6.2 4.4-6.2 8.2s2.5 7.1 6.2 8.2l38.6 11z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'queued'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'h-3 w-3 fill-gray-500'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path d="M352.2 82.3L351 73.8c-3-21.1-19.6-37.7-40.7-40.7l-8.5-1.2c-8.7-1.2-14.8-9.4-13.6-18.1S297.5-1.1 306.3 .2l8.5 1.2c35.2 5 62.9 32.7 67.9 67.9l1.2 8.5c1.2 8.7-4.8 16.9-13.6 18.1s-16.9-4.8-18.1-13.6zM185.4 70.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192c3.3 3.3 9.4 1.1 9.4-3.7l0-77.7c0-22.1 17.9-40 40-40s40 17.9 40 40V312.3c0 60.2-27.9 116.9-75.5 153.7C385.7 505.3 321 515.7 263 498.7c-28.4-7.1-55.3-21.8-77.6-44.1l-4.9-4.9L73.4 342.6l-32-32c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l31.9 31.9 .1 .1L165.3 344c5.2 5.2 13.6 5.2 18.7 0s5.2-13.6 0-18.7L57.4 198.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L229.3 280c5.2 5.2 13.6 5.2 18.7 0s5.2-13.6 0-18.7L105.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L293.3 216c5.2 5.2 13.6 5.2 18.7 0s5.2-13.6 0-18.7L185.4 70.6zM13.7 352.2c8.7-1.3 16.9 4.8 18.1 13.6l1.2 8.5c3 21.1 19.6 37.7 40.7 40.7l8.5 1.2c8.7 1.2 14.8 9.4 13.6 18.1s-9.4 14.8-18.1 13.6l-8.5-1.2c-35.2-5-62.9-32.7-67.9-67.9L.2 370.3c-1.2-8.7 4.8-16.9 13.6-18.1z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'withdrew'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'h-3 w-3 fill-gray-500'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="18"
                  viewBox="0 0 576 512"
                >
                  <path d="M272 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-90.7 12.6c-14-3.5-28.7-3.5-42.7 0l-1.8 .5c-13.3 3.3-25.6 9.7-35.9 18.6L56.4 165.8c-10.1 8.6-11.2 23.8-2.6 33.8s23.8 11.2 33.8 2.6l44.5-38.2c4.7-4 10.3-6.9 16.3-8.4l1.8-.5c6.4-1.6 13-1.6 19.4 0l8.6 2.1-32.7 98c-8.5 25.5 2.3 53.4 25.7 66.5l88 49.5L225.1 480.8c-4 12.7 3.1 26.1 15.7 30.1s26.1-3.1 30.1-15.8L307 379.5c5.6-18-2.1-37.5-18.6-46.8l-32.1-18 28.1-84.4 5.6 18.2c7.2 23.5 28.9 39.5 53.5 39.5H392c13.3 0 24-10.7 24-24s-10.7-24-24-24H343.6c-3.5 0-6.6-2.3-7.6-5.6l-19.7-64.2c-5.8-18.7-20.9-33.1-39.9-37.9l-95-23.7zm70.8 67.2l-38.3 115-19-10.7c-3.3-1.9-4.9-5.9-3.7-9.5L225 169l27.1 6.8zM122.5 317.1L103.4 368H24c-13.3 0-24 10.7-24 24s10.7 24 24 24h84.9c16.7 0 31.6-10.3 37.4-25.9l14.1-37.6-4.9-2.8c-14.1-8-25.4-19.3-33-32.6zM528 48V464H432V303.2c-10.2 10.4-24.3 16.8-40 16.8c-2.7 0-5.4-.2-8-.6V464c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H432c-26.5 0-48 21.5-48 48V208.6c2.6-.4 5.3-.6 8-.6c15.7 0 29.8 6.4 40 16.8V48h96z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'approveduser'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'h-3 w-3 fill-gray-500'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="14"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'rejecteduser'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'h-3 w-3 fill-gray-500'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="12"
                  viewBox="0 0 384 512"
                >
                  <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
            <p class="mt-0.5 text-sm text-gray-400 dark:text-gray-500">Reason: {{ item.referenceReason && item.referenceReason.length > 0 ? item.referenceReason : "No reason provided." }}</p>
          </div>
        </template>
        <template v-if="item.type === 'rescinded'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    item.moderation ? 'fill-red-500' : 'fill-gray-500',
                    'h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
            <p v-if="item.moderation" class="mt-0.5 text-sm text-gray-400 dark:text-gray-500">Moderation Action: Rescinded punishment for <a :href="`/${item.referenceUsername}`">{{ item.referenceUsername }}</a></p>
            <p class="mt-0.5 text-gray-400 dark:text-gray-500 text-sm" v-if="item.moderation && item.referenceReason">{{ item.referenceReason }}</p>
          </div>
        </template>
        <template v-if="item.type === 'pinned' || item.type === 'unpinned'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'dark:fill-gold-500 fill-gold-700 h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="12"
                  viewBox="0 0 384 512"
                >
                  <path d="M32 32C32 14.3 46.3 0 64 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H290.5l11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3H32c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64H64C46.3 64 32 49.7 32 32zM160 384h64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V384z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'message'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'fill-gray-500 h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'report:action'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'fill-gray-500 h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'promoted' || item.type === 'demoted'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                :class="[
                    'fill-gray-500 h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="14"
                  viewBox="0 0 448 512"
                >
                  <path d="M230.1 .8l152 40c8.6 2.3 15.3 9.1 17.3 17.8s-1 17.8-7.8 23.6L368 102.5v8.4c0 10.7-5.3 20.8-15.1 25.2c-24.1 10.8-68.6 24-128.9 24s-104.8-13.2-128.9-24C85.3 131.7 80 121.6 80 110.9v-8.4L56.4 82.2c-6.8-5.8-9.8-14.9-7.8-23.6s8.7-15.6 17.3-17.8l152-40c4-1.1 8.2-1.1 12.2 0zM227 48.6c-1.9-.8-4-.8-5.9 0L189 61.4c-3 1.2-5 4.2-5 7.4c0 17.2 7 46.1 36.9 58.6c2 .8 4.2 .8 6.2 0C257 114.9 264 86 264 68.8c0-3.3-2-6.2-5-7.4L227 48.6zM98.1 168.8c39.1 15 81.5 23.2 125.9 23.2s86.8-8.2 125.9-23.2c1.4 7.5 2.1 15.3 2.1 23.2c0 70.7-57.3 128-128 128s-128-57.3-128-128c0-7.9 .7-15.7 2.1-23.2zM134.4 352c2.8 0 5.5 .9 7.7 2.6l72.3 54.2c5.7 4.3 13.5 4.3 19.2 0l72.3-54.2c2.2-1.7 4.9-2.6 7.7-2.6C387.8 352 448 412.2 448 486.4c0 14.1-11.5 25.6-25.6 25.6H25.6C11.5 512 0 500.5 0 486.4C0 412.2 60.2 352 134.4 352zM352 408c-3.5 0-6.5 2.2-7.6 5.5L339 430.2l-17.4 0c-3.5 0-6.6 2.2-7.6 5.5s.1 6.9 2.9 9L331 454.8l-5.4 16.6c-1.1 3.3 .1 6.9 2.9 9s6.6 2 9.4 0L352 470.1l14.1 10.3c2.8 2 6.6 2.1 9.4 0s4-5.7 2.9-9L373 454.8l14.1-10.2c2.8-2 4-5.7 2.9-9s-4.2-5.5-7.6-5.5l-17.4 0-5.4-16.6c-1.1-3.3-4.1-5.5-7.6-5.5z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'postnote' || item.type === 'membernote'">
          <div>
            <div class="relative px-1">
              <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950">
                <svg
                  :class="[
                    'fill-gray-500 h-3 w-3'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="14"
                  viewBox="0 0 448 512"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H288V368c0-26.5 21.5-48 48-48H448V96c0-35.3-28.7-64-64-64H64zM448 352H402.7 336c-8.8 0-16 7.2-16 16v66.7V480l32-32 64-64 32-32z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm dark:text-gray-400">
              <router-link class="underline hover:decoration-2" :to="`/${item.username}`">{{item.username}}</router-link>
              {{ ' ' }}
              <span v-if="item.referenceURL">
                {{ removePost(item.activityString) }}
                <router-link :to="item.referenceURL" class="underline hover:decoration-2">{{ getTitle(item.activityString) }}</router-link>
              </span>
              <span v-else>{{ item.activityString }}</span>
              {{ ' ' }}
              <time :title="D(item.createdAt).format('LLLL')" class="whitespace-nowrap text-gray-400 dark:text-gray-500">{{ D(item.createdAt).locale('submit').fromNow() }} ago</time>
            </div>
          </div>
        </template>
      </div>
    </div>
  </li>
</template>

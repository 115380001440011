<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeUnmount, watch } from "vue"
import type { FormContext } from "@/components/input/Form.vue"
import { addWeeks, addMonths, setDate } from "date-fns"

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  persist: {
    type: Boolean,
    default: false
  },
  locked: {
    type: Boolean,
    default: false
  },
  step: {
    type: Number,
    default: 1
  },
  alt: {
    type: Boolean,
    default: false
  }
})

const relevantFields = [
  'firstOccurence',
  'startTime',
  'endTime',
  'recurringFrequency',
  'recurringCount',
  'endsAfterMidnight'
]

const formContext = inject<FormContext>('formContext');
if (!formContext) {
  throw new Error(`Date: ${props.name} must be used within a Form component.`)
}

const { registerField, focusField, blurField, fields, unregisterField, errors } = formContext
const generatedDates = ref<any>([])
const value = ref<any>([])

registerField(props.name, "recurringDays", value, props.persist, props.locked, props.step, undefined, true)

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

watchEffect(() => {
  error.value = errors.value[props.name]
})

// const firstOccurenceDay = computed(() => {
//   if (!metadata.value.firstOccurence) return null
//   const date = new Date(metadata.value.firstOccurence)
//   const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
//   return days[date.getDay()]
// })

const generateDates = () => {
  if (
    !fields.value.get('firstOccurence') ||
    !fields.value.get('startTime') ||
    !fields.value.get('endTime') ||
    !fields.value.get('recurringFrequency') ||
    !fields.value.get('recurringCount') ||
    !fields.value.get('endsAfterMidnight')
  ) {
    return []
  }

  if (
    !fields.value.get('firstOccurence')?.value ||
    !fields.value.get('startTime')?.value ||
    !fields.value.get('endTime')?.value ||
    !fields.value.get('recurringFrequency')?.value ||
    !fields.value.get('recurringCount')?.value
  ) {
    return []
  }

  // get metadata from the form
  const firstOccurence = fields.value.get('firstOccurence')?.value as any
  const startTime = fields.value.get('startTime')?.value as any
  const endTime = fields.value.get('endTime')?.value as any
  const recurringFrequency = fields.value.get("recurringFrequency")?.value as any
  const recurringCount = fields.value.get("recurringCount")?.value as any
  const endsAfterMidnight = fields.value.get("endsAfterMidnight")?.value as any

  if (!firstOccurence || !recurringFrequency || !recurringCount || !startTime || !endTime) {
    return []
  }

  const dates = []
  let currentDate = new Date(firstOccurence)
  currentDate.setHours(0, 0, 0, 0)

  for (let i = 0; i < recurringCount; i++) {
    dates.push({
      date: new Date(currentDate),
      startTime:  startTime,
      endTime:  endTime,
      late: endsAfterMidnight
    })

    switch (recurringFrequency) {
      case 'weekly':
        currentDate = addWeeks(currentDate, 1)
        break
      case 'bi-weekly':
        currentDate = addWeeks(currentDate, 2)
        break
      case 'monthly':
        currentDate = addMonths(currentDate, 1)
        break
      case 'first':
      case 'second':
      case 'third':
      case 'fourth':
        currentDate = addMonths(currentDate, 1)
        const weekNumber = ['first', 'second', 'third', 'fourth'].indexOf(recurringFrequency) + 1
        currentDate = setDate(currentDate, (weekNumber - 1) * 7 + 1)
        while (currentDate.getDay() !== new Date(firstOccurence).getDay()) {
          currentDate.setDate(currentDate.getDate() + 1)
        }
        break
      case 'last':
        currentDate = addMonths(currentDate, 1)
        currentDate.setMonth(currentDate.getMonth() + 1, 0) // Set to last day of the month
        while (currentDate.getDay() !== new Date(firstOccurence).getDay()) {
          currentDate.setDate(currentDate.getDate() - 1)
        }
        break
    }
  }

  value.value = dates
  return dates
}

watch(
  () => relevantFields.map(field => fields.value.get(field)?.value),
  () => {
    generatedDates.value = generateDates()
  },
  { immediate: true, deep: true }
)
</script>

<template>
  <div v-if="generatedDates.length > 0">
    <div class="text-sm">
      <h3 class="font-bold mb-1">This event will be created on the following days:</h3>
      <ul class="block">
        <li class="inline" v-for="(date, index) in generatedDates" :key="index">
          {{ date.date.toLocaleDateString() }}{{ index < generatedDates.length - 1 ? ', ' : '' }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, onBeforeMount, type Ref } from "vue"
import API from "@/api/api"

const Group = inject("Group") as Ref<any>
const { isModerator } = inject("isModerator") as { isModerator: Ref<boolean> }

const working = ref(false)
const newMessageWebsite = ref(false)
const newMessageEmail = ref(false)
const messageRepliesWebsite = ref(false)
const messageRepliesEmail = ref(false)
const newReportsEmail = ref(false)
const newReportsWebsite = ref(false)
const membershipRequestsWebsite = ref(false)
const membershipRequestsEmail = ref(false)
const postRequestsWebsite = ref(false)
const postRequestsEmail = ref(false)
const error = ref(false)
const success = ref(false)

onBeforeMount(() => {
  if (!Group.value.notificationSettings) return
  newMessageWebsite.value = Group.value.notificationSettings.messages.includes('platform')
  newMessageEmail.value = Group.value.notificationSettings.messages.includes('email')
  messageRepliesWebsite.value = Group.value.notificationSettings.messageReplies.includes('platform')
  messageRepliesEmail.value = Group.value.notificationSettings.messageReplies.includes('email')
  newReportsWebsite.value = Group.value.notificationSettings.reports.includes('platform')
  newReportsEmail.value = Group.value.notificationSettings.reports.includes('email')
  membershipRequestsWebsite.value = Group.value.notificationSettings.memberQueue.includes('platform')
  membershipRequestsEmail.value = Group.value.notificationSettings.memberQueue.includes('email')
  postRequestsWebsite.value = Group.value.notificationSettings.postQueue.includes('platform')
  postRequestsEmail.value = Group.value.notificationSettings.postQueue.includes('email')
})

async function saveNotifications() {
  if (working.value) return
  working.value = true

  const payload = {
    notifications: {
      messages: <string[]>[],
      messageReplies: <string[]>[],
      reports: <string[]>[],
      memberQueue: <string[]>[],
      postQueue: <string[]>[]
    }
  }

  if (newMessageWebsite.value) {
    payload.notifications.messages.push('platform')
  }
  if (newMessageEmail.value) {
    payload.notifications.messages.push('email')
  }
  if (messageRepliesWebsite.value) {
    payload.notifications.messageReplies.push('platform')
  }
  if (messageRepliesEmail.value) {
    payload.notifications.messageReplies.push('email')
  }
  if (newReportsWebsite.value) {
    payload.notifications.reports.push('platform')
  }
  if (newReportsEmail.value) {
    payload.notifications.reports.push('email')
  }
  if (membershipRequestsWebsite.value) {
    payload.notifications.memberQueue.push('platform')
  }
  if (membershipRequestsEmail.value) {
    payload.notifications.memberQueue.push('email')
  }
  if (postRequestsWebsite.value) {
    payload.notifications.postQueue.push('platform')
  }
  if (postRequestsEmail.value) {
    payload.notifications.postQueue.push('email')
  }

  try {
    await API().put(`/groups/g/${Group.value.slug}/members/${Group.value.memberId}`, payload)
    working.value = false
    success.value = true
    Group.value.notificationSettings = payload.notifications
    setTimeout(() => {
      success.value = false
    }, 5000)
  } catch (err) {
    console.error(err)
    working.value = false
    error.value = true
    setTimeout(() => {
      error.value = false
    }, 5000)
  }
}
</script>

<template>
  <main class="space-y-6">
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Notifications</h2>
      <p>Configure and manage how and what notifications {{ Group.name }} sends you.</p>
    </section>
    <section class="space-y-6" v-if="isModerator">
      <div class="grid grid-cols-3 gap-x-4">
        <div class="text-sm font-bold text-gray-500 dark:text-gray-400 mb-2"></div>
        <div class="text-sm font-bold text-gray-500 dark:text-gray-400 mb-2">Website</div>
        <div class="text-sm font-bold text-gray-500 dark:text-gray-400 mb-2">Email</div>
        <div class="col-span-3 border-b border-submit-500 mb-2 font-bold text-sm pb-1">Moderation</div>
        <div class="mb-2">New Messages</div>
        <div class="mb-2">
          <input v-model="newMessageWebsite" id="newMessageWebsite" name="newMessageWebsite" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
        <div class="mb-2">
          <input v-model="newMessageEmail" id="newMessageEmail" name="newMessageEmail" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
        <div class="mb-2">Message Replies</div>
        <div class="mb-2">
          <input v-model="messageRepliesWebsite" id="messageRepliesWebsite" name="messageRepliesWebsite" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
        <div class="mb-2">
          <input v-model="messageRepliesEmail" id="messageRepliesEmail" name="messageRepliesEmail" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
        <div class="mb-2">New Reports</div>
        <div class="mb-2">
          <input v-model="newReportsWebsite" id="newReportsWebsite" name="newReportsWebsite" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
        <div class="mb-2">
          <input v-model="newReportsEmail" id="newReportsEmail" name="newReportsEmail" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
        <div class="mb-2">Membership Requests</div>
        <div class="mb-2">
          <input v-model="membershipRequestsWebsite" id="membershipRequestsWebsite" name="membershipRequestsWebsite" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
        <div class="mb-2">
          <input v-model="membershipRequestsEmail" id="membershipRequestsEmail" name="membershipRequestsEmail" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
        <div class="mb-2">Post Requests</div>
        <div class="mb-2">
          <input v-model="postRequestsWebsite" id="postRequestsWebsite" name="postRequestsWebsite" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
        <div class="mb-2">
          <input v-model="postRequestsEmail" id="postRequestsEmail" name="postRequestsEmail" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
      </div>
      <div>
        <div>
          <button @click="saveNotifications" type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5" :disabled="working">{{ working ? 'Processing...' : 'Save Notification Settings' }}</button>
        </div>
      </div>
      <div v-if="error" class="text-red-500 font-bold">
        <p>There was an error saving your changes.</p>
      </div>
      <div v-if="success" class="text-green-500 font-bold">
        <p>Your changes were saved successfully.</p>
      </div>
    </section>
    <section v-if="!isModerator">
      <p class="text-sm font-bold">You do not yet have any notification settings available.</p>
    </section>
  </main>
</template>

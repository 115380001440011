<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import { ref, onBeforeMount } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const UserStore = useUserStore()
const { id, notifications } = storeToRefs(UserStore)

const working = ref(false)
const newComments = ref(false)
const newCommentReplies = ref(false)
const newMessageThreads = ref(false)
const messageThreadReplies = ref(false)
const relationshipRequests = ref(false)
const inactivityReminders = ref(false)
const mentions = ref(false)
const error = ref(false)
const success = ref(false)

onBeforeMount(() => {
  newComments.value = notifications.value.newComments ? notifications.value.newComments.includes('email') : true
  newCommentReplies.value = notifications.value.newCommentReplies ? notifications.value.newCommentReplies.includes('email') : true
  newMessageThreads.value = notifications.value.newMessageThreads.includes('email')
  messageThreadReplies.value = notifications.value.messageThreadReplies.includes('email')
  relationshipRequests.value = notifications.value.relationshipRequests ? notifications.value.relationshipRequests.includes('email') : true
  inactivityReminders.value = notifications.value.inactivityReminders ? notifications.value.inactivityReminders.includes('email') : true
  mentions.value = notifications.value.mentions ? notifications.value.mentions.includes('email') : true
})

async function saveNotifications() {
  if (working.value) return
  working.value = true

  try {
    await API().patch(`/users/${id.value}`, {
      "notifications.newComments": [newComments.value ? 'email' : 'none'],
      "notifications.newCommentReplies": [newCommentReplies.value ? 'email' : 'none'],
      "notifications.newMessageThreads": [newMessageThreads.value ? 'email' : 'none'],
      "notifications.messageThreadReplies": [messageThreadReplies.value ? 'email' : 'none'],
      "notifications.relationshipRequests": [relationshipRequests.value ? 'email' : 'none'],
      "notifications.inactivityReminders": [inactivityReminders.value ? 'email' : 'none'],
      "notifications.mentions": [mentions.value ? 'email' : 'none']
    })
    working.value = false
    success.value = true
    UserStore.updateNotifications({
      newComments: [newComments.value ? 'email' : 'none'],
      newCommentReplies: [newCommentReplies.value ? 'email' : 'none'],
      newMessageThreads: [newMessageThreads.value ? 'email' : 'none'],
      messageThreadReplies: [messageThreadReplies.value ? 'email' : 'none'],
      relationshipRequests: [relationshipRequests.value ? 'email' : 'none'],
      inactivityReminders: [inactivityReminders.value ? 'email' : 'none'],
      mentions: [mentions.value ? 'email' : 'none']
    })
    setTimeout(() => {
      success.value = false
    }, 5000)
  } catch (err) {
    console.error(err)
    working.value = false
    error.value = true
    setTimeout(() => {
      error.value = false
    }, 5000)
  }
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Notifications</h2>
        <p>Configure and manage how and what notifications Submit sends you.</p>
        <div class="grid grid-cols-2 gap-x-4">
          <div class="text-sm font-bold text-gray-500 dark:text-gray-400 mb-2"></div>
          <div class="text-sm font-bold text-gray-500 dark:text-gray-400 mb-2">Email</div>
          <div class="mb-2">New Comments</div>
          <div class="mb-2">
            <input v-model="newComments" id="newComments" name="newComments" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
          </div>
          <div class="mb-2">Comment Replies</div>
          <div class="mb-2">
            <input v-model="newCommentReplies" id="newCommentReplies" name="newCommentReplies" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
          </div>
          <div class="mb-2">Mentions</div>
          <div class="mb-2">
            <input v-model="mentions" id="mentions" name="mentions" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
          </div>
          <div class="mb-2">New Direct Messages</div>
          <div class="mb-2">
            <input v-model="newMessageThreads" id="newMessageThreads" name="newMessageThreads" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
          </div>
          <div class="mb-2">Direct Message Replies</div>
          <div class="mb-2">
            <input v-model="messageThreadReplies" id="messageThreadReplies" name="messageThreadReplies" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
          </div>
          <div class="mb-2">Relationship Requests</div>
          <div class="mb-2">
            <input v-model="relationshipRequests" id="relationshipRequests" name="relationshipRequests" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
          </div>
          <div class="mb-2">Inactivity Reminders</div>
          <div class="mb-2">
            <input v-model="inactivityReminders" id="inactivityReminders" name="inactivityReminders" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
          </div>
        </div>
        <div>
          <button @click="saveNotifications" type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5" :disabled="working">{{ working ? 'Processing...' : 'Save Notification Settings' }}</button>
        </div>
        <div v-if="error" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="success" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>
      </div>
    </section>
  </main>
</template>

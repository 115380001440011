<script setup lang="ts">
import { ref, inject, reactive, computed, type Ref } from "vue"
import Checkbox from "@/components/input/Checkbox.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const UserStore = useUserStore()
const { id: userId } = storeToRefs(UserStore)

const removeSchema = zod.object({
  reason: zod.string()
    .optional(),
  notify: zod.boolean()
    .optional()
})

const banSchema = zod.object({
  reason: zod.string()
    .min(3, { message: "This is required." }),
  alsoBlockForUser: zod.boolean()
    .optional(),
  privateNotes: zod.string()
    .optional()
})

const unbanSchema = zod.object({
  reason: zod.string()
    .optional(),
  privateNotes: zod.string()
    .optional(),
  notify: zod.boolean()
    .optional()
})

const removeInitialValues = reactive({
  notify: true
})

const banInitialValues = reactive({
  alsoBlockForUser: false
})

interface Props {
  user: any
}

const props = defineProps<Props>()
const emits = defineEmits(['removed'])

const short = inject<any>("short") as Ref<string>
const isOwner = inject<any>("isOwner") as Ref<boolean>
const filterType = inject<any>("filterType") as Ref<string>

const userIsOwner = ref(props.user.isOwner)
const userIsOrganizer = ref(props.user.isOrganizer)

const working = ref(false)
const modalOpen = ref<boolean>(false)
const action = ref<string>("")

const error = ref(false)
const errorCode = ref("general")
const errorMessage = ref("There was an error processing your request, please try again.")

const isSelf = computed(() => {
  return userId.value === props.user._id
})

function toggleModal(setAction: string) {
  if (setAction !== "close") {
    action.value = setAction
  }
  modalOpen.value = !modalOpen.value
}

async function addOrRemoveOrganizer() {
  if (working.value) return
  working.value = true

  let path = `/events/${short.value}/organizers`

  try {
    await API().post(path, {
      user: props.user._id
    })
    if (userIsOrganizer.value) {
      userIsOrganizer.value = false
    } else {
      userIsOrganizer.value = true
    }
    toggleModal("close")
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    return
  }
}

async function removeUser(values: any) {
  if (working.value) return
  working.value = true

  let path = `/events/${short.value}/remove`

  try {
    await API().post(path, {
      user: props.user._id,
      reason: values.reason && values.reason.length > 0 ? values.reason : undefined,
      notify: values.notify
    })
    toggleModal("close")
    working.value = false
    emits("removed", props.user._id)
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    return
  }
}

async function banUser(values: any) {
  if (working.value) return
  working.value = true

  let path = `/events/${short.value}/ban`

  try {
    await API().post(path, {
      user: props.user._id,
      reason: values.reason && values.reason.length > 0 ? values.reason : undefined,
      alsoBlockForUser: values.alsoBlockForUser,
      privateNotes: values.privateNotes && values.privateNotes.length > 0 ? values.privateNotes : undefined
    })
    toggleModal("close")
    working.value = false
    emits("removed", props.user._id)
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    return
  }
}

async function unbanUser(values: any) {
  if (working.value) return
  working.value = true

  let path = `/events/${short.value}/unban`

  try {
    await API().post(path, {
      user: props.user._id,
      notify: values.notify,
      reason: values.reason && values.reason.length > 0 ? values.reason : undefined,
      privateNotes: values.privateNotes && values.privateNotes.length > 0 ? values.privateNotes : undefined
    })
    toggleModal("close")
    working.value = false
    emits("removed", props.user._id)
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    return
  }
}

function handleSubmit(values: any) {
  if (action.value === 'ban') {
    banUser(values)
  } else if (action.value === 'unban') {
    unbanUser(values)
  } else {
    removeUser(values)
  }
}

function determineSchema() {
  if (action.value === 'ban') {
    return banSchema
  }
  if (action.value === 'unban') {
    return unbanSchema
  }
  return removeSchema
}

function determineReasonHelpText() {
  if (action.value === 'ban') {
    return "This reason will be visible to the user."
  }
  if (action.value === 'unban') {
    return "This reason will be visible to the user if they are notified of the unban."
  }
  return "This reason will be visible to the user if they are notified of the removal."
}
</script>

<template>
  <tr class="text-sm">
    <td class="py-2">
      <router-link class="hover:underline font-semibold" :to="`/${user.username}`">
        {{ user.username }}
      </router-link>
    </td>
    <td v-if="filterType === 'banned'">
      {{ user.reason ? user.reason : "No reason provided" }}
    </td>
    <td v-if="filterType === 'banned'">
      {{ user.privateNotes ? user.privateNotes : "No notes provided" }}
    </td>
    <td v-if="!userIsOwner && (userIsOrganizer && !userIsOwner && !isSelf)" class="flex py-2 gap-4 justify-end">
      <button type="button" @click="toggleModal('organizer')" class="font-semibold py-2 px-4 rounded-md dark:bg-submit-700 bg-gray-300 hover:bg-white dark:hover:bg-submit-500" v-if="!userIsOrganizer && isOwner && filterType === 'rsvp'">Add Organizer</button>
      <button type="button" @click="toggleModal('organizer')" class="font-semibold py-2 px-4 rounded-md dark:bg-submit-700 bg-gray-300 hover:bg-white dark:hover:bg-submit-500" v-if="userIsOrganizer && isOwner && filterType === 'rsvp'">Remove Organizer</button>
      <button type="button" @click="toggleModal('remove')" class="font-semibold py-2 px-4 rounded-md dark:bg-yellow-800 bg-yellow-600 hover:bg-yellow-500 dark:hover:bg-yellow-700" v-if="filterType !== 'banned'">Remove</button>
      <button type="button" @click="toggleModal('ban')" class="font-semibold py-2 px-4 rounded-md dark:bg-red-800 bg-red-600 hover:bg-red-500 dark:hover:bg-red-700" v-if="filterType !== 'banned'">Ban</button>
      <button type="button" @click="toggleModal('unban')" class="font-semibold py-2 px-4 rounded-md dark:bg-red-800 bg-red-600 hover:bg-red-500 dark:hover:bg-red-700" v-if="filterType === 'banned'">Unban</button>
    </td>
    <td class="text-right py-2" v-else>
      <button v-if="!isSelf" class="font-semibold py-2 px-4 rounded-md dark:bg-submit-700 bg-gray-300 text-gray-600 dark:text-gray-500" disabled>No actions available</button>
      <button v-else class="font-semibold py-2 px-4 rounded-md dark:bg-submit-700 bg-gray-300 text-gray-600 dark:text-gray-500" disabled>You</button>
    </td>
    <TransitionRoot appear :show="modalOpen" as="template">
      <Dialog as="div" @close="toggleModal('close')" class="relative z-[100]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-xl font-bold leading-6 text-black dark:text-white mb-4"
                >
                  {{ action === "ban" ? `Ban ${user.username}` : action === "organizer" ? `${userIsOrganizer ? "Remove" : "Add"} ${user.username} as an Organizer` : action === "unban" ? `Unban ${user.username}` : `Remove ${user.username}` }}
                </DialogTitle>
                <div class="flex flex-col mt-2 space-y-4">
                  <p class="text-sm" v-if="action === 'ban'">Ban this user. They will be unable see or interact with this event. If they RSVPd or indicated interest, they will be notified.</p>
                  <p class="text-sm" v-if="action === 'unban'">Unban this user. They will be able to see and interact with this event, you can choose to notify them of the unban.</p>
                  <p class="text-sm" v-if="action === 'remove'">Remove this user from the event. They will be able to RSVP or indicate interest in the future. You should notify them of the removal, but you can choose not to.</p>
                  <p class="text-sm" v-if="action === 'organizer'">Are you sure you want to {{ userIsOrganizer ? `remove ${user.username} from this event's organizers?` : `add ${user.username} as an organizer?` }}</p>
                  <Form
                    :schema="determineSchema()"
                    @on-submit="handleSubmit"
                    :initial-values="action === 'ban' ? banInitialValues : removeInitialValues"
                    class="space-y-6"
                    v-if="action !== 'organizer'"
                  >
                    <div>
                      <Label field="reason">Reason</Label>
                      <Field name="reason" type="textarea" :help-text="determineReasonHelpText()" alt />
                    </div>
                    <div v-if="action === 'ban' || action === 'unban'">
                      <Label field="privateNotes">Private Notes</Label>
                      <Field name="privateNotes" type="textarea" :help-text="`These notes will not be visible to the user. They are only be visible to you and other organizers.`" alt />
                    </div>
                    <div v-if="action === 'ban'">
                      <Checkbox name="alsoBlockForUser">
                        Also add this user to your blocklist
                      </Checkbox>
                    </div>
                    <div v-if="action === 'remove' || action === 'unban'">
                      <Checkbox name="notify">
                        Notify user of the {{ action === 'remove' ? 'removal' : action === 'unban' ? 'unban' : action }}
                      </Checkbox>
                    </div>
                    <div class="flex justify-end gap-4">
                      <button type="button" @click="toggleModal('close')" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Cancel</button>
                      <button type="submit" class="inline-flex justify-center py-2 px-8 shadow-sm text-sm font-bold rounded-md text-black bg-gold-700 dark:bg-gold-500 focus:outline-indigo-950" :disabled="working">{{ working ? 'Processing...' : action === "ban" ? "Ban User" : action === "unban" ? "Unban User" : "Remove User" }}</button>
                    </div>
                    <div v-if="error">
                      <div class="text-red-500 text-sm font-semibold mt-3">
                        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                      </div>
                    </div>
                  </Form>
                  <div v-else>
                    <div class="flex justify-end gap-4">
                      <button type="button" @click="toggleModal('close')" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Cancel</button>
                      <button type="button" @click="addOrRemoveOrganizer" class="inline-flex justify-center py-2 px-8 shadow-sm text-sm font-bold rounded-md text-black bg-gold-700 dark:bg-gold-500 focus:outline-indigo-950">{{ userIsOrganizer ? "Remove" : "Add" }} Organizer</button>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </tr>
</template>

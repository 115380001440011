<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import API from "@/api/api"

interface Option {
  value: string
  text: string
}

interface Props {
  name: string
  options?: Option[]
  apiEndpoint: string
  modelValue?: string
  initialValue?: string
  helpText?: string
  disabled?: boolean
  method?: string
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  method: 'PATCH',
  helpText: ''
})
const emit = defineEmits(['update:modelValue', 'update'])

const internalValue = ref(props.initialValue ?? '')
const currentValue = computed({
  get: () => props.modelValue ?? internalValue.value,
  set: (value: string) => {
    emit('update:modelValue', value)
    internalValue.value = value
  }
})

const success = ref(false)
const errorMessage = ref('')

// function to prepare data, check if string is "true" or "false" and convert to boolean
const prepareData = (value: string) => {
  if (value === "true") {
    return true
  } else if (value === "false") {
    return false
  } else {
    return value
  }
}

const sendData = async () => {
  try {
    const dataObj = JSON.stringify({
      [props.name]: prepareData(currentValue.value)
    })

    const response = await API().request({
      headers: { 'Content-Type': 'application/json' },
      url: props.apiEndpoint,
      method: props.method,
      data: dataObj,
      withCredentials: true
    })

    success.value = true
    errorMessage.value = ''
    setTimeout(() => {
      success.value = false
    }, 5000)
  } catch (error: any) {
    success.value = false
    errorMessage.value = error.message
    setTimeout(() => {
      errorMessage.value = ''
    }, 5000)
  }
}

watch(
  () => currentValue.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      sendData()
      emit('update')
    }
  }
)
</script>

<template>
<div>
  <div class="flex items-center gap-x-4">
    <div class="flex-grow">
      <select :name="name" v-model="currentValue" :class="`w-full p-4 rounded-md text-sm bg-white dark:bg-submit-900 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50 transition-all`" autocomplete="off" :disabled="disabled">
        <option v-for="(option, index) in options" :key="index" :value="option.value">{{ option.text }}</option>
      </select>
    </div>
    <div v-if="errorMessage">
      <svg class="fill-red-500 w-6 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm97.9-320l-17 17-47 47 47 47 17 17L320 353.9l-17-17-47-47-47 47-17 17L158.1 320l17-17 47-47-47-47-17-17L192 158.1l17 17 47 47 47-47 17-17L353.9 192z"/></svg>
    </div>
    <div v-if="success">
      <svg class="fill-green-500 w-6 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337l-17 17-17-17-64-64-17-17L160 222.1l17 17 47 47L335 175l17-17L385.9 192l-17 17z"/></svg>
    </div>
  </div>
  <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
</div>
</template>

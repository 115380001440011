<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { inject, computed } from "vue"
import { storeToRefs } from "pinia"

const md = 2032639

const profileData: any = inject("profileData")
const UserStore = useUserStore()
const { roles } = storeToRefs(UserStore)

const isAdmin = computed(() => roles.value.includes("admin"))
const standingLabel = computed(() => {
  if (md === profileData.value.moderation) {
    return "All Good"
  } else {
    return "Limited"
  }
})
</script>

<template>
  <div v-if="!isAdmin" class="mt-24 row-start-3 text-xs text-gray-500 dark:text-gray-400 row-end-3 md:row-start-auto md:row-end-auto md:col-span-12 flex flex-col justify-center text-center">
    <span class="font-bold block">Public Key:</span>
    <div class="break-all">{{ profileData.publicKey }}</div>
    <span class="font-bold block mt-4">Account Standing:</span>
    <div class="break-all">{{ standingLabel }}</div>
  </div>
  <div v-else class="mt-24 row-start-3 text-xs text-gray-500 dark:text-gray-400 row-end-3 md:row-start-auto md:row-end-auto md:col-span-12 flex flex-col justify-center text-center">
    <span class="font-bold block">Public Key:</span>
    <div class="break-all">{{ profileData.publicKey }}</div>
    <span class="font-bold block mt-4">Last IP:</span>
    <div class="break-all">{{ profileData.lastIp }}</div>
    <span class="font-bold block mt-4">Last Sign In:</span>
    <div class="break-all">{{ D(profileData.lastSignIn).format('LLLL') }}</div>
  </div>
</template>

<script setup lang="ts">
</script>

<template>
  <main class="space-y-6">
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Group Profile</h2>
      <p>If you're a backer, set a custom profile photo and summary for display in this group. This functionality will be enabled shortly.</p>
    </section>
  </main>
</template>

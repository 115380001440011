<script setup lang="ts">
import Checkbox from "@/components/input/Checkbox.vue"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useRouter } from "vue-router"
import { ref, reactive, } from "vue"
import API from "@/api/api"
import * as zod from "zod"

const Router = useRouter()

const schema = zod.object({
  title: zod.string()
    .min(3, { message: "Minimum 3 characters." }),
  summary: zod.string()
    .optional(),
  type: zod.string()
    .min(1, { message: "This is required." }),
  comments: zod.boolean()
    .optional(),
  reactions: zod.boolean()
    .optional(),
  featured: zod.boolean()
    .optional()
    .default(false),
  featuredType: zod.string()
    .optional(),
  featuredIcon: zod.string()
    .optional(),
  featuredTitle: zod.string()
    .optional(),
  featuredSummary: zod.string()
    .optional(),
  html: zod.string()
    .min(3, { message: "Minimum 3 characters." })
}).superRefine((data, ctx) => {
  if (!data.featured) return;

  if (!data.featuredType) {
    ctx.addIssue({
      code: zod.ZodIssueCode.custom,
      message: "Featured type is required when featured is enabled",
      path: ["featuredType"]
    });
  }

  if (!data.featuredIcon) {
    ctx.addIssue({
      code: zod.ZodIssueCode.custom,
      message: "Featured icon is required when featured is enabled",
      path: ["featuredIcon"]
    });
  }

  if (!data.featuredTitle) {
    ctx.addIssue({
      code: zod.ZodIssueCode.custom,
      message: "Featured title is required when featured is enabled",
      path: ["featuredTitle"]
    });
  }

  if (!data.featuredSummary) {
    ctx.addIssue({
      code: zod.ZodIssueCode.custom,
      message: "Featured summary is required when featured is enabled",
      path: ["featuredSummary"]
    });
  }
})

interface Props {
  announcement?: any
}

const props = defineProps<Props>()

const initialValues = reactive({
  title: props.announcement ? props.announcement.title : "",
  summary: props.announcement ? (props.announcement.summary ? props.announcement.summary : "") : "",
  type: props.announcement ? props.announcement.type : "",
  comments: props.announcement ? props.announcement.comments : true,
  reactions: props.announcement ? props.announcement.reactions : true,
  featured: props.announcement ? props.announcement.featured : false,
  featuredType: props.announcement ? props.announcement.featuredType : "",
  featuredIcon: props.announcement ? props.announcement.featuredIcon : "",
  featuredTitle: props.announcement ? props.announcement.featuredTitle : "",
  featuredSummary: props.announcement ? props.announcement.featuredSummary : "",
  html: props.announcement ? props.announcement.html : ""
})

const debug = ref(false)
const working = ref(false)
const error = ref(false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  let body: any = { announcement: {
    title: values.title,
    html: values.html,
    type: values.type,
    status: "published",
    comments: values.comments,
    reactions: values.reactions,
    featured: values.featured
  } }

  if (values.summary.length > 3) {
    body.announcement.summary = values.summary
  }

  if (values.featured) {
    body.announcement.featuredType = values.featuredType
    body.announcement.featuredIcon = values.featuredIcon
    body.announcement.featuredTitle = values.featuredTitle
    body.announcement.featuredSummary = values.featuredSummary
  }

  try {
    if (props.announcement) {
      await API().put(`/announcements/${props.announcement._id}`, body)
    } else {
      await API().post(`/announcements`, body)
    }
    Router.push("/announcements")
  } catch (err) {
    error.value = true
    errorMessage.value = "Your announcement failed to publish."
    working.value = false
  }
}
</script>

<template>
  <div class="grid grid-cols-1 content-start space-y-6">
    <header>
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">{{ announcement ? 'Edit' : 'New' }} Announcement</h2>
    </header>
    <Form @on-submit="handleSubmit" class="grid grid-cols-1 md:grid-cols-2 gap-3" :initial-values="initialValues" :schema="schema" v-slot="{fields, tools, errors}">
      <div class="col-span-full">
        <Label field="title">Title</Label>
        <Field name="title" />
      </div>
      <div class="col-span-full">
        <Label field="summary">Summary</Label>
        <Field name="summary" type="textarea" rows="3" help-text="This field is optional, but recommended." />
      </div>
      <div>
        <Label field="type">Type</Label>
        <Field name="type" type="select">
          <option value="">Select a Type</option>
          <option value="release">Release</option>
          <option value="update">Update</option>
          <option value="maintenance">Maintenance</option>
        </Field>
      </div>
      <div>
        <Label field="">Options</Label>
        <div class="grid grid-cols-2">
          <div>
            <Checkbox name="comments">Comments</Checkbox>
            <Checkbox name="reactions">Reactions</Checkbox>
          </div>
          <div>
            <Checkbox name="featured">Featured</Checkbox>
          </div>
        </div>
      </div>
      <div v-if="tools.getFieldValue('featured')">
        <Label field="featuredType">Featured Type</Label>
        <Field name="featuredType" type="select">
          <option value="">Select a Type</option>
          <option value="icon">Icon</option>
          <option value="image" disabled>Image</option>
        </Field>
      </div>
      <div v-if="tools.getFieldValue('featured')">
        <Label field="featuredIcon">Featured Icon</Label>
        <Field name="featuredIcon" type="select">
          <option value="">Select an Icon</option>
          <option value="calendar">Calendar</option>
          <option value="celebrate">Celebrate</option>
          <option value="launch">Launch</option>
          <option value="alert">Alert</option>
        </Field>
      </div>
      <div class="col-span-full" v-if="tools.getFieldValue('featured')">
        <Label field="featuredTitle">Featured Title</Label>
        <Field name="featuredTitle" />
      </div>
      <div class="col-span-full" v-if="tools.getFieldValue('featured')">
        <Label field="featuredSummary">Featured Summary</Label>
        <Field name="featuredSummary" type="textarea" rows="3" />
      </div>
      <div class="col-span-full">
        <Editor name="html" />
      </div>
      <div class="flex gap-3">
        <button @click="" type="button" class="text-sm font-bold px-4 py-1.5 dark:text-gray-400 text-gray-500">Cancel</button>
        <button type="submit" class="bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 text-sm font-bold" :disabled="working">{{ working ? "Publishing..." : (announcement ? 'Edit Announcement' : 'Publish Announcement') }}</button>
      </div>

      <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200 col-span-full" v-if="error">
        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
      </div>

      <section class="col-span-full" v-if="debug">
        <pre class="text-sm">
{{ fields }}
        </pre>
        <pre class="text-sm">
{{ errors }}
        </pre>
      </section>
    </Form>
  </div>
</template>

<script setup lang="ts">
import RTNStatus from "@/components/misc/RTNStatus.vue"
import LocaleSwitcher from "./LocaleSwitcher.vue"
import ThemeSwitcher from "./ThemeSwitcher.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"

const userStore = useUserStore()
const { signedIn } = storeToRefs(userStore)

const d = new Date()
const year = d.getFullYear()
</script>

<template>
  <div class="px-2 self-end">
    <footer class="container grid grid-cols-1 sm:grid-cols-2 grid-rows-2 gap-y-10 pt-10 pb-4 border-t border-t-gray-700 text-gray-500 dark:text-gray-400 items-center mx-auto">
      <div class="hidden sm:flex gap-2 items-center">
        <router-link to="/">
        <svg class="dark:fill-gold-500 fill-gold-700" width="32" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
          <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
          <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
        </svg>
        </router-link>
        <router-link to="/">
          <h1 class="text-indigo-950 dark:text-neutral-100 font-bold font-display text-lg">Submit</h1>
        </router-link>
      </div>
      <ul class="flex flex-wrap text-xs whitespace-nowrap items-center gap-6 font-semibold list-none justify-center sm:justify-end capitalize">
        <li>
          <router-link class="hover:underline hover:text-black dark:hover:text-gray-300 capitalize" to="/about">{{ $t("about") }}</router-link>
        </li>
        <li>
          <a class="hover:underline hover:text-black dark:hover:text-gray-300" href="https://blog.submit.gg">{{ $t("blog") }}</a>
        </li>
        <li>
          <a class="hover:underline hover:text-black dark:hover:text-gray-300 capitalize" href="https://careers.submit.gg">{{ $t("careers") }}</a>
        </li>
        <li>
          <a class="hover:underline hover:text-black dark:hover:text-gray-300 capitalize" href="https://docs.submit.gg">{{ $t("guidelines") }}</a>
        </li>
        <li>
          <a class="hover:underline hover:text-black dark:hover:text-gray-300 capitalize" href="https://docs.submit.gg/privacy">{{ $t("privacy") }}</a>
        </li>
        <li>
          <a class="hover:underline hover:text-black dark:hover:text-gray-300 capitalize" href="https://docs.submit.gg/terms">{{ $t("terms") }}</a>
        </li>
        <li>
          <router-link class="hover:underline hover:text-black dark:hover:text-gray-300 capitalize" to="/transparency">{{ $t("transparency") }}</router-link>
        </li>
        <li>
          <router-link class="hover:underline hover:text-black dark:hover:text-gray-300 capitalize" to="/contact">{{ $t("contact") }}</router-link>
        </li>
      </ul>
      <div class="flex flex-wrap gap-4 text-center xs:text-left text-xs items-center justify-center sm:justify-start">
        <span>&copy; {{year}} Submit, All Rights Reserved.</span>
        <ThemeSwitcher />
        <LocaleSwitcher />
        <RTNStatus v-if="signedIn" />
        <a class="hover:underline hover:text-black dark:hover:text-gray-300 capitalize" target="_blank" href="https://status.submit.gg">{{ $t("status") }}</a>
      </div>
      <div class="flex items-center justify-center sm:justify-end">
        <ul class="flex gap-6 list-none items-center">
          <li>
            <a title="Patreon" href="https://patreon.com/submitgg" target="_blank">
              <svg role="img" aria-describedby="patreonTitle" class="dark:fill-gray-400 fill-gray-500 hover:fill-black dark:hover:fill-white hover:cursor-pointer h-[14px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <title id="patreonTitle">Patreon</title>
                <path d="M512 194.8c0 101.3-82.4 183.8-183.8 183.8-101.7 0-184.4-82.4-184.4-183.8 0-101.6 82.7-184.3 184.4-184.3C429.6 10.5 512 93.2 512 194.8zM0 501.5h90v-491H0v491z"/>
              </svg>
            </a>
          </li>
          <li>
            <a title="X" href="https://x.com/submitgg" target="_blank">
              <svg role="img" aria-describedby="xTitle" class="fill-gray-500 dark:fill-gray-400 hover:fill-black dark:hover:fill-white hover:cursor-pointer h-[15px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <title id="xTitle">X/Twitter</title>
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
              </svg>
            </a>
          </li>
          <li>
            <a title="Bluesky" href="https://bsky.app/profile/submit.gg" target="_blank">
              <svg role="img" aria-describedby="blueskyTitle" class="fill-gray-500 dark:fill-gray-400 hover:fill-black dark:hover:fill-white hover:cursor-pointer h-[14px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <title id="blueskyTitle">Bluesky</title>
                <path d="M407.8 294.7c-3.3-.4-6.7-.8-10-1.3c3.4 .4 6.7 .9 10 1.3zM288 227.1C261.9 176.4 190.9 81.9 124.9 35.3C61.6-9.4 37.5-1.7 21.6 5.5C3.3 13.8 0 41.9 0 58.4S9.1 194 15 213.9c19.5 65.7 89.1 87.9 153.2 80.7c3.3-.5 6.6-.9 10-1.4c-3.3 .5-6.6 1-10 1.4C74.3 308.6-9.1 342.8 100.3 464.5C220.6 589.1 265.1 437.8 288 361.1c22.9 76.7 49.2 222.5 185.6 103.4c102.4-103.4 28.1-156-65.8-169.9c-3.3-.4-6.7-.8-10-1.3c3.4 .4 6.7 .9 10 1.3c64.1 7.1 133.6-15.1 153.2-80.7C566.9 194 576 75 576 58.4s-3.3-44.7-21.6-52.9c-15.8-7.1-40-14.9-103.2 29.8C385.1 81.9 314.1 176.4 288 227.1z"/>
              </svg>
            </a>
          </li>
          <li>
            <a title="Discord" href="https://discord.gg/N7nFueSy4H" target="_blank">
              <svg role="img" aria-describedby="discordTitle" class="fill-gray-500 dark:fill-gray-400 hover:fill-black dark:hover:fill-white hover:cursor-pointer h-[14px]" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title id="discordTitle">Discord</title>
                <g clip-path="url(#clip0_1203_34)">
                <path d="M18.6239 1.49267C17.2217 0.859859 15.7181 0.393635 14.1459 0.126608C14.1173 0.121455 14.0887 0.134334 14.0739 0.160092C13.8805 0.498381 13.6663 0.939707 13.5163 1.28659C11.8254 1.0376 10.1431 1.0376 8.48679 1.28659C8.33676 0.931997 8.11478 0.498381 7.92053 0.160092C7.90578 0.135193 7.87718 0.122314 7.84855 0.126608C6.27725 0.392781 4.7736 0.859006 3.37052 1.49267C3.35838 1.49782 3.34797 1.50641 3.34106 1.51757C0.488942 5.70847 -0.292371 9.79636 0.0909151 13.8336C0.0926494 13.8533 0.103922 13.8722 0.119532 13.8842C2.00127 15.2434 3.82406 16.0685 5.61301 16.6155C5.64164 16.6241 5.67197 16.6138 5.69019 16.5906C6.11337 16.0222 6.49059 15.4229 6.81402 14.7926C6.83311 14.7557 6.81489 14.7119 6.77588 14.6973C6.17754 14.4741 5.6078 14.2019 5.05975 13.8928C5.0164 13.8679 5.01293 13.8069 5.05281 13.7777C5.16814 13.6927 5.2835 13.6043 5.39363 13.515C5.41355 13.4987 5.44131 13.4953 5.46474 13.5056C9.06518 15.1224 12.9631 15.1224 16.521 13.5056C16.5445 13.4944 16.5722 13.4978 16.593 13.5142C16.7032 13.6034 16.8185 13.6927 16.9347 13.7777C16.9746 13.8069 16.972 13.8679 16.9286 13.8928C16.3806 14.2079 15.8108 14.4741 15.2116 14.6965C15.1726 14.7111 15.1553 14.7557 15.1744 14.7926C15.5047 15.422 15.882 16.0213 16.2973 16.5897C16.3147 16.6138 16.3459 16.6241 16.3745 16.6155C18.1721 16.0685 19.9949 15.2434 21.8766 13.8842C21.8931 13.8722 21.9035 13.8542 21.9053 13.8344C22.364 9.16697 21.1369 5.1126 18.6525 1.51842C18.6465 1.50641 18.6361 1.49782 18.6239 1.49267ZM7.35169 11.3753C6.26771 11.3753 5.37454 10.3965 5.37454 9.19446C5.37454 7.99239 6.25039 7.01358 7.35169 7.01358C8.46163 7.01358 9.34616 8.00098 9.32881 9.19446C9.32881 10.3965 8.45296 11.3753 7.35169 11.3753ZM14.6619 11.3753C13.5779 11.3753 12.6847 10.3965 12.6847 9.19446C12.6847 7.99239 13.5606 7.01358 14.6619 7.01358C15.7718 7.01358 16.6563 8.00098 16.639 9.19446C16.639 10.3965 15.7718 11.3753 14.6619 11.3753Z"/>
                </g>
                <defs>
                <clipPath id="clip0_1203_34">
                <rect width="22" height="16.7619" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import type { Field as FieldType } from "@/components/input/Form.vue"
import { ref, computed, watch, onMounted, reactive } from "vue"
import FileUploader from "@/components/input/File.vue"
import Checkbox from "@/components/input/Checkbox.vue"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import Tags from "@/components/input/Tags.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

interface Props {
  maxSize?: number
  accept?: string
  collection?: string
}

const props = withDefaults(defineProps<Props>(), {
  maxSize: 10,
  accept: "image/*",
  collection: "none"
})
const emit = defineEmits(["uploaded"])

const UserStore = useUserStore()
const { defaultVisibility, circles } = storeToRefs(UserStore)

const schema = zod.object({
  permission: zod.boolean(),
  meme: zod.boolean(),
  caption: zod.string().optional(),
  tags: zod.array(zod.string()),
  file: zod.instanceof(File, { message: "An image is required" })
    .refine(
      (file) => file.size <= props.maxSize * 1024 * 1024,
      { message: `Image must be smaller than ${props.maxSize}MB` }
    )
    .refine(
      (file) => {
        const allowedTypes = props.accept.split(',').map(t => t.trim())
        return allowedTypes.some(type => {
          // Handle wildcards like "image/*"
          if (type.endsWith('/*')) {
            const baseType = type.split('/')[0]
            return file.type.startsWith(`${baseType}/`)
          }
          return file.type === type
        })
      },
      { message: `File must be an image` }
    ),
  collection: zod.string().optional(),
  visibility: zod.string()
})

const editorOptions = {
  bold: true,
  italic: true,
  strike: true,
  link: true,
  mentions: true
}

const initialValues = reactive({
  permission: false,
  meme: false,
  caption: "",
  collection: props.collection === "none" ? "none" : props.collection,
  visibility: defaultAudience(defaultVisibility.value),
  tags: []
})

const validateForm = (fields: Map<string, FieldType>, errors: Record<string, string | null>, validateOnSubmit = false) => {
  const permissionField = fields.get('permission')
  const memeField = fields.get('meme')

  // Only validate if either field has been touched OR if we're validating on submit
  if (!validateOnSubmit && !permissionField?.touched && !memeField?.touched) {
    return
  }

  const permission = permissionField?.value
  const meme = memeField?.value

  if (permission && meme) {
    errors.meme = "Only one of these can be selected"
  } else if (!permission && !meme) {
    errors.meme = "You must select one of these"
  } else {
    errors.meme = null
  }
}

const debug = ref(false)
const working = ref(false)

const raw = ref("")

const fetchedCollections = ref(false)
const collections = ref<any[]>([])
const uploaderOpen = ref(false)
const error = ref(false)

const formSlot = ref<any>(null)

onMounted(() => {
  // check query string for new=status
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.get('new') === 'media') {
    toggleUploaderOpen()
  }
})

function audienceDisplay(audience: string) {
  if (audience === "public") return "Everyone"
  if (audience === "followers") return "Followers"
  if (audience === "relationships") return "Relationships"
  // if audience isn't any of the above, check circles and return the matching name
  const circle = circles.value.find((circle: any) => circle.code === audience)
  if (circle) return circle.name
}

function defaultAudience(audience: string) {
  if (audience === "public") return "public"
  if (audience === "followers") return "followers"
  if (audience === "relationships") return "relationships"
  return "public"
}

const isVisibilityLocked = computed(() => {
  const collection = formSlot.value?.tools?.getFieldValue('collection') ?? 'none'
  return collection !== 'none'
})

const visibilityLockedValue = computed(() => {
  const collection = formSlot.value?.tools?.getFieldValue('collection') ?? 'none'
  if (collection === 'none') return 'public'

  const selectedCollection = collections.value.find((c) => c.slug === collection)
  return selectedCollection?.visibility || 'public'
})

const visibilityLockedDisplay = computed(() => {
  return isVisibilityLocked.value ? audienceDisplay(visibilityLockedValue.value) : 'Everyone'
})

const collectionLocked = computed(() => {
  return props.collection !== "none"
})

const collectionLockedValue = computed(() => {
  return props.collection
})

const collectionLockedDisplay = computed(() => {
  return collectionLocked.value ? collections.value.find((c) => c.slug === props.collection).title : 'None'
})


const toggleUploaderOpen = async () => {
  if (!uploaderOpen.value) {
    if (!fetchedCollections.value) {
      try {
        const response = await API().get(`/collections?type=media`)
        collections.value = response.data.data
      } catch (err: any) {
        console.error(err)
      }
      fetchedCollections.value = true
    }
  }
  uploaderOpen.value = !uploaderOpen.value
}

function updateRaw(newRaw: string) {
  raw.value = newRaw.trim()
}

function imageSize(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight })
    }
    img.onerror = reject
    img.src = url
  })
}

function getImageData(file: File): Promise<any> {
  return new Promise((resolve) => {
    const blob = URL.createObjectURL(file)
    const reader = new FileReader()
    reader.onload = async () => {
      const imageDimensions = await imageSize(blob)
      resolve({
        name: file.name,
        size: file.size,
        src: blob,
        type: file.type,
        raw: file,
        width: imageDimensions.width,
        height: imageDimensions.height
      })
    }
    reader.readAsArrayBuffer(file)
  })
}

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  let formData = new FormData()

  if (values.file) {
    try {
      const imageData = await getImageData(values.file)
      formData.append('file', imageData.raw)
      formData.append('width', imageData.width || 0)
      formData.append('height', imageData.height || 0)
      formData.append('type', 'photo')
    } catch (error) {
      console.error('Error processing media:', error)
      working.value = false
      return
    }
  }

  formData.append("visibility", values.visibility)
  formData.append('raw', raw.value)

  if (raw.value.length > 0) formData.append("description", values.caption)
  formData.append("tags", values.tags.length > 0 ? values.tags.join(',') : '')
  if (values.collection !== "none") {
    const collectionData = collections.value.find((c) => c.slug === values.collection)
    if (collectionData) {
      formData.append("collection", collectionData._id)
    } else {
      return
    }
  }

  try {
    const response = await API().post(`/media`, formData)

    const { data } = response.data

    if (values.collection !== "none") {
      const collectionData = collections.value.find((c) => c.slug === values.collection)
      if (collectionData) {
        emit("uploaded", {
          _id: data._id,
          url: data.thumb,
          collection: collectionData.slug,
          collectionOnly: collectionData.collectionOnly
        })
      }
    } else {
      emit("uploaded", {
        _id: data._id,
        url: data.thumb,
        collection: "default"
      })
    }

    working.value = false
    toggleUploaderOpen()
  } catch (err: any) {
    console.error(err)
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <div @click="toggleUploaderOpen">
    <slot></slot>
    <TransitionRoot as="template" :show="uploaderOpen">
      <Dialog as="div" class="relative z-[100]" @close="toggleUploaderOpen">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                  <div class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl">
                    <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                      <div class="flex flex-grow flex-col">
                        <DialogTitle class="text-base font-semibold leading-6 dark:text-white">Upload</DialogTitle>
                        <p class="text-sm">Add new media.</p>
                      </div>
                      <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="toggleUploaderOpen">
                        <span class="sr-only">Close panel</span>
                        <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                      </button>
                    </div>
                    <Form
                      @on-submit="handleSubmit"
                      :schema="schema"
                      :initial-values="initialValues"
                      v-slot="slot"
                      :custom-validation="validateForm"
                    >
                      <template v-if="formSlot = slot">
                        <section class="flex flex-col mt-10 flex-1 px-4 sm:px-6 space-y-6">
                          <FileUploader name="file" accept="image/*" type="photo" />
                          <div class="space-y-3">
                            <Checkbox name="permission">
                              This media is a photo of me or taken by me and I have the rights to post it. If the photo contains persons other than myself, I have their permission to post it.
                            </Checkbox>
                            <Checkbox name="meme">
                              This media is a meme. I created it, have permission to post it, or it is in the public domain.
                            </Checkbox>
                          </div>
                          <div>
                            <Label field="caption">Caption</Label>
                            <Editor name="caption" :options="editorOptions" help-text="Optional, but recommended." @raw="updateRaw" />
                          </div>
                          <div>
                            <Label field="collection">Collection</Label>
                            <Field
                              type="select"
                              name="collection"
                              :locked="collectionLocked"
                              :locked-value="collectionLockedValue"
                              :locked-display="collectionLockedDisplay"
                              :help-text="collectionLocked ? `This is locked to the current collection.` : ''"
                            >
                              <optgroup label="No Collection">
                                <option value="none">None</option>
                              </optgroup>
                              <optgroup label="Your Collections" v-if="collections.length > 0">
                                <option :value="obj.slug" v-for="obj in collections" :key="obj.slug">{{obj.title}}</option>
                              </optgroup>
                            </Field>
                          </div>
                          <div>
                            <Label field="visibility">Audience</Label>
                            <Field
                              type="select"
                              name="visibility"
                              :locked="isVisibilityLocked"
                              :locked-value="visibilityLockedValue"
                              :locked-display="visibilityLockedDisplay"
                              :help-text="isVisibilityLocked ? `This is locked to the selected collection's visibility.` : ''"
                            >
                              <option value="public">Everyone</option>
                              <option value="followers">Followers</option>
                              <option value="relationships">Relationships</option>
                              <optgroup label="Circles" v-if="circles.length > 0">
                                <option v-for="circle in circles" :key="circle._id" :value="circle.code">{{ circle.name }}</option>
                              </optgroup>
                            </Field>
                          </div>
                          <div>
                            <Label field="tags">Tags</Label>
                            <Tags name="tags" :help-text="`Optional, but recommended. Max 6. Hit enter or comma to add a tag.`" />
                          </div>
                        </section>
                        <div class="flex border-t dark:border-t-gray-700 border-t-black mt-6 pt-6 px-4 sm:px-6">
                          <div class="flex justify-end w-full gap-4">
                            <button type="button" @click="toggleUploaderOpen" class="py-2 text-sm px-4">Cancel</button>
                            <button type="submit" :class="`py-2 px-4 text-sm border border-transparent rounded-md font-semibold bg-gold-700 dark:bg-gold-500 text-black`" :disabled="working">{{ working ? 'Uploading...' : 'Upload' }}</button>
                          </div>
                        </div>
                        <div v-if="debug">
                          <pre>
{{ formSlot.fields }}
                          </pre>
                          <pre>
{{ formSlot.errors }}
                          </pre>
                        </div>
                      </template>
                    </Form>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, inject, onMounted, type Ref } from "vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import Checkbox from "../input/Checkbox.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const UserStore = useUserStore()
const { id, username } = storeToRefs(UserStore)

const schema = zod.object({
  bottomUserNotOnSubmit: zod.boolean().optional(),
  username: zod.string().min(3, { message: "3 characters is the required minimum for a username." }).optional()
}).superRefine((data, ctx) => {
  if (!data.bottomUserNotOnSubmit && !data.username) {
    ctx.addIssue({
      code: zod.ZodIssueCode.custom,
      message: "Username is required if the person is on Submit.",
      path: ["username"]
    })
  }
})

const initialValues = {
  bottomUserNotOnSubmit: false,
  username: "",
}

const modalOpen = inject("modalOpen") as Ref<boolean>
const toggleModal = inject("toggleModal") as () => void

const emits = defineEmits(["added"])

const debug = ref(false)

const initialLoadComplete = ref(false)
const working = ref(false)
const error = ref(false)
const errorDetails = ref<any>(undefined)
const relationshipTypes = ref<any>([])
const relationshipType = ref("")

const relationshipTypesValues = computed(() => {
  return relationshipTypes.value.map((type: any) => {
    return {
      label: type.name,
      value: type._id
    }
  })
})

const toggleAndClean = () => {
  toggleModal()
  setTimeout(() => {
    error.value = false
    errorDetails.value = undefined
    relationshipType.value = ""
  }, 1000)
}

const selectedRelationshipType = computed(() => {
  return relationshipTypes.value.find((type: any) => type._id === relationshipType.value)
})

function needsUsername(fields: any) {
  // get the value of bottomUserNotOnSubmit from the fields map
  const bottomUserNotOnSubmit = fields.get("bottomUserNotOnSubmit")
  if (bottomUserNotOnSubmit) {
    return bottomUserNotOnSubmit.value ? false : true
  }
  return true
}

onMounted(async () => {
  await getRelationshipTypes()
  initialLoadComplete.value = true
})

async function getRelationshipTypes() {
  try {
    const types = await API().get("/relationships/types")
    relationshipTypes.value = types.data.data
  } catch (err) {
    console.error(err)
  }
}

async function handleCreateRelationship(values?: any) {
  if (working.value) return
  working.value = true

  if (!relationshipType.value) {
    working.value = false
    return
  }

  const data: any = {
    relationshipType: relationshipType.value,
    topUser: {
      user: id.value,
      username: username.value
    }
  }

  if (values && values.bottomUserNotOnSubmit) {
    data.bottomUserNotOnSubmit = true
  } else if (values && values.username) {
    data.bottomUsers = [values.username]
  }

  const path = `/relationships`
  try {
    await API().post(path, data)
    working.value = false
    emits("added")
    toggleAndClean()
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorDetails.value = response.data
      }
    } else {
      console.error(err)
    }
    error.value = true
    working.value = false
  }
}

</script>

<template>
  <TransitionRoot as="template" :show="modalOpen">
    <Dialog as="div" class="relative z-[100]" @close="toggleAndClean">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl">
                  <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                    <div class="flex flex-grow flex-col">
                      <DialogTitle class="text-base font-semibold leading-6 dark:text-white">Add Relationship</DialogTitle>
                    </div>
                    <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="toggleAndClean">
                      <span class="sr-only">Close panel</span>
                      <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                    </button>
                  </div>
                  <div class="flex flex-col mt-10 flex-1 px-4 sm:px-6 justify-start space-y-3" v-if="modalOpen">
                    <div>
                      <Label field="relationshipType">Relationship</Label>
                      <select class="w-full p-4 text-sm rounded-md bg-white dark:bg-submit-900 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" v-model="relationshipType">
                        <option value="">Select One</option>
                        <optgroup label="Romantic">
                          <option v-for="type in relationshipTypesValues" :key="type.value" :value="type.value">{{ type.label }}</option>
                        </optgroup>
                      </select>
                    </div>
                    <Form @on-submit="handleCreateRelationship" v-if="relationshipType && (selectedRelationshipType.mono || selectedRelationshipType.poly)" :schema="schema" :initial-values="initialValues" v-slot="{ fields, errors }" class="space-y-3">
                      <div class=mt-3>
                        <Checkbox name="bottomUserNotOnSubmit">The Person I'm in a relationship with is not on Submit</Checkbox>
                      </div>
                      <div v-if="needsUsername(fields)">
                        <Label field="username">Username</Label>
                        <Field name="username" help-text="The username of the user you're in a relationship with." />
                      </div>
                      <div v-if="needsUsername(fields)">
                        <p class="text-sm">Note: You can only request a relationship with users that you are following and that are following you.</p>
                      </div>
                      <div>
                        <button class="w-full mt-3 bg-gold-700 dark:bg-gold-500 dark:text-black p-4 rounded-md text-sm font-semibold" type="submit" :disabled="working">{{ working ? 'Processing...' : 'Add Relationship' }}</button>
                      </div>
                      <div v-if="debug">
                        <pre>
{{ fields }}
                        </pre>
                      </div>
                      <div v-if="debug">
                        <pre>
{{ errors }}
                        </pre>
                      </div>
                    </Form>
                    <div v-else>
                      <p class="text-sm mt-3" v-if="!relationshipType">Select a relationship above to get started.</p>
                      <div class="space-y-6" v-else>
                        <p class="text-sm mt-3">Relationship has no additional options.</p>
                        <button @click="handleCreateRelationship" class="w-full bg-gold-700 dark:bg-gold-500 dark:text-black p-4 rounded-md text-sm font-semibold" type="submit" :disabled="working">{{ working ? 'Processing...' : 'Add Relationship' }}</button>
                      </div>
                    </div>
                    <div class="mt-3" v-if="error">
                      <div class="bg-red-800 p-4 rounded-md text-red-100 text-sm font-semibold" v-if="errorDetails">
                        <h3 class="font-bold text-white">Error</h3>
                        <p>{{ errorDetails.description }}</p>
                      </div>
                      <div class="bg-red-800 p-4 rounded-md text-red-100 text-sm font-semibold" v-else>
                        <p>There was an error trying to create your relationship, please try again.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref, inject, type Ref } from "vue"
import D from "@/composables/TimeDisplay"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"


interface Props {
  request: any
}

const props = defineProps<Props>()
const emits = defineEmits(['handled'])

const short = inject<Ref<string>>("short") as Ref<string>

const working = ref(false)
const error = ref(false)
const modalOpen = ref<boolean>(false)
const reason = ref<string>("")
const rejectedUser = ref("")

function toggleModal() {
  if (modalOpen.value) {
    error.value = false
    reason.value = ""
    rejectedUser.value = ""
  }
  modalOpen.value = !modalOpen.value
}

async function approveUser() {
  if (working.value) return
  working.value = true

  let path = `/events/${short.value}/request`

  try {
    await API().post(path, {
      requestingUser: props.request.user._id,
      action: "approve"
    })
    working.value = false
    emits("handled", props.request._id)
  } catch (err) {
    error.value = true
    working.value = false
    return
  }
}

async function rejectUser() {
  if (working.value) return
  working.value = true

  const user = rejectedUser.value

  let path = `/events/${short.value}/request`

  try {
    await API().post(path, {
      requestingUser: props.request.user._id,
      action: "decline",
      reason: reason.value
    })
    toggleModal()
    working.value = false
    emits("handled", props.request._id)
  } catch (err) {
    error.value = true
    working.value = false
    return
  }
}
</script>

<template>
  <li class="grid grid-cols-3 gap-4 items-center text-sm">
    <router-link :to="`/${request.user.username}`" class="flex gap-2 items-center">
      <figure class="flex w-12 h-12 bg-submit-950 text-gray-500 rounded-full items-center justify-center font-semibold bg-cover" :style="request.user.pfp ? `background-image: url('${request.user.pfp}')` : ''">
        {{ request.user.pfp ? '' : request.user.firstLetter }}
      </figure>
      <div class="flex flex-col">
        <h3 class="font-bold">{{ request.user.username }}</h3>
        <span class="text-xs">{{ request.user.meta }}</span>
      </div>
    </router-link>
    <div>
      {{ D(request.createdAt).format('LLL') }}
    </div>
    <div class="flex gap-4 justify-end text-sm">
      <button type="button" @click="approveUser" class="text-green-500 font-bold dark:hover:bg-submit-800 py-2 px-4 rounded-md">Accept</button>
      <button type="button" @click="toggleModal" class="text-red-500 font-bold dark:hover:bg-submit-800 py-2 px-4 rounded-md">Decline</button>
    </div>
    <TransitionRoot appear :show="modalOpen" as="template">
      <Dialog as="div" @close="toggleModal()" class="relative z-[100]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-xl font-bold leading-6 text-black dark:text-white mb-4"
                >
                  Reject RSVP Request
                </DialogTitle>
                <div class="flex flex-col mt-2 space-y-4">
                  <p class="text-sm">Are you sure you want to decline {{ request.user.username }}'s RSVP request?</p>
                  <div>
                    <label for="reason" class="text-sm block font-bold mb-1">Reason</label>
                    <textarea v-model="reason" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                    <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> Reason is shared with the user.</p>
                  </div>
                  <div class="flex self-end">
                    <button
                      type="button"
                      @click="toggleModal"
                      class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      :disabled="working"
                      @click="rejectUser"
                    >
                      {{ working ? 'Working...' : 'Reject' }}
                    </button>
                  </div>
                  <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                    <p>There was an error processing your request, please try again.</p>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </li>
</template>

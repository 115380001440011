<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import { ref, computed } from "vue"

const context = ref("public")

const UserStore = useUserStore()
const { username, pfp, firstLetter } = storeToRefs(UserStore)

const props = defineProps({
  payload: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
})

function metaDiscriminator(): string {
  const metaArray = [
    {
      // age
      privacy: props.payload.agePrivacy,
      detail: props.payload.age
    },
    {
      // role
      privacy: props.payload.rolesPrivacy,
      detail: props.payload.meta.roles[0] || false
    },
    {
      // pronouns
      privacy: props.payload.pronounsPrivacy,
      detail: props.payload.meta.pronoun || false
    }
  ]
  const metaStringArray: any[] = []
  metaArray.forEach(item => {
    switch(item.privacy) {
      case "public":
        if (context.value === "public" || context.value === "followers" || context.value === "relationships" || context.value === "private") {
          if (item.detail) {
            metaStringArray.push(item.detail)
          }
        }
        break
      case "followers":
        if (context.value === "followers" || context.value === "relationships" || context.value === "private") {
          if (item.detail) {
            metaStringArray.push(item.detail)
          }
        }
        break
      case "relationships":
        if (context.value === "relationships" || context.value === "private") {
          if (item.detail) {
            metaStringArray.push(item.detail)
          }
        }
        break
      case "private":
        if (context.value === "private") {
          if (item.detail) {
            metaStringArray.push(item.detail)
          }
        }
        break
      default:
        break
    }
  })
  if(metaStringArray.length > 0) {
    if (metaStringArray.length === 1) {
      return Number.isInteger(metaStringArray[0]) ? `${metaStringArray[0]}, ${props.payload.detailsLabel}` : metaStringArray[0]
    }
    return metaStringArray.join(", ")
  } else {
    return props.payload.detailsLabel
  }
}

function locationDiscriminator(): string {
  if (props.payload.location.version && props.payload.location.version === 2) {
    if (typeof props.payload.location.country === "object" && props.payload.location.country.name === "United States") {
      props.payload.location.country.name = "USA"
    }
  } else {
    if (props.payload.location.country === "United States") {
      props.payload.location.country = "USA"
    }
  }

  const locationArray = [
    {
      privacy: props.payload.cityPrivacy,
      location: props.payload.location.city
    },
    {
      privacy: props.payload.statePrivacy,
      location: props.payload.location.state ? props.payload.location.state : false
    },
    {
      privacy: props.payload.countryPrivacy,
      location: props.payload.location.country
    }
  ]
  const finalLocationArray: any[] = []
  locationArray.forEach(item => {
    switch(item.privacy) {
      case "public":
        if (context.value === "public" || context.value === "followers" || context.value === "relationships" || context.value === "private") {
          if (item.location) {
            finalLocationArray.push(item.location)
          }
        }
        break
      case "followers":
        if (context.value === "followers" || context.value === "relationships" || context.value === "private") {
          if (item.location) {
            finalLocationArray.push(item.location)
          }
        }
        break
      case "relationships":
        if (context.value === "relationships" || context.value === "private") {
          if (item.location) {
            finalLocationArray.push(item.location)
          }
        }
        break
      case "private":
        if (context.value === "private") {
          if (item.location) {
            finalLocationArray.push(item.location)
          }
        }
        break
      default:
        break
    }
  })
  if(finalLocationArray.length > 0) {
    if (props.payload.location.version && props.payload.location.version === 2) {
      // the array contains objects that have a name property, flatten the array so its just the name property and then join with commas
      return finalLocationArray.map(item => item.name).join(", ")
    } else {
      return finalLocationArray.join(", ")
    }
  } else {
    return props.payload.anonymousLabel
  }
}

const display = computed(() => {
  switch (props.type) {
    case "location":
      return {
        label: "Location",
        value: locationDiscriminator()
      }
    case "details":
      return {
        label: "Details",
        value: metaDiscriminator()
      }
    default:
      return {
        label: "Broken",
        value: "Words"
      }
  }
})
</script>

<template>
  <figure>
    <label class="text-sm font-bold mb-1 block">Profile Preview ({{ display.label }})</label>
    <p class="text-sm text-gray-500 dark:text-gray-400 mb-3">Use the preview to see what your profile display will look like to different types of users.</p>
    <p class="text-sm text-gray-500 dark:text-gray-400 mb-4 inline-flex pr-4">View as:</p>
    <ul class="mb-4 text-sm sm:gap-x-4 grid grid-cols-2 gap-2 sm:inline-flex text-gray-500 dark:text-gray-400">
      <li @click="context = 'public'" :class="`${ context === 'public' ? 'font-bold underline' : ''} hover:underline hover:cursor-pointer`">Public</li>
      <li @click="context = 'followers'" :class="`${ context === 'followers' ? 'font-bold underline' : ''} hover:underline hover:cursor-pointer`">Followers</li>
      <li @click="context = 'relationships'" :class="`${ context === 'relationships' ? 'font-bold underline' : ''} hover:underline hover:cursor-pointer`">Relationships</li>
      <li @click="context = 'private'" :class="`${ context === 'private' ? 'font-bold underline' : ''} hover:underline hover:cursor-pointer`">Only Me</li>
    </ul>
    <div class="flex items-center bg-white dark:bg-submit-900 rounded-md p-4">
      <figure class="flex flex-none w-12 h-12 bg-slate-300 dark:bg-[#707482] rounded-full mr-2 items-center text-2xl justify-center font-semibold bg-cover" :style="pfp ? `background-image: url('${pfp}')` : ''">
        {{ pfp ? '' : firstLetter }}
      </figure>
      <div>
        <h1 class="text-base sm:text-lg font-display font-semibold sm:leading-6">{{ username }}</h1>
        <h2 class="text-xs sm:text-sm">{{ display.value }}</h2>
      </div>
    </div>
  </figure>
</template>

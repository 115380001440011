<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeUnmount, watch, computed } from "vue"
import type { FormContext } from "@/components/input/Form.vue"
import VueDatePicker, { type TimeModel } from "@vuepic/vue-datepicker"

const theme = ref(localStorage.getItem("theme") || "dark")

interface DayObject {
  date: string
  startTime: TimeModel | null
  endTime: TimeModel | null
  late: boolean
}

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  persist: {
    type: Boolean,
    default: false
  },
  locked: {
    type: Boolean,
    default: false
  },
  step: {
    type: Number,
    default: 1
  },
  alt: {
    type: Boolean,
    default: false
  }
})

const formContext = inject<FormContext>('formContext');
if (!formContext) {
  throw new Error(`Date: ${props.name} must be used within a Form component.`)
}

const { registerField, focusField, blurField, fields, unregisterField, errors } = formContext
const value = ref<DayObject[]>([])

const previousDates = ref<Date[]>([])

registerField(props.name, "eventDays", value, props.persist, props.locked, props.step, undefined, true)

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

const startDate = computed(() => {
  const startDateTime = fields.value.get("startDateTime")
  if (startDateTime) {
    return new Date(startDateTime.value as any)
  }
  return null
})

const endDate = computed(() => {
  const endDateTime = fields.value.get("endDateTime")
  if (endDateTime) {
    return new Date(endDateTime.value as any)
  }
  return null
})

function getComputedEndTime(day: DayObject) {
  if (day.late) {
    return { hours: 23, minutes: 59 }
  }
  return day.endTime
}

function handleLateChange(day: DayObject) {
  if (day.late) {
    day.endTime = { hours: 23, minutes: 59 }
  }
}

const duration = computed(() => {
  if (!startDate.value || !endDate.value || !startDate.value.getTime() || !endDate.value.getTime()) return 0
  const start = startDate.value
  const end = endDate.value
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const diffDays = Math.round((end.getTime() - start.getTime()) / oneDay)
  return diffDays + 1 // Include both start and end dates
})

function addDayToValueArray(date: string) {
  const index = value.value.findIndex(day => day.date > date)
  const newDay: DayObject = { date, startTime: null, endTime: null, late: false }
  if (index === -1) {
    value.value.push(newDay)
  } else {
    value.value.splice(index, 0, newDay)
  }
}

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

watchEffect(() => {
  error.value = errors.value[props.name]
})

function generateDateArray(start: Date, end: Date) {
  const dates = []
  let currentDate = new Date(start)
  const endDate = new Date(end)

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dates
}

watch(
  () => [startDate.value, endDate.value],
  ([start, end]) => {
    if (start && end) {
      const newDates = generateDateArray(start, end)
      const newDateStrings = newDates.map(date => date.toISOString().split('T')[0])
      const currentDateStrings = value.value.map(day => day.date)

      // Remove old dates
      value.value = value.value.filter(day => newDateStrings.includes(day.date))

      // Add new dates
      newDateStrings.forEach(dateString => {
        if (!currentDateStrings.includes(dateString)) {
          addDayToValueArray(dateString)
        }
      })

      // Sort the array (just in case)
      value.value.sort((a, b) => a.date.localeCompare(b.date))

      // Update previousDates
      previousDates.value = newDates
    }
  },
  { immediate: true } // This ensures the watch runs immediately on component creation
)

function getDayLabel(day: DayObject) {
  const date = new Date(day.date + 'T00:00:00')
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  return days[date.getDay()]
}
</script>

<template>
  <div class="text-sm grid gap-4">
    <div v-if="duration <= 12">
      You're creating a {{ duration }} day event. {{ duration > 1 ? 'Set the start and end times for each day below.' : '' }}
    </div>
    <div v-else>
      You can only create events that are 12 days or less in duration.
    </div>
    <div class="grid grid-cols-3 gap-x-4 gap-y-1 items-center" v-for="day in value" :key="day.date">
      <div class="text-sm">
        <div class="font-bold">{{ day.date }}</div>
        <div class="text-xs">{{ getDayLabel(day) }}</div>
      </div>
      <div>
        <label class="text-xs font-bold" :for="`${day.date}-startTime`">Start Time</label>
        <VueDatePicker
          v-model="day.startTime"
          :name="`${day.date}-startTime`"
          :id="`${day.date}-startTime`"
          placeholder="HH:mm"
          :input-class-name="alt ? 'dark:bg-submit-950 bg-neutral-125' : 'dark:bg-submit-900 bg-white'"
          hide-input-icon
          :dark="theme === 'dark'"
          auto-apply
          time-picker
          :is24="false"
        />
      </div>
      <div>
        <label class="text-xs font-bold" :for="`${day.date}-endTime`">End Time</label>
        <VueDatePicker
          :name="`${day.date}-endTime`"
          :id="`${day.date}-endTime`"
          placeholder="HH:mm"
          :input-class-name="alt ? 'dark:bg-submit-950 bg-neutral-125' : 'dark:bg-submit-900 bg-white'"
          hide-input-icon
          :dark="theme === 'dark'"
          auto-apply
          time-picker
          :is24="false"
          :disabled="day.late"
          :model-value="getComputedEndTime(day)"
        />
      </div>
      <div class="col-start-3">
        <input
          type="checkbox"
          v-model="day.late"
          :id="`${day.date}-late`"
          :name="`${day.date}-late`"
          class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-700/70 dark:bg-submit-900 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30 focus:border-0 focus:ring-0 focus:ring-offset-0"
          @change="handleLateChange(day)"
        >
        <label class="text-xs font-bold" :for="`${day.date}-late`">Ends after midnight</label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import API from "@/api/api"

const props = defineProps(["filter"])

const initialLoadComplete = ref(false)
const interests = ref<any>({})

const filterTitle = computed(() => {
  return props.filter === "interest" ? "Interests" : "Kinks"
})

onMounted(async () => {
  await loadInterests()
  initialLoadComplete.value = true
})

async function loadInterests() {
  const queryString = props.filter ? `?filter=${props.filter}` : ''
  const response = await API().get(`/interests/all${queryString}`)
  interests.value = response.data.data
}
</script>

<template>
  <div class="content-start">
    <h2 class="text-sm font-bold mb-6">All {{ filterTitle }}</h2>
    <section v-if="initialLoadComplete" class="space-y-6">
      <nav>
        <ul class="flex flex-wrap break-modern">
          <li class="flex-1 text-center text-gray-500 dark:text-gray-400 px-4 md:px-2" v-for="letter in Object.keys(interests)" :key="letter">
            <a class="font-bold hover:dark:text-white hover:text-black" :href="'#' + letter">{{ letter }}</a>
          </li>
        </ul>
      </nav>
      <div v-for="letter in Object.keys(interests)" :key="letter" :id="letter">
        <h2 class="mb-2 font-bold text-xl dark:text-gold-500 text-gold-700">{{ letter }}</h2>
        <ul class="inline break-modern">
          <li class="inline" v-for="(item, index) in interests[letter]" :key="item">
            <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/interests/${item.slug}`">{{ item.name }}</router-link>
            <span v-if="index !== interests[letter].length - 1">, </span>
          </li>
        </ul>
      </div>
    </section>
    <section v-else>
      <div>Loading...</div>
    </section>
  </div>
</template>

<script setup lang="ts">
import type { FormContext } from "@/components/input/Form.vue"
import { inject, ref, watchEffect, onBeforeUnmount, watch } from "vue"

// Define props with modelValue as type 'any' to accept all types of values
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  helpText: {
    type: String,
    default: ''
  },
  persist: {
    type: Boolean,
    default: false
  },
  locked: {
    type: Boolean,
    default: false
  },
  lockedValue: {
    type: Boolean,
    default: false
  },
  step: {
    type: Number,
    default: 1
  }
})

const emit = defineEmits(['changed'])

const formContext = inject<FormContext>('formContext');
if (!formContext) {
  throw new Error(`Checkbox: ${props.name} must be used within a Form component.`)
}

const { registerField, focusField, blurField, unregisterField, errors } = formContext
const value = ref<any>("")

registerField(props.name, "checkbox", value, props.persist, props.locked, props.step)

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

watchEffect(() => {
  if (props.locked) {
    value.value = props.lockedValue
  }
})

watchEffect(() => {
  error.value = errors.value[props.name]
})

watch(value, (newVal) => {
  emit('changed', newVal)
})
</script>

<template>
  <div>
    <div class="flex">
      <div class="flex items-center h-5">
        <input
          v-model="value"
          :id="name"
          :name="name"
          :value="true"
          type="checkbox"
          class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-700/70 dark:bg-submit-900 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30 focus:border-0 focus:ring-0 focus:ring-offset-0"
          :disabled="locked"
          @focus="handleFocus"
          @blur="handleBlur"
        >
      </div>
      <div class="ml-3 text-sm">
        <label :for="name" class="font-medium">
          <slot></slot>
        </label>
      </div>
    </div>
    <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>
  </div>
</template>

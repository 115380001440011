<script setup lang="ts">
import { computed, ref, onBeforeMount, onMounted, inject, type Ref } from "vue"
import Message from "@/components/messages/Message.vue"
import { usePlatformStore } from "@/stores/Platform"
import Editor from "@/components/input/Editor.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { useRoute } from "vue-router"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const Route = useRoute()
const User = useUserStore()
const Platform = usePlatformStore()

const { pfp, firstLetter } = storeToRefs(User)

interface Props {
  group?: boolean
}

const schema = zod.object({
  html: zod.string()
    .min(10, { message: "Too short." })
})

const props = withDefaults(defineProps<Props>(), {
  group: false
})

const debug = ref(false)
const { id: userId } = storeToRefs(User)
const threadId = ref<string>(Route.params.id as string)
const groupId = ref<string | undefined>(undefined)
const groupSlug = ref<string | undefined>(undefined)
const initialLoadComplete = ref(false)
const working = ref(false)
const thread = ref<any>({})
const firstURL = ref("")
const messages = ref<any[]>([])
const error = ref(false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")
const pageInfo = ref<any>({
  endCursor: undefined
})

const canReply = ref(false)
const isGroupMessage = ref(false)

function toggleModal(type: string) {
  if (type !== "close") {
    modalType.value = type
  }
  modalOpen.value = !modalOpen.value
}

const modalOpen = ref(false)
const modalType = ref("")

const revokeSender = ref(false)

const context = computed(() => {
  if (props.group) {
    // check if props.thread.to is a string
    if (typeof thread.value.to === "string") {
      return thread.value.to === groupId.value ? "to" : "from"
    } else {
      return thread.value.to._id === groupId.value ? "to" : "from"
    }
  } else {
    if (typeof thread.value.to === "string") {
      return thread.value.to === userId.value ? "to" : "from"
    } else {
      return thread.value.to._id === userId.value ? "to" : "from"
    }
  }
})

const threadLocation = computed(() => {
  let viewContext = thread.value[`${context.value}Status`]
  if (viewContext === "archived") {
    return "archives"
  } else {
    return "inbox"
  }
})

const firstLabel = computed(() => {
  if (context.value === "to") {
    return "From: "
  } else {
    return "To: "
  }
})

const firstDisplay = computed(() => {
  if (context.value === "to") {
    if (thread.value.fromType === "group") {
      firstURL.value = `/groups/${thread.value.fromGroupSlug}`
      return thread.value.fromGroupName
    } else {
      firstURL.value = `/${thread.value.from.username}`
      return thread.value.from.username
    }
  } else {
    if (thread.value.toType === "group") {
      firstURL.value = `/groups/${thread.value.toGroupSlug}`
      return thread.value.toGroupName
    } else {
      firstURL.value = `/${thread.value.to.username}`
      return thread.value.to.username
    }
  }
})

const sendToID = computed(() => {
  if (context.value === "to") {
    if (thread.value.fromType === "group") {
      return thread.value.from
    } else {
      return thread.value.from._id
    }
  } else {
    if (thread.value.toType === "group") {
      return thread.value.to
    } else {
      return thread.value.to._id
    }
  }
})

const sendToType = computed(() => {
  if (context.value === "to") {
    return thread.value.fromType
  } else {
    return thread.value.toType
  }
})

const hasMessageRequests = computed(() => {
  if (context.value === "to") {
    if (thread.value.fromType === "group") {
      return false
    } else {
      return thread.value.to.hasMessageRequests
    }
  } else {
    if (thread.value.toType === "group") {
      return false
    } else {
      return thread.value.from.hasMessageRequests
    }
  }
})

const firstMessage = computed(() => {
  return messages.value[0]
})

const threadMessages = computed(() => {
  return messages.value.slice(1)
})

onBeforeMount(() => {
  if (props.group) {
    const id = inject("groupId") as Ref<string>
    const slug = inject("slug") as Ref<string>
    groupId.value = id.value
    groupSlug.value = slug.value
  }
})

onMounted(async () => {
  await getMessages(false)
  if (error.value) return
  canReply.value = thread.value.canReply
  isGroupMessage.value = (thread.value.toType === "group" || thread.value.fromType === "group")
  // set override if archived
  if (threadLocation.value === "archives") {
    Platform.setCategoryOverride("archives")
  }
  initialLoadComplete.value = true
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
  if (!thread.value.toRead || !thread.value.fromRead) {
    markAsRead()
    if (props.group) {
      if ((thread.value.to === groupId.value) && !thread.value.toRead) {
        // decrement group counter
      }
      if ((thread.value.from === groupId.value) && !thread.value.fromRead) {
        // decrement group counter
      }
    } else {
      if (thread.value.to._id && (thread.value.to._id === userId.value) && !thread.value.toRead) {
        User.incrementMessages(-1)
      }
      if (thread.value.from._id && (thread.value.from._id === userId.value) && !thread.value.fromRead) {
        User.incrementMessages(-1)
      }
    }
  }
})

async function markAsRead() {
  let path: string

  if (props.group) {
    path = `/groups/g/${groupSlug.value}/inbox/${thread.value._id}/read`
  } else {
    path = `/messages/${thread.value._id}/read`
  }

  try {
    await API().get(path)
  } catch (err) {
    console.error(err)
    return
  }
}

async function revokeMessageConsent() {
  if (working.value) return
  if (props.group) return
  working.value = true

  let path: string = `/messages/${thread.value._id}/revoke`

  let data: any = {}

  if (hasMessageRequests.value) {
    data.revokeSender = revokeSender.value
  }

  try {
    await API().post(path, data)
    canReply.value = false
    toggleModal("close")
    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
    return
  }

  const result = await fetch(path, data)

  if (result.status !== 200) {
    error.value = true
    working.value = false
    return
  }
}

async function archiveThread() {
  if (working.value) return
  working.value = true

  let path: string

  if (props.group) {
    path = `/groups/g/${groupSlug.value}/inbox/${thread.value._id}/archive`
  } else {
    path = `/messages/${thread.value._id}/archive`
  }

  try {
    await API().get(path)
    if (props.group) {
      window.location.href = `/groups/${groupSlug.value}/moderation/messages`
    } else {
      window.location.href = `/messages`
    }
  } catch (err) {
    console.error(err)
    return
  }
}

async function getMessages(setWorking: boolean = true) {
  if (working.value) return
  if (setWorking) working.value = true

  let url: string

  if (props.group) {
    url = `/groups/g/${groupId.value}/inbox/${threadId.value}`
  } else {
    url = `/messages/${threadId.value}`
  }

  if (pageInfo.value.endCursor) {
    url += `?${new URLSearchParams({
      cursor: pageInfo.value.endCursor
    }).toString()}`
  }

  try {
    const response = await API().get(url)
    if (!pageInfo.value.endCursor) {
      messages.value.push(...response.data.data.messages)
      thread.value = response.data.data
    } else {
      messages.value.push(...response.data.data)
    }
    pageInfo.value = response.data.pageInfo
    if (setWorking) working.value = false
  } catch (err) {
    error.value = true
    working.value = false
    return
  }
}

async function sendReply(values: any, resetFields: Function) {
  if (working.value) return
  working.value = true

  const messageData = {
    from: userId.value,
    fromGroup: props.group ? groupId.value : false,
    to: sendToID.value,
    toGroup: sendToType.value === 'group' ?  thread.value.to : false,
    message: values.html,
    createdAt: new Date(),
  }

  const messagePayload = {
    fromGroup: props.group ? groupId.value : false,
    to: sendToID.value,
    toGroup: sendToType.value === 'group' ?  thread.value.to : false,
    message: values.html,
    createdAt: new Date()
  }

  try {
    await API().post(`/messages/${thread.value._id}`, messagePayload)
    messages.value.push(messageData)
    working.value = false
    resetFields()
  } catch (err) {
    error.value = true
    working.value = false
    return
  }
}

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: sendToID.value,
    contentId: thread.value._id,
    contentType: "messageThread"
  })
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getMessages()
      }
    }
  })
}, options)
</script>

<template>
  <div class="grid grid-cols-1 content-start">
    <header class="flex justify-end items-center">
      <ul class="flex gap-x-4 items-center">
        <li v-if="!isGroupMessage && canReply">
          <button @click="toggleModal('revoke')">
            <svg aria-labelledby="revokeID revokeDescID" role="img" class="dark:fill-gray-500 dark:hover:fill-white cursor-pointer w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <title id="revokeID">Revoke Consent</title>
              <desc id="revokeDescID">Revoke your consent for this Message Thread.</desc>
              <path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zM184 184c-13.3 0-24 10.7-24 24s10.7 24 24 24H328c13.3 0 24-10.7 24-24s-10.7-24-24-24H184z"/>
            </svg>
          </button>
          <TransitionRoot appear :show="modalOpen" as="template">
            <Dialog as="div" @close="toggleModal('close')" class="relative z-[100]">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
              </TransitionChild>
              <div class="fixed inset-0 overflow-y-auto">
                <div
                  class="flex min-h-full items-center justify-center p-4 text-center"
                >
                  <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95"
                  >
                    <DialogPanel
                      class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                    >
                      <DialogTitle
                        as="h3"
                        class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                      >
                        <template v-if="modalType === 'revoke'">
                          Revoke Message Consent
                        </template>
                      </DialogTitle>
                      <template v-if="modalType === 'revoke'">
                        <div class="flex flex-col mt-2 space-y-4">
                          <p class="text-sm">Revoke your consent for this message thread. Revoking consent will remove all participants ability to reply to this message thread.</p>
                          <div class="flex" v-if="hasMessageRequests">
                            <div class="flex items-center h-5">
                              <input v-model="revokeSender" id="revokeSender" name="revokeSender" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
                            </div>
                            <div class="ml-3 text-sm">
                              <label for="revokeSender" class="font-medium">Remove approved message request, if one exists</label>
                            </div>
                          </div>
                          <div class="flex self-end">
                            <button
                              type="button"
                              @click="toggleModal('close')"
                              class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                            >
                              Close
                            </button>
                            <button @click="revokeMessageConsent" type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5" :disabled="working">{{ working ? 'Processing...' : 'Revoke Consent' }}</button>
                          </div>
                          <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                            <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                          </div>
                        </div>
                      </template>
                    </DialogPanel>
                  </TransitionChild>
                </div>
              </div>
            </Dialog>
          </TransitionRoot>
        </li>
        <li v-if="initialLoadComplete && threadLocation !== 'archives'">
          <button @click="archiveThread">
            <svg aria-labelledby="archiveID archiveDescID" role="img" class="dark:fill-gray-500 dark:hover:fill-white cursor-pointer w-4 h-4" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
              <title id="archiveID">Archive Thread</title>
              <desc id="archiveDescID">Archive this Message Thread.</desc>
              <path d="M32 32H480c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H32C14.3 128 0 113.7 0 96V64C0 46.3 14.3 32 32 32zm0 128H480V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V160zm128 80c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"/>
            </svg>
          </button>
        </li>
        <li>
          <button @click="openReportDialog">
            <svg aria-labelledby="reportID reportDescID" role="img" class="dark:fill-gray-500 dark:hover:fill-white cursor-pointer w-4 h-4" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
              <title id="reportID">Report Thread</title>
              <desc id="reportDescID">Reoprt this Message Thread to Submit moderation.</desc>
              <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
            </svg>
          </button>
        </li>
      </ul>
    </header>
    <main class="mt-4" v-if="initialLoadComplete">
      <section class="space-y-6 divide-y dark:divide-gray-800">
        <article class="thread">
          <figure class="flex items-center justify-center bg-white dark:bg-submit-900 rounded-full bg-cover w-10 h-10" :style="thread.fromType !== 'group' && thread.from.pfp ? `background-image: url('${thread.from.pfp}')` : ''">
            <div v-if="!thread.from.pfp" class="flex items-center justify-center w-full h-full font-semibold text-black dark:text-gray-400 select-none">
              {{ thread.fromType === 'group' ? 'G' : thread.from.firstLetter }}
            </div>
          </figure>
          <main>
            <header class="flex justify-between items-baseline">
              <h1 class="text-2xl">{{ thread.subject }}</h1>
              <time class="text-sm dark:text-gray-500" :title="D(thread.createdAt).format('LLLL')">{{ D(thread.createdAt).fromNow() }}</time>
            </header>
            <div class="text-sm dark:text-gray-500">{{ firstLabel }}<a :href="firstURL">{{ firstDisplay }}</a></div>
            <section class="mt-6 CommonBody" v-html="firstMessage.message"></section>
          </main>
        </article>
        <Message v-for="msg in threadMessages" :message="msg" :key="msg._id" :thread="thread" />
      </section>
    </main>
    <main class="mt-4 text-sm" v-else>
      Loading...
    </main>
    <div id="loadMore"></div>
    <section class="mt-12 thread" v-if="initialLoadComplete && thread.canReply">
      <figure class="flex flex-none rounded-full bg-cover bg-white dark:bg-submit-900 w-10 h-10" :style="pfp && !group ? `background-image: url('${pfp}')` : ''">
        <span v-if="!pfp && !group" class="flex items-center justify-center w-full h-full font-semibold text-black dark:text-gray-400">{{ firstLetter }}</span>
        <span v-if="group" class="flex items-center justify-center w-full h-full font-semibold text-black dark:text-gray-400">G</span>
      </figure>
      <Form @on-submit="sendReply" :schema="schema" v-slot="{fields, errors}" class="space-y-3">
        <div>
          <Editor name="html" />
        </div>
        <div>
          <button type="submit" class="dark:bg-gold-500 bg-gold-700 text-black px-4 py-1.5 rounded-md text-sm font-semibold">{{ working ? 'Sending...' : 'Send Reply' }}</button>
        </div>
        <section v-if="debug">
          <h2 class="text-sm font-bold">Debug</h2>
<pre>
{{ fields }}
</pre>
<pre>
{{ errors }}
</pre>
        </section>
      </Form>
    </section>
    <section class="flex flex-col items-center gap-y-6 py-12" v-if="initialLoadComplete && !thread.canReply">
      <h2 class="text-xl text-gray-400 dark:text-gray-700">Thread Locked</h2>
      <p class="flex text-sm text-gray-400 dark:text-gray-700 gap-3 align-middle">
        You cannot reply to this thread.
      </p>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const UserStore = useUserStore()
const { pfp, firstLetter, hasModerationDefaults, id } = storeToRefs(UserStore)

const status = ref("good")
const activeViolationCount = ref(0)
const previousViolationCount = ref(0)

const statusLabel = computed(() => {
  switch (status.value) {
    case "good": return "all good"
    case "limited": return "limited"
    case "timedout": return "timed out"
    case "banned": return "banned"
  }
})

const statusText = computed(() => {
  switch (status.value) {
    case "good": return "Thank you for upholding our community standards. Your account is in good standing. Violations will show here."
    case "limited": return "Your account has been limited. You may be timed out or banned in the future if you continue to violate our guidelines."
    case "timedout": return "Your account has been timed out. You may be banned in the future if you continue to violate our guidelines."
    case "banned": return "Your account has been banned. You are no longer able to access Submit."
  }
})

async function fetchCounts() {
  const path = `/users/${id.value}/violations/counts`
  const { data } = await API().get(path)
  activeViolationCount.value = data.data.active
  previousViolationCount.value = data.data.archived
}

onBeforeMount(fetchCounts)
</script>

<template>
  <div class="space-y-6">
    <section class="dark:bg-submit-925 bg-white p-8 rounded-lg">
      <header class="flex items-center justify-center flex-col gap-2">
        <figure class="flex w-24 h-24 border-4 dark:border-gray-600 border-gray-300 dark:bg-submit-950 bg-white text-gray-500 rounded-full items-center justify-center font-semibold bg-cover" :style="pfp ? `background-image: url('${pfp}')` : ''">
          {{ pfp ? '' : firstLetter }}
        </figure>
        <div class="text-center flex flex-col max-w-lg gap-2">
          <h2 class="sm:text-2xl text-xl">Your account is <span :class="[status === 'good' ? 'text-green-500' : '', status === 'limited' ? 'text-yellow-500' : '', status === 'timedout' ? 'text-orange-500' : '', status === 'banned' ? 'text-red-500' : '']">{{ statusLabel }}</span></h2>
          <p class="text-sm">{{ statusText }}</p>
        </div>
      </header>
      <ol class="flex items-center sm:text-sm text-xs">
        <li class="relative flex-1">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full dark:bg-gray-600 bg-gray-300" />
          </div>
          <div
            :class="[
              status === 'good' ? 'sm:pt-9 pt-8' : 'sm:pt-10 pt-9',
              'relative flex items-start flex-col'
            ]"
          >
            <div
              :class="[
                status === 'good' ? 'bg-green-500 h-6 w-6' : 'dark:bg-gray-600 bg-gray-300 border-4 dark:border-submit-925 border-white h-4 w-4',
              'flex items-center align-middle justify-center rounded-full'
            ]"
            >
              <svg v-if="status === 'good'" class="fill-green-950 w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
              </svg>
            </div>
            <div :class="[status === 'good' ? 'text-green-500 pt-4' : 'pt-5']">All Good</div>
          </div>
        </li>
        <li class="relative flex-1">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full dark:bg-gray-600 bg-gray-300" />
          </div>
          <div
            :class="[
              status === 'limited' ? 'sm:pt-9 pt-8' : 'sm:pt-10 pt-9',
              'relative flex items-center flex-col'
            ]"
          >
            <div
              :class="[
                status === 'limited' ? 'bg-yellow-500 h-6 w-6' : 'dark:bg-gray-600 bg-gray-300 border-4 dark:border-submit-925 border-white h-4 w-4',
                'flex items-center align-middle justify-center rounded-full'
              ]"
            >
              <svg v-if="status === 'limited'" class="fill-yellow-950 w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                <path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
              </svg>
            </div>
            <div :class="[status === 'limited' ? 'text-yellow-500 pt-4' : 'pt-5']">Limited</div>
          </div>
        </li>
        <li class="relative flex-1">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full dark:bg-gray-600 bg-gray-300" />
          </div>
          <div
            :class="[
              status === 'timedout' ? 'sm:pt-9 pt-8' : 'sm:pt-10 pt-9',
              'relative flex items-center flex-col'
            ]"
          >
            <div
              :class="[
                status === 'timedout' ? 'bg-orange-500 h-6 w-6' : 'dark:bg-gray-600 bg-gray-300 border-4 dark:border-submit-925 border-white h-4 w-4',
                'flex items-center align-middle justify-center rounded-full'
              ]"
            >
              <svg v-if="status === 'timedout'" class="fill-orange-950 w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                <path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
              </svg>
            </div>
            <div :class="[status === 'timedout' ? 'text-orange-500 pt-4' : 'pt-5']">Timed Out</div>
          </div>
        </li>
        <li class="relative flex-1">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full dark:bg-gray-600 bg-gray-300" />
          </div>
          <div
            :class="[
              status === 'banned' ? 'sm:pt-9 pt-8' : 'sm:pt-10 pt-9',
              'relative flex items-end flex-col'
            ]"
          >
            <div
              :class="[
                status === 'banned' ? 'bg-red-500 h-6 w-6' : 'dark:bg-gray-600 bg-gray-300 border-4 dark:border-submit-925 border-white h-4 w-4',
                'flex items-center align-middle justify-center rounded-full'
              ]"
            >
              <svg v-if="status === 'banned'" class="fill-red-950 w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                <path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
              </svg>
            </div>
            <div :class="[status === 'banned' ? 'text-red-500 pt-4' : 'pt-5']">Banned</div>
          </div>
        </li>
      </ol>
    </section>
    <section class="dark:bg-submit-925 bg-white p-4 rounded-lg" v-if="!hasModerationDefaults">
      <header class="flex items-center gap-2">
        <svg class="w-4 h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
        <div class="flex items-baseline gap-2">
          <h3 class="text-lg font-semibold">Limitations</h3>
          <p class="text-sm text-gray-500">You have no limitations.</p>
        </div>
      </header>
    </section>
    <section class="dark:bg-submit-925 bg-white p-4 rounded-lg">
      <header class="flex items-center gap-2">
        <svg class="w-4 h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
        <div class="flex items-baseline gap-2">
          <h3 class="text-lg font-semibold">Active Violations</h3>
          <p class="text-sm text-gray-500" v-if="activeViolationCount === 0">You have no active violations.</p>
          <p class="text-sm text-gray-500" v-else>{{ activeViolationCount }} active violations.</p>
        </div>
      </header>
    </section>
    <section class="dark:bg-submit-925 bg-white p-4 rounded-lg">
      <header class="flex items-center gap-2">
        <svg class="w-4 h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9L0 168c0 13.3 10.7 24 24 24l110.1 0c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24l0 104c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65 0-94.1c0-13.3-10.7-24-24-24z"/></svg>
        <div class="flex items-baseline gap-2">
          <h3 class="text-lg font-semibold">Previous Violations</h3>
          <p class="text-sm text-gray-500" v-if="previousViolationCount === 0">You have no previous violations.</p>
          <p class="text-sm text-gray-500" v-else>{{ previousViolationCount }} previous violations.</p>
        </div>
      </header>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

interface User {
  id: string
  label: string
  isHelper?: boolean
}

interface Props {
  items: User[]
  command: (item: any) => void
}

const props = defineProps<Props>()
const selectedIndex = ref(0)

// Reset selected index when items change
watch(() => props.items, () => {
  selectedIndex.value = 0
})

const onKeyDown = ({ event }: { event: KeyboardEvent }) => {
  // Prevent default behavior for these keys
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter') {
    event.preventDefault()
  }

  if (event.key === 'ArrowUp') {
    upHandler()
    return true
  }

  if (event.key === 'ArrowDown') {
    downHandler()
    return true
  }

  if (event.key === 'Enter') {
    enterHandler()
    return true
  }

  return false
}

const upHandler = () => {
  selectedIndex.value = ((selectedIndex.value + props.items.length) - 1) % props.items.length
}

const downHandler = () => {
  selectedIndex.value = (selectedIndex.value + 1) % props.items.length
}

const enterHandler = () => {
  selectItem(selectedIndex.value)
}

const selectItem = (index: number) => {
  const item = props.items[index]

  // Don't allow selection of helper message
  if (item && !item.isHelper) {
    props.command(item)
  }
}

defineExpose({
  onKeyDown
})
</script>

<template>
  <div class="dropdown-menu">
    <template v-if="items.length">
      <button
        v-for="(item, index) in items"
        :key="item.id"
        :class="{
          'is-selected': index === selectedIndex,
          'is-helper': item.isHelper
        }"
        @click="selectItem(index)"
        :disabled="item.isHelper"
      >
        {{ item.label }}
      </button>
    </template>
    <div class="item" v-else>
      No result
    </div>
  </div>
</template>

<style lang="postcss">
.dropdown-menu {
  @apply dark:bg-submit-500 bg-gray-50 flex flex-col gap-0.5 overflow-auto p-2 rounded-lg relative shadow-lg
}
.dropdown-menu button,
.dropdown-menu .item {
  @apply bg-transparent text-left w-full flex items-center gap-1 rounded-lg px-2 py-0.5
}
.dropdown-menu button.is-selected:not(.is-helper),
.dropdown-menu button:not(.is-helper):hover {
  @apply bg-gold-700/25 dark:bg-gold-500/25;
}
.dropdown-menu button.is-helper {
  @apply text-gray-500 dark:text-gray-400 italic cursor-default
}
</style>

<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import { computed, ref } from "vue"

const User = useUserStore()
const { id } = storeToRefs(User)

interface Props {
  thread: any
  group?: string
}

const props = defineProps<Props>()
const contextLabel = ref("")

const threadURL = computed(() => {
  if (props.group) {
    return `/groups/${props.group}/moderation/messages/${props.thread._id}`
  } else {
    return `/messages/${props.thread._id}`
  }
})

const isRead = computed(() => {
  let context: string
  if (props.group) {
    // check if props.message.to is a string
    if (typeof props.thread.to === "string") {
      context = props.thread.to === props.group ? "to" : "from"
    } else {
      context = props.thread.to._id === props.group ? "to" : "from"
    }
  } else {
    if (typeof props.thread.to === "string") {
      context = props.thread.to === id.value ? "to" : "from"
    } else {
      context = props.thread.to._id === id.value ? "to" : "from"
    }
  }
  return props.thread[`${context}Read`]
})

const fromLabel = computed(() => {
  let context: string
  if (props.group) {
    // check if props.message.to is a string
    if (typeof props.thread.to === "string") {
      context = props.thread.to === props.group ? "from" : "to"
    } else {
      context = props.thread.to._id === props.group ? "from" : "to"
    }
  } else {
    if (typeof props.thread.to === "string") {
      context = props.thread.to === id.value ? "from" : "to"
    } else {
      context = props.thread.to._id === id.value ? "from" : "to"
    }
  }
  // return group name if group name is set on the message, else return username
  contextLabel.value = context
  return props.thread[`${context}GroupName`] ? props.thread[`${context}GroupName`] : props.thread[`${context}`].username
})
</script>

<template>
  <li :data-to="threadURL" class="ListItem dark:bg-submit-900 bg-white hover:dark:bg-submit-700 p-4 grid grid-cols-1 gap-2 sm:gap-0 rounded-md cursor-pointer group">
    <header class="flex justify-between items-center">
      <div class="flex flex-col sm:flex-row gap-x-2 items-baseline">
        <router-link :to="threadURL" class="text-xl font-semibold group-hover:dark:text-gold-500 group-hover:text-gold-700">{{ thread.subject }}</router-link>
        <time class="dark:text-gray-400 text-gray-500 text-sm" :title="D(thread.lastMessage).format('LLLL')">{{ D(thread.lastMessage).fromNow() }}</time>
      </div>
      <span v-if="!isRead" class="text-xs bg-red-500 dark:text-submit-950 rounded-md px-2 py-0.5">Unread</span>
    </header>
    <div class="flex flex-col xs:flex-row xs:justify-between text-sm">
      <p><span class="capitalize">{{ contextLabel }}:</span> <router-link class="hover:underline" :to="`/${fromLabel}`">{{ fromLabel }}</router-link></p>
      <p>{{ thread.messageCount }} Message{{ thread.messageCount === 1 ? '' : 's' }}</p>
    </div>
  </li>
</template>

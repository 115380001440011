<script setup lang="ts">
import Discord from "@/components/discord/Discord.vue"
import { ref, onBeforeMount, reactive } from "vue"
import Field from "@/components/input/Field.vue"
import Label from "@/components/input/Label.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

const UserStore = useUserStore()
const { id, username } = storeToRefs(UserStore)

const initialUsernameValues = reactive({
  username: username.value
})

const initialEmailValues = reactive({
  email: ""
})

const userSchema = zod.object({
  username: zod.string()
    .min(4, { message: "Can be between 4 and 24 characters." } )
    .max(24, { message: "Can be between 4 and 24 characters." } )
    .regex(/^[a-zA-Z0-9_-]+$/, { message: "Can only contain letters, numbers, underscores, and dashes." })
})

const emailSchema = zod.object({
  email: zod.string()
    .email({ message: "Must be a valid email address." })
    .min(1, { message: "This is required." })
})

const detailsLoading = ref(true)
const detailsError = ref(false)
const dob = ref("")

onBeforeMount(async () => {
  try {
    const response = await API().get(`/users/${id.value}/details`)
    const data = response.data.data
    initialEmailValues.email = data.email
    dob.value = new Date(data.dob).toISOString().split("T")[0]
    detailsLoading.value = false
  } catch (err) {
    detailsError.value = true
    detailsLoading.value = false
  }
})
</script>

<template>
  <main class="space-y-24">

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Username</h2>
        <p>You can change your username once every 30 days. Your previously used usernames are visible to all members.</p>
      </div>
      <Form :initial-values="initialUsernameValues" :schema="userSchema" class="space-y-3">
        <div>
          <Label field="username">Your Username</Label>
          <Field name="username" help-text="Usernames must be between 3 and 24 characters long. They can include letters, numbers, dashes, and underscores. They cannot include spaces." disabled />
        </div>
        <div>
          <button class="font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 text-black py-2 px-12 mr-4 cursor-not-allowed" type="submit" disabled>Change Username</button>
        </div>
        <small class="block font-bold text-red-500">Username changes are disabled during Early Access.</small>
      </Form>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Email</h2>
        <p>Any time you change your email address, it requires verification. You need access to both your current email address and new email address in order to change your email address.</p>
      </div>
      <Form :initial-values="initialEmailValues" :schema="emailSchema" v-if="!detailsLoading" class="space-y-3">
        <div>
          <Label field="email">Your Email</Label>
          <Field type="input" subtype="email"name="email" disabled />
        </div>
        <div>
          <button class="font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 text-black py-2 px-12 mr-4 cursor-not-allowed" type="submit" disabled>Change Email</button>
        </div>
        <small class="block font-bold text-red-500">Email changes are disabled during Early Access.</small>
      </Form>
      <div v-if="detailsLoading">Loading...</div>
      <div v-if="detailsError">Error loading email address.</div>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Date of Birth</h2>
        <p>Your Date of Birth that you provided when you created your Submit account. You cannot change this information. If you made a mistake when entering your DOB, you'll need to create a support request by e-mailing <a class="underline hover:decoration-2" href="mailto:support@submit.gg?subject=DOB Change Request">support@submit.gg</a>.</p>
      </div>
      <div v-if="!detailsLoading">
        <div class="mb-1 text-sm font-bold">Your DOB</div>
        <div class="bg-white dark:bg-submit-900 flex p-4 items-center w-full text-sm rounded-md select-none outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30">{{ dob }}</div>
      </div>
      <div v-if="detailsLoading">Loading...</div>
      <div v-if="detailsError">Error loading dob.</div>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Integrations</h2>
        <p>Manage any third party services that you have hooked up to your Submit account. Currently the only integration that is supported is Discord.</p>
      </div>
      <Discord />
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Deactivation & Deletion</h2>
        <p>Need a break or want to leave Submit permanently? You can deactivate or delete your Submit account using these controls.</p>
      </div>
      <p class="text-red-500 text-sm font-bold">During Early Access, deactivation and deletion are disabled. If you'd like to delete your Submit account during Early Access, you can send an email to <a class="underline hover:decoration-2" href="mailto:support@submit.gg?subject=Account Deletion Request">support@submit.gg</a>.</p>
    </section>
  </main>
</template>

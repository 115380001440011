<script setup lang="ts">
import { ref, reactive, computed, inject, type Ref} from "vue"
import Editor from "@/components/input/Editor.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import File from "@/components/input/File.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

const contextCategory = inject<Ref<string>>('contextCategory')
const feedContext = inject<Ref<string>>('feedContext')

const UserStore = useUserStore()
const { id: UserID, username, defaultVisibility, pfp, firstLetter, circles, backer, verified, roles } = storeToRefs(UserStore)

const initialValues = reactive({
  audience: contextCategory && contextCategory.value === "circle" ? feedContext && feedContext.value : defaultVisibility.value === "private" ? "public" : defaultVisibility.value,
  media: null,
  text: ""
})

const editorOptions = {
  bold: true,
  italic: true,
  strike: true,
  code: true,
  lists: true,
  link: true,
  mentions: true
}

const schema = zod.object({
  audience: zod.string()
    .min(1, { message: "This is required." })
    .optional(),
  text: zod.string()
    .max(512, { message: "512 characters is the maximum allowed for a status udpate." })
    .optional()
})

const emit = defineEmits(["close"])

const working = ref(false)
const error = ref(false)
const raw = ref("")

const isStaff = computed(() => {
  return roles.value.includes("admin")
})

function updateRaw(newRaw: string) {
  raw.value = newRaw.trim()
}

function close() {
  emit("close")
}

function imageSize(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight })
    }
    img.onerror = reject
    img.src = url
  })
}

function getImageData(file: File): Promise<any> {
  return new Promise((resolve) => {
    const blob = URL.createObjectURL(file)
    const reader = new FileReader()
    reader.onload = async () => {
      const imageDimensions = await imageSize(blob)
      resolve({
        name: file.name,
        size: file.size,
        src: blob,
        type: file.type,
        raw: file,
        width: imageDimensions.width,
        height: imageDimensions.height
      })
    }
    reader.readAsArrayBuffer(file)
  })
}

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  let formData = new FormData()

  if (values.media) {
    try {
      const imageData = await getImageData(values.media)
      formData.append('media', imageData.raw)
      formData.append('mediaWidth', imageData.width.toString())
      formData.append('mediaHeight', imageData.height.toString())
    } catch (error) {
      console.error('Error processing media:', error)
    }
  }

  formData.append('type', 'image')
  formData.append('audience', values.audience)
  formData.append('text', values.text)
  formData.append('raw', raw.value)

  try {
    const response = await API().post('/status', formData)
    const { data } = response.data

    const timestamp = new Date()

    let feedPayload: any = {
      "_id": `newfeeditem-${Math.floor(Math.random() * 101)}`,
      "author": {
        "_id": UserID.value,
        "username": username.value,
        "verified": verified.value,
        "backer": backer.value,
        "staff": isStaff.value
      },
      "activity": data.activity,
      "activityType": "status",
      "audience": values.audience,
      "content": {
        "_id": data.status,
        "boostCount": 0,
        "commentCount": 0,
        "reactionsCount": {
          "like": 0,
          "dislike": 0,
          "love": 0,
          "hot": 0,
          "laugh": 0,
          "cry": 0,
          "angry": 0,
          "celebrate": 0
        },
        "user": UserID.value,
        "type": "image",
        "audience": values.audience,
        "mediaUrl": data.mediaUrl,
        "mediaWidth": data.mediaWidth,
        "mediaHeight": data.mediaHeight,
        "text": values.text,
        "status": "published",
        "hasReacted": false,
        "reactions": true,
        "comments": true,
        "createdAt": timestamp
      },
      "self": true,
      "createdAt": timestamp
    }

    if (pfp) {
      feedPayload.author.pfp = pfp.value
    } else {
      feedPayload.author.firstLetter = firstLetter.value
    }

    UserStore.addSelfPostToQueue(feedPayload)
    close()
  } catch (err) {
    console.error('Error publishing status:', err)
    error.value = true
  } finally {
    working.value = false
  }
}
</script>

<template>
  <Form @on-submit="handleSubmit" :schema="schema" :initialValues="initialValues" v-slot="{ fields, errors, tools }" class="space-y-3">
    <File name="media" type="status" />
    <Editor name="text" placeholder="Tell us about it!" theme="status" :limit="512" :options="editorOptions" @raw="updateRaw" />
    <div class="grid grid-cols-2 gap-4 items-center">
      <div class="flex items-center gap-2">
        <svg class="w-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
        <Field name="audience" type="select" slim>
          <option value="public">Everyone</option>
          <option value="followers">Followers</option>
          <option value="relationships">Relationships</option>
          <optgroup label="Circles" v-if="circles.length > 0">
            <option v-for="circle in circles" :key="circle._id" :value="circle.code">{{ circle.name }}</option>
          </optgroup>
        </Field>
      </div>
      <div class="justify-self-end">
        <button @click="close" class="inline-flex justify-center py-1 px-6 text-sm dark:hover:text-white dark:text-gray-400 text-gray-800" type="button">Cancel</button>
        <button class="bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 inline-flex justify-center py-1 px-6 border-0 text-sm font-bold rounded-md text-black" type="submit" :disabled="working">{{ working ? 'Sharing...' : 'Share' }}</button>
      </div>
    </div>
  </Form>
</template>

<script lang="ts">
import { CircleStencil, Cropper } from "vue-advanced-cropper"
import TagsInput from "@/components/input/TagsRaw.vue"
import Label from "@/components/input/Label.vue"
import "vue-advanced-cropper/dist/style.css"
import API from "@/api/api"

function getMimeType(file: any, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
      header += byteArray[i].toString(16);
    }
	switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback
  }
}

export default {
  components: { Cropper, CircleStencil, TagsInput, Label },
  emits: ['uploaded', 'close'],
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      image: {
        src: null,
        type: null
      },
      permission: false,
      imgError: false,
      permError: false,
      uploading: false,
      done: false,
      show: false,
      tags: []
    }
  },
  // watch for changes to the permission prop and reset the error
  watch: {
    permission() {
      this.permError = false
    }
  },
  methods: {
    crop(event: any) {
      // check if the image is set
      if (!this.image.src) {
        this.imgError = true
        return
      }
      // check if permission is chcked
      if (!this.permission) {
        this.permError = true
        return
      }
      const { canvas } = (this.$refs.cropper as any).getResult()
      canvas.toBlob(async (blob: any) => {
        let formData = new FormData()
        formData.append("file", blob)
        formData.append("type", "profile")
        if (this.tags.length > 0) formData.append("tags", this.tags.join(","))
        this.uploading = true
        const response = await fetch(`${import.meta.env.VITE_PUBLIC_API}/media`, {
          method: "POST",
          body: formData,
          credentials: "include"
        })
        if (!response.ok) {
          this.uploading = false
          this.done = false
          return
        }
        this.uploading = false
        this.done = true
        const { data } = await response.json()
        this.$emit('uploaded', data.url)
      }, this.image.type)
    },
    reset() {
      this.image = {
        src: null,
        type: null
      }
      this.show = false
    },
    loadImage(event: any) {
      const { files } = event.target
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        const blob = URL.createObjectURL(files[0])
        const reader = new FileReader()
        reader.onload = (e: any) => {
          this.image = {
            src: blob as any,
            type: getMimeType(e.target.result, files[0].type) as any
          }
        }
        reader.readAsArrayBuffer(files[0])
        this.show = true
      }
    }
  },
  destroyed() {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  }
}
</script>

<template>
<div class="flex flex-col">
    <cropper
      v-show="show"
      ref="cropper"
      class="w-96 h-96 block"
      :src="image.src"
      :stencil-component="$options.components?.CircleStencil"
      :stencil-props="{
        handlers: {},
        movable: false,
        scalable: false,
        aspectRatio: 1
      }"
      :stencil-size="{
        width: 300,
        height: 300
      }"
      image-restriction="stencil"
      :debounce="false"
      :canvas="{
        height: 300,
        width: 300
      }"
    />
    <div class="flex max-w-[24rem] h-96 items-center justify-center rounded-md border-dashed border dark:border-indigo-350" v-show="!show">
      <button class="button" @click="($refs.file as unknown as HTMLInputElement).click()">
        <input class="hidden" type="file" ref="file" @change="loadImage($event)" accept="image/*">
        <span class="bg-neutral-200 dark:bg-submit-500/75 dark:hover:bg-submit-500 py-2 px-3 border-0 rounded-md text-sm leading-4 font-medium focus:outline-none">Select a File</span>
      </button>
    </div>
    <div class="flex flex-row justify-between bg-black rounded-b-md p-2" v-show="show">
      <button class="button" @click="($refs.file as unknown as HTMLInputElement).click()">
        <input class="hidden" type="file" ref="file" @change="loadImage($event)" accept="image/*">
        <span class="text-sm leading-4 font-medium text-gray-400 hover:text-white focus:outline-none">Change File</span>
      </button>
      <button class="button" @click="reset">
        <span class="text-sm leading-4 font-medium text-gray-400 hover:text-white focus:outline-none">Remove File</span>
      </button>
    </div>
    <div class="mt-2 space-y-3 max-w-[384px]">
      <div>
        <Label field="tags">Tags</Label>
        <TagsInput name="tags" :value="[]" v-model="tags" :limit="6" alt />
        <p class="mt-1 text-xs text-gray-500 dark:text-gray-400">
          You can add up to 6 tags.
        </p>
      </div>
      <div>
        <div class="flex items-start">
          <div class="flex items-center h-5">
            <input v-model="permission" id="permission" name="permission" value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-yellow-450 focus:outline-indigo-325/30">
          </div>
          <div class="ml-3 text-sm">
            <label for="permission">This photo is of me or created by me and I have the rights to post it. If the photo contains persons other than myself, I have their permission to post it.</label>
          </div>
        </div>
        <small v-if="permError" class="text-xs block mt-2 text-red-500 font-bold">This is required.</small>
      </div>
    </div>
    <div class="mt-3">
      <button v-if="!uploading && !done" @click="crop" class="block text-center w-full rounded-md bg-neutral-200 dark:bg-submit-500/75 dark:hover:bg-submit-500 py-2 text-sm font-bold">Crop &amp; Upload</button>
      <div v-if="uploading && !done" class="block text-center w-full rounded-md bg-neutral-200 dark:bg-indigo-925 animate-pulse py-2 text-sm font-bold">Uploading...</div>
      <div v-if="!uploading && done" class="block text-center w-full rounded-md bg-neutral-200 dark:bg-indigo-925 py-2 text-sm font-bold">Uploaded</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InboxItem from "@/components/messages/InboxItem.vue"
import setupCommons from "@/composables/Common"
import { useUserStore } from "@/stores/User"
import { ref, onMounted } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const { actionHandler, scrollToTop } = setupCommons()

const User = useUserStore()
const { messages } = storeToRefs(User)

interface Props {
  context?: string
}

const props = withDefaults(defineProps<Props>(), {
  context: "inbox"
})

const threads = ref<any[]>([])
const initialLoadComplete = ref(false)
const working = ref(false)
const error = ref(false)
const pageInfo = ref<any>({
  endCursor: undefined
})
const markAllLabel = ref('Mark All as Read')

onMounted(async () => {
  await getThreads(false)
  initialLoadComplete.value = true
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
})

async function markAllAsRead() {
  if (working.value) return
  markAllLabel.value = 'Marking...'
  working.value = true

  let path = `/messages/read`

  try {
    await API().get(path)
    // iterate through messages and mark them as read
    threads.value.forEach(thread => {
      thread.toRead = true
      thread.fromRead = true
    })

    User.setMessages(0)

    markAllLabel.value = 'Mark All as Read'
    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
    return
  }
}

async function getThreads(setWorking: boolean = true) {
  if (working.value) return
  if (props.context === 'notices') return
  if (setWorking) working.value = true
  if (props.context === 'notices') {
    return
  }

  let path = `/messages`
  let params: any = {
    context: props.context
  }

  if (pageInfo.value.endCursor) {
    params = {
      ...params,
      cursor: pageInfo.value.endCursor
    }
  }

  path = `${path}?${new URLSearchParams(params).toString()}`

  try {
    const response = await API().get(path)
    const data = response.data
    threads.value.push(...data.data)
    pageInfo.value = data.pageInfo

    if (setWorking) working.value = false
  } catch (err) {
    error.value = true
    working.value = false
    return
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getThreads()
      }
    }
  })
}, options)
</script>

<template>
  <div class="grid grid-cols-1 content-start">
    <header class="flex justify-between items-center text-sm font-bold">
      <div class="text-sm font-bold capitalize">{{ context }}</div>
      <ul class="flex gap-2" v-if="context === 'inbox'">
        <button @click="markAllAsRead">
          {{ markAllLabel }}
        </button>
      </ul>
    </header>
    <main class="mt-4" v-if="initialLoadComplete">
      <ul @click="actionHandler" class="ItemList grid grid-cols-1 gap-2">
        <InboxItem v-for="thread in threads" :thread="thread" :key="thread._id" v-if="threads.length > 0" />
        <li class="text-sm" v-if="threads.length === 0">No threads found.</li>
      </ul>
    </main>
    <main class="mt-4 text-sm" v-else>
      Loading...
    </main>
    <div id="loadMore"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch, toRaw, nextTick } from "vue"
import LoadIndicator from "@/components/misc/LoadIndicator.vue"
import FeedItem from "@/components/feed/FeedItem.vue"
import { useUserStore } from "@/stores/User"
import { feedDB } from "@/stores/Feed"
import { useRoute } from "vue-router"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const Route = useRoute()
const userStore = useUserStore()
const { id, username, selfPostQueue } = storeToRefs(userStore)
const isPageReload = !Route.meta.navigationFromApp

const props = defineProps({
  feedContext: {
    type: String,
    required: false,
    default: "all"
  }
})

const SCROLL_POSITION_KEY = `feedScrollPosition_${id.value}_${props.feedContext}`

const initialLoadComplete = ref(false)
const working = ref(false)
const maintenance = ref(false)
const feedList = ref<any[]>([])
const newPostCount = ref(0)
const error = ref(false)
const pageInfo = ref<any>({
  endCusor: undefined
})
let selfPostWatcher: any = undefined
const selfPostCountSinceLastCheck = ref(0)

const showNewItemsButton = ref(false)

let cleanupIntervalId: number

function setupIntersectionObserver() {
  let target = document.querySelector('#loadMore')
  if (target) {
    observer.disconnect() // Disconnect any existing observation
    observer.observe(target)
  }
}

const restoreScrollPosition = () => {
  const savedPosition = sessionStorage.getItem(SCROLL_POSITION_KEY)
  console.log('savedPosition', savedPosition)
  if (savedPosition) {
    console.log('restoring scroll position', savedPosition)
    window.scrollTo(0, parseInt(savedPosition))
    sessionStorage.removeItem(SCROLL_POSITION_KEY)
  }
}

onMounted(async () => {
  if (maintenance.value) return

  try {
    await feedDB.cleanup()

    // Clear cache if this is a page reload
    if (isPageReload) {
      await feedDB.clearFeedData(id.value, props.feedContext)
      sessionStorage.removeItem(SCROLL_POSITION_KEY)
      console.log('Page reload detected - cleared feed cache')
    }

    // Load cached items and metadata
    const cachedItems = await feedDB.getFeedItems(id.value, props.feedContext)
    const metadata = await feedDB.getFeedMetadata(id.value, props.feedContext)

    console.log('Cached items:', cachedItems.length)
    console.log('Metadata:', metadata)

    if (cachedItems.length > 0 && metadata) {
      console.log('Using cached items')
      feedList.value = cachedItems
      pageInfo.value = metadata.pageInfo || { endCursor: undefined }
      initialLoadComplete.value = true

      const newItems = await fetchNewItemsSince(metadata.firstPulledAt)
      if (newItems.length > 0) {
        // Prepend new items to the feed
        feedList.value = [...newItems, ...feedList.value]
        // Update cache with new items
        await feedDB.addFeedItems(id.value, props.feedContext, newItems)
        await feedDB.updateFeedMetadata(id.value, props.feedContext, {
          firstPulledAt: Date.now()
        })
      }
    } else {
      console.log('Fetching from server')
      await fetchFeed()
      initialLoadComplete.value = true
    }

    // Handle self posts
    if (selfPostQueue.value.length > 0) {
      const post = selfPostQueue.value[0]
      feedList.value.unshift(post)
      userStore.removeFromSelfPostQueue(0)
      // Add to cache
      await feedDB.addFeedItems(id.value, props.feedContext, [post])
    }

    // Setup self post watcher
    selfPostWatcher = watch(
      selfPostQueue,
      (newVal) => {
        const newArray = toRaw(newVal)
        if (newArray.length > 0) {
          const post = newArray[0]
          feedList.value.unshift(post)
          userStore.removeFromSelfPostQueue(0)
          selfPostCountSinceLastCheck.value++
          // Add to cache
          feedDB.addFeedItems(id.value, props.feedContext, [post])
        }
      },
      { deep: true, immediate: true }
    )

    startPeriodicCheck()
    startPeriodicCleanup()
    nextTick(() => {
      setupIntersectionObserver()
      setupSeenTracking()
      restoreScrollPosition()
    })
  } catch (error: any) {
    console.error('Error loading feed:', error)
    error.value = true
  }
})

async function fetchNewItemsSince(since: number): Promise<any[]> {
  const path = `/feed?${new URLSearchParams({ since: new Date(since).toISOString() })}`
  try {
    const response = await API().get(path)
    return response.data.data.filter((item: any) => item !== null)
  } catch (err) {
    console.error(err)
    return []
  }
}

function startPeriodicCleanup() {
  // Run cleanup every 6 hours
  cleanupIntervalId = setInterval(() => {
    feedDB.cleanup().catch(err => {
      console.error('Error during feed cleanup:', err)
    })
  }, 6 * 60 * 60 * 1000) as any
}

let seenObserver = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const itemId = entry.target.getAttribute('data-item-id')
      if (itemId) {
        feedDB.markItemAsSeen(id.value, props.feedContext, itemId)
        seenObserver.unobserve(entry.target)
      }
    }
  })
}, {
  root: null,
  rootMargin: '0px',
  threshold: 0.5  // Item needs to be 50% visible to be marked as seen
})

onBeforeUnmount(() => {
  if (typeof selfPostWatcher === 'function') {
    selfPostWatcher()
  }
  observer.disconnect()
  seenObserver.disconnect()
  clearInterval(intervalId)
  clearInterval(cleanupIntervalId)
  sessionStorage.setItem(SCROLL_POSITION_KEY, window.scrollY.toString())
})

const CHECK_INTERVAL = 30000
let intervalId: number

function startPeriodicCheck() {
  intervalId = setInterval(() => {
    if (document.hasFocus()) {
      checkForNewFeedItems()
    }
  }, CHECK_INTERVAL) as any
}

function setupSeenTracking() {
  nextTick(() => {
    const feedItems = document.querySelectorAll('li[data-item-id]')
    feedItems.forEach(item => {
      seenObserver.observe(item)
    })
  })
}

async function fetchFeed() {
  const retryAttempts = 3
  const backoffMs = 1000

  for (let attempt = 0; attempt < retryAttempts; attempt++) {
    try {
      if (working.value) return
      working.value = true
      error.value = false

      let path = `/feed`
      let queryParams: any = {}

      if (props.feedContext !== "all") {
        if (pageInfo.value.endCursor) {
          queryParams.cursor = pageInfo.value.endCursor
          queryParams.circle = props.feedContext
        } else {
          queryParams.circle = props.feedContext
        }
      } else if (pageInfo.value.endCursor) {
        queryParams.cursor = pageInfo.value.endCursor
      }

      if (Object.keys(queryParams).length > 0) {
        path += `?${new URLSearchParams(queryParams)}`
      }

      // check if we have feedDB metadata
      const metadata = await feedDB.getFeedMetadata(id.value, props.feedContext)

      const firstPulledAt = metadata?.firstPulledAt || new Date().toISOString()
      const lastPulledAt = new Date().toISOString()
      const response = await API().get(path)
      const data = response.data
      const filteredData = data.data.filter((item: any) => item !== null)

      // Simply append new items - FeedDB will handle ordering
      feedList.value.push(...filteredData)
      pageInfo.value = data.pageInfo

      // Save to cache - FeedDB will maintain chronological order
      await feedDB.addFeedItems(id.value, props.feedContext, filteredData)
      await feedDB.updateFeedMetadata(id.value, props.feedContext, {
        pageInfo: data.pageInfo,
        lastPulledAt: lastPulledAt,
        firstPulledAt: firstPulledAt
      })

      nextTick(() => {
        setupIntersectionObserver()
        setupSeenTracking()
      })
      return
    } catch (err) {
      if (attempt === retryAttempts - 1) {
        error.value = true
        // Fall back to cached content if available
        const cachedItems = await feedDB.getFeedItems(id.value, props.feedContext)
        if (cachedItems.length > 0) {
          feedList.value = cachedItems
          // TODO: Show offline indicator
        }
      } else {
        await new Promise(resolve =>
          setTimeout(resolve, backoffMs * Math.pow(2, attempt))
        )
      }
    } finally {
      working.value = false
    }
  }
}

watch(() => props.feedContext, async () => {
  // Clean up existing watcher
  if (typeof selfPostWatcher === 'function') {
    selfPostWatcher()
    selfPostWatcher = undefined
  }

  initialLoadComplete.value = false
  feedList.value = []
  pageInfo.value = { endCursor: undefined }

  // Clear scroll position when feed context changes
  sessionStorage.removeItem(SCROLL_POSITION_KEY)

  await fetchFeed()
  initialLoadComplete.value = true

  nextTick(() => {
    setupIntersectionObserver()
  })

  if (props.feedContext === "all") {
    selfPostWatcher = watch(
      selfPostQueue,
      (newVal) => {
        const newArray = toRaw(newVal)
        if (newArray.length > 0) {
          const post = newArray[0]
          feedList.value.unshift(post)
          userStore.removeFromSelfPostQueue(0)
          selfPostCountSinceLastCheck.value++
        }
      },
      { deep: true, immediate: true }
    )
  }
}, { immediate: false })

async function checkForNewFeedItems() {
  const metadata = await feedDB.getFeedMetadata(id.value, props.feedContext)
  let path = `/feed/check`

  if (metadata?.lastPulledAt) {
    path += `?${new URLSearchParams({
      since: new Date(metadata.firstPulledAt).toISOString()
    })}`
  }

  try {
    const response = await API().get(path)
    // Subtract self posts from the count, but ensure we don't go negative
    const newItems = Math.max(0, response.data.data - selfPostCountSinceLastCheck.value)
    newPostCount.value = newItems
  } catch (err) {
    console.error(err)
  }
}

async function getNewFeedItems() {
  if (newPostCount.value === 0) return

  if (newPostCount.value > 10) {
    // Full refresh needed
    initialLoadComplete.value = false
    feedList.value = []
    pageInfo.value = { endCursor: undefined }

    await feedDB.updateFeedMetadata(id.value, props.feedContext, {
      pageInfo: { endCursor: undefined },
      lastPulledAt: Date.now(),
      firstPulledAt: Date.now()
    })

    scrollToTop(new Event('click'))
    await fetchFeed()
    // clear scroll position
    sessionStorage.removeItem(SCROLL_POSITION_KEY)
    newPostCount.value = 0
    selfPostCountSinceLastCheck.value = 0
    initialLoadComplete.value = true
  } else {
    // Fetch only new items
    const metadata = await feedDB.getFeedMetadata(id.value, props.feedContext)
    let path = `/feed`

    if (metadata?.firstPulledAt) {
      path += `?${new URLSearchParams({
        since: new Date(metadata.firstPulledAt).toISOString()
      })}`
    }

    try {
      const response = await API().get(path)
      const filteredData = response.data.data.filter((item: any) => item !== null)

      // Add new items to state and let FeedDB handle ordering
      feedList.value = [...filteredData, ...feedList.value]
      await feedDB.addFeedItems(id.value, props.feedContext, filteredData)
      await feedDB.updateFeedMetadata(id.value, props.feedContext, {
        firstPulledAt: Date.now()
      })

      scrollToTop(new Event('click'))
    } catch (err) {
      console.error(err)
    } finally {
      selfPostCountSinceLastCheck.value = 0
      newPostCount.value = 0
    }
  }
}

async function refreshSingleFeedItem(itemId: string) {
  try {
    const response = await API().get(`/feed/${itemId}`)
    const newData = response.data.data
    if (newData) {
      // Update local data
      feedList.value = feedList.value.map((item: any) => item._id === itemId ? newData : item)
      // Update cache
      await feedDB.updateFeedItem(id.value, props.feedContext, itemId, newData)
    } else {
      console.error('Failed to refresh item:', response.data)
    }
  } catch (err: any) {
    console.error('Failed to refresh item:', err)
  }
}

function removeItemFromFeed(id: string) {
  const index = feedList.value.findIndex((item: any) => item._id === id)
  if (index > -1) feedList.value.splice(index, 1)
}

function scrollToTop(e: Event) {
  e.preventDefault()
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

let showNewItemsObserver: any

watch(newPostCount, async (newVal) => {
  if (newVal > 0) {
    await nextTick()
    const topButton = document.querySelector('#TopNewItemsButton')
    if (topButton) {
      showNewItemsObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          showNewItemsButton.value = !entry.isIntersecting
        })
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      })
      showNewItemsObserver.observe(topButton)
    }
  } else {
    if (showNewItemsObserver) {
      showNewItemsObserver.disconnect()
    }
    showNewItemsButton.value = false
  }
})

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        fetchFeed()
      }
    }
  })
}, options)
</script>

<template>
  <section class="relative">
    <div class="sticky top-0 translate-y-20 w-full flex justify-center pointer-events-none" v-if="showNewItemsButton">
      <button @click="getNewFeedItems" class="opacity-75 hover:opacity-100 dark:bg-submit-600 bg-white text-gray-500 dark:text-gray-400 pointer-events-auto px-10 py-1.5 rounded-2xl">Load New Feed Items</button>
    </div>
    <ul class="FeedList">
      <li v-if="newPostCount > 0">
        <button @click="getNewFeedItems" id="TopNewItemsButton" class="w-full dark:bg-submit-900 bg-white text-gray-500 dark:text-gray-400 text-center p-4 rounded-2xl">
          Load New Feed Items
        </button>
      </li>
      <li v-if="initialLoadComplete && feedList.length === 0 && !error && !maintenance && !working && feedContext === 'all'">
        <article class="FeedItem dark:bg-submit-900 bg-white text-gray-500 dark:text-gray-400">
          <router-link class="PFP w-10 h-10 bg-gray-200 dark:bg-submit-950" :to="`/Apollyon`" :style="`background-image: url('https://assets.submit.gg/apollyon.jpg')`"></router-link>
          <header class="inline-wrap">
            <router-link :to="`/Apollyon`" class="truncate text-base font-semibold dark:text-gold-500 text-gold-700">Apollyon</router-link>
          </header>
          <div class="text-xs dark:text-gray-400">
            <span>Welcome to Submit!</span>
          </div>
          <section class="break-modern CommonBody">
            <p>On behalf of the entire Submit team, I'm excited to be the first to welcome you on to the platform, {{ username }}!</p>
            <p>It's a pleasure to have you onboard during this exhilarating early access phase of our platform. Here at Submit, we're constantly evolving – improving, refining, and expanding our features daily. While we're still ironing out some kinks (heh), you'll notice ongoing enhancements and new functionalities popping up regularly. Keep an eye on our <router-link class="underline dark:hover:decoration-gold-500 hover:decoration-gold-700 hover:decoration-2" to="/changelog">changelog</router-link> for the latest updates!</p>
            <p>We're on the cusp of completing our major core features, and soon, we'll transition from early access to a full-scale general release. It's an exciting time, and we're thrilled to have you with us for this journey. Some key areas to dive into right now:</p>
            <ul class="list-disc ml-4">
              <li><router-link class="underline dark:hover:decoration-gold-500 hover:decoration-gold-700 hover:decoration-2" to="/groups/submit-development">Submit Development Group</router-link></li>
              <li><router-link class="underline dark:hover:decoration-gold-500 hover:decoration-gold-700 hover:decoration-2" to="/groups">Groups</router-link></li>
              <li><router-link class="underline dark:hover:decoration-gold-500 hover:decoration-gold-700 hover:decoration-2" to="/explore">Explore</router-link></li>
            </ul>
            <p>Why not start by customizing your profile? Add a personal touch with a <router-link class="underline dark:hover:decoration-gold-500 hover:decoration-gold-700 hover:decoration-2" to="/settings/profile/photo">profile photo</router-link>, flesh out <router-link class="underline dark:hover:decoration-gold-500 hover:decoration-gold-700 hover:decoration-2" to="/settings/profile/details">your details</router-link>, and <router-link class="underline dark:hover:decoration-gold-500 hover:decoration-gold-700 hover:decoration-2" to="/settings/profile/about">pen an intriguing 'about me' section</router-link>. Searching for users by their username is a breeze, and soon, you'll have even more robust search capabilities at your fingertips.</p>
            <p>Once your profile is set up, explore and connect with other incredible members of our community. Share status updates, <router-link class="underline dark:hover:decoration-gold-500 hover:decoration-gold-700 hover:decoration-2" :to="`/${username}/writing`">writings</router-link>, <router-link class="underline dark:hover:decoration-gold-500 hover:decoration-gold-700 hover:decoration-2" :to="`/${username}/media`">media</router-link>, and engage with others through comments or reactions. Our newly launched Groups feature is a great space to join existing groups, participate in discussions, or kickstart your own by submitting a <router-link class="underline dark:hover:decoration-gold-500 hover:decoration-gold-700 hover:decoration-2" to="/reservations/groups">Group Reservation Request</router-link>.</p>
            <p>Once again, Welcome to Submit, I hope you're excited as we are for the future of BDSM, Fetish, &amp; Kinky community!</p>
          </section>
          <footer>
            <small class="dark:text-gray-500">
              This message self destructs once you've posted your first status update or followed someone!
            </small>
          </footer>
        </article>
      </li>
      <li v-if="initialLoadComplete && feedList.length === 0 && !error && !maintenance && !working && feedContext !== 'all'">
        <article class="FeedItem dark:bg-submit-900 bg-white text-gray-500 dark:text-gray-400">
          <div class="PFP w-10 h-10 dark:bg-submit-950 bg-gray-200">
            <span class="flex items-center justify-center w-full h-full text-2xl font-semibold text-gray-800 dark:text-gray-500">!</span>
          </div>
          <header class="inline-wrap">
            <span class="truncate text-base font-semibold text-yellow-500">No Activity</span>
          </header>
          <div class="text-xs dark:text-gray-400">
            <span>This circle or filter has no activity.</span>
          </div>
          <section>
            <p>The users or settings in this circle or filter have resulted in no activity. You may want to make some adjustments or include some more active users.</p>
          </section>
          <footer>
            <small class="dark:text-gray-500">
              This message self destructs once there is some activity!
            </small>
          </footer>
        </article>
      </li>
      <li v-if="maintenance">
        <article class="FeedItem dark:bg-submit-900 bg-white text-gray-500 dark:text-gray-400">
          <div class="PFP w-10 h-10 dark:bg-submit-950 bg-gray-200">
            <span class="flex items-center justify-center w-full h-full text-2xl font-semibold text-gray-800 dark:text-gray-500">!</span>
          </div>
          <header class="inline-wrap">
            <span class="truncate text-base font-semibold text-yellow-500">Feed Maintenance</span>
          </header>
          <div class="text-xs dark:text-gray-400">
            <span>The feed is getting a back rub!</span>
          </div>
          <section>
            <p>We apologize for the disruption, the feed is currently undergoing maintenance and will be back shortly.</p>
          </section>
          <footer>
            <small class="dark:text-gray-500">
              This message self destructs once the feed is back online!
            </small>
          </footer>
        </article>
      </li>
      <FeedItem
        v-for="item in feedList"
        :item="item"
        :key="item ? item._id : null"
        :data-item-id="item ? item._id : null"
        :image-error-handler="refreshSingleFeedItem"
        @delete="removeItemFromFeed"
        v-if="initialLoadComplete && !error && feedList.length > 0"
      />
      <li v-if="error">
        <article class="FeedItem dark:bg-submit-900 bg-white text-gray-500 dark:text-gray-400">
          <div class="PFP w-10 h-10 dark:bg-submit-950 bg-gray-200">
            <span class="flex items-center justify-center w-full h-full text-2xl font-semibold text-gray-800 dark:text-gray-500">!</span>
          </div>
          <header class="inline-wrap">
            <span class="truncate text-base font-semibold text-red-500">Whoops!</span>
          </header>
          <div class="text-xs dark:text-gray-400">
            <span>The feed fell asleep!</span>
          </div>
          <section>
            <p>It looks like that jerk, the feed, decided to take a nap instead of do it's job.</p>
          </section>
          <footer>
            <small class="dark:text-gray-500">
              This message self destructs once the feed is back online!
            </small>
          </footer>
        </article>
      </li>
      <li class="flex justify-center" v-if="(!initialLoadComplete && feedList.length === 0) || (initialLoadComplete && working)">
        <LoadIndicator />
      </li>
      <li class="flex flex-col items-center gap-y-6 pt-8" v-if="initialLoadComplete && feedList.length > 0 && !pageInfo.hasNextPage">
        <h2 class="text-xl dark:text-gray-700">You've reached the end</h2>
        <a href="#" class="flex text-sm dark:text-gray-700 gap-3 align-middle" @click.prevent="scrollToTop">
          <svg class="h-4 flex-none dark:fill-gray-700" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
            <path d="M32 32C14.3 32 0 46.3 0 64S14.3 96 32 96H352c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM214.6 169.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 269.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V269.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"/>
          </svg>
          Go to the top
        </a>
      </li>
    </ul>
    <div id="loadMore"></div>
  </section>
</template>

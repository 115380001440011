<script setup lang="ts">
import NewCollection from "@/components/collections/New.vue"
import { ref, onMounted, inject, computed } from "vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const userStore = useUserStore()
const { backer, id } = storeToRefs(userStore)

interface Props {
  type: string
}

const props = defineProps<Props>()
const profileData: any = inject("profileData")

const initialLoadComplete = ref(false)
const collectionLimit = ref(backer ? 1000 : 6)
const collections = ref<any[]>([])
const working = ref(false)

const isOwner = computed(() => profileData.value._id === id.value)

onMounted(async () => {
  await fetchCollections()
  initialLoadComplete.value = true
})

async function fetchCollections() {
  if (working.value) return
  working.value = true

  try {
    const response = await API().get(`/users/${profileData.value._id}/collections?${ new URLSearchParams({
      type: props.type
    })}`)
    collections.value.push(...response.data.data)
    working.value = false
  } catch (err: any) {
    console.error(err)
  }
}

function handleNewCollection(data: any) {
  collections.value.push(data)
}
</script>

<template>
  <section class="space-y-4" v-if="initialLoadComplete">
    <div class="flex justify-between text-sm font-bold">
      <span>{{ collections.length }} collections</span>
      <NewCollection @created="handleNewCollection" :type="props.type" v-if="isOwner && collections.length < collectionLimit">Add Collection</NewCollection>
    </div>
    <ul class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-3 sm:gap-6">
      <li v-for="collection in collections" :key="collection._id" v-if="collections.length > 0">
        <router-link :to="`/${profileData.username}/${collection.type}/collections/${collection.slug}`" class="flex flex-col relative w-full aspect-[5/3] bg-white dark:bg-submit-925/50 rounded-md group">
          <div class="flex justify-center flex-grow">
            <svg v-if="collection.type === 'media'" class="w-8 fill-gray-400 dark:fill-gray-500 group-hover:fill-gold-700 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 480H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H192c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64zM176 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm120 0c-8 0-15.5 4-20 10.7l-56 84L202.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H392c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144C311.5 228 304 224 296 224z"/></svg>
            <svg v-if="collection.type === 'writing'" class="w-8 fill-gray-400 dark:fill-gray-500 group-hover:fill-gold-700 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
          </div>
          <div class="text-sm font-bold w-full p-4 bg-neutral-50 dark:bg-submit-900 dark:group-hover:bg-submit-800 rounded-b-md">{{ collection.title }}</div>
        </router-link>
      </li>
    </ul>
  </section>
  <section class="space-y-4" v-else>
    <div class="flex justify-between">
      <span class="bg-neutral-50 dark:bg-indigo-925/25 w-24 h-4 animate-pulse"></span>
    </div>
    <ul class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3 sm:gap-6">
      <li v-for="i in 3" :key="i">
        <div class="block w-full aspect-[5/3] bg-neutral-50 dark:bg-indigo-925/25 rounded-md animate-pulse"></div>
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeMount, onBeforeUnmount, computed } from "vue"
import type { FormContext } from "@/components/input/Form.vue"

interface Props {
  name: string
  options?: Array<{ label: string, value: string }>
  helpText?: string
  persist?: boolean
  disabled?: boolean
  locked?: boolean
  lockedValue?: string
  lockedDisplay?: string
  step?: number
}

const props = withDefaults(defineProps<Props>(), {
  persist: false,
  disabled: false,
  locked: false,
  lockedValue: '',
  step: 1
})

const formContext = inject<FormContext>('formContext')
if (!formContext) {
  throw new Error(`Field: ${props.name} must be used within a Form component.`)
}

const { registerField, unregisterField, focusField, blurField, steps, errors } = formContext
const value = ref<any>("")

onBeforeMount(() => {
  registerField(props.name, "fieldset", value, props.persist, props.locked, props.step)
})

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

watchEffect(() => {
  error.value = errors.value[props.name]
})
</script>

<template>
  <fieldset @blur="handleBlur" @focus="handleFocus">
    <legend class="text-sm font-bold">
      <slot name="legend">Legend</slot>
    </legend>
    <p v-if="helpText">{{ helpText }}</p>
    <div class="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-4">
      <div v-for="(item, i) in options" :key="item.value" class="flex items-start col-span-2">
        <div class="flex items-center h-5">
          <input v-model="value" :id="`${name}${i}`" :name="name" :value="item.value" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-600 dark:bg-submit-500 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
        </div>
        <div class="ml-3 text-sm">
          <label :for="`${name}${i}`" class="font-medium">{{item.label}}</label>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import { ref } from "vue"

const emit = defineEmits(["update:modelValue", "blur", "focus"])
const props = defineProps<{
  name: string
  modelValue?: string[]
  value: string[],
  limit?: number
  alt?: boolean
}>()

const tags = ref(props.value)
const newTag = ref("")

const noUse = [
  "tag",
  "tags",
  "tagging",
  "tagged",
  "submit",
  "apollyon",
  "boomography",
  "this",
  "that",
  "then",
  "and",
  "community",
  "announcement",
  "announcements",
  "news",
  "admin",
  "moderator",
  "staff",
  "support"
]

const addTag = (tag: string) => {
  if (tag.length < 3) return
  if (props.limit && tags.value.length >= props.limit) return
  tag = tag.toLowerCase()
  // regex to allow a-z, 0-9, and spaces
  tag = tag.replace(/[^a-z0-9 ]/g, "")
  tag = tag.trim()
  if (tags.value.includes(tag)) return
  if (noUse.includes(tag.toLowerCase())) return
  tags.value.push(tag)
  newTag.value = ""
  onTagsChange()
}

const removeTag = (index: number) => {
  tags.value.splice(index, 1)
  onTagsChange()
}

const onTagsChange = () => {
  emit("update:modelValue", tags.value)
}

const onFocus = () => {
  emit('focus')
}

const onBlur = () => {
  emit('blur')
}
</script>

<template>
  <div :class="`flex flex-wrap px-4 py-3 border-0 ${ alt ? 'bg-neutral-125 dark:bg-submit-950' : 'bg-white dark:bg-submit-900'} dark:text-gray-300 focus:ring-0 rounded-md min-h-[42px]`">
    <ul class="flex flex-wrap gap-2 w-full items-center">
      <li :class="`flex items-center gap-2 px-2 py-1 rounded-md ${ alt ? 'bg-white dark:bg-submit-900' : 'bg-neutral-125 dark:bg-submit-950'} text-sm`" v-for="tag, index in tags">
        {{ tag }}
        <svg @click="removeTag(index)" class="cursor-pointer w-2 dark:fill-indigo-325" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
      </li>
      <li class="flex flex-auto">
        <input
          :class="`border-0 px-0 py-1 m-0 outline-none focus:ring-0 flex flex-auto min-w-[100px] ${ props.alt ? 'bg-neutral-125 dark:bg-submit-950' : 'bg-white dark:bg-submit-900'} text-sm placeholder:text-indigo-250/50`"
          :name="name"
          :id="name"
          v-model="newTag"
          type="text"
          placeholder="Add tag"
          @keydown.prevent.enter="addTag(newTag)"
          @keydown.prevent.,="addTag(newTag)"
          @keydown.backspace="newTag.length || removeTag(tags.length - 1)"
          @blur="onBlur"
          @focus="onFocus"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useEditor, EditorContent, Extension, type Editor } from "@tiptap/vue-3"
import { ImageExtension as ImageWithCaption } from "@/components/editor/Image"
import { ref, computed, onMounted, onUnmounted, watch, provide } from "vue"
import CharacterCount from "@tiptap/extension-character-count"
import FileHandler from "@tiptap-pro/extension-file-handler"
import Suggestion from "@/components/editor/Suggestion.js"
import Placeholder from "@tiptap/extension-placeholder"
import Typography from "@tiptap/extension-typography"
import { sanitizeUrl } from "@braintree/sanitize-url"
import Mention from "@tiptap/extension-mention"
import StarterKit from "@tiptap/starter-kit"
import Link from "@tiptap/extension-link"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const props = defineProps(
  {
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Enter some text..."
    },
    theme: {
      type: String,
      default: "editor"
    },
    editorStyle: {
      type: String,
      default: "default"
    },
    limit: {
      type: Number
    },
    attachments: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({
        all: true
      })
    },
    mentionConfig: {
      type: Object,
      default: () => ({})
    }
  }
)

const emit = defineEmits(['update:modelValue', 'blur', 'focus', 'raw', 'attachments'])

let observer: any

const modalOpen = ref(false)
const modalType = ref("")
const count = ref(0)
const words = ref(0)
const attachments = ref<string[]>(props.attachments as string[] || [])
const file = ref<any>(null)
const acceptedTypes = ref<string[]>(['image/png', 'image/jpeg', 'image/gif', 'image/webp'])

const acceptedTypesString = computed(() => {
  return acceptedTypes.value.join(',')
})

function uploadAttachment(payload: FormData, updateProgressCallback: (progress: number) => void) {
  return API().post("media/attachment", payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (progressEvent: any) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100
      updateProgressCallback(progress)
    }
  })
}

function removeAttachment(id: string) {
  const index = attachments.value.indexOf(id)
  if (index > -1) {
    attachments.value.splice(index, 1)
  }
  emit('attachments', attachments.value)
}

provide('removeAttachment', removeAttachment)

function imageSize(url:any) {
  const img = new Image()

  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      const width  = img.naturalWidth
      const height = img.naturalHeight

      resolve({width, height})
    }

    img.onerror = reject
  })

  img.src = url

  return promise
}

const uploadImage = async (file: any, editor: Editor, pos: any = null) => {
  const blob = URL.createObjectURL(file)
  if (!pos) pos = editor.state.selection.anchor

  const fileReader = new FileReader()

  const imageDimensions: any = await imageSize(blob)

  fileReader.readAsDataURL(file)
  fileReader.onload = async () => {
    // Insert the image with the DataURL and a custom attribute for upload progress
    editor.chain().insertContentAt(pos, {
      type: 'imageWithCaption',
      attrs: {
        src: blob,
        uploadProgress: 0 // Initial upload progress
      }
    }).focus().run()

    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await uploadAttachment(formData, (progress) => {
        editor.commands.updateAttributes('imageWithCaption', { uploadProgress: progress })
      })

      editor.commands.updateAttributes('imageWithCaption', {
        src: response.data.data.url,
        id: response.data.data._id,
      })

      attachments.value.push(response.data.data._id)
      emit('attachments', attachments.value)
    } catch (error) {
      console.error(error)
    }
  }
}

function toggleModal(type: string) {
  if (type !== "close") {
    modalType.value = type
  }
  modalOpen.value = !modalOpen.value
}

onMounted(() => {
  let navElement = document.querySelector('.Nav') as HTMLElement
  observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'scroll-direction') {
        // Adjust the editor's menu based on the Nav's scroll-direction attribute
        const navIsStuck = navElement.getAttribute('scroll-direction') === 'up'
        const editorMenu = document.querySelector('.EditorMenu') as HTMLElement // Replace with your editor menu selector
        const navHeight = navElement.offsetHeight

        if (navIsStuck) {
          editorMenu.style.top = `${navHeight}px` // Adjust this as necessary
        } else {
          editorMenu.style.top = '0px' // Reset position
        }
      }
    })
  })

  // Start observing the Nav element
  if (navElement) {
    observer.observe(navElement, { attributes: true })
  }
})

onUnmounted(() => {
  if (observer) {
    observer.disconnect()
  }
})

const customLink = Link.extend({
  inclusive: false
})

function validateURL(href: string): object {
  let newHref = {
    href: '',
    target: '_blank'
  }

  href = sanitizeUrl(href)

  // Check if the URL is already relative
  const isRelative = !href.startsWith('http://') && !href.startsWith('https://') && !href.startsWith('www.');

  // Check if the URL includes 'submit.gg'.
  if (href.includes('submit.gg')) {
    // Create a relative link.
    const urlParts = href.split('submit.gg')
    newHref.href = urlParts[1] || '/'
    // if the relative url is empty, set it to the root.
    if (newHref.href === '') {
      newHref.href = '/'
    }
    // if the relative link doesn't start with a slash, add it.
    if (!newHref.href.startsWith('/')) {
      newHref.href = '/' + newHref.href
    }
    newHref.target = '_self'
  } else if (isRelative && !href.includes('.') && !href.startsWith('www.')) {
    // If it's a relative URL and doesn't look like an absolute URL (no dots and doesn't start with www)
    newHref.href = href
    // if the relative url is empty, set it to the root.
    if (newHref.href === '') {
      newHref.href = '/'
    }
    // if the relative link doesn't start with a slash, add it.
    if (!newHref.href.startsWith('/')) {
      newHref.href = '/' + newHref.href
    }
    newHref.target = '_self'
  } else {
    // Create an absolute link.
    // Check if the URL includes 'http' or 'https'.
    if (!href.startsWith('http://') && !href.startsWith('https://')) {
      newHref.href = 'http://' + href
    } else {
      newHref.href = href
    }
    newHref.target = '_blank'
  }

  return newHref
}

function handleFileChange(e: any) {
  const { files } = e.target

  if (files && files[0]) {
    uploadImage(files[0], editor.value!)
  }
}

function setLink() {
  const previousUrl = editor.value!.getAttributes('link')?.href
  let url: any = window.prompt('Enter the URL', previousUrl)
  if (url === null) {
    return
  }
  if (url === '') {
    editor.value!
      .chain()
      .focus()
      .extendMarkRange('link')
      .unsetLink()
      .run()
    return
  }

  url = validateURL(url)

  editor.value!
    .chain()
    .focus()
    .extendMarkRange('link')
    .setLink({ href: url.href, target: url.target })
    .run()
}

function unSetLink() {
  editor.value!
    .chain()
    .focus()
    .unsetLink()
    .run()
  return
}

const themes: any = {
  status: "break-modern focus:outline-none min-h-[8ch] CommonBody",
  editor: "break-modern focus:outline-none min-h-[12ch] CommonBody",
  prose: "prose max-w-none min-h-[24ch] prose-bqfree prose-code:before:content-none prose-code:after:content-none dark:prose-invert prose-h1:text-gold-700 dark:prose-h1:text-gold-500 prose-h2:text-gold-700 dark:prose-h2:text-gold-500 prose-h3:text-gold-700 dark:prose-h3:text-gold-500 dark:prose-h4:text-white dark:prose-a:text-gold-500 prose-headings:font-display prose-headings:font-semibold break-words focus:outline-none"
}

const styles: string[] = [ "default", "alt"]

const DisableMetaEnter = Extension.create({
  addKeyboardShortcuts() {
    return {
      'Mod-Enter': () => true,
    }
  }
})

function configureStarterKit() {
  const heading = {
    levels: [2, 3, 4, 5]
  }

  let options: any = {
    codeBlock: false
  }

  if (props.options.all) {
    return {
      ...options,
      heading
    }
  }

  if (props.options.heading) {
    options = {
      heading
    }
  }

  if (!props.options.bold) {
    options = {
      ...options,
      bold: false
    }
  }

  if (!props.options.italic) {
    options = {
      ...options,
      italic: false
    }
  }

  if (!props.options.strike) {
    options = {
      ...options,
      strike: false
    }
  }

  if (!props.options.code) {
    options = {
      ...options,
      code: false
    }
  }

  if (!props.options.lists) {
    options = {
      ...options,
      bulletList: false,
      orderedList: false,
      listItem: false
    }
  }

  if (!props.options.blockquote) {
    options = {
      ...options,
      blockquote: false
    }
  }

  if (props.options.codeblock) {
    options = {
      codeBlock: true,
      ...options
    }
  }

  if (!props.options.hr) {
    options = {
      ...options,
      horizontalRule: false
    }
  }

  return options
}

const MentionConfiguration = Extension.create({
  name: 'mentionConfiguration',
  addStorage() {
    return {
      config: props.mentionConfig
    }
  }
})

function buildExtensions() {
  let extensions = []

  if (props.options.attachments) {
    extensions.push(ImageWithCaption)
    extensions.push(FileHandler.configure({
      allowedMimeTypes: acceptedTypes.value,
      onDrop: (currentEditor: any, files, pos) => {
        files.forEach(async (file) => {
          uploadImage(file, currentEditor, pos)
        })
      },
      onPaste: (currentEditor: any, files) => {
        files.forEach(file => {
          uploadImage(file, currentEditor)
        })
      }
    }))
  }

  if (props.options.mentions) {
    extensions.push(MentionConfiguration)
    extensions.push(Mention.configure({
      HTMLAttributes: {
        class: "mention",
        'data-mention-config': JSON.stringify(props.mentionConfig)
      },
      suggestion: Suggestion
    }))
  }

  if (props.options.all || props.options.link) {
    extensions.push(customLink.configure({
      openOnClick: false,
      HTMLAttributes: {
        target: "_blank",
        rel: "",
        "data-link": ""
      }
    }))
  }

  extensions.push(CharacterCount.configure({
    limit: props.limit || null
  }))

  extensions.push(Placeholder.configure({
    placeholder: props.placeholder
  }))

  const starterKitOptions = configureStarterKit()
  extensions.push(StarterKit.configure(starterKitOptions))

  extensions.push(Typography)
  extensions.push(DisableMetaEnter)

  return extensions
}

const editor = useEditor({
  extensions: buildExtensions(),
  content: props.modelValue,
  editorProps: {
    attributes: {
      class: themes[props.theme],
    },
  },
  onCreate: ({ editor }) => {
    const raw = editor.getText() as string
    emit('update:modelValue', editor.getHTML())
    emit('raw', raw)
    count.value = editor.storage.characterCount.characters()
    words.value = editor.storage.characterCount.words()
  },
  onUpdate: ({editor}) => {
    const raw = editor.getText() as string
    emit('update:modelValue', editor.getHTML())
    emit('raw', raw)
    count.value = editor.storage.characterCount.characters()
    words.value = editor.storage.characterCount.words()
  },
  autofocus: false,
  onFocus({ editor, event }) {
    emit('focus')
  },
  onBlur({ editor, event }) {
    emit('blur')
  },

})

watch(() => props.modelValue, (newValue) => {
  const isSame = editor.value?.getHTML() === newValue
  if (isSame) return
  editor.value?.commands.setContent(newValue, true)
})
</script>

<template>
  <div class="border border-gray-300 dark:border-gray-700 rounded-md grid outline-indigo-325/30 outline-2 outline-offset-2">
    <TransitionRoot appear :show="modalOpen" as="template">
      <Dialog as="div" @close="toggleModal('close')" class="relative z-[100]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                >
                  <template v-if="modalType === 'help'">
                    Editor Help
                  </template>
                </DialogTitle>
                <template v-if="modalType === 'help'">
                  <div class="flex flex-col mt-2 space-y-4">
                    <p class="text-sm">The editor is what you see is what you get (WYSIWYG), so as you apply formatting, it'll be identical to as it's displayed.</p>
                    <p class="text-sm">There are also some formatting shortcuts that you can use:</p>
                    <table class="table w-full text-sm">
                      <tbody class="space-y-1">
                        <tr v-if="options.all || options.bold">
                          <td><strong>Bold</strong></td>
                          <td><code>**bold text**</code></td>
                        </tr>
                        <tr v-if="options.all || options.italic">
                          <td><em>Italic</em></td>
                          <td><code>*italicized text*</code></td>
                        </tr>
                        <tr v-if="options.all || options.strike">
                          <td class="line-through">Strikethrough</td>
                          <td><code>~~striken text~~</code></td>
                        </tr>
                        <tr v-if="options.all || options.code">
                          <td><code>Code</code></td>
                          <td><code>`inline code`</code></td>
                        </tr>
                        <tr v-if="options.all || options.lists">
                          <td class="align-top">Unordered List</td>
                          <td>
                            <code>
                              - list item 1<br />
                              - list item 2<br />
                              - list item 3
                            </code>
                          </td>
                        </tr>
                        <tr v-if="options.all || options.lists">
                          <td class="align-top">Ordered List</td>
                          <td>
                            <code>
                              1. list item 1<br />
                              2. list item 2<br />
                              3. list item 3
                            </code>
                          </td>
                        </tr>
                        <tr v-if="options.all || options.blockquote">
                          <td>Blockquote</td>
                          <td><code>> quoted text</code></td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="flex self-end">
                      <button
                        type="button"
                        @click="toggleModal('close')"
                        class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </template>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <nav
      :class="[
        editorStyle === 'alt' ? 'dark:bg-submit-950 bg-neutral-125' : '',
        editorStyle === 'default' ? 'dark:bg-submit-900 bg-white' : '',
        'EditorMenu border-b border-gray-300 dark:border-gray-700 sticky top-0 rounded-t-md z-10 px-4 py-2 overflow-scroll scrollbar-hide'
      ]"
    >
      <ul class="flex gap-x-6 py-1">
        <li class="flex gap-x-6 items-center">
          <button type="button" title="Bold" @click="editor?.chain().focus().toggleBold().run()" class="group" v-if="options.all || options.bold">
            <svg
              :class="[
                editor?.isActive('bold') ? 'dark:fill-white fill-black' : 'fill-gray-500 dark:fill-gray-400',
                'h-4 group-hover:fill-black dark:group-hover:fill-white'
              ]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M0 64C0 46.3 14.3 32 32 32H80 96 224c70.7 0 128 57.3 128 128c0 31.3-11.3 60.1-30 82.3c37.1 22.4 62 63.1 62 109.7c0 70.7-57.3 128-128 128H96 80 32c-17.7 0-32-14.3-32-32s14.3-32 32-32H48V256 96H32C14.3 96 0 81.7 0 64zM224 224c35.3 0 64-28.7 64-64s-28.7-64-64-64H112V224H224zM112 288V416H256c35.3 0 64-28.7 64-64s-28.7-64-64-64H224 112z"/>
            </svg>
          </button>
          <button type="button" title="Italic" @click="editor?.chain().focus().toggleItalic().run()" class="group" v-if="options.all || options.italic">
            <svg
              :class="[
                editor?.isActive('italic') ? 'dark:fill-white fill-black' : 'fill-gray-500 dark:fill-gray-400',
                'h-4 group-hover:fill-black dark:group-hover:fill-white'
              ]"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
            >
              <path d="M128 64c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H293.3L160 416h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H90.7L224 96H160c-17.7 0-32-14.3-32-32z"/>
            </svg>
          </button>
          <button type="button" title="Strikethrough" @click="editor?.chain().focus().toggleStrike().run()" class="group" v-if="options.all || options.strike">
            <svg
              :class="[
                editor?.isActive('strike') ? 'dark:fill-white fill-black' : 'fill-gray-500 dark:fill-gray-400',
                'h-4 group-hover:fill-black dark:group-hover:fill-white'
              ]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M161.3 144c3.2-17.2 14-30.1 33.7-38.6c21.1-9 51.8-12.3 88.6-6.5c11.9 1.9 48.8 9.1 60.1 12c17.1 4.5 34.6-5.6 39.2-22.7s-5.6-34.6-22.7-39.2c-14.3-3.8-53.6-11.4-66.6-13.4c-44.7-7-88.3-4.2-123.7 10.9c-36.5 15.6-64.4 44.8-71.8 87.3c-.1 .6-.2 1.1-.2 1.7c-2.8 23.9 .5 45.6 10.1 64.6c4.5 9 10.2 16.9 16.7 23.9H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H270.1c-.1 0-.3-.1-.4-.1l-1.1-.3c-36-10.8-65.2-19.6-85.2-33.1c-9.3-6.3-15-12.6-18.2-19.1c-3.1-6.1-5.2-14.6-3.8-27.4zM348.9 337.2c2.7 6.5 4.4 15.8 1.9 30.1c-3 17.6-13.8 30.8-33.9 39.4c-21.1 9-51.7 12.3-88.5 6.5c-18-2.9-49.1-13.5-74.4-22.1c-5.6-1.9-11-3.7-15.9-5.4c-16.8-5.6-34.9 3.5-40.5 20.3s3.5 34.9 20.3 40.5c3.6 1.2 7.9 2.7 12.7 4.3l0 0 0 0c24.9 8.5 63.6 21.7 87.6 25.6l0 0 .2 0c44.7 7 88.3 4.2 123.7-10.9c36.5-15.6 64.4-44.8 71.8-87.3c3.6-21 2.7-40.4-3.1-58.1H335.1c7 5.6 11.4 11.2 13.9 17.2z"/>
            </svg>
          </button>
          <button type="button" title="Link" @click="setLink" class="group"  v-if="options.all || options.link">
            <svg
              :class="[
                editor?.isActive('link') ? 'dark:fill-white fill-black' : 'fill-gray-500 dark:fill-gray-400',
                'h-4 group-hover:fill-black dark:group-hover:fill-white'
              ]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/>
            </svg>
          </button>
          <button type="button" title="Unset Link" @click="unSetLink" class="group" v-if="editor?.isActive('link')">
            <svg
              class="h-4 fill-gray-500 dark:fill-gray-400 group-hover:fill-black dark:group-hover:fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L489.3 358.2l90.5-90.5c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114l-96 96-31.9-25C430.9 239.6 420.1 175.1 377 132c-52.2-52.3-134.5-56.2-191.3-11.7L38.8 5.1zM239 162c30.1-14.9 67.7-9.9 92.8 15.3c20 20 27.5 48.3 21.7 74.5L239 162zM406.6 416.4L220.9 270c-2.1 39.8 12.2 80.1 42.2 110c38.9 38.9 94.4 51 143.6 36.3zm-290-228.5L60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5l61.8-61.8-50.6-39.9z"/>
            </svg>
          </button>
          <button type="button" title="Inlline Code" @click="editor?.chain().focus().toggleCode().run()" class="group"  v-if="options.all || options.code">
            <svg
              :class="[
                editor?.isActive('code') ? 'dark:fill-white fill-black' : 'fill-gray-500 dark:fill-gray-400',
                'h-4 group-hover:fill-black dark:group-hover:fill-white'
              ]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path d="M416 32H384c-17.7 0-32 14.3-32 32s14.3 32 32 32h32c17.7 0 32 14.3 32 32v37.5c0 25.5 10.1 49.9 28.1 67.9L498.7 256l-22.6 22.6c-18 18-28.1 42.4-28.1 67.9V384c0 17.7-14.3 32-32 32H384c-17.7 0-32 14.3-32 32s14.3 32 32 32h32c53 0 96-43 96-96V346.5c0-8.5 3.4-16.6 9.4-22.6l45.3-45.3c12.5-12.5 12.5-32.8 0-45.3l-45.3-45.3c-6-6-9.4-14.1-9.4-22.6V128c0-53-43-96-96-96zM160 32c-53 0-96 43-96 96v37.5c0 8.5-3.4 16.6-9.4 22.6L9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l45.3 45.3c6 6 9.4 14.1 9.4 22.6V384c0 53 43 96 96 96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H160c-17.7 0-32-14.3-32-32V346.5c0-25.5-10.1-49.9-28.1-67.9L77.3 256l22.6-22.6c18-18 28.1-42.4 28.1-67.9V128c0-17.7 14.3-32 32-32h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H160z"/>
            </svg>
          </button>
        </li>
        <li class="flex gap-x-6 items-center border-x px-6 border-indigo-325/30"  v-if="options.all || options.lists">
          <button type="button" title="Unordered List" @click="editor?.chain().focus().toggleBulletList().run()" class="group">
            <svg
              :class="[
                editor?.isActive('bulletList') ? 'dark:fill-white fill-black' : 'fill-gray-500 dark:fill-gray-400',
                'h-4 group-hover:fill-black dark:group-hover:fill-white'
              ]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/>
            </svg>
          </button>
          <button type="button" title="Ordered List" @click="editor?.chain().focus().toggleOrderedList().run()" class="group">
            <svg
              :class="[
                editor?.isActive('orderedList') ? 'dark:fill-white fill-black' : 'fill-gray-500 dark:fill-gray-400',
                'h-4 group-hover:fill-black dark:group-hover:fill-white'
              ]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M24 56c0-13.3 10.7-24 24-24H80c13.3 0 24 10.7 24 24V176h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H56V80H48C34.7 80 24 69.3 24 56zM86.7 341.2c-6.5-7.4-18.3-6.9-24 1.2L51.5 357.9c-7.7 10.8-22.7 13.3-33.5 5.6s-13.3-22.7-5.6-33.5l11.1-15.6c23.7-33.2 72.3-35.6 99.2-4.9c21.3 24.4 20.8 60.9-1.1 84.7L86.8 432H120c13.3 0 24 10.7 24 24s-10.7 24-24 24H32c-9.5 0-18.2-5.6-22-14.4s-2.1-18.9 4.3-25.9l72-78c5.3-5.8 5.4-14.6 .3-20.5zM216 72H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 160H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 160H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24z"/>
            </svg>
          </button>
        </li>
        <li class="flex items-center" v-if="options.all || options.blockquote">
          <button type="button" title="Blockquote" @click="editor?.chain().focus().toggleBlockquote().run()" class="group">
            <svg
              :class="[
                editor?.isActive('blockquote') ? 'dark:fill-white fill-black' : 'fill-gray-500 dark:fill-gray-400',
                'h-4 group-hover:fill-black dark:group-hover:fill-white'
              ]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path d="M88 0C39.4 0 0 39.4 0 88v56 24 24c0 26.5 21.5 48 48 48H96c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48H64V88c0-13.3 10.7-24 24-24h8c17.7 0 32-14.3 32-32s-14.3-32-32-32H88zM264 0c-48.6 0-88 39.4-88 88v56 24 24c0 26.5 21.5 48 48 48h48c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48H240V88c0-13.3 10.7-24 24-24h8c17.7 0 32-14.3 32-32s-14.3-32-32-32h-8zM576 424V368 344 320c0-26.5-21.5-48-48-48H480c-26.5 0-48 21.5-48 48v48c0 26.5 21.5 48 48 48h32v8c0 13.3-10.7 24-24 24h-8c-17.7 0-32 14.3-32 32s14.3 32 32 32h8c48.6 0 88-39.4 88-88zM312 512c48.6 0 88-39.4 88-88V368 344 320c0-26.5-21.5-48-48-48H304c-26.5 0-48 21.5-48 48v48c0 26.5 21.5 48 48 48h32v8c0 13.3-10.7 24-24 24h-8c-17.7 0-32 14.3-32 32s14.3 32 32 32h8z"/>
            </svg>
          </button>
        </li>
        <li class="flex items-center border-l pl-6 border-indigo-325/30" v-if="options.attachments">
          <button @click="file.click()" type="button" title="Attachment" class="group">
            <svg
              :class="[
                'h-4 fill-gray-500 dark:fill-gray-400 group-hover:fill-black dark:group-hover:fill-white'
              ]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/>
            </svg>
            <input class="hidden" ref="file" type="file" @change="handleFileChange" :accept="acceptedTypesString" />
          </button>
        </li>
        <li class="flex flex-1 gap-x-6 place-content-end">
          <button type="button" title="Editor Help" @click="toggleModal('help')" class="group">
            <svg
              class="h-4 fill-gray-500 dark:fill-gray-400 group-hover:fill-black dark:group-hover:fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
            </svg>
          </button>
        </li>
      </ul>
    </nav>
    <div class="p-4">
      <editor-content :editor="editor" />
    </div>
    <div :class="[
      editorStyle === 'alt' ? 'dark:bg-submit-950 bg-neutral-125' : '',
      editorStyle === 'default' ? 'dark:bg-submit-900 bg-white' : '',
      'px-4 text-xs py-1 rounded-b-md border-t border-gray-300 dark:border-gray-700 text-gray-500 dark:text-gray-400'
    ]"
    class="">
      {{ count }} {{ limit ? `/ ${limit}` : '' }} character{{ count === 1 ? '' : 's' }}, {{ words }} word{{ words === 1 ? '' : 's' }}<span v-if="options.attachments">, {{ attachments.length }} attachment{{ attachments.length === 1 ? '' : 's' }}</span>
    </div>
  </div>
</template>

<style lang="postcss">
.ProseMirror {
  overflow-wrap: anywhere !important;
  word-break: normal !important;
}
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  pointer-events: none;
  height: 0;
  @apply text-gray-300 dark:text-gray-600;
}
</style>

<script setup lang="ts">
import Editor from "@/components/editor/Editor.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import { ref } from "vue"

interface Props {
  content?: string
  forAuthor: string
  forAudience: string
  type?: string
  reply?: boolean
  parent?: string
  autofocus?: boolean
  edit?: boolean
  comment?: any
  locked?: boolean
  canComment?: boolean
  forGroup?: string
}

const props = withDefaults(defineProps<Props>(), {
  reply: false,
  autofocus: true,
  edit: false,
  locked: false,
  canComment: true
})

const editorOptions = {
  bold: true,
  italic: true,
  strike: true,
  code: true,
  link: true,
  blockquote: true,
  lists: true,
  mentions: props.type !== 'event' && (props.forAudience === 'public' || props.forGroup ? true : false)
}

const mentionConfig = ref({
  contentId: props.content ?? (props.comment?.for ?? undefined),
  contentType: props.type ?? (props.comment?.forType ?? undefined),
  contentAuthorId: props.forAuthor ?? (props.comment?.forAuthor ?? undefined),
  group: props.forGroup ?? (props.comment?.forGroup ?? undefined)
})

const UserStore = useUserStore()
const { id: UserID, username, pfp, firstLetter } = storeToRefs(UserStore)

const emit = defineEmits(["cancel", "publish", "update"])

const newHtml = ref(props.edit ? props.comment.html : "")
const error = ref(false)
const publishing = ref(false)
const destroyed = ref(false)
const count = ref(0)
const raw = ref("")

function updateRaw(newRaw: string) {
  raw.value = newRaw
}

function cancel() {
  emit("cancel")
}

async function update() {
  if (publishing.value) return
  publishing.value = true
  if (!newHtml.value || newHtml.value.length === 0) return // empty comment
  if (raw.value.length > 4096) return // too long comment

  try {
    const response = await API().put(`/comments/${props.comment.forType}/${props.comment._id}`, {
      html: newHtml.value,
      raw: raw.value.trim()
    })
    emit("update", newHtml.value)
    publishing.value = false
  } catch (err) {
    error.value = true
    publishing.value = false
  }
}

async function publish() {
  if (publishing.value) return
  if (!newHtml.value || newHtml.value.length === 0) return // empty comment
  if (raw.value.length > 4096) return // too long comment
  publishing.value = true

  let data: any = {
    for: props.content,
    forType: props.type,
    forAuthor: props.forAuthor,
    forAudience: props.forAudience,
    html: newHtml.value,
    raw: raw.value.trim()
  }
  if (props.forGroup) {
    data = {
      ...data,
      forGroup: props.forGroup
    }
  }
  if (props.reply) {
    data = {
      ...data,
      child: true,
      parent: props.parent
    }
  }
  try {
    const response = await API().post("/comments", data)
    newHtml.value = ""
    destroyed.value = true

    data._id = response.data.data
    data.author = {
      _id: UserID,
      username: username,
      pfp: pfp,
      firstLetter: firstLetter
    }
    data.self = true
    data.createdAt = new Date()

    emit("publish", data)
    newHtml.value = ""
    publishing.value = false
    destroyed.value = false
  } catch (err) {
    error.value = true
    publishing.value = false
  }
}
</script>

<template>
  <div :class="{ 'editor--reply': reply, 'editor--edit': edit }" class="editor">
    <div class="pfp-container" :class="reply ? 'pt-0.5' : 'pt-[1px]'" v-if="!edit">
      <a title="Go to Your Profile" aria-label="Go to Your Profile" :href="`/${username}`" class="flex flex-none rounded-full bg-cover bg-white dark:bg-submit-900" :class="reply ? 'w-9 h-9' : 'w-10 h-10'" :style="pfp ? `background-image: url('${pfp}')` : ''">
        <span v-if="!pfp" class="flex items-center justify-center w-full h-full font-semibold text-black dark:text-gray-400" :class="reply ? 'text-xl' : 'text-2xl'">{{ firstLetter }}</span>
      </a>
    </div>
    <div class="editor-continer">
      <Editor v-model="newHtml" :options="editorOptions" v-if="!destroyed && !locked && canComment" @raw="updateRaw" :limit="4096" :mention-config="mentionConfig" />
      <div class="w-full break-modern border rounded-md dark:border-gray-700 focus:outline-none p-2" v-if="destroyed">Resetting</div>
      <div class="w-full break-modern border rounded-md dark:border-gray-700 focus:outline-none p-2" v-if="locked && canComment">Comments Locked</div>
      <div class="w-full break-modern border rounded-md dark:border-gray-700 focus:outline-none p-2" v-if="!canComment">Join the group in order to comment!</div>
    </div>
    <div class="buttons-container flex w-full justify-end gap-x-2">
      <button @click="cancel" class="mt-2 text-black dark:text-gray-500 px-4 py-1.5 rounded-md text-sm" v-if="reply || edit">Cancel</button>
      <button @click="update" class="mt-2 dark:bg-gold-500 bg-gold-700 text-black px-4 py-1.5 rounded-md text-sm" v-if="edit && !locked">{{ publishing ? "Updating" : "Update"}}</button>
      <button @click="publish" class="mt-2 dark:bg-gold-500 bg-gold-700 text-black px-4 py-1.5 rounded-md text-sm" v-if="!edit && !locked && canComment">{{ publishing ? "Commenting..." : "Comment"}}</button>
    </div>
  </div>
</template>

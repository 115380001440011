<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, inject, nextTick, type Ref, type ComputedRef } from "vue"
import LoadIndicator from "@/components/misc/LoadIndicator.vue"
import PostItem from "@/components/groups/PostItem.vue"
import { useRouter } from "vue-router"
import API from "@/api/api"

interface Props {
  listType?: string
}

const props = withDefaults(defineProps<Props>(), {
  listType: 'all'
})

const Router = useRouter()

const slug = inject("slug") as Ref<string>
const { type } = inject("type") as { type: Ref<string>, setType: (type: string) => void }
const { pinnedPosts } = inject("pinnedPosts") as { pinnedPosts: Ref<string[]>, setPinnedPosts: (pinnedPosts: string[]) => void }
const { isMember } = inject("isMember") as { isMember: Ref<boolean>, setIsMember: (isMember: boolean) => void }
const canView = inject("canView") as ComputedRef<boolean>
const canPost = inject("canPost") as ComputedRef<boolean>

const posts = ref<any[]>([])
const postCount = ref(0)
const initialLoadComplete = ref(false)
const working = ref(false)
const error = ref(false)
const pageInfo = ref<any>({
  endCursor: undefined
})

onMounted(async () => {
  if (canView.value) {
    await getPosts(false)
    initialLoadComplete.value = true
    await nextTick()
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

onBeforeUnmount(() => {
  observer.disconnect()
})

function handlePin(pinnedPost: any) {
  if (pinnedPost.isPinned) {
    pinnedPosts.value = pinnedPosts.value.filter((postId) => postId !== pinnedPost.post)
  } else {
    pinnedPosts.value.push(pinnedPost.post)
  }
}

async function getPosts(setWorking: boolean = true) {
  if (working.value) return
  if (setWorking) working.value = true

  let path = `/groups/g/${slug.value}/posts`
  let params: any = {}

  if (pageInfo.value.endCursor) {
    params = {
      ...params,
      cursor: pageInfo.value.endCursor
    }
  }

  if (props.listType === 'personals') {
    params = {
      ...params,
      type: 'personal'
    }
  }

  path = `${path}?${new URLSearchParams(params).toString()}`

  try {
    const response = await API().get(path)
    const data = response.data
    posts.value.push(...data.data)
    pageInfo.value = data.pageInfo
    if (data.totalCount) postCount.value = data.totalCount
    if (setWorking) working.value = false
  } catch (err) {
    error.value = true
    working.value = false
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getPosts()
      }
    }
  })
}, options)

function handleAction(e: any) {
  if (e.target.nodeName === 'A' || e.target.nodeName === 'IMG' || e.target.nodeName === 'SVG' || e.target.nodeName === 'path' || e.target.closest('a') || e.target.classList.contains('PostList') || e.target.classList.contains('ActionItem'))
    return
    Router.push(e.target.closest('.PostItem').getAttribute('data-to'))
}

function scrollToTop(e: Event) {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

function handleNewPost() {
  if (props.listType === 'personals') {
    Router.push(`/groups/${slug.value}/create?type=personal`)
  } else {
    Router.push(`/groups/${slug.value}/create`)
  }
}
</script>

<template>
  <div class="grid grid-cols-1 content-start">
    <header class="flex justify-between items-center text-sm font-bold">
      <div>{{ initialLoadComplete ? postCount : 'Loading' }} {{ listType === 'personals' ? 'Personal' : 'Discussion' }}{{ postCount === 1 ? '' : 's' }}</div>
      <button @click="handleNewPost" type="button" v-if="canPost">{{ listType === 'personals' ? 'Share' : 'Start' }} a New {{ listType === 'personals' ? 'Personal' : 'Discussion' }}</button>
    </header>
    <main class="mt-4" v-if="canView && initialLoadComplete && posts.length > 0">
      <ul @click="handleAction" class="PostList grid grid-cols-1 gap-2">
        <PostItem @pinned="handlePin" :post="post" v-for="post in posts" :key="post._id" />
        <li class="flex justify-center" v-if="initialLoadComplete && working">
          <LoadIndicator />
        </li>
      </ul>
      <div class="flex flex-col items-center gap-y-6 mt-8" v-if="initialLoadComplete && posts.length > 0 && !pageInfo.hasNextPage">
        <h2 class="text-xl text-gray-400 dark:text-gray-700">You've reached the end</h2>
        <button @click="scrollToTop" type="button" class="flex text-sm text-gray-400 dark:text-gray-700 dark:hover:text-gray-400 group gap-3 align-middle">
          <svg class="h-4 flex-none fill-gray-400 dark:fill-gray-700 dark:group-hover:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M32 32C14.3 32 0 46.3 0 64S14.3 96 32 96H352c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM214.6 169.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 269.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V269.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"/></svg>
          Go to the top
        </button>
      </div>
    </main>
    <main class="mt-4" v-if="canView && initialLoadComplete && posts.length === 0">
      <p class="text-sm" v-if="isMember">No {{ listType === 'personals' ? 'personals' : 'discussions' }} found. <router-link v-if="isMember && type !== 'official'" class="underline" :to="`/groups/${slug}/create${listType === 'personals' ? '?type=personal' : ''}`">{{ listType === 'personals' ? 'Share a Personal!' : 'Start a New Discussion!' }}</router-link></p>
      <p class="text-sm" v-else>No posts found. <span v-if="type !== 'official'">To {{ listType === 'personals' ? 'share a personal' : 'start a discussion' }}, join the group!</span></p>
    </main>
    <main class="mt-4" v-if="canView && !initialLoadComplete">
      <p class="text-sm">Loading...</p>
    </main>
    <main class="mt-4" v-if="!canView">
      <p class="text-sm">You must be a member of this group to view {{ listType === 'personals' ? 'personals' : 'discussions' }}.</p>
    </main>
    <div id="loadMore"></div>
  </div>
</template>

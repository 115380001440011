<script setup lang="ts">
import { useRoute } from "vue-router"
import { computed } from "vue"

const route = useRoute()

const activeRoute = computed(() => {
  return route.name
})
</script>

<template>
  <section class="px-2 mt-8">
    <nav class="container mx-auto">
      <ul class="flex w-full text-xs xs:text-sm text-gray-500 dark:text-gray-400">
        <li
          class="flex flex-1 xs:flex-initial justify-center xs:justify-start px-2 xs:px-4 pb-2 border-b"
          :class="activeRoute === 'Events.Discover' ? 'border-b-gold-700 dark:border-b-gold-500' : 'border-b-gray-700'"
        >
          <router-link to="/events" class="flex items-center gap-2 group">
            <svg
              class="h-4 xs:h-3 dark:group-hover:fill-white"
              :class="activeRoute === 'Events.Discover' ? 'fill-black dark:fill-white' : 'fill-gray-500 dark:fill-gray-400'"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/>
            </svg>
            <span class="hidden xs:inline dark:group-hover:text-white" :class="activeRoute === 'Events.Discover' ? 'dark:text-white font-semibold' : ''">Discover</span>
          </router-link>
        </li>
        <li
          class="flex flex-1 xs:flex-initial justify-center xs:justify-start px-2 xs:px-4 pb-2 border-b"
          :class="activeRoute === 'Events.Yours' ? 'border-b-gold-700 dark:border-b-gold-500' : 'border-b-gray-700'"
        >
          <router-link to="/events/yours" class="flex items-center gap-2 group">
            <svg
              class="h-4 xs:h-3 dark:group-hover:fill-white"
              :class="activeRoute === 'Events.Yours' ? 'fill-black dark:fill-white' : 'fill-gray-500 dark:fill-gray-400'"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path d="M128 0C110.3 0 96 14.3 96 32l0 32L48 64C21.5 64 0 85.5 0 112l0 48 448 0 0-48c0-26.5-21.5-48-48-48l-48 0 0-32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 32L160 64l0-32c0-17.7-14.3-32-32-32zM256 368c0-91.8 70.3-167.2 160-175.3l0-.7L0 192 0 464c0 26.5 21.5 48 48 48l282.8 0C285.6 480.1 256 427.5 256 368zm320 0a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-66.3 80.7C489.5 468.1 462.2 480 432 480s-57.5-11.9-77.7-31.3c6.2-19 24-32.7 45.1-32.7l65.2 0c21 0 38.9 13.7 45.1 32.7zM384 336a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"/>
            </svg>
            <span class="hidden xs:inline dark:group-hover:text-white" :class="activeRoute === 'Events.Yours' ? 'dark:text-white font-semibold' : ''">Your Events</span>
          </router-link>
        </li>
        <li
          class="flex flex-1 xs:flex-initial justify-center xs:justify-start px-2 xs:px-4 pb-2 border-b"
          :class="activeRoute === 'Events.Create' ? 'border-b-gold-700 dark:border-b-gold-500' : 'border-b-gray-700'"
        >
          <router-link to="/events/create" class="flex items-center gap-2 group">
            <svg
              class="h-4 xs:h-3 dark:group-hover:fill-white"
              :class="activeRoute === 'Events.Create' ? 'fill-black dark:fill-white' : 'fill-gray-500 dark:fill-gray-400'"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M96 32l0 32L48 64C21.5 64 0 85.5 0 112l0 48 448 0 0-48c0-26.5-21.5-48-48-48l-48 0 0-32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 32L160 64l0-32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192L0 192 0 464c0 26.5 21.5 48 48 48l352 0c26.5 0 48-21.5 48-48l0-272zM224 248c13.3 0 24 10.7 24 24l0 56 56 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-56 0 0 56c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-56-56 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l56 0 0-56c0-13.3 10.7-24 24-24z"/>
            </svg>
            <span class="hidden xs:inline dark:group-hover:text-white" :class="activeRoute === 'Events.Create' ? 'dark:text-white font-semibold' : ''">Create Event</span>
          </router-link>
        </li>
        <li class="hidden xs:block flex-grow px-2 pb-2 border-b border-b-gray-700"></li>
      </ul>
    </nav>
  </section>
</template>

<script setup lang="ts">
import DOMPurify from "dompurify"
import { computed } from "vue"
import { marked } from "marked"

const props = defineProps(["markdown", "selectable"])

marked.setOptions({
  gfm: true,
  breaks: true
})

const html = computed(() => {
  if (!props.markdown) return "Please enter some markdown text in the editor."
  const rawHTML = marked(props.markdown)
  return DOMPurify.sanitize(rawHTML as string, {
    ALLOWED_TAGS: [
      'pre',
      'code',
      'ul',
      'ol',
      'li',
      'p',
      'blockquote',
      'hr',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'a',
      'strong',
      'em',
      'b',
      'i',
      'del',
      'br'
    ]
  })
})
</script>

<template>
  <article :class="`prose prose-bqfree prose-code:before:content-none prose-code:after:content-none max-w-none dark:prose-invert prose-h1:text-gold-700 dark:prose-h1:text-gold-500 prose-h2:text-gold-700 dark:prose-h2:text-gold-500 prose-h3:text-gold-700 dark:prose-h3:text-gold-500 dark:prose-h4:text-white dark:prose-a:text-gold-500 prose-headings:font-display prose-headings:font-semibold ${ selectable ? '' : 'select-none' } break-words`" v-html="html">
  </article>
</template>

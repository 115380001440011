<script setup lang="ts">
import { nextTick, ref, watch, inject } from "vue"
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-3"
import API from "@/api/api"

const props = defineProps({
  ...nodeViewProps
})

const uniqueId = props.node.attrs.shortID
const shouldFadeOut = ref(false)
const removeAttachment = inject('removeAttachment') as (id: string) => void

const caption = ref(!!props.node.attrs.caption) // Initialize based on whether there's an initial caption

watch(() => props.node.attrs.uploadProgress, (newProgress) => {
  if (newProgress === 100) {
    // Wait for the next DOM update to apply the fade-out class
    nextTick(() => {
      shouldFadeOut.value = true

      // Remove the progress bar after the fade-out animation completes (adjust the timeout to match your animation duration)
      setTimeout(() => {
        props.updateAttributes({ uploadProgress: null })
        shouldFadeOut.value = false // Reset for the next upload
      }, 1000) // Assuming the fade-out animation takes 1 second
    });
  }
})

const updateCaption = (event: Event) => {
  const target = event.target as HTMLDivElement
  const newCaption = target.innerText.trim() // Trim whitespace

  // Check if the caption is empty and add/remove the 'empty-caption' class accordingly
  if (newCaption === '') {
    target.classList.add('empty-caption')
  } else {
    target.classList.remove('empty-caption')
  }

  props.updateAttributes({ caption: newCaption })
}

const toggleCaption = () => {
  if (caption.value) {
    props.updateAttributes({ caption: null }) // Remove the caption attribute
  }
  caption.value = !caption.value
}

const handleDeleteNode = () => {
  props.deleteNode()
  // send a request to delete the image from the server
  const imageId = props.node.attrs.id
  const published = props.node.attrs.published
  // delete with fetch
  if (!published) {
    try {
      API().delete(`/media/attachment/${imageId}`)
      removeAttachment(imageId)
    } catch (err) {
      console.error(err)
    }
  } else {
    removeAttachment(imageId)
  }
}
</script>

<template>
  <NodeViewWrapper>
    <figure class="p-4 bg-white dark:bg-submit-900 rounded-md" contentEditable="false" :data-ref="uniqueId" draggable="true" data-drag-handle="">
      <div class="relative rounded-md">
        <img class="my-0 rounded-md" :src="node.attrs.src" :alt="node.attrs.alt" />
        <div class="progress-bar rounded-b-md" v-if="node.attrs.uploadProgress != null" :class="{ 'fade-out': shouldFadeOut }">
          <div class="progress rounded-bl-md dark:bg-gold-500" :class="node.attrs.uploadProgress === 100 ? 'rounded-br-md' : ''" :style="{ width: node.attrs.uploadProgress + '%' }"></div>
        </div>
      </div>
      <figcaption :class="`Caption ${ node.attrs.caption ? '' : 'empty-caption'} my-2 mx-1 outline-none text-xs`" :contentEditable="true" @input="updateCaption" v-if="caption">
        {{ node.attrs.caption ? node.attrs.caption : '' }}
      </figcaption>
      <div class="mt-1 flex gap-2">
        <button class="text-xs px-2 py-1 rounded-md bg-neutral-125 dark:bg-submit-950" type="button" @click.prevent="toggleCaption">{{ caption ? 'Remove' : 'Add' }} Caption</button>
        <button class="text-xs px-2 py-1 rounded-md bg-neutral-125 dark:bg-submit-950" type="button" @click.prevent="handleDeleteNode">Remove Image</button>
      </div>
    </figure>
  </NodeViewWrapper>
</template>

<style scoped>
.Caption.empty-caption::before {
  content: 'Type a caption';
  color: #ccc; /* Placeholder text color */
  pointer-events: none; /* Prevent the placeholder from being selectable */
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px; /* Adjust the height of the progress bar */
}

.progress {
  height: 100%;
  transition: width 0.5s ease;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-out {
  animation: fadeOut 1s ease forwards; /* Use 'forwards' to retain the final state of the animation */
}
</style>

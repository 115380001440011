<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeMount, onBeforeUnmount, computed } from "vue"
import type { FormContext } from "@/components/input/Form.vue"

interface Props {
  name: string
  placeholder?: string
  type?: string
  subtype?: string
  maxLength?: string
  rows?: string
  options?: Array<{ value: string, text: string }>
  min?: number
  max?: number
  helpText?: string
  slim?: boolean
  alt?: boolean
  persist?: boolean
  disabled?: boolean
  locked?: boolean
  lockedValue?: string
  lockedDisplay?: string
  step?: number
  customClass?: string
  opignore?: boolean
  handleChange?: () => void
}

const props = withDefaults(defineProps<Props>(), {
  type: 'input',
  placeholder: 'entertext',
  subtype: 'text',
  maxLength: "256",
  rows: "5",
  helpText: '',
  slim: false,
  alt: false,
  persist: false,
  disabled: false,
  locked: false,
  lockedValue: '',
  step: 1,
  opignore: false
})

const inputClassDefaults = `w-full text-sm rounded-md ${ props.alt ? 'bg-neutral-125 dark:bg-submit-950' : 'bg-white dark:bg-submit-900'} outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50`
const textareaClassDefaults = `p-4 w-full text-sm ${ props.alt ? 'bg-neutral-125 dark:bg-submit-950' : 'bg-white dark:bg-submit-900'} placeholder:text-indigo-250/50 block rounded-md border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30`

const formContext = inject<FormContext>('formContext')
if (!formContext) {
  throw new Error(`Field: ${props.name} must be used within a Form component.`)
}

const { registerField, unregisterField, focusField, blurField, steps, errors } = formContext
const value = ref<any>("")
const passwordType = ref<string>("password")

onBeforeMount(() => {
  registerField(props.name, "field", value, props.persist, props.locked, props.step)
})

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

const lockedDisplay = computed(() => {
  if (props.locked) {
    if (props.lockedDisplay) return props.lockedDisplay
    if (props.type === 'select' && props.options) {
      return props.options?.find(option => option.value === props.lockedValue)?.text
    }
    if ((props.type === 'input' || props.type === 'textarea') && !props.lockedValue && !props.lockedDisplay) {
      return value.value
    }
    return props.lockedValue
  }
})

watchEffect(() => {
  if (props.locked) {
    value.value = props.lockedValue
  }
})

watchEffect(() => {
  error.value = errors.value[props.name]
})
</script>

<template>
  <div>
    <input
      v-if="type === 'input' && !locked"
      :name="name"
      :id="name"
      :type="subtype"
      v-model="value"
      :placeholder="placeholder === 'entertext' ? $t('entertext') : placeholder"
      :class="[
        customClass && customClass,
        (!customClass && type === 'input') && inputClassDefaults,
        (!customClass && slim) ? 'px-2' : 'p-4'
      ]"
      :disabled="disabled"
      :data-1p-ignore="opignore ? true : null"
      @focus="handleFocus"
      @blur="handleBlur"
    />
    <div
      v-if="type === 'password' && !locked"
      :class="[
        type === 'password' && inputClassDefaults,
        'flex gap-4 items-center pr-4'
      ]"
    >
      <input
        :type="passwordType"
        :name="name"
        :id="name"
        v-model="value"
        :placeholder="placeholder === 'entertext' ? $t('entertext') : placeholder"
        :class="[
          slim ? 'px-2' : 'p-4',
          `text-sm p-0 flex-grow bg-white ${ props.alt ? 'bg-neutral-125 dark:bg-submit-950' : 'bg-white dark:bg-submit-900'} placeholder:text-indigo-250/50 outline-none border-0 focus:ring-0 focus:border-0`
        ]"
        :disabled="disabled"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <svg tabindex="0" :class="`flex-none h-5 ${passwordType === 'password' ? 'dark:fill-indigo-325 fill-neutral-400' : 'dark:fill-white fill-black'} cursor-pointer`" @click="passwordType === 'password' ? passwordType = 'text' : passwordType = 'password'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <title>Toggle Password Visibility</title>
        <desc>Click/tap to toggle the password visibility.</desc>
        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
      </svg>
    </div>
    <input
      v-if="type === 'number' && !locked"
      type="number"
      v-model="value"
      :name="name"
      :id="name"
      :placeholder="placeholder === 'entertext' ? $t('entertext') : placeholder"
      :class="[
        inputClassDefaults,
        slim ? 'px-2' : 'p-4'
      ]"
      :min="min"
      :max="max"
    />
    <textarea
      v-if="type === 'textarea' && !locked"
      v-model="value"
      :name="name"
      :id="name"
      :placeholder="placeholder === 'entertext' ? $t('entertext') : placeholder"
      :rows="rows"
      :maxlength="maxLength"
      :class="[
        type === 'textarea' && textareaClassDefaults
      ]"
      :disabled="disabled"
      @focus="handleFocus"
      @blur="handleBlur"
    />
    <select
      v-if="type === 'select' && !locked"
      v-model="value"
      :name="name"
      :id="name"
      :class="[
        inputClassDefaults,
        slim ? 'pl-2 py-0' : 'p-4'
      ]"
      :disabled="disabled"
      @change="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
    >
      <slot>
        <option v-for="option in (options as any)" :value="option.value" :key="option.value">{{ option.text }}</option>
      </slot>
    </select>
    <div
      :class="[
        slim ? 'px-2' : 'p-4',
        alt ? 'bg-neutral-125 dark:bg-submit-950' : 'bg-white dark:bg-submit-900',
        `flex justify-between items-center w-full text-sm rounded-md select-none outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30`
      ]"
      v-if="locked"
    >
      {{ lockedDisplay }}
      <svg class="flex-none h-3 dark:fill-gray-400 fill-gray-500 pr-1.5" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
    </div>
    <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>
  </div>
</template>

<script setup lang="ts">
import Cropper from "@/components/utility/Cropper.vue"
import { ref, computed, onBeforeMount } from "vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const UserStore = useUserStore()
const { firstLetter, id, pfp, username } = storeToRefs(UserStore)

const initialLoadComplete = ref<boolean>(false)
const cropOpen = ref(false)
const pfpData = ref<any>(null)

onBeforeMount(async () => {
  await getPhoto()
  initialLoadComplete.value = true
})

async function getPhoto() {
  if (!pfp.value) {
    return
  }
  try {
    const response = await API().get(`/media/profile`)
    pfpData.value = response.data.data
  } catch (err) {
    console.error(err)
  }

}

async function removePhoto() {
  if (!confirm("Are you sure you want to permanently remove your profile photo?")) return
  if (pfp.value) {
    try {
      await API().delete(`/media/${pfpData.value._id}`)
      UserStore.updatePFP(undefined)
    } catch (err) {
      console.error(err)
    }
  }
}

function uploadComplete(url: string) {
  UserStore.updatePFP(url)
  cropOpen.value = false
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Profile Photo</h2>
        <p>Upload, crop, delete, or replace your profile photo. You may not have a profile photo that is focused on or includes your genitals.</p>
      </div>
      <figure>
        <div class="text-sm font-bold mb-1">Preview</div>
        <div class="flex items-center bg-white dark:bg-submit-900 rounded-md p-4">
          <figure class="flex flex-none w-12 h-12 bg-slate-300 dark:bg-[#707482] rounded-full mr-2 items-center text-2xl justify-center font-semibold bg-cover" :style="pfp ? `background-image: url('${pfp}')` : ''">
            {{ pfp ? '' : firstLetter }}
          </figure>
          <div>
            <h1 class="text-base sm:text-lg font-display font-semibold sm:leading-6">{{ username }}</h1>
            <h2 class="text-xs sm:text-sm">Human</h2>
          </div>
        </div>
        <div class="flex justify-between mt-2 text-sm">
          <span class="hover:underline cursor-pointer" tabindex="0" @click="cropOpen = true">{{ pfp ? "Replace Photo" : "Add Photo" }}</span>
          <span class="hover:underline cursor-pointer" tabindex="0" @click="removePhoto" v-if="pfp">Remove Photo</span>
        </div>
        <p class="text-sm mt-6" v-if="pfp">
          <strong>Note: </strong> Currently, replacing an existing profile image will permanently delete the old one.
        </p>
      </figure>
      <TransitionRoot appear :show="cropOpen" as="template">
        <Dialog as="div" class="relative z-10" @close="cropOpen = false">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div class="fixed inset-0 overflow-y-auto">
            <div
              class="flex min-h-full items-center justify-center p-4 text-center"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="transform overflow-hidden rounded-2xl bg-white dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                >
                  <DialogTitle
                    as="h3"
                    class="flex flex-row justify-between text-lg font-medium leading-6 dark:text-white"
                  >
                    <span>Profile Photo</span>
                    <svg @click="cropOpen = false" class="cursor-pointer w-2.5 dark:fill-indigo-250" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                  </DialogTitle>
                  <div class="mt-2">
                    <Cropper @uploaded="uploadComplete" />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </section>
  </main>
</template>

<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeUnmount, onBeforeMount } from "vue"
import type { FormContext } from "@/components/input/Form.vue"
import ComboField from "@/components/input/ComboRaw.vue"

interface Props {
  name: string
  placeholder?: string
  options: any
  multiple?: boolean
  limit?: number
  helpText?: string
  alt?: boolean
  persist?: boolean
  disabled?: boolean
  locked?: boolean
  lockedValue?: string
  lockedDisplay?: string
  step?: number
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: 'entertext',
  helpText: '',
  multiple: false,
  limit: 6,
  slim: false,
  alt: false,
  persist: false,
  disabled: false,
  locked: false,
  lockedValue: '',
  step: 1
})

const formContext = inject<FormContext>('formContext');
if (!formContext) {
  throw new Error(`Tags: ${props.name} must be used within a Form component.`)
}

const { registerField, unregisterField, focusField, blurField, errors } = formContext
const value = ref<any>([])

onBeforeMount(() => {
  registerField(props.name, "combo", value, props.persist, props.locked, props.step)
})

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

watchEffect(() => {
  error.value = errors.value[props.name]
})
</script>

<template>
  <div>
    <ComboField
      :data="options"
      v-model="value"
      :placeholder="placeholder"
      :multiple="multiple"
      :limit="limit"
      @blur="handleBlur"
      @focus="handleFocus"
    />
    <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>
  </div>
</template>

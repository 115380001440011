<script setup lang="ts">
import NotSeeking from '@/data/NotSeeking'
import Seeking from '@/data/Seeking'

interface Props {
  profileData: any
}

const { profileData } = defineProps<Props>()

function getSeeking(seeking: string) {
  return Seeking.find((seek) => seek.value === seeking)?.label
}

function getNotSeeking(notSeeking: string) {
  return NotSeeking.find((notSeek) => notSeek.value === notSeeking)?.label
}
</script>

<template>
  <div class="flex flex-col row-start-1 row-end-1 mb-6 md:mb-0 md:row-start-auto md:row-end-auto md:col-span-3 lg:col-span-4 gap-y-3">
    <details class="flex md:hidden group">
      <summary class="flex">
        <svg class="w-4 mr-2 dark:fill-white group-open:rotate-90 transition-all" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM294.6 135.1l99.9 107.1c3.5 3.8 5.5 8.7 5.5 13.8s-2 10.1-5.5 13.8L294.6 376.9c-4.2 4.5-10.1 7.1-16.3 7.1C266 384 256 374 256 361.7l0-57.7-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-57.7c0-12.3 10-22.3 22.3-22.3c6.2 0 12.1 2.6 16.3 7.1z"/></svg>
        <span class="text-sm font-semibold dark:text-white">Details</span>
      </summary>
      <div class="grid md:hidden grid-cols-2 gap-2 text-sm mt-4 dark:text-gray-400 text-gray-500">
        <div v-if="profileData.meta.roles" class="font-semibold">
          Roles:
        </div>
        <ul v-if="profileData.meta.roles" class="space-y-1">
          <li v-for="role in profileData.meta.roles">{{role}}</li>
        </ul>
        <div v-if="profileData.meta.gender" class="font-semibold">
          Gender:
        </div>
        <ul v-if="profileData.meta.gender" class="space-y-1">
          <li v-for="gender in profileData.meta.gender">{{gender}}</li>
        </ul>
        <div v-if="( (profileData.meta.sexual && profileData.meta.sexual.length > 0) || (profileData.meta.romantic && profileData.meta.romantic.length > 0) )" class="font-semibold">
          Orientations:
        </div>
        <ul v-if="( (profileData.meta.sexual && profileData.meta.sexual.length > 0) || (profileData.meta.romantic && profileData.meta.romantic.length > 0) )" class="space-y-1">
          <li v-if="(profileData.meta.sexual && profileData.meta.sexual.length > 0)" v-for="sexual in profileData.meta.sexual">{{sexual}}</li>
          <li v-if="(profileData.meta.romantic && profileData.meta.romantic.length > 0)" v-for="romantic in profileData.meta.romantic">{{romantic}}</li>
        </ul>
        <div v-if="(profileData.meta.seeking && profileData.meta.seeking.length > 0)" class="font-semibold">
          Seeking:
        </div>
        <ul v-if="(profileData.meta.seeking && profileData.meta.seeking.length > 0)" class="capitalize space-y-1">
          <li v-for="seek in profileData.meta.seeking">{{seek}}</li>
        </ul>
        <div v-if="(profileData.meta.notSeeking && profileData.meta.notSeeking.length > 0)" class="font-semibold">
          Not Seeking:
        </div>
        <ul v-if="(profileData.meta.notSeeking && profileData.meta.notSeeking.length > 0)" class="capitalize space-y-1">
          <li v-for="seek in profileData.meta.notSeeking">{{seek}}</li>
        </ul>
        <div class="font-semibold">
          Joined:
        </div>
        <div>
          {{ profileData.createdAt }}
        </div>
      </div>
    </details>
    <details class="flex md:hidden group" v-if="profileData.relationships && profileData.relationships.length > 0">
      <summary class="flex">
        <svg class="w-4 mr-2 dark:fill-white group-open:rotate-90 transition-all" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM294.6 135.1l99.9 107.1c3.5 3.8 5.5 8.7 5.5 13.8s-2 10.1-5.5 13.8L294.6 376.9c-4.2 4.5-10.1 7.1-16.3 7.1C266 384 256 374 256 361.7l0-57.7-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-57.7c0-12.3 10-22.3 22.3-22.3c6.2 0 12.1 2.6 16.3 7.1z"/></svg>
        <span class="text-sm font-semibold dark:text-white">Relationships</span>
      </summary>
      <div class="grid md:hidden grid-cols-2 gap-2 text-sm mt-4 dark:text-gray-400 text-gray-500" v-for="relationship in profileData.relationships">
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUsers && relationship.bottomUsers.length === 1">
          {{ relationship.topDisplayString }}: <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${relationship.bottomUsers[0].username}`">{{ relationship.bottomUsers[0].username }}</router-link>
        </template>
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUsers && relationship.bottomUsers.length > 1">
          <div>{{ relationship.topDisplayString }}:</div>
          <ul>
            <li v-for="user in relationship.bottomUsers">
              <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${user.username}`">{{ user.username }}</router-link>
            </li>
          </ul>
        </template>
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUserNotOnSubmit">
          <div class="col-span-full">{{ relationship.topDisplayString }} someone not on Submit</div>
        </template>
        <template v-if="relationship.mono && relationship.bottomUsers && relationship.bottomUsers.length === 1 && relationship.bottomUsers.some((user: any) => user.user === profileData._id)">
          {{ relationship.bottomDisplayString }}: <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${relationship.topUser.username}`">{{ relationship.topUser.username }}</router-link>
        </template>
        <template v-if="relationship.solo">
          {{ relationship.topDisplayString }}
        </template>
      </div>
    </details>
    <h3 class="hidden md:block text-sm font-bold dark:text-white">Details</h3>
    <div class="hidden md:grid lg:grid-cols-3 lg:gap-2 text-sm dark:text-indigo-325 mb-6">
      <div v-if="profileData.meta.roles" class="font-semibold">
        Roles:
      </div>
      <ul v-if="profileData.meta.roles" class="mb-2 lg:mb-0 col-span-2 lg:space-y-1">
        <li v-for="role in profileData.meta.roles">{{role}}</li>
      </ul>
      <div v-if="profileData.meta.gender" class="font-semibold">
        Gender:
      </div>
      <ul v-if="profileData.meta.gender" class="mb-2 lg:mb-0 col-span-2 lg:space-y-1">
        <li v-for="gender in profileData.meta.gender">{{gender}}</li>
      </ul>
      <div v-if="( (profileData.meta.sexual && profileData.meta.sexual.length > 0) || (profileData.meta.romantic && profileData.meta.romantic.length > 0) )" class="font-semibold">
        Orientations:
      </div>
      <ul v-if="( (profileData.meta.sexual && profileData.meta.sexual.length > 0) || (profileData.meta.romantic && profileData.meta.romantic.length > 0) )" class="mb-2 lg:mb-0 col-span-2 lg:space-y-1">
        <li v-if="profileData.meta.sexual && profileData.meta.sexual.length > 0" v-for="sexual in profileData.meta.sexual">{{sexual}}</li>
        <li v-if="profileData.meta.romantic && profileData.meta.romantic.length > 0" v-for="romantic in profileData.meta.romantic">{{romantic}}</li>
      </ul>
      <div v-if="(profileData.meta.seeking && profileData.meta.seeking.length > 0)" class="font-semibold">
        Seeking:
      </div>
      <ul v-if="(profileData.meta.seeking && profileData.meta.seeking.length > 0)" class="mb-2 lg:mb-0 col-span-2 capitalize lg:space-y-1">
        <li v-for="seek in profileData.meta.seeking">{{getSeeking(seek)}}</li>
      </ul>
      <div v-if="(profileData.meta.notSeeking && profileData.meta.notSeeking.length > 0)" class="font-semibold">
        Not Seeking:
      </div>
      <ul v-if="(profileData.meta.notSeeking && profileData.meta.notSeeking.length > 0)" class="mb-2 lg:mb-0 col-span-2 capitalize lg:space-y-1">
        <li v-for="seek in profileData.meta.notSeeking">{{getNotSeeking(seek)}}</li>
      </ul>
      <div class="font-semibold">
        Joined:
      </div>
      <div class="mb-2 lg:mb-0 col-span-2">
        {{ profileData.createdAt }}
      </div>
    </div>
    <h3 class="hidden lg:block text-sm font-bold dark:text-white" v-if="profileData.relationships && profileData.relationships.length > 0">Relationships</h3>
    <div class="hidden md:grid lg:grid-cols-1 lg:gap-2 text-sm dark:text-indigo-325 mb-6" v-if="profileData.relationships && profileData.relationships.length > 0">
      <div class="grid lg:grid-cols-3 lg:gap-2" v-for="relationship in profileData.relationships">
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUsers && relationship.bottomUsers.length === 1">
          {{ relationship.topDisplayString }}: <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${relationship.bottomUsers[0].username}`">{{ relationship.bottomUsers[0].username }}</router-link>
        </template>
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUsers && relationship.bottomUsers.length > 1">
          <div>{{ relationship.topDisplayString }}:</div>
          <ul>
            <li v-for="user in relationship.bottomUsers">
              <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${user.username}`">{{ user.username }}</router-link>
            </li>
          </ul>
        </template>
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUserNotOnSubmit">
          <div class="col-span-full">{{ relationship.topDisplayString }} someone not on Submit</div>
        </template>
        <template v-if="relationship.mono && relationship.bottomUsers && relationship.bottomUsers.length === 1 && relationship.bottomUsers.some((user: any) => user.user === profileData._id)">
          {{ relationship.bottomDisplayString }}: <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${relationship.topUser.username}`">{{ relationship.topUser.username }}</router-link>
        </template>
        <template v-if="relationship.solo">
          {{ relationship.topDisplayString }}
        </template>
      </div>
    </div>
    <h3 class="hidden lg:block text-sm font-bold dark:text-white">Trophy Case</h3>
    <ul class="hidden lg:grid gap-2">
      <li class="flex items-center gap-2" v-if="profileData.username === 'Apollyon'">
        <img src="https://assets.submit.gg/achievements/Trailblazer.png" class="h-16" />
        <div class="text-sm">
          <h4 class="text-sm font-bold">Trailblazer</h4>
          <p class="dark:text-gray-500">Became a backer way back when.</p>
        </div>
      </li>
      <li class="flex items-center gap-2" v-if="profileData.username === 'Apollyon'">
        <img src="https://assets.submit.gg/achievements/EarlyAccess.svg" class="h-16" />
        <div class="text-sm">
          <h4 class="text-sm font-bold">Early Adopter</h4>
          <p class="dark:text-gray-500">One of the first.</p>
        </div>
      </li>
      <!-- <li class="flex items-center gap-2" v-if="profileData.username === 'Apollyon'">
        <img src="https://assets.submit.gg/achievements/Intergalactic.png" class="h-16" />
        <div class="text-sm">
          <h4 class="text-sm font-bold">Intergalactic</h4>
          <p class="dark:text-gray-500">Shared on the Submit Podcast.</p>
        </div>
      </li> -->
      <li class="flex items-center gap-2" v-if="profileData.username === 'Apollyon'">
        <img src="https://assets.submit.gg/achievements/Verified.png" class="h-16" />
        <div class="text-sm">
          <h4 class="text-sm font-bold">Verified</h4>
          <p class="dark:text-gray-500">Verified their identity.</p>
        </div>
      </li>
      <li class="flex items-center gap-2" v-if="profileData.username === 'Apollyon'">
        <img src="https://assets.submit.gg/achievements/TheFirst.png" class="h-16" />
        <div class="text-sm">
          <h4 class="text-sm font-bold">The First</h4>
          <p class="dark:text-gray-500">User number one.</p>
        </div>
      </li>
      <li v-else class="text-sm dark:text-gray-500">No trophies configured.</li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeUnmount, onBeforeMount, computed, onMounted } from "vue"
import type { FormContext } from "@/components/input/Form.vue"

interface Props {
  name: string
  helpText?: string
  alt?: boolean
  persist?: boolean
  disabled?: boolean
  locked?: boolean
  lockedValue?: string
  lockedDisplay?: string
  step?: number
  accept?: string
  type?: string
}

const props = withDefaults(defineProps<Props>(), {
  helpText: '',
  alt: false,
  persist: false,
  disabled: false,
  locked: false,
  lockedValue: '',
  step: 1,
  accept: '*',
  type: 'cover'
})

const formContext = inject<FormContext>('formContext');
if (!formContext) {
  throw new Error(`Tags: ${props.name} must be used within a Form component.`)
}

const { registerField, unregisterField, focusField, blurField, errors } = formContext
const value = ref<any>(null)
const aspectRatio = ref<number | null>(null)
const imageWidth = ref<number | null>(null)
const imageHeight = ref<number | null>(null)

onBeforeMount(() => {
  registerField(props.name, "file", value, props.persist, props.locked, props.step, undefined, true)
})

onBeforeUnmount(() => {
  if (previewUrl.value) {
    URL.revokeObjectURL(previewUrl.value)
  }
  if (props.persist) return
  unregisterField(props.name)
})

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

watchEffect(() => {
  error.value = errors.value[props.name]
})

const fileInputRef = ref<HTMLInputElement | null>(null)
const fileName = ref<string | null>(null)
const previewUrl = ref<string | null>(null)

const isImage = computed(() => {
  return value.value && typeof value.value === 'object' && 'type' in value.value && value.value.type.startsWith('image/')
})

onMounted(() => {
  if (value.value && typeof value.value === 'object' && 'name' in value.value) {
    fileName.value = value.value.name
    if (isImage.value) {
      previewUrl.value = URL.createObjectURL(value.value)
    }
  }
})

const updateFileInfo = (file: File) => {
  value.value = file
  fileName.value = file.name
  if (file.type && file.type.startsWith('image/')) {
    if (previewUrl.value) {
      URL.revokeObjectURL(previewUrl.value)
    }
    previewUrl.value = URL.createObjectURL(file)
    const img = new Image()
    img.onload = () => {
      imageWidth.value = img.width
      imageHeight.value = img.height
      aspectRatio.value = img.width / img.height
    }
    img.src = previewUrl.value
  } else {
    previewUrl.value = null
    aspectRatio.value = null
    imageWidth.value = null
    imageHeight.value = null
  }
}

const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  if (target.files && target.files.length > 0) {
    updateFileInfo(target.files[0])
  }
}

const handleDrop = (event: DragEvent) => {
  event.preventDefault()
  if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
    updateFileInfo(event.dataTransfer.files[0])
  }
}

const handleDragOver = (event: DragEvent) => {
  event.preventDefault()
}

const openFileDialog = () => {
  fileInputRef.value?.click()
}

const removeFile = () => {
  value.value = null
  fileName.value = null
  if (previewUrl.value) {
    URL.revokeObjectURL(previewUrl.value)
    previewUrl.value = null
    aspectRatio.value = null
    imageWidth.value = null
    imageHeight.value = null
  }
  if (fileInputRef.value) {
    fileInputRef.value.value = ''
  }
}

const changeImage = () => {
  openFileDialog()
}
</script>

<template>
  <div>
    <input
      type="file"
      ref="fileInputRef"
      :accept="accept"
      class="hidden"
      @change="handleFileChange"
    >
    <section
      :class="[
        type === 'cover' ? 'border-t-2 border-x-2 border-dashed rounded-t-lg dark:border-submit-500 h-64' : '',
        type === 'status' ? 'h-32' : '',
        type === 'photo' ? 'border-black dark:border-gray-300 border border-dashed rounded-md min-h-[6rem]' : '',
        'relative flex flex-col items-center justify-center cursor-pointer overflow-hidden'
      ]"
      @click="openFileDialog"
      @drop="handleDrop"
      @dragover="handleDragOver"
    >
      <template v-if="isImage && previewUrl">
        <img :src="previewUrl" alt="Preview" class="absolute inset-0 w-full h-full" :class="type === 'status' ? 'object-contain' : 'object-cover'">
        <div class="absolute bottom-2 right-2 flex flex-col items-end">
          <p class="text-white text-xs mb-1 bg-black bg-opacity-50 px-2 py-1 rounded">{{ fileName }}</p>
          <div class="flex space-x-2">
            <button
              @click.stop="changeImage"
              class="bg-submit-500 hover:bg-submit-950 text-white text-xs font-bold py-1 px-2 rounded"
            >
              Change
            </button>
            <button
              @click.stop="removeFile"
              class="bg-red-500 hover:bg-red-600 text-white text-xs font-bold py-1 px-2 rounded"
            >
              Remove
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center text-sm">
          <h4>{{ type === 'cover' ? 'Cover Photo (680px x 256px)' : type === 'photo' ? 'Photo' : 'Media' }}</h4>
          <p v-if="!fileName" class="text-gray-500 dark:text-gray-400">
            Drag and drop an image here or click to browse.
          </p>
        </div>
      </template>
    </section>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>
  </div>
</template>

<script setup lang="ts">
import { usePlatformStore } from '@/stores/Platform'
import { watch, computed } from 'vue'
import { storeToRefs } from 'pinia'

const platformStore = usePlatformStore()
const { toasts } = storeToRefs(platformStore)

const visibleToasts = computed(() => {
  return Array.from(toasts.value)
})

watch(visibleToasts, (updatedToasts) => {
  if (updatedToasts.length > 0) {
    if (updatedToasts[0].hasTimeoutSet) return
    setToastTimeout(updatedToasts[0])
  }
})

const setToastTimeout = (toast: any) => {
  if (toast.hasTimeoutSet) return
  toast.hasTimeoutSet = true
  toast.timeoutID = setTimeout(() => {
    removeToast(toast)
  }, 5000) // 5000 defafult for 5 seconds
}

const removeToast = (toast: any) => {
  clearTimeout(toast.timeoutID)
  platformStore.removeToast(toast)
}

</script>

<template>
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 pt-16 sm:items-start sm:px-4" v-if="visibleToasts.length > 0">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end relative">
      <transition-group
        name="toast"
        tag="div"
      >
        <div v-for="(toast, index) in visibleToasts" :key="toast.id" :class="['toast pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-submit-900 shadow-lg ring-1 ring-black ring-opacity-5']">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 dark:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium dark:text-white">{{ toast.title }}</p>
                <p class="mt-1 text-sm text-gray-500">{{ toast.message }}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button @click="removeToast(toast)" class="inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <svg class="h-3.5 w-3.5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<style>
/* Adjust the duration and delay to create an overlap */
.toast-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.toast-leave-to {
  opacity: 0;
  transform: translateY(50px);
}
.toast-enter-active {
  transition: opacity 0.3s ease 0.15s, transform 0.3s ease 0.15s; /* Delay the start to allow for overlap */
}
.toast-enter-from {
  opacity: 0;
  transform: translateY(-50px); /* Adjust based on your layout */
}
.toast-enter-to {
  opacity: 1;
  transform: translateY(0);
}

/* Control the move transition for smoother reordering */
.toast-move {
  transition: transform 0.3s ease;
}

.toast {
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 10
}

.toast:nth-child(1) {
  z-index: 10;
}

/* Adjustments for the second and third visible toasts */
.toast:nth-child(2) {
  transform: translateY(10px) translateX(10px) scale(0.9);
  z-index: 9;
}

.toast:nth-child(3) {
  transform: translateY(20px) translateX(20px) scale(0.8);
  z-index: 8;
}

/* Ensuring subsequent toasts don't trigger enter animation */
.toast:nth-child(n+4) {
  transform: translateY(20px) translateX(20px) scale(0.8); /* Match the transform of the third toast */
  opacity: 0; /* Fully transparent to avoid visibility */
  z-index: auto; /* Default stacking without specific z-index */
  pointer-events: none; /* Ensures they don't interfere with interactions */
  transition: none; /* No transition to avoid unwanted animations */
}
</style>

<script setup lang="ts">
import { ref, onMounted, computed, reactive } from "vue"
import Checkbox from "@/components/input/Checkbox.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useRoute } from "vue-router"
import API from "@/api/api"
import * as zod from "zod"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue"

const schema = zod.object({
  limit: zod.boolean(),
  indicatedInterest: zod.string()
    .min(1, { message: "Required." }),
  visibility: zod.string()
    .min(1, { message: "Required." }),
  indicatedClarifying: zod.string()
    .optional()
})

const interestTypes = [
  { value: "into", label: "Into", usage: "into" },
  { value: "curious", label: "Curious", usage: "curious about" }
]

const limitTypes = [
  { value: "soft", label: "Soft Limit" },
  { value: "hard", label: "Hard Limit" }
]

const clarificationTypes = [
  { value: "giving", label: "Giving", usage: "giving" },
  { value: "receiving", label: "Receiving", usage: "receiving" },
  { value: "watching", label: "Watching", usage: "watching" },
  { value: "wearing", label: "Wearing", usage: "wearing" },
  { value: "owearing", label: "Watching Others Wear", usage: "watching others wear" },
  { value: "everything", label: "Everything", usage: "everything to do with"}
]

const visibility = [
  { value: "public", label: "Everyone" }
  // { value: "followers", label: "Followers" }
]

const Route = useRoute()

const working = ref(false)
const interest = ref<any>({})
const error = ref(false)
const initialLoadComplete = ref(false)
const users = ref<any[]>([])
const groups = ref<any[]>([])
const starterPacks = ref<any[]>([])

const associateOpen = ref(false)
const toggleOpen = async () => {
  associateOpen.value = !associateOpen.value
}

const associated = ref(false)

const initialValues = reactive({
  indicatedInterest: "",
  indicatedClarifying: "",
  visibility: "public",
  limit: false
})

const isLimit = function(tools: any) {
  return tools.getFieldValue("limit")
}

function usableInterestTypes(tools: any) {
  if (tools.getFieldValue("limit")) {
    return limitTypes
  } else {
    return interest.value.interestTypes.map((type: string) => {
      return interestTypes.find((t) => t.value === type)
    })
  }
}

const usableClarificationTypes = computed(() => {
  return interest.value.clarifyingTypes.map((type: string) => {
    return clarificationTypes.find((t) => t.value === type)
  })
})

const itLabel = computed(() => {
  if (interest.value.type === 'official') {
    if (initialValues.limit) {
      return limitTypes.find((it) => it.value === initialValues.indicatedInterest)?.label
    } else {
      return interestTypes.find((it) => it.value === initialValues.indicatedInterest)?.usage
    }
  } else {
    return "into"
  }
})

const icLabel = computed(() => {
  return clarificationTypes.find((it) => it.value === initialValues.indicatedClarifying)?.usage
})

const addLabel = computed(() => {
  if (interest.value.type === 'official') return "a kink or limit"
  if (interest.value.type === 'user') return "a kink"
  if (interest.value.type === 'interest') return "an interest"
  return "kink"
})

onMounted(async () => {
  await getInterest()
  associated.value = interest.value.associated
  initialValues.indicatedInterest = interest.value.association ? interest.value.association.indicatedInterest : ""
  initialValues.visibility = interest.value.association ? interest.value.association.visibility : "public"
  initialValues.limit = interest.value.association ? interest.value.association.indicatedLimit : false
  initialValues.indicatedClarifying = interest.value.association ? interest.value.association.indicatedClarifying : ""
  await getRandomUsers()
  await getRandomGroups()
  await getStarterPacks()
  initialLoadComplete.value = true
})

async function getInterest() {
  if (working.value) return
  working.value = true

  try {
    const response = await API().get(`/interests/${Route.params.slug}`)
    interest.value = response.data.data
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    return
  }
}

async function getRandomUsers() {
  try {
    const response = await API().get(`/interests/${interest.value._id}/users`)
    users.value = response.data.data
  } catch (err) {
    console.error(err)
    return
  }
}

async function getRandomGroups() {
  try {
    const response = await API().get(`/interests/${interest.value._id}/groups`)
    groups.value = response.data.data
  } catch (err) {
    console.error(err)
    return
  }
}

async function getStarterPacks() {
  try {
    const response = await API().get(`/interests/${interest.value._id}/starterpacks`)
    starterPacks.value = response.data.data
  } catch (err) {
    console.error(err)
    return
  }
}
async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  let interestAssociation: any = {
    indicatedInterest: values.indicatedInterest,
    visibility: values.visibility,
    limit: values.limit
  }

  if (!values.limit && values.indicatedClarifying !== "") {
    interestAssociation.indicatedClarifying = values.indicatedClarifying
  }

  if (values.limit) {
    interestAssociation.indicatedClarifying = undefined
  }

  let path = `/interests/${interest.value._id}`

  if (associated.value) {
    path += "/update"
  } else {
    path += "/associate"
  }

  try {
    await API().post(path, interestAssociation)
    associated.value = true
    working.value = false
    initialValues.indicatedInterest = values.indicatedInterest
    initialValues.visibility = values.visibility
    initialValues.limit = values.limit
    initialValues.indicatedClarifying = values.indicatedClarifying !== "" ? values.indicatedClarifying : ""
    associateOpen.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    return
  }
}

async function associateInterest() {
  if (working.value) return
  working.value = true

  try {
    await API().post(`/interests/${interest.value._id}/associate`)
    associated.value = true
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    return
  }
}

async function removeAssociation() {
  if (working.value) return
  working.value = true

  try {
    await API().delete(`/interests/${interest.value._id}/remove`)
    associated.value = false
    initialValues.indicatedInterest = ""
    initialValues.visibility = "public"
    initialValues.limit = false
    initialValues.indicatedClarifying = ""
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    return
  }
}
</script>

<template>
  <div class="grid content-start">
    <main v-if="initialLoadComplete" class="space-y-6">
      <header>
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">{{ interest.name }}</h2>
        <div v-if="associated">
          <div v-if="!initialValues.limit">You are {{ itLabel }} {{ initialValues.indicatedClarifying !== '' ? icLabel : "" }} {{ interest.name }}</div>
          <div v-else>{{ interest.name }} is a {{ itLabel }}</div>
          <ul class="flex gap-2 text-sm text-gray-500">
            <li v-if="interest.type === 'official'"><button class="dark:hover:text-gray-300 hover:text-black" @click="toggleOpen">Update</button></li>
            <li v-if="interest.type === 'official'">&middot;</li>
            <li><button class="dark:hover:text-gray-300 hover:text-black" @click="removeAssociation">Remove</button></li>
          </ul>
        </div>
        <div v-else>
          <button class="underline hover:decoration-2 hover:decoration-gold-700 dark:hover:decoration-gold-500" @click="interest.type === 'official' ? toggleOpen() : associateInterest()">Add {{ interest.name }} as {{ addLabel }}</button>
        </div>
      </header>
      <section>
        <header class="flex justify-between items-center">
          <div>
            <h3 class="font-display text-xl font-semibold">{{ interest.interestCounter }} User{{ interest.interestCounter === 1 ? '' : 's' }} into {{ interest.name }}</h3>
            <p class="text-sm text-gray-400 dark:text-gray-500">A selection of 24 random users.</p>
          </div>
          <div>
            <button class="relative flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group" @click="getRandomUsers">
              <svg class="flex-none h-3 dark:fill-gray-400 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
            </button>
          </div>
        </header>
        <ul class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-4">
          <li class="flex items-center p-4 gap-4 bg-white dark:bg-submit-900 rounded-md" v-for="user in users" :key="user._id">
            <router-link :to="`/${user.username}`">
              <figure class="flex flex-none w-12 h-12 bg-slate-300 dark:bg-[#707482] text-indigo-950 rounded-full mr-2 items-center text-2xl justify-center font-semibold bg-cover" :style="user.pfp ? `background-image: url('${user.pfp}')` : ''">
                {{ user.pfp ? '' : user.firstLetter }}
              </figure>
            </router-link>
            <div>
              <h2 class="font-display font-semibold">
                <router-link :to="`/${user.username}`">{{ user.username }}</router-link>
              </h2>
              <div class="text-sm">{{ user.meta.metaLabel }}</div>
            </div>
          </li>
        </ul>
      </section>
      <section>
        <header class="flex justify-between items-center">
          <div>
            <h3 class="font-display text-xl font-semibold">{{ interest.associatedGroupCounter }} Group{{ interest.associatedGroupCounter === 1 ? '' : 's' }} into {{ interest.name }}</h3>
            <p class="text-sm text-gray-400 dark:text-gray-500">A selection of 24 random groups.</p>
          </div>
          <div>
            <button class="relative flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group" @click="getRandomGroups">
              <svg class="flex-none h-3 dark:fill-gray-400 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
            </button>
          </div>
        </header>
        <ul class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-4">
          <li class="flex items-center p-4 gap-4 bg-white dark:bg-submit-900 rounded-md" v-for="group in groups" :key="group._id">
            <div>
              <h2 class="font-display font-semibold">
                <router-link :to="`/groups/${group.slug}`">{{ group.name }}</router-link>
              </h2>
              <div class="text-sm">{{ group.memberCount }} member{{ group.memberCount === 1 ? '' : 's' }}</div>
            </div>
          </li>
        </ul>
      </section>
      <section>
        <header class="flex justify-between items-center">
          <div>
            <h3 class="font-display text-xl font-semibold">{{ starterPacks.length }} Starter Pack{{ starterPacks.length === 1 ? '' : 's' }} include {{ interest.name }}</h3>
            <p class="text-sm text-gray-400 dark:text-gray-500">A selection of 24 random starter packs.</p>
          </div>
          <div>
            <button class="relative flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group" @click="getStarterPacks">
              <svg class="flex-none h-3 dark:fill-gray-400 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
            </button>
          </div>
        </header>
      </section>
      <TransitionRoot appear :show="associateOpen" as="template">
        <Dialog as="div" class="relative z-[100]" @close="toggleOpen">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div class="fixed inset-0 overflow-y-auto">
            <div
              class="flex min-h-full items-center justify-center p-4 text-center"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                >
                  <DialogTitle
                    as="h3"
                    class="text-xl font-medium mb-3"
                  >
                    {{ associated ? 'Update' : 'Add' }} {{ initialValues.limit ? 'Limit' : 'Kink' }}
                  </DialogTitle>
                  <Form @on-submit="handleSubmit" :schema="schema" :initial-values="initialValues" v-slot="{ tools }" class="flex flex-col space-y-3">
                    <div>
                      <Checkbox name="limit">
                        This is a limit.
                      </Checkbox>
                    </div>
                    <div>
                      <Label field="indicatedInterest" class="block mb-1 text-sm font-bold">
                        {{ isLimit(tools) ? "Limit Type" : "I am" }}
                      </Label>
                      <Field name="indicatedInterest" type="select" alt>
                        <option v-for="it in usableInterestTypes(tools)" :value="it.value">{{ it.label }}</option>
                      </Field>
                    </div>
                    <div v-if="!isLimit(tools)">
                      <Label field="indicatedClarifying">
                        Clarification (optional)
                      </Label>
                      <Field name="indicatedClarifying" type="select" alt>
                        <option value="">None</option>
                        <option v-for="ct in usableClarificationTypes" :value="ct.value">{{ ct.label }}</option>
                      </Field>
                    </div>
                    <div>
                      <Label field="visibility">
                        Visibility
                      </Label>
                      <Field name="visibility" type="select" alt>
                        <option v-for="vt in visibility" :value="vt.value">{{ vt.label }}</option>
                      </Field>
                    </div>
                    <div class="flex gap-4 self-end">
                      <button
                        type="button"
                        @click="toggleOpen"
                        class="text-sm font-bold px-4 py-1.5 dark:text-gray-400 text-gray-500"
                      >
                        Cancel
                      </button>
                      <button type="submit" class="bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 text-sm font-bold" :disabled="working">
                        {{ working ? `${associated ? 'Updating...' : 'Adding...'}` : `${associated ? 'Update' : 'Add'} ${isLimit(tools) ? 'Limit' : 'Kink'}` }}
                      </button>
                    </div>
                    <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                      <p>There was an error setting this interest, please try again.</p>
                    </div>
                  </Form>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </main>
    <main v-else>
      Loading...
    </main>
  </div>
</template>

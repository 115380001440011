<script setup lang="ts">
import { ref, inject, type Ref } from "vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import API from "@/api/api"
import * as zod from "zod"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const UserStore = useUserStore()

const schema = zod.object({
  name: zod.string()
    .min(1, { message: "Name is required." })
    .refine(name => name.toLowerCase() !== "close friends", {
      message: "The name 'Close Friends' is not allowed."
    }),
  type: zod.string().optional()
}).superRefine((data, ctx) => {
  if (props.canHaveXL && !data.type) {
    ctx.addIssue({
      code: zod.ZodIssueCode.custom,
      message: "Type is required.",
      path: ["type"]
    })
  }
})

const props = defineProps<{
  canHaveXL: boolean
}>()
const emits = defineEmits(["created"])

const debug = ref(false)
const modalOpen = inject("modalOpen") as Ref<boolean>
const toggleModal = inject("toggleModal") as () => void

const working = ref(false)
const error = ref(false)
const errorDetails = ref<any>(undefined)

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true
  error.value = false
  errorDetails.value = undefined

  if (!values.type) {
    values.type = "regular"
  }

  try {
    const response = await API().post("/circles", values)
    emits("created", {
      _id: response.data.data._id,
      code: response.data.data.code,
      name: values.name,
      type: values.type,
      count: 0
    })
    working.value = false
    UserStore.addCircle({
      _id: response.data.data._id,
      code: response.data.data.code,
      name: values.name,
      count: 0
    })
    toggleModal()
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorDetails.value = response.data
      }
    } else {
      console.error(err)
    }
    error.value = true
    working.value = false
  }
}

</script>

<template>
  <TransitionRoot as="template" :show="modalOpen">
    <Dialog as="div" class="relative z-[100]" @close="toggleModal">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl">
                  <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                    <div class="flex flex-grow flex-col">
                      <DialogTitle class="text-base font-semibold leading-6 dark:text-white">New Circle</DialogTitle>
                    </div>
                    <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="toggleModal">
                      <span class="sr-only">Close panel</span>
                      <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                    </button>
                  </div>
                  <div class="flex flex-col mt-10 flex-1 px-4 sm:px-6 justify-start space-y-3" v-if="modalOpen">
                    <Form @on-submit="handleSubmit" :schema="schema" v-slot="{ fields, errors }" class="space-y-3">
                      <div>
                        <Label field="name">Name</Label>
                        <Field name="name" help-text="The name of the circle you're creating." />
                      </div>
                      <div v-if="canHaveXL">
                        <Label field="type">Type</Label>
                        <Field name="type" type="select" help-text="XL supports up to 256 users in a circle.">
                          <option value="regular">Regular</option>
                          <option value="xl">XL</option>
                        </Field>
                      </div>
                      <div>
                        <button class="w-full mt-3 bg-gold-700 dark:bg-gold-500 dark:text-black p-4 rounded-md text-sm font-semibold" type="submit" :disabled="working">{{ working ? 'Processing...' : 'Create Circle' }}</button>
                      </div>
                      <div v-if="debug">
                        <pre>
{{ fields }}
                        </pre>
                      </div>
                      <div v-if="debug">
                        <pre>
{{ errors }}
                        </pre>
                      </div>
                    </Form>
                    <div class="mt-3" v-if="error">
                      <div class="bg-red-800 p-4 rounded-md text-red-100 text-sm font-semibold" v-if="errorDetails">
                        <h3 class="font-bold text-white">Error</h3>
                        <p>{{ errorDetails.description }}</p>
                      </div>
                      <div class="bg-red-800 p-4 rounded-md text-red-100 text-sm font-semibold" v-else>
                        <p>There was an error trying to create your circle, please try again.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

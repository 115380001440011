<script setup lang="ts">
import ActivityItem from "@/components/groups/ModerationActivityItem.vue"
import { ref, onMounted, inject, type Ref } from "vue"
import API from "@/api/api"

const slug = inject("slug") as Ref<string>

const initialLoadComplete = ref(false)
const working = ref(false)
const items = ref<any>([])
const error = ref(false)
const pageInfo = ref<any>({
  endCursor: undefined
})

onMounted(async () => {
  await getActivityItems()
  initialLoadComplete.value = true
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
})

async function getActivityItems() {
  if (working.value) return
  working.value = true

  try {
    let path =`/groups/g/${slug.value}/activity`
    let params: any = {}
    if (pageInfo.value.endCursor) {
      params = {
        ...params,
        cursor: pageInfo.value.endCursor
      }
    }
    path += `?${new URLSearchParams(params).toString()}`

    const response = await API().get(path)
    items.value.push(...response.data.data)
    pageInfo.value = response.data.pageInfo
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getActivityItems()
      }
    }
  })
}, options)
</script>

<template>
  <main class="space-y-6">
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Activity Log</h2>
      <p>Review all of the activity, including moderation actions, that has taken place in the group since it was created.</p>
    </section>
    <section class="flow-root">
      <ul role="list">
        <ActivityItem v-for="(item, itemIndex) in items" :key="item._id" :item="item" :index="itemIndex" :length="items.length" v-if="initialLoadComplete && items.length > 0" />
        <li v-if="!initialLoadComplete">
          <div class="relative">
            <div class="relative flex items-start space-x-3">
              <div>
                <div class="relative px-1">
                  <div class="flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-submit-600 ring-8 ring-neutral-125 dark:ring-submit-950"></div>
                </div>
              </div>
              <div class="min-w-0 flex-1 py-1.5">
                <div class="text-sm dark:text-gray-400">
                  Loading...
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div id="loadMore"></div>
    </section>
  </main>
</template>

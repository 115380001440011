<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeMount, onBeforeUnmount, computed, provide } from "vue"
import type { FormContext } from "@/components/input/Form.vue"
import { v4 as uuid } from "uuid"

interface Props {
  name: string
  cmpnt: any
  count?: number
  limit: number
  label: string
  helpText?: string
  persist?: boolean
  disabled?: boolean
  step?: number
  addLabel?: string
}

const props = withDefaults(defineProps<Props>(), {
  count: 0,
  helpText: '',
  persist: false,
  disabled: false,
  step: 1,
  addLabel: 'Add'
})

const formContext = inject<FormContext>('formContext')
if (!formContext) {
  throw new Error(`Field: ${props.name} must be used within a Form component.`)
}

const { registerField, unregisterField, focusField, blurField, steps, errors } = formContext
const value = ref<any[]>([])
const count = ref(props.count)

onBeforeMount(() => {
  registerField(props.name, "array", value, props.persist, false, props.step)
})

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

watchEffect(() => {
  error.value = errors.value[props.name]
})

function addDataToArray(data: any, id: string) {
  const idx = value.value.findIndex((obj) => obj.id === id)
  value.value[idx] = data
}

function removeObjectFromArray(id: string) {
  const idx = value.value.findIndex((obj) => obj.id === id)
  value.value.splice(idx, 1)
  count.value --
}

provide('addDataToArray', addDataToArray)
provide('removeObjectFromArray', removeObjectFromArray)

function addComponent() {
  if (count.value < props.limit) {
    value.value.push({ id: uuid() })
    count.value ++
  }
}
</script>

<template>
  <div class="space-y-3">
    <div class="text-sm font-bold">{{ label }} (limit of {{ limit }})</div>
    <ul class="grid grid-cols-1 xl:grid-cols-2 gap-4">
      <li v-for="(n, idx) in value" :key="n.id" v-if="value.length > 0">
        <component :is="cmpnt" :initial-data="n" :count="idx" @focus="handleFocus" @blur="handleBlur" />
      </li>
      <li v-if="value.length === 0">
        No {{ label }} added.
      </li>
    </ul>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">Validation Error: All fields must be completed.</small>
    <button class="text-sm font-bold" type="button" @click="addComponent" v-if="value.length < limit">{{ addLabel }}</button>
    <div class="text-sm text-gray-500" v-if="value.length === limit">Limit Reached, remove items to add more.</div>
    <div class="text-sm text-gray-500">{{ helpText }}</div>
  </div>
</template>

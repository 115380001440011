<script setup lang="ts">
import { computed, ref, inject, type Ref, type ComputedRef } from "vue"
import Reactions from "@/components/reactions/Reactions.vue"
import { usePlatformStore } from "@/stores/Platform"
import D from "@/composables/TimeDisplay"
import API from "@/api/api"

const Platform = usePlatformStore()

const canPin = inject("canPin") as ComputedRef<boolean>
const groupId = inject("groupId") as Ref<string>
const slug = inject("slug") as Ref<string>
const emits = defineEmits(["pinned"])
const props = defineProps(["post"])
const isPinned = ref(props.post.isPinned)
const working = ref(false)
const error = ref(false)

const typeLabel = computed(() => {
  switch (props.post.type) {
    case "text":
      return "Text Post"
    case "image":
      return "Media Post"
    case "poll":
      return "Poll Post"
    case "personal":
      return "Personals Post"
  }
})

async function togglePin() {
  if (working.value) return
  working.value = true

  let path = `/groups/g/${slug.value}/posts/${props.post.short}/pin`

  try {
    const response = await API().get(path)
    emits('pinned', {
      isPinned: isPinned.value,
      post: props.post._id
    })
    isPinned.value = !isPinned.value
    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
    return
  }
}

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: props.post.user._id,
    contentId: props.post._id,
    contentType: "group:post",
    group: groupId.value,
    url: `/groups/${slug.value}/${props.post.short}`
  })
}
</script>

<template>
  <li :data-to="`/groups/${slug}/${post.short}`" class="PostItem px-4 pt-4 pb-3 bg-white dark:bg-submit-900 hover:dark:bg-submit-700 rounded-md cursor-pointer">
    <header class="flex items-center gap-x-2 mb-0.5">
      <svg v-if="isPinned" class="flex-none w-4 h-4 fill-gray-400 dark:fill-gray-500" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M32 32C32 14.3 46.3 0 64 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H290.5l11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3H32c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64H64C46.3 64 32 49.7 32 32zM160 384h64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V384z"/></svg>
      <router-link :to="`/groups/${slug}/${post.short}`" class="text-gold-700 dark:text-gold-500 text-xl font-display font-semibold">{{ post.title }}</router-link>
    </header>
    <div class="flex items-center gap-1.5 text-xs">
      <router-link :to="`/${post.user.username}`" class="flex flex-none items-center justify-center bg-neutral-125 dark:bg-submit-950 rounded-full bg-cover w-5 h-5" :style="post.user.pfp ? `background-image: url('${post.user.pfp}')` : ''">
        <span class="font-semibold text-black dark:text-gray-400 dark:hover:text-white" v-if="!post.user.pfp">{{ post.user.firstLetter }}</span>
      </router-link>
      <div>
        <span class="font-semibold">
          <router-link class="hover:underline" :to="`/${post.user.username}`">{{ post.user.username }}</router-link>
        </span>
        <span class="text-xs dark:text-gray-400 text-gray-500">
          &nbsp;&middot;&nbsp;
          <time :title="D(post.createdAt).format('LLLL')">{{ D(post.createdAt).locale('submit').fromNow() }}</time>
          &nbsp;&middot;&nbsp;
          <span>{{ typeLabel }}</span>
        </span>
      </div>
    </div>
    <article :class="`space-y-4 ${ post.type !== 'personal' ? 'my-4' : 'mt-4'}`">
      <router-link :to="`/groups/${slug}/${post.short}`" class="text-sm leading-[22px]">{{ post.raw }}{{ post.hasMore ? '... ' : '' }}<span class="dark:text-gold-500 font-semibold" v-if="post.hasMore" >More</span></router-link>
    </article>
    <footer v-if="post.type !== 'personal'">
      <ul class="flex gap-4 items-center text-xs text-gray-500 dark:text-gray-400">
        <li>
          <Reactions :reactions="post.reactionsCount" :path="`/groups/${slug}/${post.short}`" :reacted="post.hasReacted" :reactionData="post.reactionData ? post.reactionData : undefined" small/>
        </li>
        <li>
          <router-link class="flex gap-x-2 group hover:dark:text-white" :to="`/groups/${slug}/${post.short}`">
            <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
            {{ post.commentCount }} Comment{{ post.commentCount === 1 ? '' : 's' }} <span v-if="post.lastCommentUser">(Last comment {{ D(post.lastComment).locale('submit').fromNow() }} ago by {{ post.lastCommentUsername }})</span>
          </router-link>
        </li>
        <li class="flex flex-grow gap-x-4 justify-end">
          <svg v-if="!post.isAuthor" @click="openReportDialog" class="ActionItem flex-none w-4 h-4 dark:fill-gray-400 cursor-pointer hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
          <svg tabindex="0" @click="togglePin" v-if="canPin" class="ActionItem flex-none w-4 h-4 dark:fill-gray-600 cursor-pointer hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M32 32C32 14.3 46.3 0 64 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H290.5l11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3H32c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64H64C46.3 64 32 49.7 32 32zM160 384h64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V384z"/></svg>
        </li>
      </ul>
    </footer>
  </li>
</template>

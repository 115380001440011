<script setup lang="ts">
import NewComment from "@/components/comments/NewComment.vue"
import Comment from "@/components/comments/Comment.vue"
import { ref, onMounted, computed } from "vue"
import API from "@/api/api"

interface Props {
  type: string,
  content: string,
  forAuthor: string,
  forAudience: string,
  locked: boolean,
  size?: string,
  group?: any
  event?: any
  short?: any
}

const props = withDefaults(defineProps<Props>(), {
  size: "full"
})

const initialLoadComplete = ref(false)
const isFetching = ref(false)
const loading = ref(false)
const error = ref(false)
const commentCount = ref(0)
const comments = ref<any[]>([])
const deeplinked = ref(false)
const direction = ref("below")
const pageInfo = ref<any>({
  deeplink: false,
  initialPull: true,
  endCursor: undefined
})

const isLocked = computed(() => {
  if (props.locked) {
    return true
  } else if (props.group) {
    return !props.group.moderation.commentingEnabled ? !props.group.moderation.commentingEnabled : false
  } else {
    return false
  }
})

const canComment = computed(() => {
  if (props.group && props.group.isMember) {
    return true
  } else if (props.group && !props.group.isMember) {
    return false
  } else {
    return true
  }
})

function handleReply(comment: any) {
  comments.value.push(comment)
  commentCount.value++
}

function handleChildReply() {
  commentCount.value++
}

onMounted(async () => {
  if (location.hash) {
    deeplinked.value = true
  }
  await fetchComments()
  initialLoadComplete.value = true
  if (pageInfo.value.hasNextPage && !pageInfo.value.deeplink) {
    let target = document.querySelector('#loadMoreV2')
    observer.observe(target as any)
  }
})

async function fetchComments(dir: string = "below") {
  if (isFetching.value) return
  isFetching.value = true
  loading.value = true

  direction.value = dir

  let path = `/comments/v2/${props.type}/${props.content}`

  if (deeplinked.value && pageInfo.value.initialPull) {
    let hash = location.hash
    path = `/comments/v2/${props.type}/${props.content}?${new URLSearchParams({
      deepLinkId: hash
    })}`
  } else if (!deeplinked.value && pageInfo.value.initialPull) {
    path = `/comments/v2/${props.type}/${props.content}`
  } else {
    path = `/comments/v2/${props.type}/${props.content}?${new URLSearchParams({
      cursor: direction.value === "below" ? pageInfo.value.endCursor : pageInfo.value.startCursor,
      direction: direction.value
    })}`
  }

  try {
    const response = await API().get(path)

    const data = response.data.data

    if (direction.value === "below") {
      comments.value.push(...data)
      if (pageInfo.value.initialPull) {
        pageInfo.value = response.data.pageInfo
        pageInfo.value.initialPull = false
      } else {
        pageInfo.value.hasNextPage = response.data.pageInfo.hasNextPage
        pageInfo.value.endCursor = response.data.pageInfo.endCursor
      }
    } else {
      comments.value.unshift(...data)
      pageInfo.value.hasPreviousPage = response.data.pageInfo.hasPreviousPage
      pageInfo.value.startCursor = response.data.pageInfo.startCursor
    }

    if (response.data.pageInfo.totalCount) {
      commentCount.value = response.data.pageInfo.totalCount
    }

    loading.value = false
    isFetching.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    loading.value = false
    return
  }
}

let options = {
  root: null, // observing changes with respect to the document's viewport
  rootMargin: '0px', // margin around the root
  threshold: 0.1 // triggers the observer callback when 10% of the target is visible
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    // when our #loadMoreIndicator element comes into view
    if (entry.isIntersecting) {
      // load more content
      if (
        initialLoadComplete.value &&
        !loading.value &&
        pageInfo.value.hasNextPage
      ) {
        fetchComments("below")
      }
    }
  })
}, options)
</script>

<template>
  <section class="container mx-auto">
    <div :class="[
      size === 'medium' ? 'xl:w-3/4 2xl:w-3/5' : '',
      'w-full'
    ]">
      <div class="mb-6" v-if="type !== 'post'">
        <small class="font-bold">{{ initialLoadComplete ? commentCount : "Loading" }} Comments {{ isLocked ? "(Locked)" : "" }}</small>
      </div>
      <NewComment :forGroup="group ? group._id : undefined" :forAudience="forAudience" :forAuthor="forAuthor" :locked="isLocked" :canComment="canComment" :content="content" :type="type" @publish="handleReply" :reply="false" :autofocus="false" />
      <div class="mt-12 space-y-6">
        <ul class="space-y-4">
          <li tabindex="0" class="cursor-pointer py-2" @click="fetchComments('above')" v-if="pageInfo.hasPreviousPage && !isFetching">Load more comments</li>
          <li class="animate-pulse py-2" v-if="isFetching && direction === 'above'">Loading more...</li>
          <Comment :canComment="canComment" :group="group" :event="event" :short="short" @child="handleChildReply" :forAudience="forAudience" :locked="isLocked" :comment="comment" v-for="comment in comments" :key="comment._id" />
          <li class="text-sm" v-if="initialLoadComplete && comments.length === 0">No comments, yet.</li>
          <li v-if="!initialLoadComplete">Loading...</li>
          <li tabindex="0" class="cursor-pointer py-2" @click="fetchComments('below')" v-if="pageInfo.hasNextPage && pageInfo.deeplink && !isFetching">Load more comments</li>
          <li class="animate-pulse" v-if="initialLoadComplete && isFetching && direction === 'below'">Loading more...</li>
        </ul>
        <div v-if="comments.length > 0" id="loadMoreV2"></div>
      </div>
    </div>
  </section>
</template>

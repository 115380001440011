<script setup lang="ts">
import { useRouter } from "vue-router"
import { computed } from "vue"

const Router = useRouter()

interface Props {
  reactions: { [key: string]: number }
  path: string
  reacted: boolean
  reactionData?: any
  small?: boolean
}

const props = defineProps<Props>()

const reactionDisplay = computed(() => {
  return Object.entries(props.reactions).filter(([type, count]) => count > 0)
})

function getEmojiForType(type: string) {
  return type === "like" ? "👍" : type === "dislike" ? "👎" : type === "love" ? "❤️" : type === "hot" ? "🥵" : type === "laugh" ? "😂" : type === "cry" ? "😢" : type === "angry" ? "😠" : type === "celebrate" ? "🥳" : ""
}

function goToPath() {
  Router.push(props.path)
}
</script>

<template>
  <ul :class="`flex items-center gap-1 min-h-[30px]`">
    <li v-for="([type, count]) in reactionDisplay" :key="type" v-if="reactionDisplay.length > 0">
      <button
        :class="`flex items-center gap-2 border border-transparent rounded-md px-1.5 py-0.5 ${reacted && reactionData && type === reactionData.type ? 'dark:border-gold-500/75 border-gold-700/75' : 'border-transparent'}`"
        type="button"
        @click="goToPath"
      >
        <span :class="`${small ? 'text-base' : 'text-xl'}`">{{ getEmojiForType(type) }}</span>
        {{ count }}
      </button>
    </li>
    <li v-if="reactionDisplay.length === 0">
      <span>No reactions yet</span>
    </li>
  </ul>
</template>

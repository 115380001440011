<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeMount, onBeforeUnmount, computed } from "vue"
import type { FormContext } from "@/components/input/Form.vue"
import Label from "@/components/input/Label.vue"
import API from "@/api/api"
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot
} from "@headlessui/vue"

interface Props {
  name: string
  type: string
  helpText?: string
  persist?: boolean
  disabled?: boolean
  locked?: boolean
  lockedValue?: string
  lockedDisplay?: string
  step?: number
}

const props = withDefaults(defineProps<Props>(), {
  persist: false,
  disabled: false,
  locked: false,
  lockedValue: '',
  step: 1
})

const formContext = inject<FormContext>('formContext')
if (!formContext) {
  throw new Error(`Field: ${props.name} must be used within a Form component.`)
}

const { registerField, unregisterField, focusField, blurField, steps, errors } = formContext
const value = ref<any[]>([])
const newData = ref(<any>[])
const selectedItem = ref<any>(null)

const label = computed(() => {
  switch(props.type) {
    case "groups":
      return `Groups (${value.value.length})`
    case "users":
      return `Users (${value.value.length})`
    default:
      return ""
  }
})

const placeholder = computed(() => {
  switch(props.type) {
    case "groups":
      return "Search for a group to add"
    case "users":
      return "Search for a user to add"
    default:
      return "Search for a thing to add"
  }
})

const path = computed(() => {
  switch(props.type) {
    case "groups":
      return "/groups/typeahead"
    case "users":
      return "/starterpacks/users/typeahead"
    default:
      return "/"
  }
})

const recordProperty = computed(() => {
  switch(props.type) {
    case "groups":
      return "name"
    case "users":
      return "username"
    default:
      return ""
  }
})

onBeforeMount(() => {
  registerField(props.name, "starterpacks:list", value, props.persist, props.locked, props.step)
})

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

watchEffect(() => {
  error.value = errors.value[props.name]
})

async function getTypeheadData(search: string) {
  try {
    if(search.length > 2) {
      const response = await API().post(path.value, { query: search })
      // remove any items already in the list
      const filteredData = response.data.data.filter((item: any) => !value.value.some((v: any) => v._id === item._id))
      return newData.value = filteredData
    }
    return newData.value = []
  } catch (err) {
    if (err) console.error(err)
  }
}

const handleSelect = (item: any) => {
  if (item && !value.value.some(v => v._id === item._id)) {
    value.value.push(item)
  }
  selectedItem.value = null // Clear the current selection
}
</script>

<template>
  <div>
    <Label :field="name">{{ label }}</Label>
    <Combobox v-model="selectedItem" :nullable="true" @update:modelValue="handleSelect">
      <div class="relative">
        <ComboboxInput
          :placeholder="placeholder"
          @change="getTypeheadData($event.target.value)"
          @focus="handleFocus"
          @blur="handleBlur"
          :displayValue="() => ''"
          class="w-full text-sm py-4 px-4 border-0 rounded-md bg-white dark:bg-submit-900 ring-0 focus:ring-0 outline-none focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50"
        />
        <TransitionRoot
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ComboboxOptions class="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div
              v-if="newData.length === 0"
              class="relative cursor-default select-none py-2 px-4"
            >
              Nothing found.
            </div>
            <ComboboxOption
              v-for="record in newData"
              :value="record"
              v-slot="{ selected, active }"
              as="template"
            >
              <li class="relative cursor-default select-none py-2 px-4"
              :class="{
                'dark:bg-gold-500 bg-gold-700 text-black': active,
                'dark:text-gray-300': !active,
              }">
                {{ record[recordProperty] }}
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
    <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>

    <div class="mt-3 flex flex-wrap gap-2">
      <div v-for="item in value" :key="item._id"
           class="bg-white dark:bg-submit-700 px-3 py-1 rounded-full flex items-center gap-2 text-sm">
        {{ item[recordProperty] }}
        <button @click="value = value.filter(u => u._id !== item._id)"
                class="text-gray-500 hover:text-gray-700">&times;</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import GroupItem from "@/components/groups/GroupItem.vue"
import Filter from "@/components/input/Filter.vue"
import setupCommons from "@/composables/Common"
import { ref, onMounted, watch } from "vue"
import { useRouter } from "vue-router"
import API from "@/api/api"

const { actionHandler, scrollToTop } = setupCommons()

const sortOptions = [
  { label: "Newest", value: "newest" },
  { label: "Oldest", value: "oldest" },
  { label: "Most Members", value: "most-members" },
  { label: "Least Members", value: "least-members" },
  { label: "Last Activity", value: "last-activity" }
]

const Router = useRouter()

const props = defineProps(["type"])
const sortBy = ref("most-members")
const groups = ref<any[]>([])
const initialLoadComplete = ref(false)
const working = ref(false)
const error = ref(false)
const pageInfo = ref<any>({
  endCursor: undefined
})

watch(sortBy, async () => {
  groups.value = []
  pageInfo.value = {
    endCursor: undefined
  }
  initialLoadComplete.value = false
  await getGroups()
  initialLoadComplete.value = true
})

onMounted(async () => {
  await getGroups(false)
  initialLoadComplete.value = true
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
})

async function getGroups(setWorking: boolean = true) {
  if (working.value) return
  if (setWorking) working.value = true

  let sortByValue = ""
  let sortDirection = ""
  switch(sortBy.value) {
    case "newest":
      sortByValue = "createdAt"
      sortDirection = "desc"
      break
    case "oldest":
      sortByValue = "createdAt"
      sortDirection = "asc"
      break
    case "most-members":
      sortByValue = "memberCount"
      sortDirection = "desc"
      break
    case "least-members":
      sortByValue = "memberCount"
      sortDirection = "asc"
      break
    case "last-activity":
      sortByValue = "lastActivity"
      sortDirection = "desc"
      break
  }

  let path = `/groups`
  let params: any = {
    type: props.type,
    sortBy: sortByValue,
    sortDirection: sortDirection
  }

  if (pageInfo.value.endCursor) {
    params = {
      ...params,
      cursor: pageInfo.value.endCursor
    }
  }

  path = `${path}?${new URLSearchParams(params)}`

  try {
    const response = await API().get(path)
    const data = response.data
    groups.value.push(...data.data)
    pageInfo.value = data.pageInfo
    if (setWorking) working.value = false
  } catch (err) {
    error.value = true
    working.value = false
  }
}

let options = {
  root: null, // observing changes with respect to the document's viewport
  rootMargin: '0px', // margin around the root
  threshold: 0.1 // triggers the observer callback when 10% of the target is visible
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    // when our #loadMore element comes into view
    if (entry.isIntersecting) {
      // load more content
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getGroups()
      }
    }
  })
}, options)
</script>

<template>
  <div class="grid grid-cols-1">
    <header class="flex justify-between items-center">
      <div class="text-sm font-bold">All Groups</div>
      <div class="flex gap-2">
        <Filter label="Sort" :options="sortOptions" v-model="sortBy" />
        <button class="relative flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group cursor-not-allowed" disabled>
          <svg class="flex-none h-3 dark:fill-gray-400 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
        </button>
      </div>
    </header>
    <main class="mt-4" v-if="initialLoadComplete && groups.length > 0">
      <ul @click="actionHandler" class="GroupList ItemList grid grid-cols-1 gap-2">
        <GroupItem v-for="group in groups" :group="group" />
        <li class="flex flex-col items-center gap-y-6 mt-8 TheEnd" v-if="groups.length > 0 && !pageInfo.hasNextPage">
          <h2 class="text-xl text-gray-400 dark:text-gray-700">You've reached the end</h2>
          <button @click="scrollToTop" type="button" class="flex text-sm text-gray-400 dark:text-gray-700 dark:hover:text-gray-400 group gap-3 align-middle">
            <svg class="h-4 flex-none fill-gray-400 dark:fill-gray-700 dark:group-hover:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M32 32C14.3 32 0 46.3 0 64S14.3 96 32 96H352c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM214.6 169.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 269.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V269.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"/></svg>
            Go to the top
          </button>
        </li>
      </ul>
    </main>
    <main class="mt-4" v-if="initialLoadComplete && groups.length === 0">
      <p class="text-sm">No groups found.</p>
    </main>
    <main class="mt-4" v-if="!initialLoadComplete">
      <p class="text-sm">Loading...</p>
    </main>
    <div id="loadMore"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue"
import API from "@/api/api"

const loading = ref(true)
const working = ref(false)
const linkStatus = ref('unlinked')
const linkCode = ref('')

onMounted(() => {
  checkLinkStatus()
})

// check link status
async function checkLinkStatus() {
  try {
    const response = await API().get(`/discord/status`)
    const data = response.data.data
    linkStatus.value = data.status
    if (data.status === 'code') {
      linkCode.value = data.code
    }
    loading.value = false
  } catch (err) {
    loading.value = false
  }
}

// generate link code
async function generateLinkCode() {
  if (working.value) return
  working.value = true

  try {
    const response = await API().post(`/discord/code`)
    const data = response.data.data
    linkStatus.value = 'code'
    linkCode.value = data.code
    working.value = false
  } catch (err) {
    working.value = false
  }
}

// unlink discord
async function unlinkDiscord() {
  if (working.value) return
  working.value = true

  try {
    await API().delete(`/discord/unlink`)
    linkStatus.value = 'unlinked'
    working.value = false
  } catch (err) {
    working.value = false
  }
}

</script>

<template>
  <main>
    <section v-if="loading">
      Loading...
    </section>
    <section class="space-y-3" v-if="!loading">
      <h3 class="dark:text-white text-xl">Discord</h3>
      <p v-if="linkStatus === 'linked'">Your Discord account has been linked to your Submit account.</p>
      <p v-if="linkStatus === 'code' || linkStatus === 'unlinked'">If you'd like to participate in our Discord server, you can generate a link code here to get verified on the server and gain access.</p>
      <code class="block text-lg font-bold" v-if="linkStatus === 'code'">{{ linkCode }}</code>
      <small class="dark:text-gray-400 text-gray-500 block" v-if="linkStatus === 'code'">You can copy and paste this code into the Discord server with the <code>/link</code> command. You can find more instructions on the verification process in the Discord server.</small>
      <ul class="flex flex-wrap gap-4">
        <li v-if="linkStatus === 'unlinked'">
          <button @click="generateLinkCode" class="font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 text-black py-2 px-12">Generate Link Code</button>
        </li>
        <li v-if="linkStatus === 'linked'">
          <button @click="unlinkDiscord" class="font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 text-black py-2 px-12">{{ working ? 'Processing...' : 'Unlink' }}</button>
        </li>
        <li v-if="linkStatus === 'unlinked'">
          <a class="inline-flex font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 text-black py-2 px-12" href="https://discord.gg/submitgg" target="_blank">Join the Discord</a>
        </li>
      </ul>
    </section>
  </main>
</template>

<script setup lang="ts">
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { inject, type Ref } from "vue"
import { storeToRefs } from "pinia"
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem
} from "@headlessui/vue"

const Platform = usePlatformStore()
const UserStore = useUserStore()

const { roles } = storeToRefs(UserStore)
const isAdmin = roles.value.includes("admin")

const Event = inject<Ref<any>>("Event") as Ref<any>
const eventId = inject<Ref<string>>("eventId") as Ref<string>
const status = inject<Ref<string>>("status") as Ref<string>
const eventType = inject<Ref<string>>("eventType") as Ref<string>
const isOwner = inject<Ref<boolean>>("isOwner") as Ref<boolean>
const isAttending = inject<Ref<boolean>>("isAttending") as Ref<boolean>
const isConsidering = inject<Ref<boolean>>("isConsidering") as Ref<boolean>
const isRequested = inject<Ref<boolean>>("isRequested") as Ref<boolean>
const categoryLabel = inject<Ref<string>>("categoryLabel") as Ref<string>
const isRequestOnly = inject<Ref<boolean>>("isRequestOnly") as Ref<boolean>
const isRejected = inject<Ref<boolean>>("isRejected") as Ref<boolean>
const full = inject<Ref<boolean>>("full") as Ref<boolean>
const FormatTime = inject<Function>("FormatTime") as (date: string) => string
const RSVPtoEvent = inject<Function>("RSVPtoEvent") as () => void
const withdrawRSVP = inject<Function>("withdrawRSVP") as () => void
const considerEvent = inject<Function>("considerEvent") as () => void
const withdrawConsideration = inject<Function>("withdrawConsideration") as () => void
const withdrawRequest = inject<Function>("withdrawRequest") as () => void

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: Event.value.organizer,
    contentId: eventId.value,
    contentType: "event"
  })
}

function openModerationDialog() {
  Platform.setModerationData({
    context: "event",
    eventId: eventId.value
  })
  Platform.toggleModerationOpen()
}
</script>

<template>
  <header class="px-2 bg-submit-925">
    <div class="container mx-auto py-6">
      <div class="w-full flex flex-wrap items-center gap-4">
        <div class="flex flex-col w-full sm:w-auto sm:flex-1">
          <h1 class="font-display text-white text-3xl font-bold">
            <router-link :to="`/e/${Event.short}`">{{ Event.name }}</router-link>
          </h1>
          <h2 class="text-sm text-gray-300">{{ eventType }} Event, <span class="capitalize">{{ categoryLabel }}</span></h2>
          <h3 class="text-xs text-gray-300" :class="status === 'cancelled' ? 'line-through' : ''">{{ FormatTime(Event.actualStartDateTime) }}</h3>
        </div>
        <ul class="flex w-full sm:w-auto gap-x-2" v-if="!isRejected">
          <li class="w-full sm:w-auto" v-if="!isAttending && !isRequested && !isOwner && status === 'active' && !full">
            <button
              :class="[
                'font-bold text-gray-300 bg-submit-950 hover:text-gold-700 dark:hover:text-gold-500 py-2 px-4 rounded-md text-sm w-full sm:w-auto'
              ]"
              @click="RSVPtoEvent"
            >
              {{ isRequestOnly ? 'Request to Attend' : 'RSVP' }}
            </button>
          </li>
          <li class="w-full sm:w-auto" v-if="!isAttending && !isConsidering && !isRequested && !isOwner && !isRequestOnly && status === 'active' && !full">
            <button
              :class="[
                'font-bold text-gray-300 bg-submit-950 hover:text-gold-700 dark:hover:text-gold-500 py-2 px-4 rounded-md text-sm w-full sm:w-auto'
              ]"
              @click="considerEvent"
            >
              Considering
            </button>
          </li>
          <li class="w-full sm:w-auto" v-if="isAttending && !isConsidering && !isRequested && !isOwner && status === 'active'">
            <button
              :class="[
                'font-bold text-gray-300 bg-submit-950 hover:text-gold-700 dark:hover:text-gold-500 py-2 px-4 rounded-md text-sm w-full sm:w-auto'
              ]"
              @click="withdrawRSVP"
            >
              Withdraw RSVP
            </button>
          </li>
          <li class="w-full sm:w-auto" v-if="isConsidering && !isAttending && !isRequested && !isOwner && !isRequestOnly && status === 'active'">
            <button
              :class="[
                'font-bold text-gray-300 bg-submit-950 hover:text-gold-700 dark:hover:text-gold-500 py-2 px-4 rounded-md text-sm w-full sm:w-auto'
              ]"
              @click="withdrawConsideration"
            >
              Withdraw Consideration
            </button>
          </li>
          <li class="w-full sm:w-auto" v-if="isRequested && !isAttending && !isConsidering && !isOwner && status === 'active'">
            <button
              :class="[
                'font-bold text-gray-300 bg-submit-950 hover:text-gold-700 dark:hover:text-gold-500 py-2 px-4 rounded-md text-sm w-full sm:w-auto'
              ]"
              @click="withdrawRequest"
            >
              Withdraw Request
            </button>
          </li>
          <li class="w-full sm:w-auto" v-if="!isOwner && status === 'active' && !isRequested && !isAttending && !isConsidering && full">
            <div class="font-bold text-gray-300 bg-submit-950 py-2 px-4 rounded-md text-sm w-full sm:w-auto">Event Full</div>
          </li>
          <li class="w-full sm:w-auto" v-if="isOwner && status === 'active'">
            <div class="font-bold text-gray-300 bg-submit-950 py-2 px-4 rounded-md text-sm w-full sm:w-auto">Your Event</div>
          </li>
          <li class="w-full sm:w-auto" v-if="status === 'cancelled'">
            <div class="font-bold text-gray-300 bg-submit-950 py-2 px-4 rounded-md text-sm w-full sm:w-auto">Cancelled</div>
          </li>
          <li class="w-full sm:w-auto" v-if="status === 'completed'">
            <div class="font-bold text-gray-300 bg-submit-950 py-2 px-4 rounded-md text-sm w-full sm:w-auto">Event Over</div>
          </li>
          <Menu as="li" class="relative" v-if="(!isOwner && status === 'active') || isAdmin">
            <MenuButton class=" bg-submit-950 py-2.5 px-4 rounded-[10px] text-xs font-bold hover:text-yellow-450 transition cursor-pointer group">
              <svg class="fill-white h-4 group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M56 472a56 56 0 1 1 0-112 56 56 0 1 1 0 112zm0-160a56 56 0 1 1 0-112 56 56 0 1 1 0 112zM0 96a56 56 0 1 1 112 0A56 56 0 1 1 0 96z"/></svg>
            </MenuButton>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems class="origin-top-right divide-y divide-indigo-350 absolute top-10 right-0 w-48 rounded-md shadow-lg bg-submit-600 ring-1 ring-transparent ring-opacity-5 focus:outline-none z-50">
                <div class="p-1">
                  <MenuItem v-slot="{ active }" v-if="!isOwner">
                    <button
                      type="button"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-gray-300'
                      ]"
                      @click="openReportDialog"
                    >
                    <svg class="w-3 mr-3 fill-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                      Report
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="isAdmin">
                    <button
                      type="button"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-gray-300'
                      ]"
                      @click="openModerationDialog"
                    >
                    <svg class="w-3 mr-3 fill-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M230.1 .8l152 40c8.6 2.3 15.3 9.1 17.3 17.8s-1 17.8-7.8 23.6L368 102.5v8.4c0 10.7-5.3 20.8-15.1 25.2c-24.1 10.8-68.6 24-128.9 24s-104.8-13.2-128.9-24C85.3 131.7 80 121.6 80 110.9v-8.4L56.4 82.2c-6.8-5.8-9.8-14.9-7.8-23.6s8.7-15.6 17.3-17.8l152-40c4-1.1 8.2-1.1 12.2 0zM227 48.6c-1.9-.8-4-.8-5.9 0L189 61.4c-3 1.2-5 4.2-5 7.4c0 17.2 7 46.1 36.9 58.6c2 .8 4.2 .8 6.2 0C257 114.9 264 86 264 68.8c0-3.3-2-6.2-5-7.4L227 48.6zM98.1 168.8c39.1 15 81.5 23.2 125.9 23.2s86.8-8.2 125.9-23.2c1.4 7.5 2.1 15.3 2.1 23.2c0 70.7-57.3 128-128 128s-128-57.3-128-128c0-7.9 .7-15.7 2.1-23.2zM134.4 352c2.8 0 5.5 .9 7.7 2.6l72.3 54.2c5.7 4.3 13.5 4.3 19.2 0l72.3-54.2c2.2-1.7 4.9-2.6 7.7-2.6C387.8 352 448 412.2 448 486.4c0 14.1-11.5 25.6-25.6 25.6H25.6C11.5 512 0 500.5 0 486.4C0 412.2 60.2 352 134.4 352zM352 408c-3.5 0-6.5 2.2-7.6 5.5L339 430.2l-17.4 0c-3.5 0-6.6 2.2-7.6 5.5s.1 6.9 2.9 9L331 454.8l-5.4 16.6c-1.1 3.3 .1 6.9 2.9 9s6.6 2 9.4 0L352 470.1l14.1 10.3c2.8 2 6.6 2.1 9.4 0s4-5.7 2.9-9L373 454.8l14.1-10.2c2.8-2 4-5.7 2.9-9s-4.2-5.5-7.6-5.5l-17.4 0-5.4-16.6c-1.1-3.3-4.1-5.5-7.6-5.5z"/></svg>
                      Moderation
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </ul>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import RomanticOrientations from "@/data/RomanticOrientations"
import { ref, onBeforeMount, onMounted, reactive } from "vue"
import SexualOrientations from "@/data/SexualOrientations"
import Fieldset from "@/components/input/Fieldset.vue"
import Location from "@/components/input/Location.vue"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Combo from "@/components/input/Combo.vue"
import NotSeekingList from "@/data/NotSeeking"
import Form from "@/components/input/Form.vue"
import PronounOptions from "@/data/Pronouns"
import GenderOptions from "@/data/Gender"
import SeekingList from "@/data/Seeking"
import RoleOptions from "@/data/Roles"
import API from "@/api/api"
import * as zod from "zod"

const initialDetails = reactive({
	pronoun: "",
	gender: [],
	sexual: [],
	romantic: [],
	roles: [],
	seeking: [],
  notSeeking: []
})

const initialSummary = reactive({
  summary: ""
})

const initialLocation = reactive({
  location: {
    place_name_en: undefined
  }
})

const detailsSchema = zod.object({
  pronoun: zod.string()
    .min(1, { message: "This is required." }),
  gender: zod.array(zod.string())
    .optional(),
  sexual: zod.array(zod.string())
    .optional(),
  romantic: zod.array(zod.string())
    .optional(),
  roles: zod.array(zod.string())
    .min(1, { message: "This is required." }),
  seeking: zod.array(zod.string())
    .optional(),
  notSeeking: zod.array(zod.string())
    .optional()
})

const summarySchema = zod.object({
  summary: zod.string()
    .min(1, { message: "This is required." })
})

const locationSchema = zod.object({
  location: zod.object({
    place_name_en: zod.string()
      .min(1, { message: "This is required." }),
  })
})

const debug = ref(false)
const initialLoadComplete = ref(false)
const working = ref<boolean>(false)
const initialMeta = ref<any>({})
const locationSet = ref<boolean>(false)
const populateComplete = ref(false)

const detailsError = ref(false)
const detailsSuccess = ref(false)
const detailsButtonLabel = ref("Save Details")

const summaryError = ref(false)
const summarySuccess = ref(false)
const summaryButtonLabel = ref("Save Summary")

const locationError = ref(false)
const locationSuccess = ref(false)
const locationButtonLabel = ref("Save Location")

onBeforeMount(async () => {
  await getMetaDetails()
  initialDetails.pronoun = initialMeta.value.meta.pronoun || ""
  initialDetails.gender = initialMeta.value.meta.gender || []
  initialDetails.sexual = initialMeta.value.meta.sexual || []
  initialDetails.romantic = initialMeta.value.meta.romantic || []
  initialDetails.roles = initialMeta.value.meta.roles || []
  initialDetails.seeking = initialMeta.value.meta.seeking || []
  initialDetails.notSeeking = initialMeta.value.meta.notSeeking || []
  initialSummary.summary = initialMeta.value.meta.summary || ""
  initialLocation.location = initialMeta.value.location || { place_name_en: undefined }
  locationSet.value = initialMeta.value.location ? true : false
  populateComplete.value = true
})

onMounted(async () => {
  initialLoadComplete.value = true
})

async function getMetaDetails() {
  if (working.value) return
  working.value = true

  try {
    const response = await API().get("/meta")
    initialMeta.value = response.data.data
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

async function detailsSubmit(values: any) {
  if (working.value) return
  working.value = true
  detailsButtonLabel.value = "Saving..."
  try {
    let dataToSend = {
      "meta.roles": values.roles ? values.roles : [],
      "meta.pronoun": values.pronoun ? values.pronoun : "none",
      "meta.gender": values.gender ? values.gender : [],
      "meta.sexual": values.sexual ? values.sexual : [],
      "meta.romantic": values.romantic ? values.romantic : [],
      "meta.seeking": values.seeking ? values.seeking : [],
      "meta.notSeeking": values.notSeeking ? values.notSeeking : []
    }

    const response = await API().post("/meta", dataToSend)
    detailsButtonLabel.value = "Save Details"
    detailsSuccess.value = true
    setTimeout(() => {
      detailsSuccess.value = false
    }, 5000)
    working.value = false
  } catch (err) {
    if (err) console.error(err)
    detailsError.value = true
    detailsButtonLabel.value = "Save Details"
    working.value = false
    setTimeout(() => {
      detailsError.value = false
    }, 5000)
  }
}

async function summarySubmit(values: any) {
  if (working.value) return
  working.value = true
  summaryButtonLabel.value = "Saving..."
  try {
    let dataToSend = {
      "meta.summary": values.summary ? values.summary : ""
    }

    const response = await API().post("/meta", dataToSend)
    summaryButtonLabel.value = "Save Summary"
    summarySuccess.value = true
    setTimeout(() => {
      summarySuccess.value = false
    }, 5000)
    working.value = false
  } catch (err) {
    if (err) console.error(err)
    summaryError.value = true
    summaryButtonLabel.value = "Save Summary"
    working.value = false
    setTimeout(() => {
      summaryError.value = false
    }, 5000)
  }
}

async function locationSubmit(values: any) {
  if (working.value) return
  working.value = true
  locationButtonLabel.value = "Saving..."
  try {
    let dataToSend = <any>{
      location: values.location
    }

    await API().post("/meta", dataToSend)
    locationButtonLabel.value = "Save Location"
    locationSuccess.value = true
    locationSet.value = true
    setTimeout(() => {
      locationSuccess.value = false
    }, 5000)
    working.value = false
  } catch (err) {
    if (err) console.error(err)
    locationError.value = true
    locationButtonLabel.value = "Save Location"
    working.value = false
    setTimeout(() => {
      locationError.value = false
    }, 5000)
  }
}
</script>

<template>
  <main class="space-y-24" v-if="populateComplete && initialLoadComplete">

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Details</h2>
        <p>Share more about how you see yourself and what you're looking for (and not!) here on Submit.</p>
      </div>
      <Form @on-submit="detailsSubmit" :initial-values="initialDetails" :schema="detailsSchema" v-slot="{ fields, errors }" class="space-y-3">
        <div>
          <Label field="roles">Roles</Label>
          <Combo name="roles" :options="RoleOptions" help-text="Max 6. Select 'Not Applicable' to opt out of displaying a role." multiple />
        </div>
        <div>
          <Label field="pronoun">Pronoun</Label>
          <Combo name="pronoun" :options="PronounOptions" help-text="Max 1. Select 'Not Applicable' to opt out of displaying a pronoun." />
        </div>
        <div>
          <Label field="gender">Gender</Label>
          <Combo name="gender" :options="GenderOptions" :limit="3" help-text="Max 3. Select 'Not Applicable' to opt out of displaying a gender." multiple />
        </div>
        <div>
          <Label field="sexual">Sexual Orientation</Label>
          <Combo name="sexual" :options="SexualOrientations" :limit="3" help-text="Max 3. Select 'Not Applicable' to opt out of displaying a sexual orientation." multiple />
        </div>
        <div>
          <Label field="romantic">Romantic Orientation</Label>
          <Combo name="romantic" :options="RomanticOrientations" :limit="3" help-text="Max 3. Select 'Not Applicable' to opt out of displaying a romantic orientation." multiple />
        </div>
        <div>
          <Fieldset name="seeking" :options="SeekingList">
            <template #legend>Seeking</template>
          </Fieldset>
        </div>
        <div>
          <Fieldset name="notSeeking" :options="NotSeekingList">
            <template #legend>Not Seeking</template>
          </Fieldset>
        </div>
        <div class="pt-3">
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ detailsButtonLabel }}</button>
        </div>
        <div v-if="detailsError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="detailsSuccess" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>
        <div v-if="debug">
          <pre class="whitespace-pre-wrap">
{{ fields }}
          </pre>
        </div>
      </Form>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Summary</h2>
        <p>A bite-sized description about yourself that's used in your profile card, groups, and other places throughout Submit.</p>
      </div>
      <Form @on-submit="summarySubmit" :initial-values="initialSummary" :schema="summarySchema" v-slot="{ fields, errors }" class="space-y-3">
        <div>
          <Label field="summary">Summary</Label>
          <Editor name="summary" :limit="150" />
        </div>
        <div class="pt-3">
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ summaryButtonLabel }}</button>
        </div>
        <div v-if="summaryError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="summarySuccess" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>
        <div v-if="debug">
          <pre class="whitespace-pre-wrap">
{{ fields }}
          </pre>
        </div>
      </Form>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Location</h2>
        <p>Share where you are in the world. You can control how much of your location data you share and with whom in your <router-link class="underline hover:decoration-2" to="/settings/privacy/settings">privacy settings</router-link>.</p>
      </div>
      <Form @on-submit="locationSubmit" :initial-values="initialLocation" :schema="locationSchema" v-slot="{ fields, errors }" class="space-y-3">
        <Location name="location" :is-set="locationSet" />
        <div class="pt-3">
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ locationButtonLabel }}</button>
        </div>
        <div v-if="locationError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="locationSuccess" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>
        <div v-if="debug">
          <pre class="whitespace-pre-wrap">
{{ fields }}
          </pre>
          <pre class="whitespace-pre-wrap">
{{ errors }}
          </pre>
        </div>
      </Form>
    </section>

  </main>
  <main v-else>
    <p>Loading...</p>
  </main>
</template>

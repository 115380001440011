<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeMount, onBeforeUnmount, computed } from "vue"
import type { FormContext } from "@/components/input/Form.vue"

interface Props {
  name: string
  placeholder?: string
  persist?: boolean
  disabled?: boolean
  locked?: boolean
  lockedValue?: string
  lockedDisplay?: string
  step?: number
  customClass: string
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: 'entertext',
  persist: false,
  disabled: false,
  locked: false,
  lockedValue: '',
  step: 1
})

const formContext = inject<FormContext>('formContext')
if (!formContext) {
  throw new Error(`Custom Field: ${props.name} must be used within a Form component.`)
}

const { registerField, unregisterField, focusField, blurField, steps, errors } = formContext
const value = ref<any>("")
const passwordType = ref<string>("password")

onBeforeMount(() => {
  registerField(props.name, "custom", value, props.persist, props.locked, props.step)
})

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

const lockedDisplay = computed(() => {
  if (props.locked) {
    if (props.lockedDisplay) return props.lockedDisplay
    return props.lockedValue
  }
})

watchEffect(() => {
  if (props.locked) {
    value.value = props.lockedValue
  }
})

watchEffect(() => {
  error.value = errors.value[props.name]
})
</script>

<template>
  <input
    :name="name"
    type="input"
    :id="name"
    v-model="value"
    :placeholder="placeholder === 'entertext' ? $t('entertext') : placeholder"
    :class="[
      customClass
    ]"
    :disabled="disabled"
    @focus="handleFocus"
    @blur="handleBlur"
  />
</template>

<script setup lang="ts">
import D from "@/composables/TimeDisplay"
import API from "@/api/api"
import { ref } from "vue"

const props = defineProps(["group"])
const status = ref(props.group.status)
const count = ref(props.group.mailCount)

async function approveReservation() {
  try {
    const response = await API().get(`/groups/reservations/${props.group._id}/approve`)
    status.value = "approved"
  } catch (err) {
    console.error(err)
  }
}

async function rejectReservation() {
  try {
    const response = await API().get(`/groups/reservations/${props.group._id}/reject`)
    status.value = "rejected"
  } catch (err) {
    console.error(err)
  }
}

async function resendEmail() {
  try {
    const response = await API().get(`/groups/reservations/${props.group._id}/resend`)
    const data = response.data
    if (data.mailed) {
      count.value++
    } else {
      console.error(data.data)
    }
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <li class="p-4 bg-white dark:bg-submit-900 rounded-md">
    <h2 class="flex font-display font-semibold gap-x-1 items-center">
      {{ group.name }} ({{ group.type }})
    </h2>
    <p class="mt-2">{{ group.description }}</p>
    <p class="mt-2">Requested By: <a :href="`/${group.user.username}`">{{ group.user.username }}</a></p>
    <p class="mt-2 text-sm">Status: {{ status }}</p>
    <div class="mt-2 text-xs dark:text-gray-400">
      Requested {{ D(group.createdAt).fromNow() }}
    </div>
    <div class="mt-2 text-xs dark:text-gray-400" v-if="status === 'approved'">
      Last Updated {{ group.updatedAt ? D(group.updatedAt).fromNow() : "N/A" }}
      Mail Sent: {{ group.mailSent ? D(group.mailLastSent).fromNow() : "N/A" }}
      Mail Count: {{ count }}
    </div>
    <ul class="mt-3 border-t-2 border-t-neutral-125 dark:border-t-submit-950 pt-3 flex gap-x-4 text-sm">
      <li v-if="status === 'pending'" class="cursor-pointer hover:underline" @click="approveReservation">Approve</li>
      <li v-if="status === 'pending'" class="cursor-pointer hover:underline" @click="rejectReservation">Reject</li>
      <li v-if="status === 'approved'" class="cursor-pointer hover:underline" @click="resendEmail">Resend Email</li>
    </ul>
  </li>
</template>

<script setup lang="ts">
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { ref, inject, type Ref } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const schema = zod.object({
  subject: zod.string()
    .min(3, { message: "Too short." }),
  message: zod.string()
    .min(10, { message: "Too short." })
})

const UserStore = useUserStore()
const { id } = storeToRefs(UserStore)

interface Props {
  user: any
  canMessage: boolean
  needsMessageRequest: boolean
  messageModalOpen: boolean
}

const props = withDefaults(defineProps<Props>(), {
  canMessage: false,
  needsMessageRequest: false,
  messageModalOpen: false
})

const toggleMessageModal = inject("toggleMessageModal") as () => void
const { requestSent, setRequestSent } = inject("requestSent") as { requestSent: Ref<boolean>, setRequestSent: () => void }

const modalType = ref("message")
const error = ref(false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")
const working = ref(false)

async function sendMessageRequest() {
  if (working.value) return
  working.value = true

  let path = `/messages/request`

  try {
    await API().post(path, {to: props.user._id})
    modalType.value = "complete"
    working.value = false
    setRequestSent()
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorMessage.value = response.data.message
        errorCode.value = response.data.code
      }
    } else {
      errorMessage.value = "There was an error sending your message request, please try again."
      errorCode.value = "NEC"
      console.error(err)
    }
    error.value = true
    working.value = false
  }
}

async function sendMessage(values: any) {
  if (working.value) return
  working.value = true

  let path = `/messages`
  let params: any = {
    to: props.user._id,
    toType: "user",
    from: id.value,
    fromType: "user",
    type: "message",
    subject: values.subject,
    message: values.message
  }

  try {
    await API().post(path, params)
    modalType.value = "complete"
    working.value = false
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorMessage.value = response.data.message
        errorCode.value = response.data.code
      }
    } else {
      errorMessage.value = "There was an error sending your message, please try again."
      errorCode.value = "NEC"
      console.error(err)
    }
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <TransitionRoot appear :show="messageModalOpen" as="template">
    <Dialog as="div" @close="toggleMessageModal" class="relative z-[100]">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-2 sm:p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-lg transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-925 px-2 xs:px-4 py-6 sm:px-6 sm:py-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
              >
                <template v-if="modalType === 'message' && canMessage && !needsMessageRequest">
                  New Message
                </template>
                <template v-if="modalType === 'complete' && canMessage && !needsMessageRequest">
                  New Message
                </template>
                <template v-if="modalType === 'message' && canMessage && needsMessageRequest">
                  Message Request
                </template>
                <template v-if="modalType === 'complete' && canMessage && needsMessageRequest">
                  Message Request
                </template>
              </DialogTitle>
              <template v-if="modalType === 'message' && canMessage && needsMessageRequest">
                <section class="space-y-6" v-if="!user.messageConditions.requested && !requestSent">
                  <p>{{ user.username }} has message requests enabled. In order to send this user a message, they'll need to approve your message request first.</p>
                  <div class="flex justify-end gap-4">
                    <div>
                      <button @click="toggleMessageModal" type="button" class="text-sm px-4 py-1.5">Cancel</button>
                    </div>
                    <div>
                      <button @click="sendMessageRequest" type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5" :disabled="working">{{ working ? 'Processing...' : 'Send Request' }}</button>
                    </div>
                  </div>

                  <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                    <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                  </div>
                </section>
                <section class="space-y-6" v-else>
                  <p>Your have a pending message request. You'll be notified when {{ user.username }} approves your request.</p>
                  <div class="flex justify-end gap-4">
                    <div>
                      <button @click="toggleMessageModal" type="button" class="text-sm px-4 py-1.5">Close</button>
                    </div>
                  </div>
                </section>
              </template>
              <template v-if="modalType === 'message' && canMessage && !needsMessageRequest">
                <Form @on-submit="sendMessage" :schema="schema" v-slot="{fields, errors}" class="space-y-6">
                  <div>
                    <Label field="title">
                      Subject
                    </Label>
                    <Field name="subject" alt />
                  </div>

                  <div>
                    <Label field="message">
                      Message
                    </Label>
                    <Editor name="message" theme="editor" />
                  </div>

                  <div class="flex justify-end gap-4">
                    <div>
                      <button @click="toggleMessageModal" type="button" class="text-sm px-4 py-1.5">Cancel</button>
                    </div>
                    <div>
                      <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5" :disabled="working">{{ working ? 'Sending...' : 'Send Message' }}</button>
                    </div>
                  </div>

                  <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                    <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                  </div>
                </Form>
              </template>
              <template v-if="modalType === 'complete'">
                <div class="flex flex-col mt-2 space-y-4">
                  <p v-if="modalType === 'complete' && canMessage && !needsMessageRequest">Your message has been sent.</p>
                  <p v-if="modalType === 'complete' && canMessage && needsMessageRequest">Your message request has been sent.</p>
                  <div class="flex self-end">
                    <button
                      type="button"
                      @click="toggleMessageModal"
                      class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </template>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import type { FormContext } from "@/components/input/Form.vue"
import TagsField from "@/components/input/TagsRaw.vue"
import { inject, ref, watchEffect } from "vue"

// Define props with modelValue as type 'any' to accept all types of values
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  limit: {
    type: Number,
    default: 6
  },
  helpText: {
    type: String,
    default: ''
  },
  persist: {
    type: Boolean,
    default: false
  },
  locked: {
    type: Boolean,
    default: false
  },
  step: {
    type: Number,
    default: 1
  },
  alt: {
    type: Boolean,
    default: false
  }
})

const formContext = inject<FormContext>('formContext');
if (!formContext) {
  throw new Error(`Tags: ${props.name} must be used within a Form component.`)
}

const { registerField, focusField, blurField, errors } = formContext
const value = ref<any>([])

registerField(props.name, "tags", value, props.persist, props.locked, props.step)

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

watchEffect(() => {
  error.value = errors.value[props.name]
})
</script>

<template>
  <div>
    <TagsField
      :name="name"
      :value="value"
      v-model="value"
      :limit="limit"
      :alt="alt"
      @focus="handleFocus"
      @blur="handleBlur"
    />
    <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, inject, watch, type Ref } from "vue"
import API from "@/api/api"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const groupsSetting = inject('groupsSetting') as Ref<boolean>
const toggleGroupsSetting = inject('toggleGroupsSetting') as () => void

const working = ref(false)
const error = ref(false)
const subscriptions = ref<any[]>([])

watch(groupsSetting, async (value) => {
  if (value) {
    if (subscriptions.value.length === 0) await getSubscriptions()
  }
})

async function getSubscriptions() {
  if (working.value) return
  working.value = true
  try {
    const response = await API().get(`/groups/subscriptions`)
    subscriptions.value = response.data.data
  } catch (e) {
    console.error(e)
    error.value = true
  } finally {
    working.value = false
  }
}

async function unsubscribe(id: string) {
  if (working.value) return
  working.value = true
  try {
    await API().get(`/groups/g/${id}/unsubscribe`)
    subscriptions.value = subscriptions.value.filter(subscription => subscription._id !== id)
  } catch (e) {
    console.error(e)
    error.value = true
  } finally {
    working.value = false
  }
}

</script>

<template>
  <TransitionRoot as="template" :show="groupsSetting">
    <Dialog as="div" class="relative z-[100]" @close="toggleGroupsSetting">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md transform transition-all">
                <div class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl">
                  <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                    <div class="flex flex-grow flex-col">
                      <DialogTitle class="text-base font-semibold leading-6 dark:text-white">Groups Feed</DialogTitle>
                      <p class="text-sm">Manage your subscriptions</p>
                    </div>
                    <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="toggleGroupsSetting">
                      <span class="sr-only">Close panel</span>
                      <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                    </button>
                  </div>
                  <div class="flex flex-col mt-10 flex-1 px-4 sm:px-6 justify-start space-y-6">
                    <ul class="space-y-3 text-sm">
                      <li v-for="subscription in subscriptions" :key="subscription._id" class="flex items-center justify-between">
                        {{ subscription.name }}
                        <button @click="unsubscribe(subscription._id)" class="text-xs dark:bg-submit-925 dark:hover:bg-submit-800 px-3 py-1.5 rounded-md dark:text-gray-400" type="button">Unsubscribe</button>
                      </li>
                    </ul>
                  </div>
                  <div class="flex border-t border-t-indigo-350 mt-6 pt-6 px-4 sm:px-6">
                    <div class="flex justify-end w-full">
                      <button type="button" @click="toggleGroupsSetting" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Close</button>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

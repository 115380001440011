<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, onUnmounted, inject } from "vue"
import LoadIndicator from "@/components/misc/LoadIndicator.vue"
import FeedItem from "@/components/feed/FeedItem.vue"
import API from "@/api/api"

const profileData: any = inject("profileData")

const SCROLL_POSITION_KEY = `SBMT_ACTIVITY_scrollPosition_${profileData.value._id}`
const FEED_DATA_KEY = `SBMT_ACTIVITY_feedData_${profileData.value._id}`
const PAGE_INFO_KEY = `SBMT_ACTIVITY_pageInfo_${profileData.value._id}`

const initialLoadComplete = ref(false)
const working = ref(false)
const maintenance = ref(false)
const activityList = ref<any[]>([])
const error = ref(false)
const pageInfo = ref<any>({
  endCusor: undefined
})

onMounted(async () => {
  if (maintenance.value) return

  const savedFeedData = sessionStorage.getItem(FEED_DATA_KEY)
  const savedScrollPosition = sessionStorage.getItem(SCROLL_POSITION_KEY)
  const savedPageInfo = sessionStorage.getItem(PAGE_INFO_KEY)

  if (savedFeedData && savedPageInfo) {
    activityList.value = JSON.parse(savedFeedData)
    pageInfo.value = JSON.parse(savedPageInfo)
    initialLoadComplete.value = true
    if (savedScrollPosition) {
      requestAnimationFrame(() => {
        window.scrollTo(0, parseInt(savedScrollPosition))
      })
    }
    sessionStorage.removeItem(SCROLL_POSITION_KEY)
    sessionStorage.removeItem(FEED_DATA_KEY)
    sessionStorage.removeItem(PAGE_INFO_KEY)
  } else {
    await fetchFeed()
    initialLoadComplete.value = true
  }
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
})

onBeforeUnmount(() => {
  sessionStorage.setItem(SCROLL_POSITION_KEY, window.scrollY.toString())
})

onUnmounted(() => {
  observer.disconnect()
  sessionStorage.setItem(FEED_DATA_KEY, JSON.stringify(activityList.value))
  sessionStorage.setItem(PAGE_INFO_KEY, JSON.stringify(pageInfo.value))
})

async function fetchFeed() {
  if (working.value) return
  working.value = true

  let path = `/users/${profileData.value._id}/feed`

  if (pageInfo.value.endCursor) {
    path += `?${new URLSearchParams({ cursor: pageInfo.value.endCursor })}`
  }

  try {
    const response = await API().get(path)
    const data = response.data

    data.data = data.data.filter((item: any) => item !== null)

    activityList.value.push(...data.data)
    pageInfo.value = data.pageInfo

    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
    console.error(err)
  }
}

function scrollToTop(e: Event) {
  e.preventDefault()
  sessionStorage.removeItem(SCROLL_POSITION_KEY)
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        fetchFeed()
      }
    }
  })
}, options)
</script>

<template>
  <div class="container mx-auto Profile Activity">
    <section class="md:col-span-10 lg:col-span-9 xl:col-span-8 2xl:col-span-7">
      <ul class="FeedList">
        <li v-if="maintenance">
          <article class="FeedItem dark:bg-submit-900 bg-white text-gray-500 dark:text-gray-400">
            <div class="PFP w-10 h-10 dark:bg-submit-950 bg-gray-200">
              <span class="flex items-center justify-center w-full h-full text-2xl font-semibold text-gray-800 dark:text-gray-500">!</span>
            </div>
            <header class="inline-wrap">
              <span class="truncate text-base font-semibold text-yellow-500">Feed Maintenance</span>
            </header>
            <div class="text-xs dark:text-gray-400">
              <span>The feed is getting a back rub!</span>
            </div>
            <section>
              <p>We apologize for the disruption, the feed is currently undergoing maintenance and will be back shortly.</p>
            </section>
            <footer>
              <small class="dark:text-gray-500">
                This message self destructs once the feed is back online!
              </small>
            </footer>
          </article>
        </li>
        <FeedItem v-for="item in activityList" :item="item" :key="item ? item._id : null" v-if="initialLoadComplete && !error && activityList.length > 0" />
        <li v-if="error">
          <article class="FeedItem dark:bg-submit-900 bg-white text-gray-500 dark:text-gray-400">
            <div class="PFP w-10 h-10 dark:bg-submit-950 bg-gray-200">
              <span class="flex items-center justify-center w-full h-full text-2xl font-semibold text-gray-800 dark:text-gray-500">!</span>
            </div>
            <header class="inline-wrap">
              <span class="truncate text-base font-semibold text-red-500">Whoops!</span>
            </header>
            <div class="text-xs dark:text-gray-400">
              <span>The feed fell asleep!</span>
            </div>
            <section>
              <p>It looks like that jerk, the feed, decided to take a nap instead of do it's job.</p>
            </section>
            <footer>
              <small class="dark:text-gray-500">
                This message self destructs once the feed is back online!
              </small>
            </footer>
          </article>
        </li>
        <li class="flex justify-center" v-if="(initialLoadComplete && working) || (!initialLoadComplete && activityList.length === 0)">
          <LoadIndicator />
        </li>
        <li v-if="initialLoadComplete && activityList.length === 0">
          <article class="FeedItem dark:bg-submit-900 bg-white text-gray-500 dark:text-gray-400">
            <div class="PFP w-10 h-10 dark:bg-submit-950 bg-gray-200">
              <span class="flex items-center justify-center w-full h-full text-2xl font-semibold text-gray-800 dark:text-gray-500">!</span>
            </div>
            <header class="inline-wrap">
              <span class="truncate text-base font-semibold text-yellow-500">No Activity</span>
            </header>
            <div class="text-xs dark:text-gray-400">
              <span>This activity feed returned 0 :(</span>
            </div>
            <section>
              <p>It looks like {{ profileData.username }} hasn't done anything yet.</p>
            </section>
            <footer>
              <small class="dark:text-gray-500">
                This message self destructs once there's something to see!
              </small>
            </footer>
          </article>
        </li>
        <li class="flex flex-col items-center gap-y-6 pt-8" v-if="initialLoadComplete && activityList.length > 0 && !pageInfo.hasNextPage">
          <h2 class="text-xl dark:text-gray-700">You've reached the end</h2>
          <a href="#" class="flex text-sm dark:text-gray-700 gap-3 align-middle" @click.prevent="scrollToTop">
            <svg class="h-4 flex-none dark:fill-gray-700" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
              <path d="M32 32C14.3 32 0 46.3 0 64S14.3 96 32 96H352c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM214.6 169.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 269.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V269.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"/>
            </svg>
            Go to the top
          </a>
        </li>
      </ul>
      <div id="loadMore"></div>
    </section>
  </div>
</template>

<script setup lang="ts">
import TOTP from "@/components/settings/TOTP.vue"
import Field from "@/components/input/Field.vue"
import Label from "@/components/input/Label.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { ref, onBeforeMount } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from "@headlessui/vue"

const UserStore = useUserStore()
const { id } = storeToRefs(UserStore)

const working = ref(false)
const passwordError = ref(false)
const passwordChanged = ref(false)
const TOTPEnabled = ref(false)

const TOTPopen = ref(false)
const toggleTOTP = (type: string) => {
  TOTPtype.value = type
  TOTPopen.value = !TOTPopen.value
}
const TOTPtype = ref("setup")

const schema = zod.object({
  password: zod.string()
    .min(8, { message: "Passwords must be at least 8 characters." })
    .min(1, { message: "This is required." }),
  new_password: zod.string()
    .min(8, { message: "Passwords must be at least 8 characters." })
    .min(1, { message: "This is required." })
})

onBeforeMount(async () => {
  await getTOTPStatus()
})

async function handlePasswordChange(values: any) {
  if (working.value) return
  working.value = true
  try {
    await API().patch(`/users/${id.value}/password`, {
      currentPassword: values.password,
      newPassword: values.new_password
    })
    passwordChanged.value = true
    working.value = false
    setTimeout(() => {
      passwordChanged.value = false
    }, 10000)
  } catch (err) {
    console.error(err)
    passwordError.value = true
    working.value = false
    setTimeout(() => {
      passwordError.value = false
    }, 10000)
  }
}

async function getTOTPStatus() {
  try {
    const response = await API().get(`/totp`)
    TOTPEnabled.value = response.data.data.enabled
  } catch (err) {
    console.error(err)
  }
}

function handleClose() {
  TOTPopen.value = false
  getTOTPStatus()
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Password</h2>
        <p>Set a new password for your account.</p>
        <Form @on-submit="handlePasswordChange" :schema="schema" class="space-y-3">
          <div>
            <Label field="password">Current Password</Label>
            <Field type="password" name="password" />
          </div>
          <div>
            <Label field="new_password">New Password</Label>
            <Field type="password" name="new_password" />
          </div>
          <div>
            <button class="font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 text-black py-2 px-12" type="submit">{{ working ? 'Changing...' : 'Change Password' }}</button>
          </div>
        </Form>
        <div>
          <div class="text-green-500" v-if="passwordChanged">Password changed successfully!</div>
          <div class="text-red-500" v-if="passwordError">Error changing password.</div>
        </div>
      </div>
    </section>
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Two-Factor Authentication (TOTP)</h2>
      <p v-if="!TOTPEnabled">Increase your account security by enabling TOTP based two-factor authentication.</p>
      <p v-else>You have TOTP based two-factor authentication enabled for your account.</p>
      <div class="flex gap-4">
        <button @click="toggleTOTP(TOTPEnabled ? 'disable' : 'setup')" class="font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 text-black py-2 px-12" type="button">{{ TOTPEnabled ? 'Disable TOTP' : 'Enable TOTP' }}</button>
        <button @click="toggleTOTP('codes')" v-if="TOTPEnabled" type="button" class="font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 text-black py-2 px-12">Recovery Codes</button>
        <TransitionRoot appear :show="TOTPopen" as="template">
          <Dialog as="div" @close="handleClose" class="relative">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>
            <div class="fixed inset-0 overflow-y-auto">
              <div
                class="flex min-h-full items-center justify-center p-4 text-center"
              >
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0 scale-95"
                  enter-to="opacity-100 scale-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100 scale-100"
                  leave-to="opacity-0 scale-95"
                >
                <DialogPanel
                    class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                  >
                    <TOTP :type="TOTPtype" @close="toggleTOTP(TOTPtype)" @close:update="handleClose" />
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </div>
    </section>
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Active Sessions</h2>
      <p>View and manage all of your active Submit sessions (devices that you're signed into Submit on).</p>
      <p class="dark:text-gray-400 text-gray-500 text-sm font-bold">This feature is coming soon, check the <router-link class="underline" to="/roadmap">roadmap</router-link> and/or <router-link class="underline" to="/groups/submit-development">Submit Development</router-link> for updates and more details.</p>
    </section>
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Audit Log</h2>
      <p>View a record of all of the actions you've taken on Submit.</p>
      <p class="dark:text-gray-400 text-gray-500 text-sm font-bold">This feature is coming soon, check the <router-link class="underline" to="/roadmap">roadmap</router-link> and/or <router-link class="underline" to="/groups/submit-development">Submit Development</router-link> for updates and more details.</p>
    </section>
  </main>
</template>

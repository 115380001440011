<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import { ref, computed } from "vue"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

interface Props {
  user?: any
  org?: any
  buttonStyle?: string
  labelStyle?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'follow', data: any): void
  (e: 'unfollow', data: any): void
}>()

const UserStore = useUserStore()
const { flags, privacy } = storeToRefs(UserStore)

const requested = ref(props.user ? props.user.requested : false)
const following = ref(props.user ? props.user.following : props.org.following)
const working = ref(false)
const pCount = ref(0)
const rescindOpen = ref(false)

function toggleRescindModal() {
  rescindOpen.value = !rescindOpen.value
}

const normalizedUser = computed(() => {
  let userData: any = {}
  if (props.user) {
    // set the user id
    if (props.user.user) {
      userData._id = props.user.user
    } else {
      userData._id = props.user._id
    }
    // determine if following is enabled
    // check to see if props.user.disabled is set on user object
    if (props.user.hasOwnProperty('disabled')) {
      props.user.disabled ? userData.followingEnabled = false : userData.followingEnabled = true
    } else if (props.user.privacy && props.user.privacy.following === 'disabled') {
      userData.followingEnabled = false
    } else {
      userData.followingEnabled = true
    }
    // check if private and/or follow requests are on
    userData.private = false
    if (props.user.hasOwnProperty('private')) {
      props.user.private ? userData.private = true : userData.private = false
    }
    if (props.user.privacy && props.user.privacy.followRequests === "enabled") {
      userData.private = true
    }
    // check if self
    props.user.self ? userData.self = true : userData.self = false
  }
  if (props.org) {
    userData._id = props.org._id
    userData.followingEnabled = true
    userData.private = false
    userData.self = false
  }

  return userData
})

const canFollow = computed(() => {
  if (!flags.value.includes('following')) return false
  if (props.user) {
    if (!normalizedUser.value.followingEnabled) return false
    if (normalizedUser.value.self) return false
    if (!privacy.value.followingEnabled) return false
  }
  return true
})

const followLabel = computed(() => {
  if (requested.value) return 'Requested'
  if (working.value) return 'Processing...'
  return following.value ? "Unfollow" : "Follow"
})

async function followAction() {
  if (working.value) return
  if (!canFollow.value) return
  if (requested.value) {
    working.value = true
    try {
      const response = await API().post(`/requests/${props.user.requested}`, {
        action: 'cancel'
      })
      requested.value = false
      working.value = false
      toggleRescindModal()
      return
    } catch (err) {
      console.error(err)
      setTimeout(() => {
        working.value = false
      }, 2000)
      return
    }
  }
  if (pCount.value > 3) {
    alert('You are doing that too much. Please try again later.')
  }
  working.value = true
  pCount.value++

  let path = `/users/${normalizedUser.value._id}/`
  if (following.value) {
    path += 'unfollow'
  } else {
    path += 'follow'
  }

  try {
    await API().get(path)
    if (normalizedUser.value.private && !following.value) {
      requested.value = true
    } else {
      following.value = !following.value
      if (following.value) {
        emit('follow', normalizedUser.value._id)
      } else {
        emit('unfollow', normalizedUser.value._id)
      }
      working.value = false
    }
  } catch (err) {
    console.error(err)
    setTimeout(() => {
      working.value = false
    }, 2000)
    setTimeout(() => {
      pCount.value--
    }, 5000)
    return
  }
}
</script>

<template>
  <div
    v-if="normalizedUser.self"
    :class="[
      labelStyle ? labelStyle : ''
    ]"
  >
    Self
  </div>
  <div v-else>
    <button
      type="button"
      @click="requested ? toggleRescindModal() : followAction()"
      :class="[
        buttonStyle ? buttonStyle : 'bg-submit-600 text-gray-300 w-full py-2.5 px-2 xs:px-4 sm:px-8 rounded-[10px] text-xs font-bold hover:text-gold-500 transition-all',
        canFollow ? '' : 'cursor-not-allowed'
      ]"
      :disabled="!canFollow || working"
    >
      {{ followLabel }}
    </button>
    <TransitionRoot appear :show="rescindOpen" as="template">
      <Dialog as="div" @close="toggleRescindModal" class="relative z-[100]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-2 sm:p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-lg transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-925 px-2 xs:px-4 py-6 sm:px-6 sm:py-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                >
                  Rescind Request?
                </DialogTitle>
                <section class="space-y-6">
                  <p>Your have a pending follow request. Would you like to rescind this request?</p>
                  <div class="flex justify-end gap-4">
                    <div>
                      <button @click="toggleRescindModal" type="button" class="text-sm px-4 py-1.5">Cancel</button>
                      <button @click="followAction" v-if="requested" type="button" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5">Rescind Request</button>
                    </div>
                  </div>
                </section>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, reactive, computed, inject, type Ref } from "vue"
import Editor from "@/components/input/Editor.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

interface Props {
  status?: any
}

const contextCategory = inject<Ref<string>>('contextCategory')
const feedContext = inject<Ref<string>>('feedContext')

const debug = ref(false)
const props = defineProps<Props>()
const emit = defineEmits(["close"])

const UserStore = useUserStore()
const { id: UserID, username, defaultVisibility, pfp, firstLetter, circles, backer, verified, roles } = storeToRefs(UserStore)

const initialValues = reactive({
  audience: "",
  text: "",
  contentWarning: ""
})

const schema = zod.object({
  audience: zod.string()
    .min(1, { message: "This is required." })
    .optional(),
  text: zod.string()
    .min(3, { message: "3 characters is the minimum required for a status update." })
    .max(512, { message: "512 characters is the maximum allowed for a status udpate." }),
  contentWarning: zod.string()
    .min(3, { message: "3 character minimum." })
    .max(100, { message: "100 character maximum."})
    .optional()
    .or(zod.literal(""))
})

const working = ref(false)
const error = ref(false)
const raw = ref("")
const cwOpen = ref(false)
const cwSet = ref(false)

const buttonLabel = computed(() => {
  if (working.value) {
    return props.status ? "Updating..." : "Sharing..."
  } else {
    return props.status ? "Update" : "Share"
  }
})

const isStaff = computed(() => {
  return roles.value.includes("admin")
})

onBeforeMount(() => {
  if (props.status) {
    const statusData = props.status.content ? props.status.content : props.status
    initialValues.audience = statusData.audience
    initialValues.text = statusData.text
    initialValues.contentWarning = statusData.tw || ""
    cwSet.value = statusData.tw ? true : false
  } else {
    if (contextCategory && contextCategory.value === "circle") {
      initialValues.audience = feedContext ? feedContext.value : defaultVisibility.value !== "private" ? defaultVisibility.value : "public"
    } else {
      initialValues.audience = defaultVisibility.value !== "private" ? defaultVisibility.value : "public"
    }
  }
})

function cwIsDsiabled(tools: any) {
  const field = tools.getFieldValue("contentWarning")
  if (cwSet.value && field.length === 0) return false
  if (!field) return true
  return field.length < 3 || field.length > 100
}

function cwButtonLabel(tools: any) {
  if (cwSet.value) {
    const field = tools.getFieldValue("contentWarning")
    if (field.length === 0) {
      return "Remove"
    } else {
      return "Update"
    }
  } else {
    return "Add"
  }
}

function cwClose(tools: any, set: boolean = false) {
  if(set) {
    if(!tools.validateField("contentWarning")) return
    const field = tools.getFieldValue("contentWarning")
    if (cwSet.value && field.length === 0) {
      cwOpen.value = false
      setTimeout(() => {
        cwSet.value = false
      }, 300)
    } else {
      if (field.length >= 3 && field.length <= 100) {
        cwOpen.value = false
        setTimeout(() => {
          cwSet.value = true
        }, 300)
      }
    }
    return
  } else {
    cwOpen.value = false
    if(cwSet.value) return
    tools.clearField("contentWarning")
  }
}

function close(values: any = null) {
  if (props.status) {
    emit("close", {
      text: values.text,
      tw: values.contentWarning || ""
    })
  } else {
    emit("close")
  }
}

function updateRaw(newRaw: string) {
  raw.value = newRaw.trim()
}

const editorOptions = {
  bold: true,
  italic: true,
  strike: true,
  code: true,
  link: true,
  mentions: true
}

async function publishPost(values: any) {
  if (working.value) return
  working.value = true

  let update: any

  if (!props.status) {
    update = {
      type: "text",
      audience: values.audience,
      text: values.text,
      raw: raw.value
    }
  } else {
    update = {
      text: values.text,
      raw: raw.value
    }
  }

  if (cwSet.value) {
    update.tw = values.contentWarning
  }

  let url = "/status"
  if (props.status) {
    url = `/status/${props.status.content ? props.status.content._id : props.status._id}`
  }

  try {
    let response: any
    if (props.status) {
      response = await API().put(url, update)
    } else {
      response = await API().post(url, update)
    }

    const { data } = response.data

    const timestamp = new Date()

    if (!props.status) {
      let feedPayload: any = {
        "_id": `newfeeditem-${Math.floor(Math.random() * 101)}`,
        "author": {
          "_id": UserID.value,
          "username": username.value,
          "verified": verified.value,
          "backer": backer.value,
          "staff": isStaff.value
        },
        "activity": data.activity,
        "activityType": "status",
        "audience": values.audience,
        "content": {
          "_id": data.status,
          "boostCount": 0,
          "commentCount": 0,
          "reactionsCount": {
            "like": 0,
            "dislike": 0,
            "love": 0,
            "hot": 0,
            "laugh": 0,
            "cry": 0,
            "angry": 0,
            "celebrate": 0
          },
          "user": UserID.value,
          "type": "text",
          "audience": values.audience,
          "text": values.text,
          "status": "published",
          "hasReacted": false,
          "reactions": true,
          "comments": true,
          "createdAt": timestamp
        },
        "self": true,
        "createdAt": timestamp
      }

      if (pfp) {
        feedPayload.author.pfp = pfp.value
      } else {
        feedPayload.author.firstLetter = firstLetter.value
      }

      if (cwSet.value) {
        feedPayload.content.tw = values.contentWarning
      }

      UserStore.addSelfPostToQueue(feedPayload)
    }

    working.value = false
    if (props.status) {
      close(values)
    } else {
      close()
    }
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <Form @on-submit="publishPost" :schema="schema" :initialValues="initialValues" v-slot="{ fields, errors, tools }" class="space-y-3">
    <div class="flex items-center gap-1">
      <label class="flex-none text-sm font-bold">Audience:</label>
      <Field name="audience" type="select" slim v-if="!status">
        <option value="public">Everyone</option>
        <option value="followers">Followers</option>
        <option value="relationships">Relationships</option>
        <optgroup label="Circles" v-if="circles.length > 0">
          <option v-for="circle in circles" :key="circle._id" :value="circle.code">{{ circle.name }}</option>
        </optgroup>
      </Field>
      <div class="text-sm capitalize" v-else>{{ status.audience }}</div>
    </div>
    <Editor name="text" placeholder="What's on your mind?" :limit="512" :options="editorOptions" @raw="updateRaw" />
    <footer class="flex justify-between items-center">
      <div>
        <button @click="cwOpen = true" class="inline-flex text-sm py-1 dark:hover:text-white" type="button">{{ cwSet ? 'Update' : 'Add' }} Content Warning</button>
        <TransitionRoot appear :show="cwOpen" as="template">
          <Dialog as="div" @close="cwClose(tools)" class="relative">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>
            <div class="fixed inset-0 overflow-y-auto">
              <div
                class="flex min-h-full items-center justify-center p-4 text-center"
              >
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0 scale-95"
                  enter-to="opacity-100 scale-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100 scale-100"
                  leave-to="opacity-0 scale-95"
                >
                  <DialogPanel
                    class="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                    :unmount="false"
                  >
                    <DialogTitle
                      as="div"
                      class="flex mb-6"
                    >
                      <h3 class="text-lg font-medium leading-6 text-gold-700 dark:text-gold-500">{{ cwSet ? 'Update' : 'Add' }} Content Warning</h3>
                    </DialogTitle>
                    <div class="space-y-3">
                      <div>
                        <label class="block text-sm font-bold mb-2">Content Warning</label>
                        <Field name="contentWarning" slim alt persist />
                      </div>
                      <div class="flex gap-2 justify-end">
                        <button type="button" @click="cwClose(tools)" class="inline-flex justify-center py-1 px-6 text-sm dark:hover:text-white dark:text-gray-400 text-gray-800">Cancel</button>
                        <button type="button" @click.prevent="cwClose(tools, true)" class="bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 inline-flex justify-center py-1 px-6 border-0 text-sm font-bold rounded-md text-black" :disabled="cwIsDsiabled(tools)">{{ cwButtonLabel(tools) }}</button>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </div>
      <div class="flex gap-2">
        <button @click="close" class="inline-flex justify-center py-1 px-6 text-sm dark:hover:text-white dark:text-gray-400 text-gray-800" type="button">Cancel</button>
        <button class="bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 inline-flex justify-center py-1 px-6 border-0 text-sm font-bold rounded-md text-black" type="submit" :disabled="working">{{ buttonLabel }}</button>
      </div>
    </footer>
    <div v-if="debug">
      <pre>
{{ fields }}
      </pre>
      <pre>
{{ errors }}
      </pre>
    </div>
  </Form>
</template>

<script setup lang="ts">
import { computed } from "vue"

interface Props {
  profileData: any
}

const { profileData } = defineProps<Props>()

const clarificationTypes = [
  { value: "giving", label: "Giving", usage: "giving" },
  { value: "receiving", label: "Receiving", usage: "receiving" },
  { value: "watching", label: "Watching", usage: "watching" },
  { value: "wearing", label: "Wearing", usage: "wearing" },
  { value: "owearing", label: "Watching Others Wear", usage: "watching others wear" },
  { value: "everything", label: "Everything", usage: "everything to do with"}
]

function clarifyingDisplay(clarifying: string) {
  const type = clarificationTypes.find(type => type.value === clarifying)
  return type?.usage
}

const i = computed(() => {
  let softLimits: any[] = [], hardLimits: any[] = [], intoKinks: any[] = [], curiousKinks: any[] = [], interests: any[] = []

  profileData.interests.forEach((interest: any) => {
    if (interest.interestType === "official") {
      if (interest.indicatedLimit) {
        if (interest.indicatedInterest === "hard") {
          hardLimits.push(interest)
        } else {
          softLimits.push(interest)
        }
      } else {
        if (interest.indicatedInterest === "into") {
          intoKinks.push(interest);
        } else {
          curiousKinks.push(interest)
        }
      }
    } else if (interest.interestType === "interest") {
      interests.push(interest)
    }
  })

  return { softLimits, hardLimits, intoKinks, curiousKinks, interests }
})
</script>

<template>
  <section class="space-y-3" v-if="((i.intoKinks && i.intoKinks.length > 0) || (i.curiousKinks && i.curiousKinks.length > 0))">
    <h2 class="dark:text-white font-bold">Kinks</h2>
    <div>
      <ul class="inline" v-if="i.intoKinks.length > 0">
        <li class="dark:text-gray-400 text-gray-500 inline">Into: </li>
        <li class="inline" v-for="(interest, idx) in i.intoKinks" :key="idx">
          <router-link :to="`/kinks/${interest.interestSlug}`" class="underline decoration-dotted hover:decoration-solid">{{interest.interestName}}</router-link>
          <span> <span class="text-xs dark:text-gray-400 text-gray-500" v-if="interest.indicatedClarifying"> ({{clarifyingDisplay(interest.indicatedClarifying)}})</span><span v-if="idx < i.intoKinks.length -1">, </span></span>
        </li>
      </ul>
    </div>
    <div>
      <ul class="inline" v-if="i.curiousKinks.length > 0">
        <li class="dark:text-gray-400 text-gray-500 inline">Curious: </li>
        <li class="inline" v-for="(interest, idx) in i.curiousKinks" :key="idx">
          <router-link :to="`/kinks/${interest.interestSlug}`" class="underline decoration-dotted hover:decoration-solid">{{interest.interestName}}</router-link>
          <span> <span class="text-xs dark:text-gray-400 text-gray-500" v-if="interest.indicatedClarifying"> ({{clarifyingDisplay(interest.indicatedClarifying)}})</span><span v-if="idx < i.curiousKinks.length -1">, </span></span>
        </li>
      </ul>
    </div>
  </section>
  <section class="space-y-3" v-if="((i.hardLimits && i.hardLimits.length > 0) || (i.softLimits && i.softLimits.length > 0))">
    <h2 class="dark:text-white font-bold">Limits</h2>
    <div>
      <ul class="inline" v-if="i.hardLimits.length > 0">
        <li class="dark:text-gray-400 text-gray-500 inline">Hard: </li>
        <li class="inline" v-for="(interest, idx) in i.hardLimits" :key="idx">
          <router-link :to="`/kinks/${interest.interestSlug}`" class="underline decoration-dotted hover:decoration-solid">{{interest.interestName}}</router-link>
          <span v-if="idx < i.hardLimits.length -1">, </span>
        </li>
      </ul>
    </div>
    <div>
      <ul class="inline" v-if="i.softLimits.length > 0">
        <li class="dark:text-gray-400 text-gray-500 inline">Soft: </li>
        <li class="inline" v-for="(interest, idx) in i.softLimits" :key="idx">
          <router-link :to="`/kinks/${interest.interestSlug}`" class="underline decoration-dotted hover:decoration-solid">{{interest.interestName}}</router-link>
          <span v-if="idx < i.softLimits.length -1">, </span>
        </li>
      </ul>
    </div>
  </section>
  <section class="space-y-3" v-if="i.interests && i.interests.length > 0">
    <h2 class="dark:text-white font-bold">Interests</h2>
    <div>
      <ul class="inline">
        <li class="inline" v-for="(interest, idx) in i.interests" :key="idx">
          <router-link :to="`/interests/${interest.interestSlug}`" class="underline decoration-dotted hover:decoration-solid">{{interest.interestName}}</router-link>
          <span v-if="idx < i.interests.length -1">, </span>
        </li>
      </ul>
    </div>
  </section>
</template>

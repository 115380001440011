<script setup lang="ts">
import { ref, onBeforeMount, computed, watch } from "vue"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue"

const isFetching = ref(true)
const error = ref(false)
const reservations = ref<any[]>([])
const addOpen = ref(false)
const sending = ref(false)

const name = ref("")
const desc = ref("")
const type = ref("open")
const pre = ref("")

onBeforeMount(() => {
  getReservations()
})

const resLength = computed(() => {
  return reservations.value.length
})

const validName = computed(() => {
  const minlength = 4
  const maxlength = 324
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9!,?'()+& -]*[a-zA-Z0-9+)!]$/
  return name.value.length <= maxlength && name.value.length >= minlength && regex.test(name.value)
})

const toggleAddOpen = async () => {
  addOpen.value = !addOpen.value
}

async function getReservations() {
  let path = `/groups/reservations`

  try {
    const response = await API().get(path)
    reservations.value.push(...response.data.data)
    isFetching.value = false
  } catch (err) {
    error.value = true
    isFetching.value = false
    return
  }
}

async function newReservation() {
  let path = `/groups/reserve`

  if (name.value.length < 4 || desc.value.length < 4) {
    return
  }

  if (name.value.length > 324) {
    return
  }

  // check to make sure group name returns true against regex
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9!,?'()+& -]*[a-zA-Z0-9+)!]$/
  if(regex.test(name.value) === false) {
    return
  }

  let reservation: any = {
    name: name.value,
    description: desc.value,
    type: type.value
  }

  if (pre.value.length > 0) {
    reservation.preExisting = pre.value
  }

  sending.value = true

  try {
    const response = await API().post(path, reservation)
    reservations.value.push(response.data.data)
    sending.value = false
    toggleAddOpen()
    name.value = ""
    desc.value = ""
    type.value = "open"
    pre.value = ""
  } catch (err) {
    error.value = true
    sending.value = false
    return
  }
}

async function removeReservation(id: string) {
  if (confirm("Are you sure you want to remove this group reservation?")) {
    let path = `/groups/reservations/${id}`
    try {
      const response = await API().delete(path)
      const index = reservations.value.findIndex((reservation) => reservation._id === id)
      reservations.value.splice(index, 1)
    } catch (err) {
      error.value = true
      return
    }
  }
  return
}
</script>

<template>
  <div class="space-y-3">
    <h3 class="text-sm font-bold">Group Reservations: ({{ reservations.length }}/6)</h3>
    <p class="dark:text-gray-400 text-gray-500" v-if="!isFetching && reservations.length === 0">You have no group name reservations.</p>
    <p class="dark:text-gray-400 text-gray-500" v-if="isFetching">Loading group reservations...</p>
    <ul class="space-y-3" v-if="!isFetching && reservations.length > 0">
      <li v-for="reservation in reservations" :key="reservation._id" class="bg-white dark:bg-submit-900 rounded-md">
        <section class="p-4">
          <h2 class="text-xl font-display font-semibold">{{ reservation.name }}</h2>
          <p class="text-sm">{{ reservation.description }}</p>
        </section>
        <footer class="flex text-sm text-gray-500 dark:text-gray-400 justify-between border-t-2 py-2 px-4 border-neutral-125 dark:border-submit-950">
          <span class="capitalize">Status: {{ reservation.status }}</span>
          <button class="flex gap-1 items-center text-sm text-gray-500 dark:text-gray-400 hover:underline" @click="removeReservation(reservation._id)">
            <svg class="w-3 h-3 flex-none dark:fill-gray-400 fill-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
            Delete Reservation
          </button>
        </footer>
      </li>
    </ul>
    <p v-if="reservations.length < 6" @click="toggleAddOpen" class="hover:cursor-pointer hover:dark:text-white hover:underline inline-block">Add New Reservation</p>
    <TransitionRoot appear :show="addOpen" as="template">
      <Dialog as="div" @close="toggleAddOpen" class="relative">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gold-700 dark:text-gold-500 mb-6"
                >
                  New Group Reservation
                </DialogTitle>
                <div class="flex flex-col mt-2 space-y-4">
                  <div>
                    <label for="name" class="block mb-1 text-sm font-bold">Group Name</label>
                    <input v-model="name" type="text" name="name" placeholder="Group Name" class="flex w-full p-2 text-sm flex-auto rounded-md border dark:bg-submit-600 bg-white dark:text-gray-300 focus:ring-0" :class="validName && name.length > 0 ? 'border-green-500 focus:border-green-500' : 'border-red-500 focus:border-red-500'" />
                  </div>
                  <div>
                    <label for="desc" class="block mb-1 text-sm font-bold">Group Description</label>
                    <textarea v-model="desc" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="desc" rows="3" placeholder="Description" />
                  </div>
                  <div>
                    <label for="type" class="block mb-1 text-sm font-bold">Group Type</label>
                    <select v-model="type" name="type" class="flex text-sm w-full px-2 py-2 border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus-within:border-neutral-600 rounded-md">
                      <option value="open">Open</option>
                      <option value="closed">Closed</option>
                      <option value="private">Private</option>
                    </select>
                    <ul class="text-xs dark:text-gray-400 text-gray-500 mt-1 space-y-3">
                      <li><strong>Open:</strong> Anyone can see the group and its content, anyone can join the group, group membership list is viewable by anyone.</li>
                      <li><strong>Closed:</strong> Anyone can see the group and its content, joining the group requires moderator approval, group membership list is viewable.</li>
                      <li><strong>Private:</strong> Anyone can see the group exists, but not its content, joining the group requires moderator approval, group membership list is not viewable.</li>
                    </ul>
                  </div>
                  <div>
                    <label for="pre" class="block mb-1 text-sm font-bold">Do you run a group like this somewhere else? If so, please share the link to it.</label>
                    <input v-model="pre" type="text" name="pre" placeholder="Pre-existing group link" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent dark:bg-submit-600 bg-white dark:text-gray-300 focus:ring-0 focus:border-neutral-900" />
                  </div>
                  <div class="flex self-end">
                    <button
                      type="button"
                      @click="toggleAddOpen"
                      class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-neutral-500 hover:text-black hover:bg-gray-200 dark:hover:bg-indigo-925 dark:hover:text-indigo-325 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-md border border-transparent bg-gold-700 dark:bg-gold-500 px-4 py-2 text-sm font-medium text-black focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      :disabled="sending"
                      @click="newReservation"
                    >
                      {{ sending ? 'Requesting...' : 'Request' }}
                    </button>
                  </div>
                  <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                    <p>There was an error creating this group reservation, please try again.</p>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { ref, onBeforeMount } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

const schema = zod.object({
  usernameToBlock: zod.string()
    .min(3, { message: "Username must be at least 3 characters long" })
    .max(24, { message: "Can be between 4 and 24 characters." } )
    .regex(/^[a-zA-Z0-9_-]+$/, { message: "Can only contain letters, numbers, underscores, and dashes." }),
  notes: zod.string()
    .optional()
})

const UserStore = useUserStore()
const { id } = storeToRefs(UserStore)

const initialLoadComplete = ref<boolean>(false)
const working = ref<boolean>(false)
const error = ref<boolean>(false)
const blocks = ref<any[]>([])
const blocked = ref<boolean>(false)
const success = ref<boolean>(false)
const pageParams = ref<any>({
  hasNextPage: undefined,
  cursor: undefined,
})

onBeforeMount(async () => {
  await getPreBlocks()
  initialLoadComplete.value = true
})

const baseRoute = `/users/${id.value}/preblocks`

async function getPreBlocks() {
  if (working.value) return
  working.value = true

  let path = baseRoute
  if (pageParams.value.hasNextPage) {
    path = `${baseRoute}?${new URLSearchParams({ cursor: pageParams.value.cursor }).toString()}`
  }

  try {
    const response = await API().get(path)
    blocks.value.push(...response.data.data)
    pageParams.value.hasNextPage = response.data.hasNextPage
    pageParams.value.cursor = response.data.nextCursor
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    working.value = false
  }
}

async function handleSubmit(values: any, resetFields: () => void){
  if (working.value) return
  working.value = true

  try {
    let dataToSend: any = {
      usernameToBlock: values.usernameToBlock,
    }
    if (values.notes && values.notes.length > 2) {
      dataToSend.notes = values.notes
    }

    const response = await API().post(baseRoute, dataToSend)
    if (response.data.state === 'blocked') {
      blocked.value = true
    } else {
      success.value = true
      blocks.value.push({
        _id: blocks.value.length + 1,
        usernameToBlock: values.usernameToBlock,
        notes: values.notes ? values.notes : 'No notes',
        createdAt: new Date().toISOString()
      })
    }
    resetFields()
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    working.value = false
    setTimeout(() => {
      blocked.value = false
      success.value = false
    }, 5000)
  }
}

async function removeBlock(blockId: string) {
  if (working.value) return
  working.value = true

  try {
    await API().delete(`${baseRoute}/${blockId}`)
    blocks.value = blocks.value.filter(block => block._id !== blockId)
  } catch (err) {
    console.error(err)
  } finally {
    working.value = false
  }
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Pre-Blocking</h2>
        <p>If you'd like to automatically block a user that joins Submit, the moment they join Submit, you can do so here. Usernames that you add to this list will be automatically blocked when they join Submit - when this happens, you'll receive a notification and they'll be moved from this list to your Block list.</p>
      </div>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Add User</h2>
        <p>Enter the username you'd like to block. Please keep in mind that if the user you intend to block doesn't use this username, we have no way of knowing that.</p>
      </div>

      <Form @on-submit="handleSubmit" class="space-y-3" :schema="schema">
        <div>
          <Label field="usernameToBlock">Username</Label>
          <Field name="usernameToBlock" />
        </div>
        <div>
          <Label field="notes">Notes</Label>
          <Field name="notes" type="textarea" rows="3" />
        </div>
        <div>
          <button type="submit" :disabled="working">Add User</button>
        </div>
        <div v-if="success" class="text-green-500 font-semibold">User added successfully!</div>
        <div v-if="blocked" class="text-green-500 font-semibold">User exists, was blocked instead.</div>
      </Form>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Pre-Block List</h2>
        <div class="space-y-4" v-if="initialLoadComplete">
          <ul class="grid gap-4 grid-cols-1 sm:grid-cols-2" v-if="blocks.length > 0">
            <li v-for="block in blocks" :key="block._id" class="bg-white dark:bg-submit-900 p-4 rounded-md">
              <h3 class="font-bold text-sm">{{ block.usernameToBlock }}</h3>
              <p class="text-sm dark:text-gray-400 text-gray-500">{{ block.notes ? block.notes : 'No Notes' }}</p>
              <ul class="flex justify-between text-xs mt-4">
                <li>{{ D(block.createdAt).fromNow() }}</li>
                <li @click="removeBlock(block._id)" class="hover:underline hover:cursor-pointer">Remove</li>
              </ul>
            </li>
          </ul>
          <div v-if="blocks.length === 0" class="text-sm">No pre-blocked users.</div>
          <button type="button" @click="getPreBlocks" v-if="pageParams.hasNextPage" class="text-sm">Load more</button>
        </div>
        <div v-else class="text-sm">Loading...</div>
      </div>
    </section>
  </main>
</template>

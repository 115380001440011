<script setup lang="ts">
import GroupItem from "@/components/groups/GroupItem.vue"
import { useRouter } from "vue-router"
import { ref, onMounted } from "vue"
import API from "@/api/api"

const Router = useRouter()

const groups = ref<any[]>([])
const managedGroups = ref<any[]>([])
const initialLoadComplete = ref(false)
const error = ref(false)

onMounted(async () => {
  await getGroups()
  initialLoadComplete.value = true
})

async function getGroups() {
  try {
    const response = await API().get("/groups/user")
    const data = response.data.data
    managedGroups.value = data.managed
    groups.value = data.joined
  } catch (err) {
    error.value = true
  }
}

function handleAction(e: any) {
  if (e.target.nodeName === 'A' || e.target.nodeName === 'IMG' || e.target.closest('a') || e.target.classList.contains('GroupList'))
    return
  Router.push(e.target.closest('.GroupItem').getAttribute('data-to'))
}
</script>

<template>
  <div class="space-y-6">
    <div class="grid grid-cols-1">
      <header class="flex justify-between items-center">
        <div class="text-sm font-bold">Groups you Manage</div>
        <div class="flex gap-2">
          <button class="relative flex border dark:border-gray-800 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm cursor-default" disabled>
            <span>No Sorting</span>
          </button>
          <button class="relative flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group cursor-not-allowed" disabled>
            <svg class="flex-none h-3 dark:fill-gray-400 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
          </button>
        </div>
      </header>
      <main class="mt-4" v-if="initialLoadComplete && managedGroups.length > 0">
        <ul @click="handleAction" class="grid grid-cols-1 gap-2">
          <GroupItem v-for="group in managedGroups" :group="group" />
        </ul>
      </main>
      <main class="mt-4" v-if="initialLoadComplete && managedGroups.length === 0">
        <p class="text-sm">You manage no groups, yet.</p>
      </main>
      <main class="mt-4" v-if="!initialLoadComplete">
        <p class="text-sm">Loading...</p>
      </main>
    </div>
    <div class="grid grid-cols-1">
      <header class="flex justify-between items-center">
        <div class="text-sm font-bold">Groups you've joined</div>
        <div class="flex gap-2">
          <button class="relative flex border dark:border-gray-800 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm cursor-default" disabled>
            <span>No Sorting</span>
          </button>
          <button class="relative flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group cursor-not-allowed" disabled>
            <svg class="flex-none h-3 dark:fill-gray-400 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
          </button>
        </div>
      </header>
      <main class="mt-4" v-if="initialLoadComplete && groups.length > 0">
        <ul @click="handleAction" class="grid grid-cols-1 gap-2">
          <GroupItem v-for="group in groups" :group="group" />
        </ul>
      </main>
      <main class="mt-4" v-if="initialLoadComplete && groups.length === 0">
        <p class="text-sm">You've joined no groups, yet.</p>
      </main>
      <main class="mt-4" v-if="!initialLoadComplete">
        <p class="text-sm">Loading...</p>
      </main>
    </div>
  </div>
</template>

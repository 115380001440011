<script setup lang="ts">
import LoadIndicator from "@/components/misc/LoadIndicator.vue"
import { ref, onMounted } from "vue"

const loadingState = ref("initial")

onMounted(() => {
  setTimeout(() => {
    if (loadingState.value === 'initial') {
      loadingState.value = 'taking-longer'
    }
  }, 5000) // Change state after 5 seconds

  setTimeout(() => {
    if (loadingState.value !== 'too-long') {
      loadingState.value = 'too-long'
    }
  }, 15000) // Change state after 15 seconds
})
</script>

<template>
  <div class="container flex w-full mt-10 mx-auto justify-center">
    <div class="flex flex-col items-center gap-2">
      <LoadIndicator />
      <span v-if="loadingState === 'taking-longer'" class="text-sm text-yellow-500 font-semibold">
        This is taking longer than usual...
      </span>
      <span v-else-if="loadingState === 'too-long'" class="text-sm text-red-500 font-bold">
        This is taking too long. Please try refreshing this page.
      </span>
    </div>
  </div>
</template>

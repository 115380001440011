<script setup lang="ts">
import { ref } from "vue"
import { useUserStore } from "@/stores/User"
import API from "@/api/api"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const UserStore = useUserStore()

const props = defineProps(['circle', 'label', 'class'])
const emits = defineEmits(['removeFromCircle'])

const modalOpen = ref(false)
const circleData = ref<any>(null)
const initialLoadComplete = ref(false)
const working = ref(false)

const toggleModal = async() => {
  modalOpen.value = !modalOpen.value
  if (modalOpen.value) {
    await loadCircle()
    initialLoadComplete.value = true
  }
  if (!modalOpen.value) {
    circleData.value = null
    initialLoadComplete.value = false
  }
}

async function loadCircle() {
  try {
    const response = await API().get(`/circles/${props.circle.code}`)
    circleData.value = response.data.data
  } catch (err) {
    console.error(err)
  }
}

async function removeFromCircle(user: string) {
  if (working.value) return
  working.value = true

  try {
    await API().put(`/circles/${props.circle.code}`, {
      user: user,
      action: "remove"
     })
    UserStore.updateCircle(props.circle.code, "remove")
    // remove the user from the circleData.users array
    circleData.value.users = circleData.value.users.filter((u: any) => u._id !== user)
    emits('removeFromCircle', {
      code: props.circle.code
    })
  } catch (err) {
    console.error(err)
  } finally {
    working.value = false
  }
}
</script>

<template>
  <div>
    <button type="button" @click="toggleModal" :class="class">{{ label }}</button>
    <TransitionRoot as="template" :show="modalOpen">
      <Dialog as="div" class="relative z-[100]" @close="toggleModal">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                  <div class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl">
                    <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                      <div class="flex flex-grow flex-col">
                        <DialogTitle class="text-base font-semibold leading-6 dark:text-white">{{ circle.name }}</DialogTitle>
                      </div>
                      <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="toggleModal">
                        <span class="sr-only">Close panel</span>
                        <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                      </button>
                    </div>
                    <div class="flex flex-col mt-10 flex-1 px-4 sm:px-6 justify-start space-y-3 text-sm" v-if="modalOpen">
                      <p v-if="!initialLoadComplete">Loading...</p>
                      <p v-if="initialLoadComplete && circleData && circleData.users.length === 0">There are no users in this circle.</p>
                      <p v-if="initialLoadComplete && circleData && circleData.users.length > 0">You have {{ circleData.count }} users in this circle.</p>
                      <ul v-if="initialLoadComplete && circleData && circleData.users.length > 0">
                        <li class="bg-submit-700 py-2 px-4 rounded-md flex gap-2 items-center" v-for="user in circleData.users" :key="user._id">
                          <router-link :to="`/${user.username}`" class="flex flex-none w-10 h-10 bg-neutral-200 dark:bg-submit-950 rounded-full items-center text-xl justify-center font-semibold bg-cover capitalize" :style="user.pfp ? `background-image: url('${user.pfp}')` : ''">
                            {{ user.pfp ? '' : user.firstLetter }}
                          </router-link>
                          <div class="flex flex-1">
                             <router-link :to="`/${user.username}`" class="font-semibold">{{ user.username }}</router-link>
                          </div>
                          <button @click="removeFromCircle(user._id)" type="button" class="group">
                            <svg class="w-3 dark:fill-gray-400 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const UserStore = useUserStore()
const { alert } = storeToRefs(UserStore)
const Router = useRouter()

async function dismissAlert() {
  try {
    await API().get(`/alerts/${alert.value._id}/seen`)
    UserStore.removeAlert()
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <section
    :class="[
      alert.type === 'goal' ? 'dark:border-gold-500 border-gold-700' : '',
      'border w-full rounded-lg text-sm font-semibold flex items-center gap-2'
    ]"
  >
    <figure class="pl-4">
      <svg class="dark:fill-gold-500 fill-gold-700" width="16" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
        <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
        <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
      </svg>
    </figure>
    <router-link :to="alert.link" class="flex-1 py-4">{{ alert.message }}</router-link>
    <button
      @click="dismissAlert"
      class="p-4"
    >
      <svg
        class="w-5 h-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </section>
</template>

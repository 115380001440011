<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import { ref, onMounted } from "vue"
import API from "@/api/api"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const UserStore = useUserStore()

const props = defineProps<{
  user: string
}>()

const modalOpen = ref(false)
const initialLoadComplete = ref(false)
const working = ref(false)
const circles = ref<any[]>([])

onMounted(async () => {
  await loadCirclesForUser()
  initialLoadComplete.value = true
})

const toggleModal = () => {
  modalOpen.value = !modalOpen.value
}

async function loadCirclesForUser() {
  if (working.value) return
  working.value = true

  try {
    const response = await API().get(`/circles/user/${props.user}`)
    circles.value = response.data.data
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

async function updateCircle(code: string, action: string) {
  if (working.value) return
  working.value = true

  try {
    const response = await API().put(`/circles/${code}`, {
      user: props.user,
      action
     })
     switch (action) {
      case "add":
        UserStore.updateCircle(code, "add")
        // set inCircle to true on the circle object in the circles array
        circles.value.forEach((circle) => {
          if (circle.code === code) {
            circle.inCircle = true
          }
        })
        break
      case "remove":
        UserStore.updateCircle(code, "remove")
        // set inCircle to false on the circle object in the circles array
        circles.value.forEach((circle) => {
          if (circle.code === code) {
            circle.inCircle = false
          }
        })
        break
     }
  } catch (err) {
    console.error(err)
  } finally {
    working.value = false
  }
}
</script>

<template>
  <li>
    <button @click="toggleModal" type="button" :class="`bg-submit-600 py-2.5 px-4 rounded-[10px] transition group`" :disabled="!initialLoadComplete">
      <svg v-if="initialLoadComplete" class="w-4 fill-gray-300 group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M1.1 232C6 179.3 26.9 131.3 58.8 92.7l45.5 45.5C83.7 164.8 69.9 196.9 65.5 232H1.1zM138.3 104.3L92.7 58.8C131.3 26.9 179.3 6 232 1.1V65.5c-35.1 4.4-67.2 18.2-93.7 38.8zM280 446.5c35.1-4.4 67.2-18.2 93.7-38.8l45.5 45.5C380.7 485.1 332.7 506 280 510.9V446.5zm127.7-72.8c20.6-26.6 34.5-58.7 38.8-93.7h64.4c-4.9 52.7-25.8 100.7-57.7 139.3l-45.5-45.5zM92.7 453.2l45.5-45.5c26.6 20.6 58.7 34.5 93.7 38.8v64.4c-52.7-4.9-100.7-25.8-139.3-57.7zM58.8 419.3C26.9 380.7 6 332.7 1.1 280H65.5c4.4 35.1 18.2 67.2 38.8 93.7L58.8 419.3zM419.3 58.8l-45.5 45.5C347.2 83.7 315.1 69.9 280 65.5V1.1C332.7 6 380.7 26.9 419.3 58.8zm-11.6 79.5l45.5-45.5c31.9 38.5 52.8 86.6 57.7 139.3H446.5c-4.4-35.1-18.2-67.2-38.8-93.7z"/></svg>
      <svg v-else class="w-4 fill-gray-300 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/></svg>
    </button>
    <TransitionRoot as="template" :show="modalOpen">
      <Dialog as="div" class="relative z-[100]" @close="toggleModal">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                  <div class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl">
                    <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                      <div class="flex flex-grow flex-col">
                        <DialogTitle class="text-base font-semibold leading-6 dark:text-white">Circles</DialogTitle>
                      </div>
                      <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="toggleModal">
                        <span class="sr-only">Close panel</span>
                        <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                      </button>
                    </div>
                    <div class="flex flex-col mt-10 flex-1 px-4 sm:px-6 justify-start space-y-3" v-if="modalOpen">
                      <p class="text-sm" v-if="circles.length > 0">Select a circle to add or remove this user to or from it. A circle with a checkmark indicates this user is currently in that circle.</p>
                      <p v-else>You have no circles, you can create some in your <router-link class="underline" to="/settings/privacy/circles">Circles Settings</router-link>.</p>
                      <ul v-if="circles.length > 0" class="space-y-2">
                        <li v-for="circle in circles" :key="circle._id">
                          <button @click="circle.inCircle ? updateCircle(circle.code, 'remove') : updateCircle(circle.code, 'add')" type="button" class="flex items-center w-full bg-white hover:bg-neutral-50 dark:bg-submit-600 dark:hover:bg-submit-500 py-2.5 px-4 rounded-[10px] transition group">
                            <div class="flex flex-1 text-sm font-bold">{{ circle.name }}</div>
                            <div class="flex rounded-full items-center justify-center bg-neutral-125 dark:bg-submit-950 w-6 h-6">
                              <svg v-if="circle.inCircle" class="w-2.5 fill-black dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </div>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </li>
</template>

<script setup lang="ts">
import { useFloating, autoUpdate, arrow, offset, autoPlacement } from "@floating-ui/vue"
import { ref, computed, type Ref, onMounted, onUnmounted } from "vue"

const reference: Ref<HTMLElement | null> = ref(null)
const floating: Ref<HTMLElement | null> = ref(null)
const floatingArrow: Ref<HTMLElement | null> = ref(null)
const isPopoverVisible: Ref<boolean> = ref(false)

const middleware = [
  autoPlacement(),
  offset(20),
  arrow({element: floatingArrow})
]

const props = defineProps<{
  mode: 'hover' | 'click',
  containerStyle?: string,
  contentStyle?: string
}>()

const { x, y, strategy, placement, middlewareData } = useFloating(reference, floating, {
  whileElementsMounted: autoUpdate,
  middleware
})

const style = computed(() => ({
  position: strategy.value,
  top: `${y.value ?? 0}px`,
  left: `${x.value ?? 0}px`,
  width: 'max-content',
  maxWidth: 'calc(100vw - 32px)',
}))

const arrowX = computed(() => middlewareData.value.arrow?.x ?? null)
const arrowY = computed(() => middlewareData.value.arrow?.y ?? null)

const arrowStyle = computed(() => {
  const side = placement.value.split('-')[0]
  const staticSide = {
    top: 'bottom', right: 'left', bottom: 'top', left: 'right',
  }[side]

  return {
    left: arrowX.value != null ? `${arrowX.value}px` : '',
    top: arrowY.value != null ? `${arrowY.value}px` : '',
    [staticSide as string]: `${-10}px`,
  }
})

const showPopover = () => {
  isPopoverVisible.value = true
}

const hidePopover = () => {
  isPopoverVisible.value = false
}

const handleClickOutside = (event: MouseEvent) => {
  if (floating.value && !floating.value.contains(event.target as Node) && reference.value && !reference.value.contains(event.target as Node)) {
    hidePopover()
  }
}

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    hidePopover()
  }
}

onMounted(() => {
  if (props.mode === 'hover') {
    reference.value?.addEventListener('mouseenter', showPopover)
    reference.value?.addEventListener('mouseleave', hidePopover)
  } else if (props.mode === 'click') {
    reference.value?.addEventListener('click', showPopover)
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)
  }
})

onUnmounted(() => {
  reference.value?.removeEventListener('mouseenter', showPopover)
  reference.value?.removeEventListener('mouseleave', hidePopover)
  reference.value?.removeEventListener('click', showPopover)
  document.removeEventListener('click', handleClickOutside)
  document.removeEventListener('keydown', handleKeyDown)
})
</script>

<template>
  <div :class="[
    containerStyle ?? '',
    'relative'
    ]">
    <button ref="reference">
      <slot name="button">Open Popover</slot>
    </button>
    <div
      :class="[
        contentStyle ?? 'p-4 rounded-md dark:bg-submit-600 bg-white',
        'popoverContainer'
      ]"
      ref="floating"
      :style="style"
      v-if="isPopoverVisible"
    >
      <slot name="content">Popover Content</slot>
      <div
        ref="floatingArrow"
        class="arrow"
        :style="arrowStyle"
      ></div>
    </div>
  </div>
</template>

<style scoped>
.popoverContainer {
  z-index: 1000;
  word-break: break-word;
  overflow-wrap: break-word;
}

.arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  @apply dark:bg-submit-600 bg-white;
  z-index: -1;
  pointer-events: none;
  transform: rotate(45deg);
}
</style>

<script setup lang="ts">
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { computed, ref, watch } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  RadioGroupDescription
} from "@headlessui/vue"

const platform = usePlatformStore()
const { reportData, reportOpen } = storeToRefs(platform)
const UserStore = useUserStore()
const { id: UserID } = storeToRefs(UserStore)
const reportType = ref<any>("")
const reportTo = ref<any>("")
const reportStep = ref(1)
const accepted = ref(false)
const test = ref(false)
const details = ref("")
const sending = ref(false)
const completed = ref(false)
const error = ref(false)

const group = computed(() => {
  if (reportData.value && reportData.value.group) {
    return reportData.value.group
  } else {
    return undefined
  }
})

const canReport = computed(() => {
  return reportData.value && reportData.value.userId !== UserID.value
})

const canProceed = computed(() => {
  if (reportStep.value === 1) {
    return reportType.value !== ""
  }
  if (reportStep.value === 2) {
    if (group.value) {
      return reportTo.value !== "" && accepted.value
    }
    return accepted.value
  }
})

const buttonLabel = computed(() => {
  if (reportStep.value === 1 && !sending.value) {
    return "Next"
  }
  if (reportStep.value === 2 && !sending.value) {
    return "Report"
  }
  if (sending.value) {
    return "Sending..."
  }
})

const payload = computed(() => {
  if (reportData.value) {
    let payload: any = {
      reportedUser: reportData.value.userId,
      reportType: reportType.value.value,
      reportURL: reportData.value.url ? reportData.value.url : window.location.href,
      reportContentType: reportData.value.contentType,
      reportContent: reportData.value.contentId,
      isTest: test.value,
    }
    if (details.value.length > 0) {
      payload.details = details.value
    }
    if (group.value) {
      payload.group = group.value
      payload.reportTo = reportTo.value.value
    }
    return payload
  } else {
    return undefined
  }
})

const nextStep = () => {
  if (canProceed.value) {
    reportStep.value++
  }
  return
}

watch(() => reportOpen.value, (val) => {
  if (val) {
    reportStep.value = 1
    reportType.value = ""
    reportTo.value = ""
    accepted.value = false
    test.value = false
    details.value = ""
    sending.value = false
    completed.value = false
    error.value = false
  }
})

async function sendReport() {
  if (sending.value) return
  if (!canProceed.value) return
  sending.value = true

  try {
    await API().post(`/reports`, payload.value)
    sending.value = false
    completed.value = true
  } catch (err) {
    error.value = true
    sending.value = false
    return
  }
}

const reportToOptions = [
  {
    label: "Submit Moderators",
    value: "submit",
    desc: `Report only to Submit Moderators.`
  },
  {
    label: "Group Moderators",
    value: "group",
    desc: `Report only to Group Moderators.`
  },
  {
    label: "Both",
    value: "both",
    desc: `Report to both Submit and Group Moderators.`
  }
]

const reportOptions = [
  {
    label: "Harassment",
    value: "harassment",
    desc: `I'm being bullied, attacked, and/or harassed on Submit.`
  },
  {
    label: "Impersonation",
    value: "impersonation",
    desc: `Someone is impersonating someone else, and/or catfishing.`
  },
  {
    label: "Spam",
    value: "spam",
    desc: `A user or group is spamming and/or scamming me and/or the community.`
  },
  {
    label: "Underage",
    value: "underage",
    desc: `This user, group, or content is underage and/or posting underage content.`
  },
  {
    label: "Prohibited Content",
    value: "prohibited",
    desc: `This content violates Submit's content rules, policies, and guidelines.`
  },
  {
    label: "DMCA",
    value: "dmca",
    desc: `This user or group is posting content or this content infringes on my intellectual property.`
  },
  {
    label: "Other",
    value: "other",
    desc: `This user, group, or content is doing something else that is not listed here.`
  }
]
</script>

<template>
  <TransitionRoot as="template" :show="reportOpen">
    <Dialog as="div" class="relative z-[100]" @close="platform.toggleReportOpen">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl">
                  <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                    <div class="flex flex-grow flex-col">
                      <DialogTitle class="text-base font-semibold leading-6 dark:text-white">Report</DialogTitle>
                      <p class="text-sm">File a new report.</p>
                    </div>
                    <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="platform.toggleReportOpen">
                      <span class="sr-only">Close panel</span>
                      <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                    </button>
                  </div>
                  <div class="flex flex-col mt-10 flex-1 px-4 sm:px-6" v-if="reportStep === 1 && canReport && !completed">
                    <div class="flex flex-1 flex-col justify-start space-y-6">
                      <p>Please select a report type&hellip;</p>
                      <RadioGroup as="template" v-model="reportType">
                        <div class="space-y-2">
                          <RadioGroupOption
                            v-slot="{ checked, active }"
                            :value="option"
                            v-for="option in reportOptions"
                            :key="option.value"
                          >
                            <div
                              :class="[
                                active
                                  ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-indigo-325'
                                  : '',
                                checked ? 'bg-gold-500 bg-opacity-75 text-black ' : 'bg-neutral-50 dark:bg-submit-600',
                              ]"
                              class="relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none"
                            >
                              <div class="flex w-full items-center justify-between">
                                <div class="flex items-center">
                                  <div class="text-sm">
                                    <RadioGroupLabel
                                      as="p"
                                      :class="checked ? 'text-black' : 'text-neutral-600 dark:text-indigo-325'"
                                      class="font-semibold"
                                    >
                                      {{ option.label }}
                                    </RadioGroupLabel>
                                    <RadioGroupDescription
                                      as="span"
                                      :class="checked ? 'text-black' : 'text-gray-500 dark:text-gray-400'"
                                      class="inline"
                                    >
                                      <span>{{ option.desc }}</span>
                                    </RadioGroupDescription>
                                  </div>
                                </div>
                                <div v-show="checked" class="shrink-0 text-white">
                                  <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
                                    <circle
                                      cx="12"
                                      cy="12"
                                      r="12"
                                      fill="#fff"
                                      fill-opacity="0.2"
                                    />
                                    <path
                                      d="M7 13l3 3 7-7"
                                      stroke="#000"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </RadioGroupOption>
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                  <div class="relative flex flex-col mt-10 flex-1 px-4 sm:px-6" v-if="reportStep === 2 && canReport && !completed">
                    <div class="flex flex-1 flex-col justify-start space-y-6">
                      <div>
                        <p class="text-sm"><strong>Report Type:</strong> {{ reportType.label }}</p>
                        <p class="text-xs" v-if="group && reportType.value === 'dmca'">DMCA reports can only be sent to Submit moderation.</p>
                      </div>
                      <div v-if="group">
                        <label class="block text-sm font-bold">Send Report To</label>
                        <p class="mb-1 text-sm text-gray-400">Choose who this report should be sent to.</p>
                        <RadioGroup as="template" v-model="reportTo">
                          <div class="space-y-2">
                            <RadioGroupOption
                              v-slot="{ checked, active }"
                              :value="option"
                              v-for="option in reportToOptions"
                              :key="option.value"
                              :disabled="reportType.value === 'dmca' && (option.value === 'group' || option.value === 'both')"
                            >
                              <div
                                :class="[
                                  active
                                    ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-indigo-325'
                                    : '',
                                  checked ? 'bg-gold-500 bg-opacity-75 text-black ' : 'bg-neutral-50 dark:bg-submit-600',
                                ]"
                                class="relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none"
                              >
                                <div class="flex w-full items-center justify-between">
                                  <div class="flex items-center">
                                    <div class="text-sm">
                                      <RadioGroupLabel
                                        as="p"
                                        :class="checked ? 'text-black' : 'text-neutral-600 dark:text-indigo-325'"
                                        class="font-semibold"
                                      >
                                        {{ option.label }}
                                      </RadioGroupLabel>
                                      <RadioGroupDescription
                                        as="span"
                                        :class="checked ? 'text-black' : 'text-gray-500 dark:text-gray-400'"
                                        class="inline"
                                      >
                                        <span>{{ option.desc }}</span>
                                      </RadioGroupDescription>
                                    </div>
                                  </div>
                                  <div v-show="checked" class="shrink-0 text-white">
                                    <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
                                      <circle
                                        cx="12"
                                        cy="12"
                                        r="12"
                                        fill="#fff"
                                        fill-opacity="0.2"
                                      />
                                      <path
                                        d="M7 13l3 3 7-7"
                                        stroke="#000"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </RadioGroupOption>
                          </div>
                        </RadioGroup>
                      </div>

                      <div class="space-y-3">
                        <div>
                          <label class="block text-sm font-bold mb-1">Additional Details</label>
                          <textarea v-model="details" placeholder="Please share any other details relevant to your report." rows="6" class="w-full border-1 dark:border-0 bg-white dark:bg-submit-600 rounded-md"></textarea>
                        </div>

                        <div class="flex">
                          <div class="flex items-center h-5">
                            <input v-model="accepted" id="accepted" name="accepted" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="accepted" class="font-medium">I understand that filing a false or fake report, or attempting to weaponize the reporting system can result in my permanent removal from Submit<span class="text-gold-700 dark:text-gold-500">*</span></label>
                          </div>
                        </div>

                        <div class="flex">
                          <div class="flex items-center h-5">
                            <input v-model="test" id="test" name="test" :value="true" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="test" class="font-medium">This is a Test Report</label>
                          </div>
                        </div>
                      </div>
                      <div v-if="error" class="text-red-500 text-sm font-bold">
                        There was an error filing this report, please try again later.
                      </div>
                    </div>
                  </div>
                  <div class="relative flex flex-col mt-10 flex-1 px-4 sm:px-6" v-if="completed && canReport">
                    <div class="flex flex-1 flex-col justify-start space-y-6">
                      <p>Thank you, your report has been filed.</p>
                    </div>
                  </div>
                  <div class="relative flex flex-col mt-10 flex-1 px-4 sm:px-6" v-if="!canReport">
                    <div class="flex flex-1 flex-col justify-start space-y-6">
                      <p v-if="reportData?.contentType !== 'group'">You can't report yourself.</p>
                      <p v-else>You can't report a group that you own.</p>
                    </div>
                  </div>
                  <div class="flex border-t border-t-indigo-350 mt-6 pt-6 px-4 sm:px-6">
                    <div class="flex justify-end w-full gap-4" v-if="canReport && !completed">
                      <button type="button" @click="platform.toggleReportOpen" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Cancel</button>
                      <button @click="reportStep === 1 ? nextStep() : sendReport()" :disabled="!canProceed" type="button" :class="`py-2 px-4 text-sm border border-transparent rounded-md font-semibold bg-gold-700 dark:bg-gold-500 ${canProceed ? '' : 'bg-opacity-75'} dark:text-indigo-950`">{{ buttonLabel }}</button>
                    </div>
                    <div class="flex justify-end w-full" v-if="completed || !canReport">
                      <button type="button" @click="platform.toggleReportOpen" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Close</button>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

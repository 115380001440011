<script setup lang="ts">
import { usePlatformStore } from "@/stores/Platform"
import { storeToRefs } from "pinia"
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const Platform = usePlatformStore()
const { outOfDate, hideOutdated } = storeToRefs(Platform)

function reloadPage() {
  // @ts-ignore
  window.location.reload(true)
}
</script>

<template>
  <TransitionRoot as="template" :show="outOfDate && !hideOutdated">
    <Dialog as="div" class="relative z-[100]" @close="Platform.setHideOutdated(true)">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-x-0 top-0 flex max-w-full">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="-translate-y-full" enter-to="translate-y-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-y-0" leave-to="-translate-y-full">
              <DialogPanel class="pointer-events-auto relative w-full">
                <button @click="reloadPage" type="button" class="bg-neutral-125 dark:bg-pink-450 dark:text-submit-950 font-bold py-3 px-2 shadow-xl w-full text-center outline-none focus:outline-none">
                  There is a newer version of Submit available, please click/tap here to load it!
                </button>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import Checkbox from "@/components/input/Checkbox.vue"
import DatePicker from "@/components/input/Date.vue"
import Field from "@/components/input/Field.vue"
import Label from "@/components/input/Label.vue"
import Combo from "@/components/input/Combo.vue"
import Form from "@/components/input/Form.vue"
import Code from "@/components/input/Code.vue"
import PronounOptions from "@/data/Pronouns"
import D from "@/composables/TimeDisplay"
import RoleOptions from "@/data/Roles"
import { ref, reactive } from "vue"
import API from "@/api/api"
import * as zod from "zod"

const initialValues = reactive({
  email: "",
  username: "",
  password: undefined,
  dob: null,
  roles: undefined,
  pronoun: undefined,
  terms: false,
  age: false
})

const schema = zod.object({
  email: zod.string()
    .email({ message: "Must be a valid email address." })
    .min(1, { message: "This is required." }),
  age: zod.boolean()
    .refine(value => value === true, { message: "You must be at least 18 years of age." }),
  terms: zod.boolean()
    .refine(value => value === true, { message: "You must agree to the Terms of Service." }),
  username: zod.string()
    .min(4, { message: "Can be between 4 and 24 characters." } )
    .max(24, { message: "Can be between 4 and 24 characters." } )
    .regex(/^[a-zA-Z0-9_-]+$/, { message: "Can only contain letters, numbers, underscores, and dashes." }),
  password: zod.string()
    .min(8, { message: "Must be at least 8 characters." })
    .max(64, { message: "Must be less than 64 characters." }),
  dob: zod.date({
    invalid_type_error: "Date of Birth is required.",
  })
    .min(new Date(D().subtract(110, 'year').toString()), { message: "110 is the maximum age supported." })
    .max(new Date(D().subtract(18, 'year').toString()), { message: "You must be 18 years or older." }),
  roles: zod.string({
    invalid_type_error: "A role is required."
  })
    .min(1, { message: "This is required." }),
  pronoun: zod.string({
    invalid_type_error: "A pronoun (or 'none') is required."
  })
    .min(1, { message: "This is required." })
})

const debug = ref(false)
const working = ref<boolean>(false)
const view = ref<string>("invite")

const sentCode = ref<boolean>(false)
const validCode = ref<boolean>(false)
const invalidCode = ref<boolean>(false)
const tokenData = ref<any>(null)
const inviteCode = ref<string>("")

const error = ref<boolean>(false)

const done = ref<boolean>(false)

function checkCode(code: string) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API(false).post("/early/validate", {
        token: code
      })
      inviteCode.value = code
      tokenData.value = response.data.data
      initialValues.email = response.data.data.email
      initialValues.username = response.data.data.username
      resolve(response.data.data)
    } catch (err) {
      reject(err)
    }
  })
}

async function handleCode(code: string) {
  if (working.value) return
  working.value = true
  try {
    await checkCode(code)
    validCode.value = true
    sentCode.value = true
    working.value = false
    setTimeout(() => {
      view.value = "create"
    }, 500)
  } catch (err) {
    invalidCode.value = true
    sentCode.value = true
    working.value = false
  }
}

function resetCode() {
  sentCode.value = false
  validCode.value = false
  invalidCode.value = false
  working.value = false
}

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  try {
    const data = {
      user: {
        username: values.username,
        email: values.email,
        password: values.password,
        dob: values.dob,
        meta: {
          roles: [values.roles],
          pronoun: values.pronoun
        }
      },
      token: inviteCode.value
    }

    await API(false).post("/register", data)

    setTimeout(() => {
      done.value = true
      view.value = "done"
    }, 1000)
  } catch (err) {
    console.error(err)
    error.value = true
  }
}
</script>

<template>
  <section v-if="view === 'invite'">
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">Sign Up</h1>
    <p class="text-sm mb-4 leading-6">Please enter your invite code below.</p>
    <Label field="code">Invite Code</Label>
    <Code
      :number="6"
      :sending="working"
      :sent="sentCode"
      :valid="validCode"
      :invalid="invalidCode"
      @complete="handleCode"
      @reset="resetCode"
    />
  </section>
  <section v-if="view === 'create'">
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">Sign Up</h1>
    <Form @on-submit="handleSubmit" :schema="schema" :initial-values="initialValues" v-slot="{ fields, errors, tools, steps }" :steps="3"  class="space-y-3">
      <template v-if="steps.currentStep === 1">
        <div>
          <Label field="email">Email</Label>
          <Field type="input" subtype="email" name="email" :step="1" persist locked />
        </div>
        <div>
          <Checkbox name="age" :step="1" persist>
            I confirm I am at least 18 years of age.
          </Checkbox>
        </div>
        <div>
          <Checkbox name="terms" :step="1" persist>
            I agree to the Terms of Service.
          </Checkbox>
        </div>
      </template>
      <template v-if="steps.currentStep === 2">
        <div>
          <Label field="username">Username</Label>
          <Field name="username" :step="2" persist locked />
        </div>
        <div>
          <Label field="password">Password</Label>
          <Field type="password" name="password" :step="2" persist />
        </div>
        <div>
          <Label field="dob">Date of Birth</Label>
          <DatePicker name="dob" :step="2" persist />
        </div>
      </template>
      <template v-if="steps.currentStep === 3">
        <div>
          <Label field="roles">Role</Label>
          <Combo name="roles" :options="RoleOptions" :step="3" help-text="You can add additional roles in your profile settings." persist />
        </div>
        <div>
          <Label field="pronoun">Pronoun</Label>
          <Combo name="pronoun" :options="PronounOptions" :step="3" help-text="Selecting 'none' opts out of displaying a pronoun." persist />
        </div>
      </template>
      <div class="flex gap-4">
        <button type="button" class="flex peer/prev basis-1/2 mt-6 py-4 font-display font-bold text-sm justify-center" @click="tools.previousStep" v-if="steps.steps > 1 && steps.currentStep !== 1">
          Previous
        </button>
        <button class="flex basis-full peer-[]/prev:basis-1/2 mt-6 py-4 font-display font-bold text-sm rounded-md justify-center bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black" type="submit" :disabled="working">
          {{
          steps.steps > 1 && steps.currentStep !== steps.steps ?
          'Next' :
          working ? 'Creating...' : 'Create Account'
          }}
        </button>
      </div>
      <div v-if="debug">
        <pre>
{{ fields }}
        </pre>
        <pre>
{{ errors }}
        </pre>
        <pre>
{{ steps }}
        </pre>
      </div>
      <div v-if="error" class="mt-6 transition-all text-center text-xs rounded-md bg-red-800 px-6 py-4">
        <h2 class="font-bold">
          <span class="text-white">Error</span>
        </h2>
        <p class="text-[#FF9595]">
          <span>There was an error creating your account, please try again.</span>
        </p>
      </div>
    </Form>
  </section>
  <section v-if="done && view === 'done'">
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">Sign Up</h1>
    <p class="text-sm mb-8 leading-6"><strong>You're all set!</strong> Your Submit account has been created, sign in to get started.</p>
    <router-link to="/signin" class="block cursor-pointer text-center mt-12 font-display font-semibold rounded-md dark:bg-submit-600 dark:hover:bg-submit-500 py-4 w-full">{{ $t("signin") }}</router-link>
  </section>
</template>

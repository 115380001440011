<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeMount, onBeforeUnmount, computed } from "vue"
import type { FormContext } from "@/components/input/Form.vue"
import Label from "@/components/input/Label.vue"
import API from "@/api/api"
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot
} from "@headlessui/vue"

interface Props {
  name: string
  type: string
  helpText?: string
  persist?: boolean
  disabled?: boolean
  locked?: boolean
  lockedValue?: string
  lockedDisplay?: string
  step?: number
}

const props = withDefaults(defineProps<Props>(), {
  persist: false,
  disabled: false,
  locked: false,
  lockedValue: '',
  step: 1
})

const formContext = inject<FormContext>('formContext')
if (!formContext) {
  throw new Error(`Field: ${props.name} must be used within a Form component.`)
}

const { registerField, unregisterField, focusField, blurField, steps, errors } = formContext
const value = ref<any>("")
const newData = ref(<any>[])

onBeforeMount(() => {
  registerField(props.name, "typeahead", value, props.persist, props.locked, props.step)
})

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

watchEffect(() => {
  error.value = errors.value[props.name]
})

const typeaheadPath = computed(() => {
  switch(props.type) {
    case "interests":
      return "/interests/typeahead"
    case "users:starterpacks":
      return "/starterpacks/users/typeahead"
    default:
      return ""
  }
})

const typeaheadPlaceholder = computed(() => {
  switch(props.type) {
    case "interests":
      return "Search for a kink or interest"
    case "users:starterpacks":
      return "Search for a user"
    default:
      return `Search for a thing`
  }
})

const typeaheadLabel = computed(() => {
  switch(props.type) {
    case "interests":
      return "name"
    case "users:starterpacks":
      return "username"
    default:
      return "name"
  }
})

const typeaheadTitle = computed(() => {
  switch(props.type) {
    case "interests":
      return "Kink or Interest"
    case "users:starterpacks":
      return "User"
  }
})

async function getTypeheadData(search: string) {
  try {
    if(search.length > 2) {
      const response = await API().post(typeaheadPath.value, { query: search })

      return newData.value = response.data.data
    }
    return newData.value = []
  } catch (err) {
    if (err) console.error(err)
  }
}
</script>

<template>
  <div>
    <Label :field="name">{{ typeaheadTitle }}</Label>
    <Combobox v-model="value" :nullable="true">
      <div class="relative">
        <ComboboxInput
          :placeholder="typeaheadPlaceholder"
          @change="getTypeheadData($event.target.value)"
          @focus="handleFocus"
          @blur="handleBlur"
          :displayValue="() => value ? value[typeaheadLabel] : ''"
          class="w-full text-sm py-4 px-4 border-0 rounded-md bg-white dark:bg-submit-900 ring-0 focus:ring-0 outline-none focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50"
        />
        <TransitionRoot
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ComboboxOptions class="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div
              v-if="newData.length === 0"
              class="relative cursor-default select-none py-2 px-4"
            >
              Nothing found.
            </div>
            <ComboboxOption
              v-for="record in newData"
              :value="record"
              v-slot="{ selected, active }"
              as="template"
            >
              <li class="relative cursor-default select-none py-2 px-4"
              :class="{
                'dark:bg-gold-500 bg-gold-700 text-black': active,
                'dark:text-gray-300': !active,
              }">
                {{ record[typeaheadLabel] }}
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
    <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>
  </div>
</template>

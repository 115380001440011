<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue"
import ReactionList from "@/components/reactions/List.vue"
import { ref, computed, onBeforeMount } from "vue"
import API from "@/api/api"

interface Props {
  self: boolean
  disabled?: boolean
  reactions: any
  reacted: boolean
  reactionData?: any
  content: any
  small?: boolean
  lm?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false
})

const reactionTypes = ref(["like", "dislike", "love", "hot", "laugh", "cry", "angry", "celebrate"])
const hasReacted = ref<boolean>(props.reacted)
const reactionData = ref<any>(props.reactionData || undefined)
const reactions = ref<{ [key: string]: number }>(props.reactions)
const lm = ref<boolean>(props.lm || false)

const working = ref<boolean>(false)
const cooldown = ref<boolean>(false)

const reactionsOpen = ref(false)

onBeforeMount(() => {
  // check if reactions has all types
  const missingTypes = reactionTypes.value.filter((type) => !(type in reactions.value))
  if (missingTypes.length > 0) {
    // set missing types to 0
    missingTypes.forEach((type) => {
      reactions.value[type] = 0
    })
  }
})

const toggleReactionsOpen = async () => {
  reactionsOpen.value = !reactionsOpen.value
}

const handleReaction = async (type: string, close?: () => void) => {
  if (hasReacted.value && reactionData.value.type === type) {
    // removing reaction
    removeReaction(type)
    reactions.value[type]--
    hasReacted.value = false
    reactionData.value = null
    if (close) {
      close()
    }
  } else if (hasReacted.value && reactionData.value.type !== type) {
    // swapping reaction
    swapReaction(type)
    reactions.value[reactionData.value.type]--
    reactions.value[type]++
    reactionData.value.type = type
    if (close) {
      close()
    }
  } else {
    // adding reaction
    reactions.value[type]++
    reactionData.value = {
      _id: "pending",
      user: "pending",
      type,
      createdAt: new Date().toISOString(),
    }
    hasReacted.value = true
    const reaction = await addReaction(type)
    reactionData.value = reaction
    if (close) {
      close()
    }
  }
}

async function addReaction(type: string) {
  if (working.value || cooldown.value) return
  try {
    const path = `/reactions`
    const reaction = {
      type: type,
      contentAuthor: props.content.contentAuthor,
      contentId: props.content.contentId,
      contentType: props.content.contentType,
      contentAudience: props.content.contentAudience
    }
    const response = await API().post(path, reaction)
    cooldown.value = true
    setTimeout(() => {
      cooldown.value = false
    }, 3000)
    return Promise.resolve(response.data.data)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

async function removeReaction(type: string) {
  if (working.value || cooldown.value) return
  try {
    const path = `/reactions/${props.content.contentType}/${reactionData.value._id}`
    await API().delete(path)
    return Promise.resolve(null)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

async function swapReaction(type: string) {
  if (working.value || cooldown.value) return
  try {
    const path = `/reactions/${props.content.contentType}/${reactionData.value._id}`
    await API().post(path, {
      type: type,
    })
    return Promise.resolve(type)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

const reactionDisplay = computed(() => {
  return Object.entries(reactions.value).filter(([type, count]) => count > 0)
})

function getEmojiForType(type: string) {
  return type === "like" ? "👍" : type === "dislike" ? "👎" : type === "love" ? "❤️" : type === "hot" ? "🥵" : type === "laugh" ? "😂" : type === "cry" ? "😢" : type === "angry" ? "😠" : type === "celebrate" ? "🥳" : ""
}
</script>

<template>
  <ul :class="`flex items-center gap-1 min-h-[30px] ${ small && lm ? '-ml-2' : ''}`">
    <li v-if="(reactionDisplay.length !== reactionTypes.length) && !self && !disabled">
      <Popover :class="`relative ${small ? 'h-[30px]' : 'h-[34px]'}`" v-slot="{ open, close }">
        <PopoverButton
          :class="[
            open ? 'border-gray-500' : 'border-transparent',
            small ? 'h-[30px]' : 'h-[34px]',
            'flex items-center gap-2 justify-center border hover:border-gray-500 rounded-md px-1.5 focus:outline-none'
          ]"
        >
          <svg :class="`${small ? 'h-[17px] pb-[1px]' : 'h-[23px] pb-[3px]'} fill-gray-400`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M544 0c13.3 0 24 10.7 24 24l0 48 48 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-48 0 0 48c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-48-48 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0 0-48c0-13.3 10.7-24 24-24zM400 288A176 176 0 1 0 48 288a176 176 0 1 0 352 0zM0 288a224 224 0 1 1 448 0A224 224 0 1 1 0 288zm144.4-80a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm128 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM146 332.8c19.1 21.6 46.9 35.2 78 35.2s58.9-13.6 78-35.2c8.8-9.9 23.9-10.9 33.9-2.1s10.9 23.9 2.1 33.9C310.2 396.1 269.4 416 224 416s-86.2-19.9-114-51.5c-8.8-9.9-7.8-25.1 2.1-33.9s25.1-7.8 33.9 2.1z"/></svg>
          <span :class="`${small ? 'text-xs' : 'text-sm'}text-sm dark:text-gray-400 text-gray-500`" v-if="reactionDisplay.length === 0">Add a reaction</span>
        </PopoverButton>

        <PopoverPanel class="absolute w-sm z-50 bg-white dark:bg-submit-500 mt-1 p-1 rounded-md">
          <ul class="flex gap-1">
            <li v-for="type in reactionTypes" :key="type">
              <button
                :class="`flex items-center gap-2 border hover:border-gray-500 rounded-md px-1.5 py-0.5 ${hasReacted && reactionData && type === reactionData.type ? 'dark:border-gold-500/75 border-gold-700/75' : 'border-transparent'}`"
                type="button"
                @click="handleReaction(type, close)"
                :disabled="working || cooldown || self || disabled"
              >
                <span class="text-xl">{{ getEmojiForType(type) }}</span>
              </button>
            </li>
          </ul>
        </PopoverPanel>
      </Popover>
    </li>
    <li v-if="(self || disabled) && reactionDisplay.length === 0">
      <div :class="`${small ? 'text-xs ml-2' : 'text-sm'} dark:text-gray-400 text-gray-500`">No reactions yet</div>
    </li>
    <li v-for="([type, count]) in reactionDisplay" :key="type" v-if="reactionDisplay.length > 0">
      <button
        :class="`flex items-center gap-2 border hover:border-gray-500 rounded-md px-1.5 py-0.5 ${hasReacted && reactionData && type === reactionData.type ? 'dark:border-gold-500/75 border-gold-700/75' : 'border-transparent'}`"
        type="button"
        @click="handleReaction(type)"
        :disabled="working || cooldown || self || disabled"
      >
        <span :class="`${small ? 'text-base' : 'text-xl'}`">{{ getEmojiForType(type) }}</span>
        {{ count }}
      </button>
    </li>
    <li v-if="reactionDisplay.length > 0">
      <button
        @click="toggleReactionsOpen"
        :class="[
          'border-transparent',
          small ? 'h-[30px]' : 'h-[34px]',
          'flex items-center gap-2 justify-center border hover:border-gray-600 rounded-md px-1.5 focus:outline-none'
        ]"
      >
        <svg class="h-4 fill-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M40 48C26.7 48 16 58.7 16 72l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24L40 48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 64zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zM16 232l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0z"/></svg>
      </button>
      <ReactionList :content="props.content" :toggle="toggleReactionsOpen" :open="reactionsOpen" v-if="reactionsOpen" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import D from "@/composables/TimeDisplay"
import { ref, inject, type Ref } from "vue"
import { useRoute } from "vue-router"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const slug = inject("slug") as Ref<string>
const Group = inject("Group") as Ref<any>

const members = ref<any[]>(Group.value.memberQueue && Group.value.memberQueue.length > 0 ? Group.value.memberQueue : [])
const working = ref(false)
const error = ref(false)
const modalOpen = ref(false)
const reason = ref("")
const rejectedUser = ref("")

function toggleModal(user: string = "") {
  if (!modalOpen.value) rejectedUser.value = user
  if (modalOpen.value) {
    error.value = false
    reason.value = ""
    rejectedUser.value = ""
  }
  modalOpen.value = !modalOpen.value
}

async function approveUser(user: string) {
  if (working.value) return
  working.value = true

  let path = `/groups/g/${slug.value}/mq/${user}/approve`

  try {
    await API().get(path)
    working.value = false
    members.value = members.value.filter(m => m.user !== user)
  } catch (err) {
    error.value = true
    working.value = false
    return
  }
}

async function rejectUser() {
  if (working.value) return
  working.value = true

  const user = rejectedUser.value

  let path = `/groups/g/${slug.value}/mq/${user}/reject`

  try {
    await API().post(path, {
      reason: reason.value
    })
    toggleModal()
    working.value = false
    members.value = members.value.filter(m => m.user !== user)
  } catch (err) {
    error.value = true
    working.value = false
    return
  }
}
</script>

<template>
  <section class="space-y-6">
    <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Member Queue</h2>
    <div v-if="members.length === 0">
      <p class="text-sm">No one has requested to join the group currently.</p>
    </div>
    <table class="table table-auto w-full" v-if="members.length > 0">
      <thead class="dark:bg-submit-900 bg-white">
        <tr>
          <th class="text-left p-2">User</th>
          <th class="text-left p-2">Status</th>
          <th class="text-left p-2">Requested</th>
          <th class="text-left p-2">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr class="border-b hover:dark:bg-submit-900/50 hover:bg-white/50 dark:border-gray-800 last:border-b-0" v-for="member in members" :key="member.user">
          <td class="p-2">
            <a class="underline decoration-dotted hover:decoration-solid" :href="`/${member.username}`">{{ member.username }}</a>
          </td>
          <td class="p-2 capitalize">
            {{ member.status }}
          </td>
          <td class="p-2">
            <time :title="D(member.createdAt).format('LLLL')">{{ D(member.createdAt).fromNow() }} ago</time>
          </td>
          <td class="p-2 gap-4 flex">
            <button @click="approveUser(member.user)">Approve</button>
            <button @click="toggleModal(member.user)">Reject</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div id="loadMore"></div>
    <TransitionRoot appear :show="modalOpen" as="template">
      <Dialog as="div" @close="toggleModal()" class="relative z-[100]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-xl font-bold leading-6 text-black dark:text-white mb-4"
                >
                  Reject Membership Request
                </DialogTitle>
                <div class="flex flex-col mt-2 space-y-4">
                  <p class="text-sm">Are you sure you want to reject this user?</p>
                  <div>
                    <label for="reason" class="text-sm block font-bold mb-1">Reason</label>
                    <textarea v-model="reason" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                    <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> Reason is shared with the user.</p>
                  </div>
                  <div class="flex self-end">
                    <button
                      type="button"
                      @click="toggleModal()"
                      class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      :disabled="working"
                      @click="rejectUser"
                    >
                      {{ working ? 'Working...' : 'Reject' }}
                    </button>
                  </div>
                  <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                    <p>There was an error rejecting this user, please try again.</p>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </section>
</template>

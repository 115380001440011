<script setup lang="ts">
import ListingEvent from "@/components/events/ListingEvent.vue"
import setupCommons from "@/composables/Common"
import { format, parseISO } from "date-fns"
import { ref, onMounted, watch } from "vue"
import API from "@/api/api"

const { actionHandler } = setupCommons()

interface Props {
  type: "listing" | "yours"
  filters: any
}

const props = defineProps<Props>()

const events = ref<any[]>([])
const sortedEvents = ref<any[]>([])

const gettingInitialEvents = ref(true)
const noLocationSet = ref(true)

const getEvents = async () => {
  const queryString = new URLSearchParams()
  let url = "/events"
  switch (props.type) {
    case "listing":
      if (props.filters.location.place_name_en) {
        noLocationSet.value = false
        queryString.append("lon", props.filters.location.geo.coordinates[0])
        queryString.append("lat", props.filters.location.geo.coordinates[1])
        queryString.append("distance", props.filters.distance)
        queryString.append("unit", props.filters.distanceUnit)
      }
      queryString.append("type", props.filters.type)
      queryString.append("date", new Date(props.filters.date).toISOString())
      queryString.append("timezone", props.filters.timezone)
      queryString.append("categories", props.filters.category.join(","))
      break
    case "yours":
      queryString.append("type", props.filters.type)
      url = "/events/my-events"
      break
  }
  if (noLocationSet.value && props.filters.type === "in-person" && props.type === "listing") {
    noLocationSet.value = true
    events.value = []
    sortedEvents.value = []
    return
  }
  try {
    const { data } = await API().get(`${url}?${queryString.toString()}`)
    events.value = [...events.value, ...data.data]
    sortEvents()
  } catch (error) {
    console.error(error)
    events.value = []
  }
}

const sortEvents = () => {
  const sorted = events.value.reduce((acc, event) => {
    const day = format(parseISO(event.actualStartDateTime), "EEEE, MMMM do, yyyy")
    const existingDay = acc.find((item: any) => item.day === day)
    if (existingDay) {
      existingDay.events.push(event)
    } else {
      acc.push({ day, events: [event] })
    }
    return acc
  }, [])
  sortedEvents.value = sorted
}

onMounted(async () => {
  await getEvents()
  gettingInitialEvents.value = false
})

watch(() => props.filters, async () => {
  console.log("doing")
  gettingInitialEvents.value = true
  events.value = []
  await getEvents()
  gettingInitialEvents.value = false
}, { deep: true })
</script>

<template>
  <div class="space-y-4">
    <div v-for="dayEvents in sortedEvents" :key="dayEvents.day" v-if="!gettingInitialEvents && sortedEvents.length > 0">
      <h3 class="text-sm font-bold mb-2">{{ dayEvents.day }}</h3>
      <ul @click="actionHandler" class="space-y-3">
        <ListingEvent v-for="event in dayEvents.events" :key="event.id" :event="event" />
      </ul>
    </div>
    <div v-if="!gettingInitialEvents && sortedEvents.length === 0 && noLocationSet && props.type === 'listing'">
      <div class="text-sm">Please enter a location to view events.</div>
    </div>
    <div v-if="!gettingInitialEvents && sortedEvents.length === 0 && (!noLocationSet || props.type !== 'listing')">
      <div class="text-sm">There were no events found for your search.</div>
    </div>
    <div v-if="gettingInitialEvents">
      <div class="text-sm">Loading events...</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CustomField from "@/components/input/Custom.vue"
import { usePlatformStore } from "@/stores/Platform"
import Form from "@/components/input/Form.vue"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import { ref } from "vue"
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue"

const Router = useRouter()

const searchSchema = zod.object({
  search: zod.string()
    .min(3, { message: "Minimum 3 characters." })
})

const Platform = usePlatformStore()
const { searchOpen } = storeToRefs(Platform)

const working = ref<boolean>(false)
const error = ref<boolean>(false)

async function runSearch(values: any) {
  if (working.value) return
  working.value = true

  const data = {
    query: values.search
  }

  try {
    const response = await API().post(`/search`, data)
    Platform.setSearchQuery(values.search)
    Platform.setSearchData(response.data)
    working.value = false
    Router.push({ name: "Search" })
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <TransitionRoot :show="searchOpen" as="template" appear>
    <Dialog as="div" class="relative z-50" @close="Platform.toggleSearchOpen">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
          <DialogPanel class="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-20 overflow-hidden rounded-xl bg-white dark:bg-submit-900 shadow-2xl transition-all">
            <Form @on-submit="runSearch" :schema="searchSchema" v-slot="{ fields, errors }">
              <div class="flex w-full items-center">
                <svg class="pointer-events-none h-5 w-5 ml-4 flex flex-none fill-gray-500" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                <CustomField name="search" custom-class="h-12 flex-grow border-0 bg-transparent px-4 dark:text-white focus:ring-0 outline-none border-0 focus:ring-0 focus:border-0 sm:text-sm" />
                <button type="submit" class="flex mr-4 ml-4 flex-none">
                  <svg class="fill-gray-500 hover:fill-black dark:hover:fill-white h-5 w-5" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM294.6 135.1l99.9 107.1c3.5 3.8 5.5 8.7 5.5 13.8s-2 10.1-5.5 13.8L294.6 376.9c-4.2 4.5-10.1 7.1-16.3 7.1C266 384 256 374 256 361.7l0-57.7-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-57.7c0-12.3 10-22.3 22.3-22.3c6.2 0 12.1 2.6 16.3 7.1z"/></svg>
                </button>
              </div>
              <div class="max-h-80 scroll-py-2 border-t border-gray-500 border-opacity-20 overflow-y-auto">
                <div class="text-red-500 text-xs font-bold px-5 mt-2" v-if="errors.search">
                  {{ errors.search }}
                </div>
                <div class="p-2 mb-3">
                  <h2 class="mb-0.5 mt-3 px-3 text-xs font-semibold dark:text-gray-200">New Search Functionality!</h2>
                  <p class="text-sm px-3">Search will now find users, media, writings, groups, and kinks & interests.</p>
                </div>
              </div>
            </Form>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

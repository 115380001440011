<script setup lang="ts">
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Preferences</h2>
        <p>Manage your preferences for various Submit features and functionality.</p>
        <p class="dark:text-gray-500 text-sm font-bold">You currently have no features enabled that have preferences.</p>
      </div>
    </section>
  </main>
</template>

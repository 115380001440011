<script setup lang="ts">
import { ref, computed, inject, type Ref } from "vue"
import { usePlatformStore } from "@/stores/Platform"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

interface Props {
  group: any
}

const schema = zod.object({
  subject: zod.string()
    .min(3, { message: "Too short." }),
  message: zod.string()
    .min(10, { message: "Too short." })
})

const Platform = usePlatformStore()
const User = useUserStore()

const { id } = storeToRefs(User)

const props = defineProps<Props>()

const { isMember, setIsMember } = inject("isMember") as { isMember: Ref<boolean>, setIsMember: (value: boolean) => void }
const { isSubscribed, setIsSubscribed } = inject("isSubscribed") as { isSubscribed: Ref<boolean>, setIsSubscribed: (value: boolean) => void }
const { isQueued, setIsQueued } = inject("isQueued") as { isQueued: Ref<boolean>, setIsQueued: (value: boolean) => void }
const { isBanned } = inject("isBanned") as { isBanned: Ref<boolean>, setIsBanned: (value: boolean) => void }
const { isTimedOut } = inject("isTimedOut") as { isTimedOut: Ref<boolean>, setIsTimedOut: (value: boolean) => void }
const { isWarned } = inject("isWarned") as { isWarned: Ref<boolean>, setIsWarned: (value: boolean) => void }
const { memberCount, incrementMemberCount, decrementMemberCount } = inject("memberCount") as { memberCount: Ref<number>, incrementMemberCount: () => void, decrementMemberCount: () => void }

const error = ref(false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")
const working = ref(false)

const modalOpen = ref(false)
const queued = ref(false)

const messageModalOpen = ref(false)
const modalType = ref("message")

function toggleModal() {
  if (props.group.isOwner) return
  if (modalOpen.value) {
    error.value = false
  }
  modalOpen.value = !modalOpen.value
}

function toggleMessageModal() {
  if (props.group.isModerator) return
  if (!messageModalOpen.value) {
    modalType.value = "message"
  }
  if (messageModalOpen.value) {
    error.value = false
  }
  messageModalOpen.value = !messageModalOpen.value
}

const joinLabel = computed(() => {
  if (props.group.isOwner) return "Group Owner"
  if (isQueued.value) return "Requested"
  if (isMember.value) return "Leave Group"
  if (!props.group.isOwner && !isMember.value && props.group.moderation.membershipClosed) return "Closed"
  return props.group.type === "open" || props.group.type === "official" ? "Join Group" : "Request to Join"
})

const buttonLabel = computed(() => {
  if (working.value) return "Processing..."
  if (messageModalOpen.value) return "Send Message"
  if (isMember.value) return "Leave Group"
  return props.group.type === "open" || props.group.type === "official" ? "Join Group" : "Request to Join"
})

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: props.group.owner,
    contentId: props.group._id,
    contentType: "group"
  })
}

async function groupMembershipAction() {
  if (!props.group.isOwner && !isMember && props.group.moderation.membershipClosed) return
  if (isMember.value) {
    await leaveGroup()
    return
  }
  working.value = true
  if (!props.group.isOwner && !isMember.value && props.group.moderation.membershipClosed) return

  let path = `/groups/g/${props.group.slug}/join`

  try {
    const response = await API().get(path)
    if (response.status === 200) {
      queued.value = true
      setIsQueued(true)
      working.value = false
      return
    }
    incrementMemberCount()
    toggleModal()
    setTimeout(() => {
      setIsMember(true)
      working.value = false
    }, 500)
  } catch (err: any) {
    if (err.response.status === 400) {
      const data = err.response.data
      if (data.code) {
        errorMessage.value = data.message
        errorCode.value = data.code
      }
    }
    error.value = true
    working.value = false
  }
}

async function withdrawRequest() {
  if (!isQueued.value) return
  if (isMember.value) return
  working.value = true

  let path = `/groups/g/${props.group.slug}/mq/withdraw`

  try {
    await API().get(path)
    toggleModal()
    setTimeout(() => {
      setIsQueued(false)
      working.value = false
    }, 500)
  } catch (err: any) {
    if (err.response.status === 400) {
      const data = err.response.data
      if (data.code) {
        errorMessage.value = data.message
        errorCode.value = data.code
      }
    }
    error.value = true
    working.value = false
  }
}

async function leaveGroup() {
  if (props.group.isOwner) return
  if (!isMember.value) return
  working.value = true

  let path = `/groups/g/${props.group.slug}/leave`

  try {
    await API().delete(path)
    decrementMemberCount()
    toggleModal()
    setTimeout(() => {
      setIsMember(false)
      working.value = false
    }, 500)
  } catch (err: any) {
    if (err.response.status === 400) {
      const data = err.response.data
      if (data.code) {
        errorMessage.value = data.message
        errorCode.value = data.code
      }
    }
    error.value = true
    working.value = false
  }
}

async function sendMessage(values: any) {
  if (working.value) return
  working.value = true

  let path = `/messages`
  let params: any = {
    to: props.group._id,
    toType: "group",
    from: id,
    fromType: "user",
    type: "message",
    subject: values.subject,
    message: values.message
  }

  const response = await fetch(path, {
    method: 'POST',
    credentials: "include",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })

  if (!response.ok) {
    const data = await response.json()

    error.value = true
    if (data.code) {
      errorMessage.value = data.message
      errorCode.value = data.code
    }
    working.value = false
    return
  }
  modalType.value = "complete"
  working.value = false
}

async function subscribe(id: string) {
  if (working.value) return
  working.value = true
  try {
    await API().get(`/groups/g/${id}/subscribe`)
    setIsSubscribed(true)
  } catch (e) {
    console.error(e)
    error.value = true
  } finally {
    working.value = false
  }
}

async function unsubscribe(id: string) {
  if (working.value) return
  working.value = true
  try {
    await API().get(`/groups/g/${id}/unsubscribe`)
    setIsSubscribed(false)
  } catch (e) {
    console.error(e)
    error.value = true
  } finally {
    working.value = false
  }
}
</script>

<template>
  <header class="px-2 bg-submit-925">
    <div class="container mx-auto py-6">
      <div class="w-full flex flex-wrap items-center gap-4">
        <div class="flex flex-col w-full sm:w-auto sm:flex-1">
          <h1 class="font-display text-white text-3xl font-bold">
            <a :href="`/groups/${ group.slug }`">{{ group.name }}</a>
          </h1>
          <h2 class="text-sm text-gray-300"><span class="capitalize">{{ group.type }}</span>, {{ memberCount }} members</h2>
        </div>
        <ul class="flex w-full sm:w-auto gap-x-2">
          <li v-if="group.isModerator">
            <button class="bg-submit-950 flex py-2.5 px-2 rounded-md" type="button">
              <svg class="w-4 h-4 fill-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/></svg>
            </button>
          </li>
          <li class="w-full sm:w-auto" v-if="!isBanned && !isTimedOut && !isWarned">
            <button
              :class="[
                group.isOwner ? 'cursor-not-allowed' : '',
                !group.isOwner && !isMember && group.moderation.membershipClosed ? 'cursor-not-allowed' : '',
                'font-bold text-gray-300 bg-submit-950 py-2 px-4 rounded-md text-sm w-full sm:w-auto'
              ]"
              :disabled="!group.isOwner && !isMember && group.moderation.membershipClosed"
              @click="toggleModal"
            >
              {{ joinLabel }}
            </button>
          </li>
          <Menu as="li" class="relative">
            <MenuButton class=" bg-submit-950 py-2.5 px-4 rounded-[10px] text-xs font-bold hover:text-yellow-450 transition cursor-pointer group">
              <svg class="fill-white h-4 group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M56 472a56 56 0 1 1 0-112 56 56 0 1 1 0 112zm0-160a56 56 0 1 1 0-112 56 56 0 1 1 0 112zM0 96a56 56 0 1 1 112 0A56 56 0 1 1 0 96z"/></svg>
            </MenuButton>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems class="origin-top-right divide-y divide-indigo-350 absolute top-10 right-0 w-48 rounded-md shadow-lg bg-submit-600 ring-1 ring-transparent ring-opacity-5 focus:outline-none z-50">
                <div class="p-1">
                  <MenuItem v-slot="{ active }" v-if="isMember">
                    <button
                      type="button"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-gray-300'
                      ]"
                      @click="toggleMessageModal"
                    >
                    <svg class="w-3 mr-3 fill-gold-500" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                      Message Mods
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="isMember && isSubscribed">
                    <button
                      type="button"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-gray-300'
                      ]"
                      @click="unsubscribe(group._id)"
                    >
                    <svg class="w-3 mr-3 fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
                      Unsubscribe
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="isMember && !isSubscribed">
                    <button
                      type="button"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-gray-300'
                      ]"
                      @click="subscribe(group._id)"
                    >
                    <svg class="w-3 mr-3 fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                      Subscribe
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button
                      type="button"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-gray-300'
                      ]"
                      @click="openReportDialog"
                    >
                    <svg class="w-3 mr-3 fill-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                      Report
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </ul>
      </div>
    </div>
    <TransitionRoot appear :show="messageModalOpen" as="template">
      <Dialog as="div" @close="toggleMessageModal" class="relative z-[100]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-2 sm:p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-lg transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-925 px-2 xs:px-4 py-6 sm:px-6 sm:py-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                >
                  Message Group Moderators
                </DialogTitle>
                <template v-if="modalType === 'message'">
                  <div class="flex flex-col mt-2 space-y-4">
                    <p class="text-sm">Send a message to the group moderators. The message will be visible to all of the group moderators. Replies to this message will show up in your normal inbox.</p>
                    <Form @on-submit="sendMessage" :schema="schema" v-slot="{fields, errors}" class="space-y-6">

                      <div>
                        <Label field="subject">Subject</Label>
                        <Field name="subject" />
                      </div>

                      <div>
                        <Label field="message">Message</Label>
                        <Editor name="message" theme="editor" />
                      </div>

                      <div class="flex justify-end gap-4">
                        <div>
                          <button @click="toggleMessageModal" type="button" class="text-sm px-4 py-1.5">Cancel</button>
                        </div>
                        <div>
                          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5" :disabled="working">{{ buttonLabel }}</button>
                        </div>
                      </div>

                      <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                      </div>
                    </Form>
                  </div>
                </template>
                <template v-if="modalType === 'complete'">
                  <div class="flex flex-col mt-2 space-y-4">
                    <p>Your message has been sent to this group's moderation team.</p>
                    <div class="flex self-end">
                      <button
                        type="button"
                        @click="toggleMessageModal"
                        class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </template>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="modalOpen" as="template">
      <Dialog as="div" @close="toggleModal" class="relative z-[100]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-md bg-neutral-125 dark:bg-submit-900 px-2 xs:px-4 py-6 sm:px-6 sm:py-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-xl text-center font-bold leading-6 text-black dark:text-white mb-4"
                >
                  <template v-if="!isMember && !queued && !isQueued">
                    Join {{ group.name }}?
                  </template>
                  <template v-if="isMember && !group.isOwner && !queued && !isQueued">
                    Leave {{ group.name }}?
                  </template>
                  <template v-if="queued || isQueued">
                    Join Request Sent
                  </template>
                </DialogTitle>
                <template v-if="!isMember && !queued && !isQueued">
                  <div class="flex flex-col mt-4">
                    <ul class="flex p-3 bg-gray-200 dark:bg-submit-500 rounded-md text-sm justify-center gap-8 dark:text-gray-400">
                      <li class="flex items-center gap-2">
                        <svg class="h-4 w-4 fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/></svg>
                        {{ memberCount }} member{{ memberCount === 1 ? '' : 's' }}
                      </li>
                      <li class="flex items-center gap-2">
                        <svg class="h-4 w-4 fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
                        Created {{ D(group.createdAt).fromNow() }}
                      </li>
                    </ul>
                  </div>
                  <p class="my-6">Joining this group will grant the group access to the following <span class="font-bold dark:text-gold-500 text-gold-700">public data</span>:</p>
                  <ul class="font-bold border-b border-b-gray-300 dark:border-b-gray-700 pb-6 mb-6">
                    <li>Your Username</li>
                    <li>Your Profile Photo</li>
                    <li>Your Public Metadata</li>
                  </ul>
                  <div class="flex flex-col gap-2">
                    <button @click="groupMembershipAction" class="bg-green-700 py-3 w-full rounded-md text-sm font-bold text-white" :disabled="working">{{ buttonLabel }}</button>
                    <button @click="toggleModal" class="bg-neutral-125 dark:bg-submit-900 py-3 w-full text-sm font-bold text-gray-500">Nevermind</button>
                  </div>
                  <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                    <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                  </div>
                </template>
                <template v-if="isMember && !queued && !isQueued">
                  <div class="flex flex-col mt-4">
                    <p>Are you sure you want to leave the group?</p>
                  </div>
                  <div class="flex flex-col mt-6 gap-2">
                    <button @click="groupMembershipAction" class="bg-red-700 py-3 w-full rounded-md text-sm font-bold text-white" :disabled="working">{{ buttonLabel }}</button>
                    <button @click="toggleModal" class="bg-neutral-125 dark:bg-submit-900 py-3 w-full text-sm font-bold text-gray-500">Nevermind</button>
                  </div>
                  <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                    <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                  </div>
                </template>
                <template v-if="queued || isQueued">
                  <div class="flex flex-col mt-4">
                    <p>You have requested to join the group. You will be notified once your request has been approved by the group's moderation team.</p>
                  </div>
                  <div class="flex flex-col mt-6 gap-2">
                    <button @click="withdrawRequest" class="bg-neutral-125 dark:bg-submit-500 py-3 w-full text-sm font-bold">{{ working ? 'Withdrawing...' : 'Withdraw Request' }}</button>
                    <button @click="toggleModal" class="bg-neutral-125 dark:bg-submit-900 py-3 w-full text-sm font-bold text-gray-500">Dismiss</button>
                  </div>
                </template>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </header>
</template>

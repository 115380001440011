<script setup lang="ts">
import AddRelationship from "@/components/actions/AddRelationship.vue"
import { ref, onMounted, provide } from "vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const UserStore = useUserStore()
const { id } = storeToRefs(UserStore)
const initialLoadComplete = ref(false)
const existingRelationships = ref<any>([])
const relationshipRequests = ref<any>([])
const working = ref(false)

const modalOpen = ref(false)
provide("modalOpen", modalOpen)
const toggleModal = () => {
  modalOpen.value = !modalOpen.value
}
provide("toggleModal", toggleModal)

const fetchExistingRelationships = async () => {
  const relationships = await API().get(`/relationships`)
  existingRelationships.value = relationships.data.data
}

const fetchRelationshipRequests = async () => {
  const requests = await API().get(`/relationships/requests`)
  relationshipRequests.value = requests.data.data
}

onMounted(async () => {
  await fetchExistingRelationships()
  await fetchRelationshipRequests()
  initialLoadComplete.value = true
})

const fetchBoth = async () => {
  await fetchExistingRelationships()
  await fetchRelationshipRequests()
}

async function removeRelationship(relationshipId: string) {
  if (working.value) return
  working.value = true
  const url = `/relationships/${relationshipId}`
  try {
    await API().delete(url)
    working.value = false
    existingRelationships.value = existingRelationships.value.filter((relationship: any) => relationship._id !== relationshipId)
  } catch (error) {
    console.error(error)
    working.value = false
  }
}

async function revokeRequest(requestId: string) {
  if (working.value) return
  working.value = true
  const url = `/relationships/requests/${requestId}`
  try {
    await API().delete(url)
    relationshipRequests.value = relationshipRequests.value.filter((request: any) => request._id !== requestId)
    working.value = false
  } catch (error) {
    console.error(error)
    working.value = false
  }
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-12">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Relationships</h2>
        <p>Manage your dynamic and romantic relationships here. Add connections to display on your profile, subject to approval from all involved parties. These relationships also function as privacy filters, allowing you to share content exclusively with your connected partners.</p>
        <button @click="toggleModal" class="dark:bg-submit-600 dark:hover:bg-submit-500 bg-gray-200 hover:bg-gray-300/50 px-6 py-2 rounded-md text-sm font-bold" type="button">Add a Relationship</button>
        <AddRelationship @added="fetchBoth" />
      </div>
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-xl">Your Relationships</h2>
        <ul v-if="initialLoadComplete">
          <li class="flex justify-between" v-for="relationship in existingRelationships" :key="relationship._id" v-if="existingRelationships.length > 0">
            <div>
              <template v-if="relationship.mono && relationship.topUser.user === id && relationship.bottomUsers && relationship.bottomUsers.length === 1">
                {{ relationship.topDisplayString }}: <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${relationship.bottomUsers[0].username}`">{{ relationship.bottomUsers[0].username }}</router-link>
              </template>
              <template v-if="relationship.mono && relationship.topUser.user === id && relationship.bottomUserNotOnSubmit">
                {{ relationship.topDisplayString }} someone not on Submit
              </template>
              <template v-if="relationship.mono && relationship.bottomUsers && relationship.bottomUsers.length === 1 && relationship.bottomUsers.some((user: any) => user.user === id)">
                {{ relationship.bottomDisplayString }}: <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${relationship.topUser.username}`">{{ relationship.topUser.username }}</router-link>
              </template>
              <template v-if="relationship.solo">
                {{ relationship.topDisplayString }}
              </template>
            </div>
            <div>
              <button class="text-gray-600 hover:text-black dark:text-gray-400 dark:hover:text-white" type="button" @click="removeRelationship(relationship._id)">Remove</button>
            </div>
          </li>
          <li v-if="initialLoadComplete && existingRelationships.length === 0">You have no relationships.</li>
        </ul>
        <div v-else>
          <p>Loading relationships...</p>
        </div>
      </div>
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-xl">Your Relationship Requests</h2>
        <ul v-if="initialLoadComplete">
          <li class="flex justify-between" v-for="request in relationshipRequests" :key="request._id">
            <div>{{ request.relationshipType }} request to <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${request.toUser.username}`">{{ request.toUser.username }}</router-link></div>
            <button class="text-gray-600 hover:text-black dark:text-gray-400 dark:hover:text-white" type="button" @click="revokeRequest(request._id)">Revoke Request</button>
          </li>
          <li v-if="initialLoadComplete && relationshipRequests.length === 0">You have no relationship requests.</li>
        </ul>
        <div v-else>
          <p>Loading requests...</p>
        </div>
      </div>
    </section>
  </main>
</template>

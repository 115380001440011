<script setup lang="ts">
import ReservationItem from "@/components/groups/ReservationItem.vue"
import Filter from "@/components/input/Filter.vue"
import { ref, onMounted, watch } from "vue"
import API from "@/api/api"

const sortOptions = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" }
]

const type = ref("pending")
const reservations = ref<any[]>([])
const initialLoadComplete = ref(false)
const working = ref(false)
const error = ref(false)
const pageInfo = ref<any>({
  endCursor: undefined
})

watch(type, async () => {
  reservations.value = []
  pageInfo.value = {
    endCursor: undefined
  }
  initialLoadComplete.value = false
  await getReservations()
  initialLoadComplete.value = true
})

onMounted(async () => {
  await getReservations(false)
  initialLoadComplete.value = true
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
})

async function getReservations(setWorking: boolean = true) {
  if (working.value) return
  if (setWorking) working.value = true

  let path = `/groups/reservations/all`
  let params: any = {
    type: type.value
  }

  if (pageInfo.value.endCursor) {
    params = {
      ...params,
      cursor: pageInfo.value.endCursor
    }
  }

  path = `${path}?${new URLSearchParams(params)}`

  try {
    const response = await API().get(path)
    reservations.value.push(...response.data.data)
    pageInfo.value = response.data.pageInfo
    if (setWorking) working.value = false
  } catch (err) {
    console.error(error)
    error.value = true
    working.value = false
  }
}

let options = {
  root: null, // observing changes with respect to the document's viewport
  rootMargin: '0px', // margin around the root
  threshold: 0.1 // triggers the observer callback when 10% of the target is visible
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    // when our #loadMore element comes into view
    if (entry.isIntersecting) {
      // load more content
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getReservations()
      }
    }
  })
}, options)
</script>

<template>
  <div class="grid grid-cols-1">
    <header class="flex justify-between items-center">
      <div class="text-sm font-bold">All Reservations</div>
      <Filter label="Type" :options="sortOptions" v-model="type" />
    </header>
    <main class="mt-4" v-if="initialLoadComplete && reservations.length > 0">
      <ul class="grid grid-cols-1 gap-2">
        <ReservationItem v-for="group in reservations" :key="group._id" :group="group" />
      </ul>
    </main>
    <main class="mt-4" v-if="initialLoadComplete && reservations.length === 0">
      <p class="text-sm">No reservations found.</p>
    </main>
    <main class="mt-4" v-if="!initialLoadComplete">
      <p class="text-sm">Loading...</p>
    </main>
    <div id="loadMore"></div>
  </div>
</template>

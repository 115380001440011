<script setup lang="ts">
import { format, parseISO } from "date-fns"
import { ref, computed } from "vue"
import API from "@/api/api"

interface Props {
  event: any
}

const props = defineProps<Props>()
const emit = defineEmits(["updateIsAttending", "updateIsConsidering", "updateIsRequested"])

const working = ref<boolean>(false)

const rsvpCount = ref<number>(props.event.rsvpCount)

const isOwner = ref<boolean>(props.event.isOwner || false)
const isAttending = ref<boolean>(props.event.isGoing || false)
const isConsidering = ref<boolean>(props.event.isInterested || false)
const isRequested = ref<boolean>(props.event.isRequested)
const isRequestOnly = ref<boolean>(props.event.isRequestOnly)
const isRejected = ref<boolean>(props.event.isRejected)
const isFull = ref<boolean>(props.event.full)

const categoryLetter = computed(() => {
  switch (props.event.category) {
    case "submit-event":
      return "O"
    case "educational":
      return "E"
    case "social":
      return "S"
    case "bdsm":
      return "B"
    case "kink":
      return "K"
    case "sex":
      return "X"
    case "conference":
      return "F"
    default:
      return "?"
  }
})

// check strings for United States and replace with USA
function adjustToUSA(location: string) {
  if (location.includes("United States")) {
    return location.replace("United States", "USA")
  }
  return location
}

async function RSVPtoEvent() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${props.event.short}/rsvp`
    const response = await API().post(path)
    const data = response.data
    if (data.message === "Requested") {
      isRequested.value = true
    } else if (data.message === "Attending") {
      if (isConsidering.value) {
        isConsidering.value = false
      }
      isAttending.value = true
      rsvpCount.value ++
    }
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

async function withdrawRSVP() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${props.event.short}/rsvp`
    await API().delete(path)
    isAttending.value = false
    rsvpCount.value --
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

async function considerEvent() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${props.event.short}/interested`
    await API().post(path)
    isConsidering.value = true
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

async function withdrawConsideration() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${props.event.short}/interested`
    await API().delete(path)
    isConsidering.value = false
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

async function withdrawRequest() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${props.event.short}/request`
    await API().delete(path)
    isRequested.value = false
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

</script>

<template>
  <li :key="event.id" :data-to="`/e/${event.short}`" class="ListItem w-full bg-white dark:bg-submit-900 dark:hover:bg-submit-700 cursor-pointer rounded-md p-4">
    <div class="text-xs" v-if="event.multiDay && event.currentDayNumber">
      Day {{ event.currentDayNumber }} of {{ event.duration }}
    </div>
    <div class="flex justify-between">
      <div>
        <div class="text-xs" v-if="event.multiDay && !event.currentDayNumber">
          {{ event.duration }} days
        </div>
        <router-link :to="`/e/${event.short}`" class="dark:text-gold-500 text-gold-700 text-xl font-semibold font-display">
          {{ event.name }}
        </router-link>
      </div>
      <div>
        <div :class="`w-fit h-fit px-2 py-0.5 dark:bg-submit-950 bg-neutral-125 ${categoryLetter === 'O' ? 'dark:text-gold-500 text-gold-700' : 'text-pink-450'} text-sm font-bold`">{{ categoryLetter }}</div>
      </div>
    </div>
    <div class="text-sm" v-if="event.location && event.location.place_name_en">
      {{ adjustToUSA(event.location.place_name_en) }}
    </div>
    <ul class="grid gap-2 mt-2 text-sm dark:text-gray-300 text-gray-700">
      <li class="flex gap-2 items-center" :class="event.status === 'cancelled' ? 'line-through' : ''">
        <svg class="w-4 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>
        <div v-if="!event.multiDay">{{ format(parseISO(new Date(event.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</div>
        <div v-if="!event.multiDay">{{ format(parseISO(new Date(event.actualStartDateTime).toISOString()), "p") }}</div>
        <div v-if="event.multiDay">{{ format(parseISO(new Date(event.displayStartDateTime).toISOString()), "LLL d") }} - {{ format(parseISO(new Date(event.displayEndDateTime).toISOString()), "LLL d, yyyy") }}</div>
      </li>
      <li class="flex gap-2 items-center">
        <svg class="w-4 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 64C28.7 64 0 92.7 0 128l0 64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320l0 64c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-64c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6l0-64c0-35.3-28.7-64-64-64L64 64zm64 112l0 160c0 8.8 7.2 16 16 16l288 0c8.8 0 16-7.2 16-16l0-160c0-8.8-7.2-16-16-16l-288 0c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32l320 0c17.7 0 32 14.3 32 32l0 192c0 17.7-14.3 32-32 32l-320 0c-17.7 0-32-14.3-32-32l0-192z"/></svg>
        <div>{{ rsvpCount }} Attendee{{ rsvpCount === 1 ? '' : 's' }}</div>
      </li>
      <li class="flex gap-2" v-if="!isAttending && !isConsidering && !isRequested && !isOwner && !isRejected && event.status === 'active' && !isFull">
        <button @click="RSVPtoEvent" class="dark:bg-submit-950 bg-neutral-125 dark:hover:text-gold-500 hover:text-black px-4 py-2 text-sm rounded-md font-bold" :disabled="working">{{ isRequestOnly ? 'Request to Attend' : 'RSVP' }}</button>
        <button @click="considerEvent" class="dark:bg-submit-950 bg-neutral-125 dark:hover:text-gold-500 hover:text-black px-4 py-2 text-sm rounded-md font-bold" :disabled="working" v-if="!isRequestOnly">Considering</button>
      </li>
      <li class="flex gap-2" v-if="isAttending && !isConsidering && !isRequested && !isOwner && !isRejected && event.status === 'active'">
        <button @click="withdrawRSVP" class="dark:bg-submit-950 bg-neutral-125 dark:hover:text-gold-500 hover:text-black px-4 py-2 text-sm rounded-md font-bold" :disabled="working">Withdraw RSVP</button>
      </li>
      <li class="flex gap-2" v-if="isConsidering && !isAttending && !isRequested && !isOwner && !isRejected && event.status === 'active'">
        <button @click="RSVPtoEvent" class="dark:bg-submit-950 bg-neutral-125 dark:hover:text-gold-500 hover:text-black px-4 py-2 text-sm rounded-md font-bold" :disabled="working" v-if="!isFull">{{ isRequestOnly ? 'Request to Attend' : 'RSVP' }}</button>
        <button @click="withdrawConsideration" class="dark:bg-submit-950 bg-neutral-125 dark:hover:text-gold-500 hover:text-black px-4 py-2 text-sm rounded-md font-bold" :disabled="working" v-if="!isRequestOnly">Withdraw Consideration</button>
      </li>
      <li class="flex gap-2" v-if="isRequested && !isAttending && !isConsidering && !isOwner && !isRejected && event.status === 'active'">
        <button @click="withdrawRequest" class="dark:bg-submit-950 bg-neutral-125 dark:hover:text-gold-500 hover:text-black px-4 py-2 text-sm rounded-md font-bold" :disabled="working">Withdraw Request</button>
      </li>
      <li class="flex gap-2" v-if="isOwner && event.status === 'active'">
        <button class="dark:bg-submit-950 bg-neutral-125 dark:hover:text-gold-500 hover:text-black px-4 py-2 text-sm rounded-md font-bold">Your Event</button>
      </li>
      <li class="flex gap-2" v-if="!isOwner && event.status === 'active' && isFull">
        <div class="font-bold text-gray-300 bg-submit-950 py-2 px-4 rounded-md text-sm w-full sm:w-auto">Event Full</div>
      </li>
      <li class="flex gap-2" v-if="event.status === 'cancelled'">
        <div class="font-bold text-gray-300 bg-submit-950 py-2 px-4 rounded-md text-sm w-full sm:w-auto">Cancelled</div>
      </li>
      <li class="flex gap-2" v-if="event.status === 'completed'">
        <div class="font-bold text-gray-300 bg-submit-950 py-2 px-4 rounded-md text-sm w-full sm:w-auto">Event Over</div>
      </li>
    </ul>
  </li>
</template>

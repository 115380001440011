<script setup lang="ts">
import { ref, computed } from "vue"
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
  TransitionRoot
} from "@headlessui/vue"

interface Props {
  data: any
  placeholder?: string
  modelValue: any
  multiple?: boolean
  limit?: number
}

const props = withDefaults(defineProps<Props>(), {
  data: [],
  placeholder: "",
  modelValue: null,
  multiple: false,
  limit: 6
})

const emit = defineEmits(["update:modelValue", "blur", "focus"])

const query = ref('')

const filteredData = computed(() =>
  query.value === ''
    ? props.data
    : props.data.filter((item:any) => {
      if (typeof item === 'string') return item.toLowerCase().includes(query.value.toLowerCase())
      return item.label.toLowerCase().includes(query.value.toLowerCase()) || item.value.toLowerCase().includes(query.value.toLowerCase())
    })
)

const value = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    if (props.multiple && newValue.length > props.limit) return
    emit('update:modelValue', newValue)
  }
})

const placeholder = computed(() => {
  if (props.multiple && value.value.length === props.limit) return 'limitreached'
  return props.placeholder
})

const removeTag = (e: any) => {
  if (e.target.value.length > 0) return
  // remove last tag from array
  value.value = value.value.slice(0, -1)
  return
}

const valueDisplay = (v: string) => {
  // check if data is an array of objects and return the label
  for (const item of props.data) {
    if (typeof item === 'object' && item.value === v) return item.label
    else if (item === v) return item
  }
}

const onFocus = (open: boolean) => {
  if (open) return
  emit('focus')
}

const onBlur = (open: boolean) => {
  if (open) return
  emit('blur')
}
</script>

<template>
  <Combobox v-model="value" v-slot="{ open }" :multiple="multiple">
    <div class="relative">
      <div class="relative p-4 cursor-default overflow-hidden text-left w-full rounded-md border-0 bg-white dark:bg-submit-900 dark:text-gray-300 outline-none focus:ring-0 focus-within:outline-indigo-325/30 placeholder:text-indigo-250/50">
        <ul class="flex gap-2 flex-wrap text-sm">
          <li v-for="(v, i) in value" :key="v" v-if="multiple">{{ valueDisplay(v) }}{{ i+1 !== value.length ? ',&nbsp;' : '' }}</li>
          <li class="flex flex-auto">
            <ComboboxInput
              :placeholder="$t(placeholder)"
              class="flex flex-auto text-sm min-w-[100px] p-0 rounded-md border-0 border-transparent ring-0 focus:border-0 bg-white dark:bg-submit-900 dark:text-gray-300 focus:ring-0"
              @change="query = $event.target.value"
              @keydown.backspace="multiple ? removeTag($event) : null"
              @keydown.enter="multiple ? ($event.target.value = '', query = '') : null"
              @focus="onFocus(open)"
              @blur="onBlur(open)"
            />
          </li>
        </ul>
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center pr-4"
        >
          <svg class="w-3 dark:fill-indigo-250" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
        </ComboboxButton>
      </div>
      <TransitionRoot
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions class="absolute text-sm z-10 mt-2 max-h-60 w-full overflow-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div
            v-if="filteredData.length === 0 && query !== ''"
            class="relative cursor-default select-none py-2 px-4"
          >
            Nothing found.
          </div>
          <ComboboxOption
            v-for="item in filteredData"
            as="template"
            v-slot="{ selected, active }"
            :value="item.value ? item.value : item"
          >
            <li
              class="relative cursor-default select-none py-2 pl-10 pr-4"
              :class="{
                'bg-gold-700 dark:bg-gold-500 text-black': active,
                'dark:text-gray-300': !active,
              }"
            >
              <span
              class="block truncate"
              :class="{ 'font-medium': selected, 'font-normal': !selected }"
              >
                {{ item.label ? item.label : item }}
              </span>
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <svg class="w-4" :class="{ 'fill-black': active, 'dark:fill-gold-500 fill-gold-700': !active }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import { inject, ref, computed, onMounted, type Ref } from "vue"
import API from "@/api/api"

interface Props {
  type: "rsvp" | "considering"
}
const props = defineProps<Props>()
const error = ref<boolean>(false)

const short = inject<Ref<string>>("short") as Ref<string>
const setRSVPUsers = inject<Function>("setRSVPUsers") as (users: any[]) => void
const RSVPUsers = inject<Ref<any[]>>("RSVPUsers") as Ref<any[]>
const RSVPCount = inject<Ref<number>>("RSVPCount") as Ref<number>
const participantLimit = inject<Ref<number>>("participantLimit") as Ref<number>
const RSVPUsersFetchedAndSet = inject<Ref<boolean>>("RSVPUsersFetchedAndSet") as Ref<boolean>
const setConsideringUsers = inject<Function>("setConsideringUsers") as (users: any[]) => void
const consideringUsers = inject<Ref<any[]>>("consideringUsers") as Ref<any[]>
const consideringCount = inject<Ref<number>>("consideringCount") as Ref<number>
const ConsideringUsersFetchedAndSet = inject<Ref<boolean>>("ConsideringUsersFetchedAndSet") as Ref<boolean>

const users = computed(() => {
  return props.type === "rsvp" ? RSVPUsers.value : consideringUsers.value
})

const isLoading = computed(() => {
  return props.type === "rsvp" ? !RSVPUsersFetchedAndSet.value : !ConsideringUsersFetchedAndSet.value
})

onMounted(async () => {
  await fetchUsers()
})

async function fetchUsers() {
  try {
    const queryString = new URLSearchParams({
      type: props.type
    })
    const response = await API().get(`/events/${short.value}/users?${queryString.toString()}`)
    if (props.type === "rsvp") setRSVPUsers(response.data.data)
    else setConsideringUsers(response.data.data)
  } catch (err) {
    console.error(err)
    error.value = true
  }
}
</script>

<template>
  <section>
    <h2 class="text-sm font-bold mb-1">{{ type === "rsvp" ? `Attending (${RSVPCount}${participantLimit ? `/${participantLimit}` : ''})` : `Considering (${consideringCount})` }}</h2>
    <ul class="flex flex-wrap gap-2">
      <li v-for="user in users" :key="user" v-if="!isLoading && users && users.length > 0">
        <router-link :to="`/${user.username}`">
          <figure class="flex w-10 h-10 bg-submit-950 text-gray-500 rounded-full items-center justify-center font-semibold bg-cover" :style="user.pfp ? `background-image: url('${user.pfp}')` : ''">
            {{ user.pfp ? '' : user.firstLetter }}
          </figure>
        </router-link>
      </li>
      <li v-if="!isLoading && (!users || users.length === 0)">No {{ type === "rsvp" ? "attendees" : "one considering attending" }}, yet.</li>
      <li v-if="isLoading && !error">Loading...</li>
      <li v-if="error">Error fetching users.</li>
    </ul>
  </section>
</template>

<script setup lang="ts">
</script>

<template>
  <header class="px-2 bg-submit-925">
    <div class="container mx-auto py-6">
      <h1 class="font-display text-white text-3xl font-bold">
        <slot />
      </h1>
    </div>
  </header>
</template>

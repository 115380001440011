<script setup lang="ts">
import ModerationActivityItem from "@/components/groups/ModerationActivityItem.vue"
import { ref, inject, computed, onMounted, watch, nextTick, type Ref } from "vue"
import Editor from "@/components/editor/Editor.vue"
import D from "@/composables/TimeDisplay"
import { useRoute } from "vue-router"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const groupId = inject("groupId") as Ref<string>
const slug = inject("slug") as Ref<string>
const { isModerator } = inject("isModerator") as { isModerator: Ref<boolean> }
const { isOwner } = inject("isOwner") as { isOwner: Ref<boolean> }
const Route = useRoute()

const initialLoadComplete = ref(false)
const member = ref<any>({})
const error = ref(false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")
const working = ref(false)

const activeSection = ref('activity')

const items = ref<any[]>([])
const moderationItems = ref<any[]>([])

const pageInfo = ref<any>({
  endCursor: undefined
})
const mPageInfo = ref<any>({
  endCursor: undefined
})

const mObserverSet = ref(false)
const duration = ref(1)
const reports = ref<any[]>([])
const subject = ref("")
const message = ref("")

const canPerformModeration = computed(() => {
  if (member.value.isOwner) return false
  if (member.value.isModerator && isModerator.value && !isOwner.value) return false
  return true
})

const buttonLabel = computed(() => {
  if (working.value) return "Processing..."
  if (modalType.value === "warning") return "Warn"
  if (modalType.value === "timeout") return "Timeout"
  if (modalType.value === "kick") return "Kick"
  if (modalType.value === "ban") return "Ban"
  if (modalType.value === "rescind") return "Rescind"
  if (modalType.value === "message") return "Send"
})

const modalOpen = ref(false)
const modalType = ref("")
const reason = ref("")
const notes = ref("")

onMounted(async () => {
  await getReport()
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
})

function toggleModal(type: string) {
  modalType.value = type
  if (modalOpen.value) {
    error.value = false
    errorMessage.value = "There was an error performing this action, please try again."
    errorCode.value = "NEC"
    duration.value = 1
    reason.value = ""
    notes.value = ""
    subject.value = ""
    message.value = ""
  }
  modalOpen.value = !modalOpen.value
}

watch(activeSection, (newValue, oldValue) => {
  nextTick(() => {
    if (newValue === "moderation" && !mObserverSet.value) {
      let target = document.querySelector('#loadMoreM')
      observer2.observe(target as any)
      mObserverSet.value = true
    }
  })
})

async function getReport() {
  try {
    let path = `/groups/g/${slug.value}/members/${Route.params.id}`
    const response = await API().get(path)
    member.value = response.data.data.member
    items.value = response.data.data.activity
    pageInfo.value = response.data.data.pageInfo
    moderationItems.value = response.data.data.moderation
    mPageInfo.value = response.data.data.mPageInfo
    reports.value = response.data.data.reports
    initialLoadComplete.value = true
  } catch (err) {
    console.error(err)
    error.value = true
  }
}

async function getItems() {
  if (working.value) return
  working.value = true

  let path = `/groups/g/${slug.value}/members/${member.value.user._id}/activity`
  let params: any = {}

  if (pageInfo.value.endCursor) {
    params = {
      ...params,
      cursor: pageInfo.value.endCursor
    }
  }

  path += `?${new URLSearchParams(params)}`

  try {
    const response = await API().get(path)
    items.value.push(...response.data.data)
    pageInfo.value = response.data.pageInfo
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    working.value = false
  }
}

async function getMItems() {
  if (working.value) return
  working.value = true

  let path = `/groups/g/${slug.value}/members/${member.value.user._id}/activity`
  let params: any = { type: "moderation" }

  if (mPageInfo.value.endCursor) {
    params = {
      ...params,
      cursor: mPageInfo.value.endCursor
    }
  }

  path += `?${new URLSearchParams(params)}`

  try {
    const response = await API().get(path)
    moderationItems.value.push(...response.data.data)
    mPageInfo.value = response.data.pageInfo
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    working.value = false
  }
}

async function performModerationAction() {
  if (working.value) return
  working.value = true

  let path = `/groups/g/${slug.value}/moderation`
  let params: any = {
    type: modalType.value,
    user: member.value.user._id,
    reason: reason.value
  }

  if (modalType.value === "timeout") {
    params = {
      ...params,
      duration: duration.value
    }
  }

  if (modalType.value === "timeout" || modalType.value === "ban") {
    params = {
      ...params,
      notes: notes.value
    }
  }

  if (modalType.value === "message") {
    params = {
      to: member.value.user._id,
      toType: "user",
      from: groupId.value,
      fromType: "group",
      type: "message",
      subject: subject.value,
      message: message.value
    }
    path = `/messages`
  }

  try {
    await API().post(path, params)
    window.location.reload()
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorMessage.value = response.data.message
        errorCode.value = response.data.code
      }
    } else {
      console.error(err)
    }
    error.value = true
    working.value = false
  }
}

async function rescindModerationAction() {
  if (working.value) return
  working.value = true

  let path = `/groups/g/${slug.value}/rescind`
  let params: any = {
    punishment: member.value.punishment._id,
    user: member.value.user._id,
    reason: reason.value,
    notes: notes.value
  }

  try {
    await API().post(path, params)
    window.location.reload()
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorMessage.value = response.data.message
        errorCode.value = response.data.code
      }
    } else {
      console.error(err)
    }
    error.value = true
    working.value = false
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getItems()
      }
    }
  })
}, options)

let observer2 = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        !working.value &&
        mPageInfo.value.hasNextPage
      ) {
        getMItems()
      }
    }
  })
}, options)
</script>

<template>
  <main v-if="initialLoadComplete">
    <header class="flex items-center gap-4 mb-6">
      <a :href="`/${member.user.username}`" class="flex flex-none items-center justify-center bg-white dark:bg-submit-900 rounded-full bg-cover w-12 h-12" :style="member.user.pfp ? `background-image: url('${member.user.pfp}')` : ''">
        <span class="font-semibold text-black dark:text-gray-400 dark:hover:text-white" v-if="!member.user.pfp">{{ member.user.firstLetter }}</span>
      </a>
      <div>
        <h2 class="font-display font-semibold text-lg">
          <a :href="`/${member.user.username}`">{{ member.user.username }}</a>
        </h2>
        <div class="text-xs text-gray-400">
          <span class="capitalize">{{ member.status }}</span>
          &nbsp;&middot;&nbsp;
          <span v-if="member.isOwner">Owner</span>
          <span v-if="member.isModerator">Moderator</span>
          <span v-if="!member.isModerator && !member.isOwner">Member</span>
          &nbsp;&middot;&nbsp;
          <time :title="D(member.createdAt).format('LLLL')">Joined {{ D(member.createdAt).fromNow() }}</time>
        </div>
      </div>
    </header>
    <section class="mb-6">
      <ul class="flex flex-col md:flex-row text-sm gap-6" v-if="canPerformModeration">
        <li tabindex="0" class="underline decoration-dotted cursor-pointer hover:decoration-solid" @click="toggleModal('warning')">Warn Member</li>
        <li tabindex="0" class="underline decoration-dotted cursor-pointer hover:decoration-solid" @click="toggleModal('timeout')">Timeout Member</li>
        <li tabindex="0" class="underline decoration-dotted cursor-pointer hover:decoration-solid" @click="toggleModal('kick')">Kick Member</li>
        <li tabindex="0" class="underline decoration-dotted cursor-pointer hover:decoration-solid" @click="toggleModal('ban')">Ban Member</li>
        <li tabindex="0" class="underline decoration-dotted cursor-pointer hover:decoration-solid" @click="toggleModal('message')">Message Member</li>
      </ul>
      <p class="text-sm" v-if="!canPerformModeration">You do not have permission to perform moderation actions on this member.</p>
      <TransitionRoot appear :show="modalOpen" as="template">
        <Dialog as="div" @close="toggleModal('close')" class="relative z-[100]">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div class="fixed inset-0 overflow-y-auto">
            <div
              class="flex min-h-full items-center justify-center p-4 text-center"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                >
                  <DialogTitle
                    as="h3"
                    class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                  >
                    <template v-if="modalType === 'warning'">
                      Warn {{ member.user.username }}
                    </template>
                    <template v-if="modalType === 'timeout'">
                      Timeout {{ member.user.username }}
                    </template>
                    <template v-if="modalType === 'kick'">
                      Kick {{ member.user.username }}
                    </template>
                    <template v-if="modalType === 'ban'">
                      Ban {{ member.user.username }}
                    </template>
                    <template v-if="modalType === 'rescind'">
                      Rescind {{ member.user.username }}'s Punishment
                    </template>
                    <template v-if="modalType === 'message'">
                      Message {{ member.user.username }}
                    </template>
                  </DialogTitle>
                  <template v-if="modalType === 'warning'">
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">This action forces the user to acknolwedge the warning before they can particpiate in the group again.</p>
                      <div>
                        <label for="reason" class="text-sm block font-bold mb-1">Warning Reason</label>
                        <textarea v-model="reason" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is shared with the group member.</p>
                      </div>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleModal('close')"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                          @click="performModerationAction"
                        >
                          {{ buttonLabel }}
                        </button>
                      </div>
                      <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                      </div>
                    </div>
                  </template>
                  <template v-if="modalType === 'timeout'">
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">This action temporarily prohibits a user from accessing or participating in the group for a set period of time.</p>
                      <div>
                        <label for="reason" class="text-sm block font-bold mb-1">Timeout Duration</label>
                        <input v-model="duration" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="duration" type="number" min="1" max="365" placeholder="Duration in Days" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> Enter the number of days to time out the member for (1-365).</p>
                      </div>
                      <div>
                        <label for="reason" class="text-sm block font-bold mb-1">Timeout Reason</label>
                        <textarea v-model="reason" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is shared with the group member.</p>
                      </div>
                      <div>
                        <label for="notes" class="text-sm block font-bold mb-1">Moderator Notes</label>
                        <textarea v-model="notes" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is <strong>not</strong> shared with the group member.</p>
                      </div>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleModal('close')"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                          @click="performModerationAction"
                        >
                          {{ buttonLabel }}
                        </button>
                      </div>
                      <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                      </div>
                    </div>
                  </template>
                  <template v-if="modalType === 'kick'">
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">This action removes a user from the group. They can re-join the group.</p>
                      <div>
                        <label for="reason" class="text-sm block font-bold mb-1">Kick Reason</label>
                        <textarea v-model="reason" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is shared with the group member.</p>
                      </div>
                      <div>
                        <label for="notes" class="text-sm block font-bold mb-1">Moderator Notes</label>
                        <textarea v-model="notes" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is <strong>not</strong> shared with the group member.</p>
                      </div>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleModal('close')"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                          @click="performModerationAction"
                        >
                          {{ buttonLabel }}
                        </button>
                      </div>
                      <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                      </div>
                    </div>
                  </template>
                  <template v-if="modalType === 'ban'">
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">This action permanently prohibits a user from accessing or participating in the group.</p>
                      <div>
                        <label for="reason" class="text-sm block font-bold mb-1">Ban Reason</label>
                        <textarea v-model="reason" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is shared with the group member.</p>
                      </div>
                      <div>
                        <label for="notes" class="text-sm block font-bold mb-1">Moderator Notes</label>
                        <textarea v-model="notes" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is <strong>not</strong> shared with the group member.</p>
                      </div>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleModal('close')"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                          @click="performModerationAction"
                        >
                          {{ buttonLabel }}
                        </button>
                      </div>
                      <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                      </div>
                    </div>
                  </template>
                  <template v-if="modalType === 'rescind'">
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">This action rescinds (cancels) an active punishment for the member.</p>
                      <div>
                        <label for="reason" class="text-sm block font-bold mb-1">Rescission Reason</label>
                        <textarea v-model="reason" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is shared with the group member.</p>
                      </div>
                      <div>
                        <label for="notes" class="text-sm block font-bold mb-1">Moderator Notes</label>
                        <textarea v-model="notes" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is <strong>not</strong> shared with the group member.</p>
                      </div>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleModal('close')"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                          @click="rescindModerationAction"
                        >
                          {{ buttonLabel }}
                        </button>
                      </div>
                      <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                      </div>
                    </div>
                  </template>
                  <template v-if="modalType === 'message'">
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">Send a message to the member. The message will be sent from the Group, not you personally. Replies to this message will show up in the group moderation inbox (and visible to all moderators).</p>
                      <div>
                        <label for="subject" class="text-sm block font-bold mb-1">Subject</label>
                        <input v-model="subject" type="text" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" placeholder="Subject" />
                      </div>
                      <div>
                        <label for="message" class="text-sm block font-bold mb-1">Message</label>
                        <Editor
                          v-model="message"
                          theme="editor"
                        />
                      </div>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleModal('close')"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                          @click="performModerationAction"
                        >
                          {{ buttonLabel }}
                        </button>
                      </div>
                      <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                      </div>
                    </div>
                  </template>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </section>
    <section class="mb-12">
      <ul class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <li class="dark:bg-submit-900 bg-white rounded-md">
          <div class="px-4 pt-4 mb-4">
            <h3 class="text-sm font-bold">Post Count</h3>
            <p class="text-4xl">{{ member.postCount }}</p>
          </div>
          <div class="px-4 py-2 dark:bg-submit-600 bg-gray-200 rounded-b-md text-sm">
            <strong>Deleted:</strong> {{ member.deletedPostCount }}
          </div>
        </li>
        <li class="dark:bg-submit-900 bg-white rounded-md">
          <div class="px-4 pt-4 mb-4">
            <h3 class="text-sm font-bold">Comment Count</h3>
            <p class="text-4xl">{{ member.commentCount }}</p>
          </div>
          <div class="px-4 py-2 dark:bg-submit-600 bg-gray-200 rounded-b-md text-sm">
            <strong>Deleted:</strong> {{ member.deletedCommentCount }}
          </div>
        </li>
        <li class="dark:bg-submit-900 bg-white rounded-md">
          <div class="px-4 pt-4 mb-4">
            <h3 class="text-sm font-bold">Personal Count</h3>
            <p class="text-4xl">{{ member.personalCount }}</p>
          </div>
          <div class="px-4 py-2 dark:bg-submit-600 bg-gray-200 rounded-b-md text-sm">
            <strong>Deleted:</strong> {{ member.deletedPersonalCount }}
          </div>
        </li>
      </ul>
      <figure class="mt-6" v-if="member.isWarned || member.isTimedOut || member.isBanned">
        <template v-if="member.isWarned">
          <div class="bg-yellow-500 p-4 rounded-md text-black text-sm">
            <h3 class="font-bold">Member has a pending Warning:</h3>
            <p>{{ member.punishment.reason }}</p>
            <button @click="toggleModal('rescind')" class="mt-2 bg-yellow-700 px-4 py-1 rounded-md text-yellow-100 text-sm font-bold">Rescind Warning</button>
          </div>
        </template>
        <template v-if="member.isTimedOut">
          <div class="bg-orange-500 p-4 rounded-md text-black text-sm">
            <h3 class="font-bold">Member is currently Timed Out:</h3>
            <p>{{ member.punishment.reason }}</p>
            <button @click="toggleModal('rescind')" class="mt-2 bg-orange-700 px-4 py-1 rounded-md text-orange-100 text-sm font-bold">Rescind Timeout</button>
          </div>
        </template>
        <template v-if="member.isBanned">
          <div class="bg-red-500 p-4 rounded-md text-black text-sm">
            <h3 class="font-bold">Member is Banned:</h3>
            <p>{{ member.punishment.reason }}</p>
            <button @click="toggleModal('rescind')" class="mt-2 bg-red-700 px-4 py-1 rounded-md text-red-100 text-sm font-bold">Rescind Ban</button>
          </div>
        </template>
      </figure>
    </section>
    <nav class="mb-6">
      <ul class="flex w-full font-display md:font-sans text-[2rem] gap-x-6 md:gap-x-0 overflow-scroll scrollbar-hide md:text-sm text-gray-500 dark:text-gray-400">
        <li
          :class="[
            activeSection === 'activity' ? 'md:border-b-gold-700 md:dark:border-b-gold-500' : 'md:border-b-gray-700',
            'md:px-4 md:pb-2 md:border-b cursor-pointer'
          ]"
          @click="activeSection = 'activity'"
        >
          <span class="flex items-center gap-2 group">
            <svg class="hidden md:block h-3 fill-gray-500 dark:fill-gray-400 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M319.1 0c14.8-.4 27.9 9.3 31.8 23.6l74 271.2 17.7-35.4c10.8-21.7 33-35.4 57.2-35.4H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H499.8L444.6 398.3c-5.9 11.9-18.6 18.8-31.8 17.5s-24.2-10.6-27.7-23.4L323.7 167.3 255.3 486.7c-3.1 14.4-15.5 24.8-30.2 25.3s-27.8-9.1-31.8-23.2L135.9 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H135.9c28.6 0 53.7 18.9 61.5 46.4L219.6 348 288.7 25.3C291.8 10.9 304.4 .4 319.1 0z"/></svg>
            <span class="dark:group-hover:text-white">Activity</span>
          </span>
        </li>
        <li
          :class="[
            activeSection === 'moderation' ? 'md:border-b-gold-700 md:dark:border-b-gold-500' : 'md:border-b-gray-700',
            'md:px-4 md:pb-2 md:border-b cursor-pointer'
          ]"
          @click="activeSection = 'moderation'"
        >
          <span class="flex items-center gap-2 group">
            <svg class="hidden md:block h-3 fill-gray-500 dark:fill-gray-400 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M230.1 .8l152 40c8.6 2.3 15.3 9.1 17.3 17.8s-1 17.8-7.8 23.6L368 102.5v8.4c0 10.7-5.3 20.8-15.1 25.2c-24.1 10.8-68.6 24-128.9 24s-104.8-13.2-128.9-24C85.3 131.7 80 121.6 80 110.9v-8.4L56.4 82.2c-6.8-5.8-9.8-14.9-7.8-23.6s8.7-15.6 17.3-17.8l152-40c4-1.1 8.2-1.1 12.2 0zM227 48.6c-1.9-.8-4-.8-5.9 0L189 61.4c-3 1.2-5 4.2-5 7.4c0 17.2 7 46.1 36.9 58.6c2 .8 4.2 .8 6.2 0C257 114.9 264 86 264 68.8c0-3.3-2-6.2-5-7.4L227 48.6zM98.1 168.8c39.1 15 81.5 23.2 125.9 23.2s86.8-8.2 125.9-23.2c1.4 7.5 2.1 15.3 2.1 23.2c0 70.7-57.3 128-128 128s-128-57.3-128-128c0-7.9 .7-15.7 2.1-23.2zM134.4 352c2.8 0 5.5 .9 7.7 2.6l72.3 54.2c5.7 4.3 13.5 4.3 19.2 0l72.3-54.2c2.2-1.7 4.9-2.6 7.7-2.6C387.8 352 448 412.2 448 486.4c0 14.1-11.5 25.6-25.6 25.6H25.6C11.5 512 0 500.5 0 486.4C0 412.2 60.2 352 134.4 352zM352 408c-3.5 0-6.5 2.2-7.6 5.5L339 430.2l-17.4 0c-3.5 0-6.6 2.2-7.6 5.5s.1 6.9 2.9 9L331 454.8l-5.4 16.6c-1.1 3.3 .1 6.9 2.9 9s6.6 2 9.4 0L352 470.1l14.1 10.3c2.8 2 6.6 2.1 9.4 0s4-5.7 2.9-9L373 454.8l14.1-10.2c2.8-2 4-5.7 2.9-9s-4.2-5.5-7.6-5.5l-17.4 0-5.4-16.6c-1.1-3.3-4.1-5.5-7.6-5.5z"/></svg>
            <span class="dark:group-hover:text-white">Moderation</span>
          </span>
        </li>
        <li
          :class="[
            activeSection === 'reports' ? 'md:border-b-gold-700 md:dark:border-b-gold-500' : 'md:border-b-gray-700',
            'md:px-4 md:pb-2 md:border-b cursor-pointer'
          ]"
          @click="activeSection = 'reports'"
        >
          <span class="flex items-center gap-2 group">
            <svg class="hidden md:block h-3 fill-gray-500 dark:fill-gray-400 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
            <span class="dark:group-hover:text-white">Reports</span>
          </span>
        </li>
        <li
          :class="[
            activeSection === 'notes' ? 'md:border-b-gold-700 md:dark:border-b-gold-500' : 'md:border-b-gray-700',
            'md:px-4 md:pb-2 md:border-b cursor-pointer'
          ]"
          @click="activeSection = 'notes'"
        >
          <span class="flex items-center gap-2 group">
            <svg class="hidden md:block h-3 fill-gray-500 dark:fill-gray-400 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M400 128V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V128H400zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM96 200c0 13.3 10.7 24 24 24H328c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-13.3 0-24 10.7-24 24zm0 96c0 13.3 10.7 24 24 24H328c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-13.3 0-24 10.7-24 24zm0 96c0 13.3 10.7 24 24 24H232c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-13.3 0-24 10.7-24 24z"/></svg>
            <span class="dark:group-hover:text-white">Notes</span>
          </span>
        </li>
        <li class="hidden md:flex flex-grow px-2 pb-2 border-b border-b-gray-700"></li>
      </ul>
    </nav>
    <section class="space-y-6" v-if="activeSection === 'activity'">
      <ul role="list" class="-mb-8" v-if="items.length > 0">
        <ModerationActivityItem v-for="(item, itemIndex) in items" :key="item._id" :item="item" :index="itemIndex" :length="items.length" />
      </ul>
      <div id="loadMore"></div>
      <p v-if="items.length === 0" class="text-sm text-gray-400 dark:text-gray-500">{{ member.user.username }} activity to show.</p>
    </section>
    <section class="space-y-6" v-if="activeSection === 'moderation'">
      <ul role="list" class="-mb-8" v-if="moderationItems.length > 0">
        <ModerationActivityItem v-for="(item, itemIndex) in moderationItems" :key="item._id" :item="item" :index="itemIndex" :length="moderationItems.length" />
      </ul>
      <div id="loadMoreM"></div>
      <p v-if="moderationItems.length === 0" class="text-sm text-gray-400 dark:text-gray-500">{{ member.user.username }} has no had no moderation action taken against them.</p>
    </section>
    <section class="space-y-6" v-if="activeSection === 'reports'">
      <p class="text-sm">Reports received about this member or their content.</p>
      <table class="table table-auto w-full" v-if="reports.length > 0">
        <thead class="dark:bg-submit-900 bg-white">
          <tr>
            <th class="text-left p-2">Report Type</th>
            <th class="text-left p-2">Reporting User</th>
            <th class="text-left p-2">Last Report</th>
            <th class="text-left p-2">Report Count</th>
            <th class="text-left p-2">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b hover:dark:bg-submit-900/50 hover:bg-white/50 dark:border-gray-800 last:border-b-0" v-for="report in reports" :key="report._id">
            <td class="p-2 capitalize">
              <a class="underline decoration-dotted hover:decoration-solid" :href="`/groups/${slug}/moderation/reports/${report._id}`">{{ report.reportType }}</a>
            </td>
            <td class="p-2">
              <a class="underline decoration-dotted hover:decoration-solid" :href="`/groups/${slug}/moderation/members/${report.firstReportingUser._id}`">{{ report.firstReportingUser.username }}</a>
            </td>
            <td class="p-2">
              <time :title="D(report.lastReportedAt).format('LLLL')">{{ D(report.lastReportedAt).fromNow() }}</time>
            </td>
            <td class="p-2">{{ report.reports.length }}</td>
            <td class="p-2 capitalize">{{ report.status }}</td>
          </tr>
        </tbody>
      </table>
      <p class="text-sm" v-if="reports.length === 0">{{ member.user.username }} has not been reported in the group.</p>
    </section>
    <section class="space-y-6" v-if="activeSection === 'notes'">
      <p class="text-sm">{{ member.user.username }} has no notes.</p>
    </section>
  </main>
</template>

<script setup lang="ts">
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { computed, ref, watch } from "vue"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const Router = useRouter()
const Platform = usePlatformStore()
const UserStore = useUserStore()
const { blockData, blockOpen } = storeToRefs(Platform)
const { id: UserID } = storeToRefs(UserStore)

const working = ref(false)
const completed = ref(false)
const error = ref(false)

const notes = ref("")

const canBlock = computed(() => {
  return blockData.value && blockData.value.userId !== UserID.value
})

watch(() => blockOpen.value, (val) => {
  if (val) {
    notes.value = ""
    completed.value = false
    error.value = false
  }
})

async function blockUser() {
  if (working.value) return
  if (!blockData.value) return
  working.value = true

  const dataObj = {
    notes: notes.value
  }

  try {
    await API().post(`/users/${blockData.value.userId}/block`, dataObj)
    Router.push(`/settings/privacy/blocks`)
    Platform.toggleBlockOpen()
    completed.value = true
    working.value = false
  } catch (e) {
    error.value = true
    working.value = false
    return
  }
}

</script>

<template>
  <TransitionRoot as="template" :show="blockOpen">
    <Dialog as="div" class="relative z-[100]" @close="Platform.toggleBlockOpen">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl">
                  <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                    <div class="flex flex-grow flex-col">
                      <DialogTitle class="text-base font-semibold leading-6 dark:text-white">Block</DialogTitle>
                      <p class="text-sm">{{ blockData?.username }}</p>
                    </div>
                    <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="Platform.toggleBlockOpen">
                      <span class="sr-only">Close panel</span>
                      <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                    </button>
                  </div>
                  <div class="flex flex-col mt-10 flex-1 px-4 sm:px-6 justify-start space-y-6" v-if="!completed && canBlock">
                    <p>Are you sure you want to block {{ blockData?.username }}?</p>
                    <div>
                      <label for="notes" class="text-sm block font-bold mb-1">Notes (Optional)</label>
                      <textarea v-model="notes" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="notes" rows="3" placeholder="Notes" />
                      <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> Block notes are private and can be viewed in your block list.</p>
                    </div>
                  </div>

                  <div class="relative flex flex-col mt-10 flex-1 px-4 sm:px-6" v-if="completed && canBlock">
                    <div class="flex flex-1 flex-col justify-start space-y-6">
                      <p>Thank you, your report has been filed.</p>
                    </div>
                  </div>
                  <div class="relative flex flex-col mt-10 flex-1 px-4 sm:px-6" v-if="!canBlock">
                    <div class="flex flex-1 flex-col justify-start space-y-6">
                      <p>You can't block yourself.</p>
                    </div>
                  </div>
                  <div class="flex border-t border-t-indigo-350 mt-6 pt-6 px-4 sm:px-6">
                    <div class="flex justify-end w-full gap-4" v-if="canBlock && !completed">
                      <button type="button" @click="Platform.toggleBlockOpen" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Cancel</button>
                      <button @click="blockUser" :disabled="working" type="button" :class="`py-2 px-4 text-sm border border-transparent rounded-md font-semibold bg-gold-700 dark:bg-gold-500 dark:text-indigo-950`">{{ working ? 'Blocking...' : 'Block' }}</button>
                    </div>
                    <div class="flex justify-end w-full" v-if="completed || !canBlock">
                      <button type="button" @click="Platform.toggleBlockOpen" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Close</button>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

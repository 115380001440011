<script setup lang="ts">
import { computed } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from "@headlessui/vue"

const props = defineProps(["label", "options", "modelValue"])
const emit = defineEmits(['update:modelValue'])

function getDisplayValue(value: string) {
  return props.options.find((option: any) => option.value === value)?.label
}

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>

<template>
<Listbox v-model="value">
  <div class="relative">
    <ListboxButton class="relative flex border dark:text-gray-400 hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm">
      <span class="font-semibold">{{ label }}:</span>
      <span class="truncate capitalize">{{ value ? getDisplayValue(value) : "All"  }}</span>
      <svg class="flex-none h-3 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
    </ListboxButton>
    <transition
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ListboxOptions
        class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-submit-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm"
      >
        <ListboxOption
          v-slot="{ active, selected }"
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          as="template"
        >
          <li
            :class="[
              active ? 'bg-gold-700 dark:bg-gold-500 text-black group' : '',
              'relative cursor-default select-none py-2 pl-7 pr-2',
            ]"
          >
            <span
              :class="[
                selected ? 'font-medium' : 'font-normal',
                'block truncate',
              ]"
              >{{ option.label }}</span
            >
            <span
              v-if="selected"
              class="absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <svg class="h-3 group-hover:fill-black fill-gold-700 dark:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
            </span>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </transition>
  </div>
</Listbox>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, computed, watch } from "vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue"
import * as zod from "zod"

const isFetching = ref(true)
const reservations = ref<any[]>([])
const addOpen = ref(false)

const working = ref(false)
const error = ref(false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")

onBeforeMount(() => {
  if (addOpen.value) {
    error.value = false
    errorMessage.value = "There was an error performing this action, please try again."
    errorCode.value = "NEC"
  }
  getReservations()
})

const schema = zod.object({
  name: zod.string()
    .min(3, { message: "Too short." })
    .regex(/^[a-zA-Z0-9][a-zA-Z0-9!,?'()+& -]*[a-zA-Z0-9+)!]$/, { message: "Invalid characters." })
    .trim(),
  handle: zod.string()
    .min(3, { message: "Too short." })
    .max(24, { message: "Too long." })
    .regex(/^[a-zA-Z0-9_-]{3,24}$/, { message: "Invalid characters." })
    .trim(),
  description: zod.string()
    .min(20, { message: "Too short." }),
  relationship: zod.string()
    .min(5, { message: "Too short." }),
  url: zod.string()
    .optional()
})

const toggleAddOpen = async () => {
  addOpen.value = !addOpen.value
}

async function getReservations() {
  let path = `/organizations/reservations`

  try {
    const response = await API().get(path)
    reservations.value.push(...response.data.data)
    isFetching.value = false
  } catch (err) {
    error.value = true
    isFetching.value = false
  }
}

async function createReservation(values: any) {
  if (working.value) return
  working.value = true

  let path = `/organizations/reservations`

  let reservation: any = {
    name: values.name,
    handle: values.handle,
    description: values.description,
    relationship: values.relationship
  }

  if (values.url && values.url.length > 0) {
    reservation.url = values.url
  }

  try {
    const response = await API().post(path, reservation)
    reservations.value.push(response.data.data)
    working.value = false
    toggleAddOpen()
  } catch (err: any) {
    if (err.response.status === 400) {
      const data = err.response.data
      if (data.code) {
        errorMessage.value = data.message
        errorCode.value = data.code
      }
    }
    error.value = true
    working.value = false
  }
}

async function removeReservation(id: string) {
  if (confirm("Are you sure you want to remove this group reservation?")) {
    let path = `/organizations/reservations/${id}`

    try {
      await API().delete(path)
      const index = reservations.value.findIndex((reservation) => reservation._id === id)
      reservations.value.splice(index, 1)
    } catch (err) {
      error.value = true
    }
  }
  return
}
</script>

<template>
  <div class="space-y-3">
    <h3 class="text-sm font-bold">Organizations ({{ reservations.length }}/2)</h3>
    <p class="dark:text-gray-400 text-gray-500" v-if="!isFetching && reservations.length === 0">You have no organization reservations.</p>
    <p class="dark:text-gray-400 text-gray-500" v-if="isFetching">Loading organization reservations...</p>
    <ul class="space-y-3" v-if="!isFetching && reservations.length > 0">
      <li v-for="reservation in reservations" :key="reservation._id" class="bg-white dark:bg-submit-900 rounded-md">
        <section class="p-4">
          <h2 class="text-xl font-display font-semibold">{{ reservation.name }}</h2>
          <p class="text-sm">{{ reservation.description }}</p>
          <ul class="mt-3 text-sm">
            <li><strong>Handle:</strong> {{ reservation.handle }}</li>
            <li class="capitalize"><strong>Role:</strong> {{ reservation.relationship }}</li>
            <li><strong>URL:</strong> {{ reservation.url ? reservation.url : "N/A" }}</li>
          </ul>
        </section>
        <footer class="flex text-sm text-gray-500 dark:text-gray-400 justify-between border-t-2 py-2 px-4 border-neutral-125 dark:border-submit-950">
          <span class="capitalize">Status: {{ reservation.status }}</span>
          <button class="flex gap-1 items-center text-sm text-gray-500 dark:text-gray-400 hover:underline" @click="removeReservation(reservation._id)">
            <svg class="w-3 h-3 flex-none dark:fill-gray-400 fill-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
            Delete Reservation
          </button>
        </footer>
      </li>
    </ul>
    <p v-if="reservations.length < 2" @click="toggleAddOpen" class="hover:cursor-pointer hover:dark:text-white hover:underline inline-block">Add New Reservation</p>
    <TransitionRoot appear :show="addOpen" as="template">
      <Dialog as="div" @close="toggleAddOpen" class="relative">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-50" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-submit-950 p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gold-700 dark:text-gold-500 mb-6"
                >
                  New Organization Reservation
                </DialogTitle>
                <Form @on-submit="createReservation" :schema="schema" v-slot="{fields, errors}" class="space-y-6">
                  <div>
                    <Label field="name">Name</Label>
                    <Field name="name" />
                  </div>
                  <div>
                    <Label field="handle">Handle</Label>
                    <Field name="handle" help-text="Think of this as your username for your organization. It'll be used in the URL for your organization (https://submit.gg/handle)." />
                  </div>
                  <div>
                    <Label field="description">Description</Label>
                    <Field name="description" type="textarea" rows="2" placeholder="Organization Description" />
                  </div>
                  <div>
                    <Label field="relationship">What is your role in this organization?</Label>
                    <Field name="relationship" placeholder="Your role in this Organization" />
                  </div>
                  <div>
                    <Label field="url">Does your organization have a presence online? If so, please enter it's URL</Label>
                    <Field name="url" />
                  </div>
                  <div class="flex justify-end w-full gap-4">
                    <button type="button" @click="toggleAddOpen" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Cancel</button>
                    <button type="submit" :class="`py-2 px-4 text-sm border border-transparent rounded-md font-semibold bg-gold-700 dark:bg-gold-500 text-black ${working ? '' : 'bg-opacity-75'}`" :disabled="working">{{ working ? 'Reserving...' : 'Reserve' }}</button>
                  </div>
                  <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                    <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                  </div>
                </Form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"

const props = defineProps({
  number: {
    type: Number,
    default: 6
  },
  sending: {
    type: Boolean,
    default: false
  },
  sent: {
    type: Boolean,
    default: false
  },
  valid: {
    type: Boolean,
    default: false
  },
  invalid: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: "invite"
  }
})

const emit = defineEmits(["complete", "reset"])

const inputElements = ref<any>([])

onMounted(() => {
  inputElements.value = [...document.querySelectorAll("input[data-code]")]
})

function keyDelete(e: any) {
  let index = parseInt(e.target.attributes["data-code"].value)-1
  if (e.target.value === "") inputElements.value[index === 0 ? 0 : index-1].focus()
}

function input(e: any) {
  let index = parseInt(e.target.attributes["data-code"].value)-1
  const [first,...rest] = e.target.value
  e.target.value = first ?? ''
  const lastInputElement = index===inputElements.value.length-1
  const insertedContent = first!==undefined
  triggerComplete()
  if(insertedContent && !lastInputElement) {
    inputElements.value[index+1].focus()
    inputElements.value[index+1].value = rest.join('')
    inputElements.value[index+1].dispatchEvent(new Event('input'))
  }
}

function triggerComplete() {
  const value = inputElements.value.map((e: any) => e.value).join('')
  if(value.length === props.number) {
    emit("complete", value)
  } else {
    if (props.sent) {
      emit("reset")
    }
  }
}
</script>

<template>
  <div>
    <div class="flex flex-col sm:flex-row sm:items-center mt-2">
      <div class="space-x-2">
        <template v-for="n in number">
          <input
            :autofocus="n === 1"
            :name="'code-'+n"
            :data-code="n"
            class="bg-white dark:bg-submit-900 border-0 outline-none ring-0 focus:ring-0 dark:text-white rounded-md max-w-[50px] px-4 py-4 text-xl md:text-2xl"
            type="text"
            @keyup.delete="keyDelete"
            @input="input"
            :maxlength="n === 1 ? number : '1'"
            autocapitalize="off"
          />
        </template>
      </div>
      <div class="flex sm:ml-3 mt-2 sm:mt-0 text-sm">
        <figure class="flex flex-row items-center" v-if="sending">
          <img class="w-8 h-8 pl-3 mr-2 sm:mr-0 fill-black" src="https://assets.submit.gg/loading.svg" />
          <span class="sm:hidden font-semibold">{{ type === 'invite' ? 'Checking invite code...' : 'Checking 2FA code...' }}</span>
        </figure>
        <figure class="flex flex-row items-center" v-if="sent && valid">
          <svg class="fill-green-500 w-5 h-5 pl-1 mr-2 sm:mr-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
          <span class="sm:hidden text-green-500 font-semibold">{{ type === 'invite' ? 'Valid invite code' : 'Valid 2FA code' }}</span>
        </figure>
        <figure class="flex flex-row items-center" v-if="sent && invalid">
          <svg class="fill-red-500 w-5 h-5 pl-1 mr-2 sm:mr-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
          <span class="sm:hidden text-red-500 font-semibold">{{ type === 'invite' ? 'Invalid invite code' : 'Invalid 2FA code' }}</span>
        </figure>
      </div>
    </div>
  </div>
</template>

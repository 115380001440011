<script setup lang="ts">
import D from "@/composables/TimeDisplay"
import { ref, onMounted, inject, type Ref } from "vue"
import API from "@/api/api"

const slug = inject("slug") as Ref<string>

const initialLoadComplete = ref(false)
const reports = ref<any[]>([])
const working = ref(false)
const error = ref(false)
const pageInfo = ref<any>({
  endCursor: undefined
})

onMounted(async () => {
  await getReports()
  initialLoadComplete.value = true
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
})

async function getReports() {
  if (working.value) return
  working.value = true

  try {
    let path = `/groups/g/${slug.value}/reports`
    let params: any = {}
    if (pageInfo.value.endCursor) {
      params = {
        ...params,
        cursor: pageInfo.value.endCursor
      }
      path += `?${new URLSearchParams(params).toString()}`
    }

    const response = await API().get(path)
    reports.value.push(...response.data.data)
    pageInfo.value = response.data.pageInfo
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getReports()
      }
    }
  })
}, options)
</script>

<template>
  <main class="space-y-6">
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Moderation Reports</h2>
    </section>
    <section>
      <p v-if="initialLoadComplete && reports.length === 0" class="text-sm">No reports found.</p>
      <p v-if="!initialLoadComplete" class="text-sm">Loading...</p>
    </section>
    <section>
      <table class="table table-auto w-full" v-if="initialLoadComplete && reports.length > 0">
        <thead class="dark:bg-submit-900 bg-white">
          <tr>
            <th class="text-left p-2">Report Type</th>
            <th class="text-left p-2">Reporting User</th>
            <th class="text-left p-2">Last Report</th>
            <th class="text-left p-2">Report Count</th>
            <th class="text-left p-2">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b hover:dark:bg-submit-900/50 hover:bg-white/50 dark:border-gray-800 last:border-b-0" v-for="report in reports" :key="report._id">
            <td class="p-2 capitalize">
              <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/groups/${slug}/moderation/reports/${report._id}`">{{ report.reportType }}</router-link>
            </td>
            <td class="p-2">
              <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/groups/${slug}/moderation/members/${report.firstReportingUser._id}`">{{ report.firstReportingUser.username }}</router-link>
            </td>
            <td class="p-2">
              <time :title="D(report.lastReportedAt).format('LLLL')">{{ D(report.lastReportedAt).fromNow() }}</time>
            </td>
            <td class="p-2">{{ report.reports.length }}</td>
            <td class="p-2 capitalize">{{ report.status }}</td>
          </tr>
        </tbody>
      </table>
      <div id="loadMore"></div>
    </section>
  </main>
</template>

import ImageWithCaption from '@/components/editor/ImageWithCaption.vue'
import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

export const ImageExtension = Node.create({
  name: 'imageWithCaption',

  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    };
  },

  group() {
    return 'block'
  },

  marks: "bold italic",

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: element => {
          const img = element.querySelector('img')
          return img ? img.src : null
        }
      },
      alt: {
        default: null,
        parseHTML: element => {
          const img = element.querySelector('img')
          return img ? img.alt : null
        }
      },
      title: {
        default: null,
        parseHTML: element => {
          const img = element.querySelector('img')
          return img ? img.title : null
        }
      },
      caption: {
        default: null,
        parseHTML: element => {
          const figcaption = element.querySelector('figcaption')
          return figcaption ? figcaption.innerHTML : null
        }
      },
      id: {
        default: null,
        parseHTML: element => {
          const img = element.querySelector('img')
          return img ? img.id : null
        }
      },
      uploadProgress: {
        default: 0
      },
      published: {
        default: false,
        parseHTML: element => {
          return element.getAttribute('data-published') === 'true'
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'figure.Image-With-Caption',
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    let elements = []
    elements.push(['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { 'data-type': 'image', src: node.attrs.src, alt: node.attrs.alt, title: node.attrs.title })])
    if (node.attrs.caption) {
      elements.push(['figcaption', node.attrs.caption])
    }

    return ['figure', { class: 'Image-With-Caption', 'data-published': true },
      ...elements
    ]
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageWithCaption)
  },
})

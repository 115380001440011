<script setup lang="ts">
import type { FormContext } from "@/components/input/Form.vue"
import Editor from "@/components/editor/Editor.vue"
import { inject, ref, watchEffect } from "vue"

// Define props with modelValue as type 'any' to accept all types of values
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: 'Enter text here'
  },
  theme: {
    type: String,
    default: 'editor'
  },
  editorStyle: {
    type: String,
    default: 'default'
  },
  limit: {
    type: Number
  },
  attachments: {
    type: Array
  },
  options: {
    type: Object
  },
  mentionConfig: {
    type: Object,
    default: () => ({})
  },
  helpText: {
    type: String,
    default: ''
  },
  persist: {
    type: Boolean,
    default: false
  },
  locked: {
    type: Boolean,
    default: false
  },
  step: {
    type: Number,
    default: 1
  }
})

const emit = defineEmits(['raw', 'attachments'])

const formContext = inject<FormContext>('formContext')
if (!formContext) {
  throw new Error(`Editor: ${props.name} must be used within a Form component.`)
}

const { registerField, focusField, blurField, errors } = formContext
const value = ref<any>("")
const raw = ref<string>("")
const attachments = ref<string[]>(props.attachments as string[] || [])

registerField(props.name, "editor", value, props.persist, props.locked, props.step, raw)

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

watchEffect(() => {
  error.value = errors.value[props.name]
})

function updateRaw (newRaw: string) {
  raw.value = newRaw
  emit('raw', newRaw)
}

function updateAttachments (newAttachments: string[]) {
  attachments.value = newAttachments
  emit('attachments', newAttachments)
}
</script>

<template>
  <div>
    <Editor
      v-model="value"
      :placeholder="placeholder"
      :theme="theme"
      :editor-style="editorStyle"
      :options="options"
      :mention-config="mentionConfig"
      :limit="limit"
      :attachments="attachments"
      @focus="handleFocus"
      @blur="handleBlur"
      @raw="updateRaw"
      @attachments="updateAttachments"
    />
    <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>
  </div>
</template>

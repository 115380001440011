<script setup lang="ts">
import Markdown from "@/components/input/Markdown.vue"
import D from "@/composables/TimeDisplay"
import { ref, onBeforeMount } from "vue"
import API from "@/api/api"

const initialLoadComplete = ref(false)
const initialContent = ref("")
const archiveData = ref<any[]>([])
const working = ref(false)
const markdown = ref("")
const error = ref(false)
const success = ref(false)
const buttonLabel = ref("Save & Publish")
const archives = ref(false)

onBeforeMount(async () => {
  await getInitialData()
  markdown.value = initialContent.value || ""
  initialLoadComplete.value = true
})

async function getInitialData() {
  try {
    const { data } = await API().get("/meta/about")
    const { data: archives } = await API().get("/meta/about/archives")
    initialContent.value = data.data
    archiveData.value = archives.data
  } catch (err) {
    error.value = true
  }
}

async function handleSubmit() {
  if (working.value) return
  working.value = true
  buttonLabel.value = "Saving..."

  try {
    await API().post("/meta/about", { about: markdown.value})
    buttonLabel.value = "Save & Publish"
    success.value = true
  } catch (err) {
    error.value = true
    buttonLabel.value = "Save & Publish"
  } finally {
    working.value = false
  }
}

function restore(data: string) {
  markdown.value = data
  archives.value = false
}

function reset() {
  markdown.value = initialContent.value || ""
}
</script>

<template>
  <main>
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">About <span v-if="archives">Archives</span></h2>
      <p>Use the editor below to tell us all about you.</p>
      <Markdown v-model="markdown" :selectable="true" v-if="!archives" />
      <div class="grid xl:grid-cols-2 gap-x-6 w-full" v-if="!archives">
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <button @click="handleSubmit" class="font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 text-black py-2 px-12 mr-4" type="submit">{{ buttonLabel }}</button>
            <svg @click="reset" class="h-4 dark:fill-indigo-325 dark:hover:fill-white hover:cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32.5 224H24c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L82.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L169 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H32.5z"/></svg>
          </div>
          <span @click="archives = true" class="dark:text-indigo-325 hover:underline hover:cursor-pointer">
            Archives
          </span>
        </div>
      </div>
      <div class="grid w-full" v-if="archives">
        <div class="flex justify-between items-center">
          <span title="Go Back to Editor" aria-label="Go Back to Editor" @click="archives = false" class="dark:text-indigo-325 hover:underline hover:cursor-pointer">
            Back
          </span>
        </div>
        <div class="mt-6">
          <ul class="space-y-4" v-if="archiveData.length > 0">
            <li class="bg-white dark:bg-submit-600 p-4 rounded-md flex justify-between" v-for="archive in archiveData" :key="archive._id">
              <details class="space-y-4 group w-full">
                <summary class="flex items-center justify-between hover:cursor-pointer"><h2><div>Version: {{ D(archive.createdAt).format('llll') }}</div></h2><svg class="dark:fill-white group-open:rotate-180 transition-all fill-black h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></summary>
                <pre class="dark:bg-submit-950 bg-neutral-125 p-4 rounded-md whitespace-pre-wrap">
{{ archive.about }}
                </pre>
                <span title="Restore Archive" aria-label="Restore Archive" @click="restore(archive.about)" class="inline-block hover:cursor-pointer hover:underline">Restore</span>
              </details>
            </li>
          </ul>
          <p v-else>You have no archives.</p>
        </div>
      </div>
      <div v-if="error" class="text-red-500 font-bold">
        <p>There was an error saving your changes.</p>
      </div>
      <div v-if="success" class="text-green-500 font-bold">
        <p>Your changes were saved successfully.</p>
      </div>
    </section>
  </main>
</template>

<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeMount, onBeforeUnmount, watch } from "vue"
import type { FormContext } from "@/components/input/Form.vue"
import Label from "@/components/input/Label.vue"
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot
} from "@headlessui/vue"

interface Props {
  name: string
  helpText?: string
  persist?: boolean
  disabled?: boolean
  isSet: boolean
  locked?: boolean
  lockedValue?: any
  lockedDisplay?: string
  step?: number
  alt?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  persist: false,
  disabled: false,
  isSet: false,
  locked: false,
  lockedValue: {
    place_name_en: undefined
  },
  step: 1,
  alt: false
})

const formContext = inject<FormContext>('formContext')
if (!formContext) {
  throw new Error(`Field: ${props.name} must be used within a Form component.`)
}

const { registerField, unregisterField, focusField, blurField, steps, errors } = formContext
const value = ref<any>({
  place_name_en: undefined
})
const isSet = ref<boolean>(props.isSet)
const newLocations = ref(<any>[])

onBeforeMount(() => {
  registerField(props.name, "location", value, props.persist, props.locked, props.step, undefined, true)
})

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

watchEffect(() => {
  error.value = errors.value[props.name]
})

watch (() => isSet.value, (newValue, oldValue) => {
  if(oldValue && !newValue) {
    newLocations.value = []
    value.value = {
      place_name_en: undefined
    }
  }
})

async function getLocation(search: string) {
  try {
    if(search.length > 2) {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?limit=5&types=place%2Clocality&autocomplete=true&language=en&access_token=${import.meta.env.VITE_PUBLIC_MAPBOX_TOKEN}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      if (!response.ok) {
        console.error(response)
        return
      }
      newLocations.value = []

      const json = await response.json()
      return json.features.forEach((feature: any) => newLocations.value.push(convertLocation(feature)))
    }
    return newLocations.value = []
  } catch (err) {
    if (err) console.error(err)
  }
}

function convertLocation(location: any) {
  const stringSplit = location.place_name_en.split(",").map((value: string) => value.trim())
  let convertedObject: any = {}
  if (stringSplit.length === 3) {
    convertedObject.city = stringSplit[0]
    convertedObject.state = stringSplit[1]
    convertedObject.country = stringSplit[2]
  }
  if (stringSplit.length === 2) {
    convertedObject.city = stringSplit[0]
    convertedObject.country = stringSplit[1]
  }
  if (stringSplit.length === 1) {
    convertedObject.country = stringSplit[0]
    convertedObject.isCityState = true
  }
  if (stringSplit.length > 3) {
    const count = stringSplit.length
    convertedObject.city = stringSplit[0],
    convertedObject.state = stringSplit[count-2],
    convertedObject.country = stringSplit[count - 1]
  }
  convertedObject.geo = {
    coordinates: location.center,
    type: "Point"
  }
  convertedObject.place_name_en = location.place_name_en
  return convertedObject
}
</script>

<template>
  <div>
    <Label :field="name">City</Label>
    <div class="flex w-full flex-row gap-1" v-if="isSet">
      <div class="w-full text-sm rounded-l-md p-4" :class="alt ? 'bg-neutral-125 dark:bg-submit-950' : 'bg-white dark:bg-submit-900'">
        {{ value.place_name_en }}
      </div>
      <button @click="isSet = false" class="text-sm hover:dark:bg-submit-600 p-4 rounded-r-md" :class="alt ? 'bg-neutral-125 dark:bg-submit-950' : 'bg-white dark:bg-submit-900'">Change</button>
    </div>
    <Combobox v-model="value" :default-value="{ place_name_en: undefined }" :on-focus="handleFocus" :on-blur="handleBlur" v-else>
      <div class="relative">
        <ComboboxInput
          placeholder="Search for your city"
          @change="getLocation($event.target.value)"
          :displayValue="() => (value.place_name_en ? value.place_name_en : '')"
          class="w-full text-sm py-4 px-4 border-0 rounded-md ring-0 focus:ring-0 outline-none focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50"
          :class="alt ? 'bg-neutral-125 dark:bg-submit-950' : 'bg-white dark:bg-submit-900'"
        />
        <TransitionRoot
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ComboboxOptions class="absolute z-[100] mt-2 max-h-60 w-full overflow-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div
              v-if="newLocations.length === 0"
              class="relative cursor-default select-none py-2 px-4"
            >
              Nothing found.
            </div>
            <ComboboxOption
              v-for="place in newLocations"
              :value="place"
              v-slot="{ selected, active }"
              as="template"
            >
              <li class="relative cursor-default select-none py-2 px-4"
              :class="{
                'dark:bg-gold-500 bg-gold-700 text-black': active,
                'dark:text-gray-300': !active,
              }">
                {{ place.place_name_en }}
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
    <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>
  </div>
</template>

<script setup lang="ts">
import Checkbox from "@/components/input/Checkbox.vue"
import { usePlatformStore } from "@/stores/Platform"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { useRouter } from "vue-router"
import { computed, ref } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const changeUsernameSchema = zod.object({
  newUsername: zod.string()
    .min(3, { message: "Can be between 3 and 24 characters." } )
    .max(24, { message: "Can be between 3 and 24 characters." } )
    .regex(/^[a-zA-Z0-9_-]+$/, { message: "Can only contain letters, numbers, underscores, and dashes." }),
  reason: zod.string()
    .min(1, { message: "Reason is required." }),
  retire: zod.boolean().optional(),
  blacklist: zod.boolean().optional(),
  release: zod.boolean().optional(),
  prior: zod.boolean().optional()
})

const reasonOnlySchema = zod.object({
  reason: zod.string()
    .min(3, { message: "10 characters is the required minimum for a reason." })
})

const banSchema = zod.object({
  reason: zod.string()
    .min(3, { message: "10 characters is the required minimum for a reason." }),
  appeal: zod.boolean().optional(),
  semdMail: zod.boolean().optional(),
  remove: zod.boolean().optional()
})

const banInitialValues = {
  reason: "",
  appeal: false,
  sendMail: false,
  remove: false
}

const changeUsernameInitialValues = {
  newUsername: "",
  reason: "",
  retire: false,
  blacklist: false,
  release: false,
  prior: true
}

const Router = useRouter()
const Platform = usePlatformStore()
const UserStore = useUserStore()
const { moderationData, moderationOpen } = storeToRefs(Platform)
const { id: UserID, roles } = storeToRefs(UserStore)

const debug = ref(true)

const working = ref(false)
const error = ref(false)
const stage = ref("actions")

const isAdmin = computed(() => roles.value.includes("admin"))

const closeModal = () => {
  Platform.toggleModerationOpen()
  setTimeout(() => {
    stage.value = "actions"
    error.value = false
  }, 500)
}

async function changeUsername(values: any) {
  if (UserID === moderationData.value.userId) {
    return
  }

  try {
    working.value = true
    await API().post(`/users/${moderationData.value.userId}/moderation/change-username`, {
      newUsername: values.newUsername,
      reason: values.reason,
      retire: values.retire,
      blacklist: values.blacklist,
      release: values.release,
      prior: values.prior
    })
    working.value = false
    Platform.toggleModerationOpen()
    Router.push(`/${values.newUsername}`)
  } catch (err) {
    console.error(err)
    working.value = false
    error.value = true
  }
}

function deleteUser() {
  if (UserID === moderationData.value.userId) {
    return
  }

  if (confirm("Are you sure you want to delete this user?")) {
    try {
      working.value = true
      API().delete(`/users/${moderationData.value.userId}`).then(() => {
        working.value = false
        Platform.toggleModerationOpen()
        Router.push("/home")
      })
    } catch (err) {
      console.error(err)
      working.value = false
      error.value = true
    }
  }
  return
}

async function banUser(values: any) {
  if (UserID === moderationData.value.userId) {
    return
  }

  if (confirm("Are you sure you want to ban this user?")) {
    try {
      working.value = true
      await API().post(`/users/${moderationData.value.userId}/punish`, {
        type: "ban",
        reason: values.reason,
        canAppeal: values.appeal,
        override: true,
        sendMail: values.sendMail,
        remove: values.remove
      })
      working.value = false
      Platform.toggleModerationOpen()
      Router.push("/home")
    } catch (err) {
      console.error(err)
      working.value = false
      error.value = true
    }
  }
  return
}

async function removeMedia(values: any) {
  if (UserID === moderationData.value.userId) {
    return
  }

  if (confirm("Are you sure you want to remove this photo?")) {
    try {
      working.value = true
      await API().post(`/media/${moderationData.value.mediaId}/moderation`, {
        reason: values.reason
      })
      working.value = false
      Platform.toggleModerationOpen()
      Router.push(`/${moderationData.value.username}/media`)
    } catch (err) {
      console.error(err)
      working.value = false
      error.value = true
    }
  }
  return
}

async function removeEvent(values: any) {
  if (confirm("Are you sure you want to remove this event?")) {
    try {
      working.value = true
      await API().delete(`/events/${moderationData.value.eventId}`, {
        data: {
          reason: values.reason
        }
      })
      working.value = false
      Platform.toggleModerationOpen()
      Router.push("/events")
    } catch (err) {
      console.error(err)
      working.value = false
      error.value = true
    }
  }
}

</script>

<template>
  <TransitionRoot as="template" :show="moderationOpen" v-if="isAdmin">
    <Dialog as="div" class="relative z-[100]" @close="closeModal">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl">
                  <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                    <div class="flex flex-grow flex-col">
                      <DialogTitle class="text-base font-semibold leading-6 dark:text-white">Moderation</DialogTitle>
                    </div>
                    <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="closeModal">
                      <span class="sr-only">Close panel</span>
                      <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                    </button>
                  </div>
                  <div class="flex flex-col mt-10 flex-1 px-4 sm:px-6 justify-start space-y-3" v-if="stage === 'actions'">
                    <button v-if="moderationData.context === 'user'" @click="stage = 'changeUsername'" class="bg-submit-700 py-2 rounded-md text-sm font-bold" :disabled="working">Change Username</button>
                    <button v-if="moderationData.context === 'user'" @click="deleteUser" class="bg-submit-900 hover:bg-submit-700 py-2 rounded-md text-sm font-bold" :disabled="working">Delete User</button>
                    <button v-if="moderationData.context === 'user'" @click="stage = 'banUser'" class="bg-submit-700 py-2 rounded-md text-sm font-bold" :disabled="working">Ban User</button>
                    <button v-if="moderationData.context === 'media'" @click="stage = 'mediaDelete'" class="bg-submit-700 py-2 rounded-md text-sm font-bold" :disabled="working">Remove Photo</button>
                    <button v-if="moderationData.context === 'event'" @click="stage = 'eventRemove'" class="bg-submit-700 py-2 rounded-md text-sm font-bold" :disabled="working">Remove Event</button>
                  </div>
                  <div class="mt-10 px-4 sm:px-6 justify-start" v-if="stage === 'changeUsername'">
                    <h3 class="mb-6 text-lg font-bold">Change Username</h3>
                    <Form @on-submit="changeUsername" :schema="changeUsernameSchema" :initial-values="changeUsernameInitialValues" v-slot="{ fields, errors }" class="space-y-3">
                      <div>
                        <Label field="newUsername">New Username</Label>
                        <Field name="newUsername" />
                      </div>
                      <div>
                        <Label field="reason">Reason</Label>
                        <Field name="reason" type="textarea" rows="3" />
                      </div>
                      <div>
                        <Checkbox name="retire">Retire username</Checkbox>
                      </div>
                      <div>
                        <Checkbox name="blacklist">Blacklist username</Checkbox>
                      </div>
                      <div>
                        <Checkbox name="release">Immediately release username</Checkbox>
                      </div>
                      <div>
                        <Checkbox name="prior">Include current username in prior username list</Checkbox>
                      </div>
                      <div class="flex gap-4">
                        <button @click="closeModal" :disabled="working">Cancel</button>
                        <button type="submit" :disabled="working">Change Username</button>
                      </div>
                    </Form>
                  </div>
                  <div class="mt-10 px-4 sm:px-6 justify-start" v-if="stage === 'banUser'">
                    <h3 class="mb-6 text-lg font-bold">Ban User</h3>
                    <Form @on-submit="banUser" :schema="banSchema" :initial-values="banInitialValues" v-slot="{ fields, errors }" class="space-y-3">
                      <div>
                        <Label field="reason">Reason</Label>
                        <Field name="reason" type="textarea" rows="3" />
                      </div>
                      <div>
                        <Checkbox name="appeal">Can Appeal Ban</Checkbox>
                      </div>
                      <div>
                        <Checkbox name="sendMail">Send Email Notification</Checkbox>
                      </div>
                      <div>
                        <Checkbox name="remove">Permanently Delete Account</Checkbox>
                      </div>
                      <div v-if="debug">
                        <pre>
{{ fields }}
                        </pre>
                        <pre>
{{ errors }}
                        </pre>
                      </div>
                      <div class="flex gap-4">
                        <button @click="closeModal" :disabled="working">Cancel</button>
                        <button type="submit" :disabled="working">Ban User</button>
                      </div>
                    </Form>
                  </div>
                  <div class="mt-10 px-4 sm:px-6 justify-start" v-if="stage === 'mediaDelete'">
                    <h3 class="mb-6 text-lg font-bold">Remove Photo</h3>
                    <Form @on-submit="removeMedia" :schema="reasonOnlySchema" class="space-y-3">
                      <div>
                        <Label field="reason">Reason</Label>
                        <Field name="reason" type="textarea" rows="3" />
                      </div>
                      <div class="flex gap-4">
                        <button @click="closeModal" :disabled="working">Cancel</button>
                        <button type="submit" :disabled="working">Remove Photo</button>
                      </div>
                    </Form>
                  </div>
                  <div class="mt-10 px-4 sm:px-6 justify-start" v-if="stage === 'eventRemove'">
                    <h3 class="mb-6 text-lg font-bold">Remove Event</h3>
                    <Form @on-submit="removeEvent" :schema="reasonOnlySchema" class="space-y-3">
                      <div>
                        <Label field="reason">Reason</Label>
                        <Field name="reason" type="textarea" rows="3" />
                      </div>
                      <div class="flex gap-4">
                        <button @click="closeModal" :disabled="working">Cancel</button>
                        <button type="submit" :disabled="working">Remove Event</button>
                      </div>
                    </Form>
                  </div>
                </div>

              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

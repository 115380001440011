<script setup lang="ts">
import { reactive, ref, computed, onBeforeMount, watch } from "vue"
import Filter from "@/components/input/Filter.vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const UserStore = useUserStore()
const { id } = storeToRefs(UserStore)

interface Filters {
  search :String
}

interface Pagination {
  after: string | null
  before: string | null
  limit: number
  page: number
}

const blocks = ref<any>({})
const working = ref<boolean>(false)
const initialLoadComplete = ref<boolean>(false)
const error = ref<boolean>(false)

onBeforeMount(async () => {
  await fetchBlocks()
  initialLoadComplete.value = true
})

const limitOptions = [
  { label: "25 blocks", value: 25 },
  { label: "50 blocks", value: 50 },
  { label: "75 blocks", value: 75 },
  { label: "100 blocks", value: 100 }
]

const filters = reactive<Filters>({
  search: ""
})

const pagination = reactive<Pagination>({ after: "", before: "", limit: 25, page: 1 })

async function fetchBlocks() {
  if (working.value) return
  working.value = true

  const params: any = {
    after: pagination.after,
    before: pagination.before,
    limit: pagination.limit,
    page: pagination.page
  }

  if (filters.search.length > 2 ) {
    params.search = filters.search
  }

  try {
    const response = await API().get(`/users/${id.value}/blocks?${new URLSearchParams(params).toString()}`)
    blocks.value = {
      data: response.data.data,
      pageInfo: response.data.pageInfo
    }
    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
  }
}

watch([filters, pagination], async () => {
  await fetchBlocks()
})

const totalItems = computed(() => blocks.value.pageInfo.totalItems || 0)
const hasNextPage = computed(() => blocks.value.pageInfo.hasNextPage || false)
const hasPreviousPage = computed(() => blocks.value.pageInfo.hasPreviousPage || false)
const currentPage = computed(() => blocks.value.pageInfo.currentPage || 1)

const selectedBlocks = ref<string[]>([])

const viewingRange = computed(() => {
  if (working.value) {
    return "0-0 of 0 blocks."
  }
  if (totalItems.value === 0) {
    return "0-0 of 0 blocks."
  } else {
    // take into account totals that are not multiples of the limit
    if (currentPage.value * pagination.limit > totalItems.value) {
      return `${(currentPage.value - 1) * pagination.limit + 1}-${totalItems.value} of ${totalItems.value} blocks.`
    }
    return `${(currentPage.value - 1) * pagination.limit + 1}-${currentPage.value * pagination.limit} of ${totalItems.value} blocks.`
  }
})

function loadNextPage() {
  if (hasNextPage.value) {
    pagination.page = currentPage.value + 1
    pagination.after = null
    pagination.before = null
  }
}

function loadPreviousPage() {
  if (hasPreviousPage.value) {
    pagination.page = currentPage.value - 1
    pagination.after = null
    pagination.before = null
  }
}

async function refreshBlocks() {
  await fetchBlocks()
}

async function removeBlock(id: string) {
  if (working.value) return
  working.value = true

  try {
    await API().get(`/users/${id}/unblock`)
    working.value = false
    await fetchBlocks()
  } catch (err) {
    error.value = true
    working.value = false
  }
}

async function removeManyBlocks() {
  if (working.value) return
  working.value = true

  try {
    await API().post(`/blocks/remove`, {
      blocks: selectedBlocks.value
    })
    working.value = false
    await fetchBlocks()
  } catch (err) {
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <section class="space-y-6 max-w-2xl">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Block Sharing &amp; Syncing</h2>
        <p>You can share your block list with close friends or groups, you can also manage any block lists that you're subscribed to here.</p>
        <p class="dark:text-gray-400 text-gray-500 text-sm font-bold">This feature is currently disabled and will be enabled in the near future.</p>
      </section>
    </section>
    <section class="space-y-6">
      <section class="space-y-6 max-w-2xl">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Block List</h2>
        <p>Manage your block list. You can search your list, view your notes, and remove users from your list.</p>
      </section>
      <div class="w-full flex lg:flex-wrap gap-4 justify-between items-center" v-if="selectedBlocks.length === 0">
        <input v-model="filters.search" type="text" name="search" class="flex p-3 w-full sm:max-w-[300px] text-sm rounded-md bg-white dark:bg-submit-900 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" placeholder="Search your block list" />
        <button @click="refreshBlocks" class="flex border py-3 px-3 hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 group">
          <svg class="flex-none h-4 fill-gray-500 dark:fill-gray-400 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
        </button>
      </div>
      <div class="w-full flex items-center" v-else>
        <div class="mr-4 font-semibold">{{ selectedBlocks.length }} blocks selected.</div>
        <button @click="removeManyBlocks" type="button" class="border dark:border-transparent flex items-center px-5 py-2.5 gap-1 text-red-500 rounded-md bg-white hover:border-black dark:bg-submit-600">
          <svg class="flex-none h-3 fill-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
          Delete
        </button>
      </div>
      <div class="grid lg:grid-cols-2 gap-6 mt-6"  v-if="initialLoadComplete">
        <div class="lg:col-span-2 text-sm font-bold" v-if="working">Loading...</div>
        <div class="lg:col-span-2" v-if="!working && blocks.data.length === 0">
          <p class="text-sm font-bold">You currently have no users on your block list.</p>
        </div>
        <div class="flex gap-1" v-if="!working && blocks.data.length > 0" v-for="block in blocks.data">
          <div class="dark:bg-submit-600 bg-white p-4 rounded-l-md flex items-center">
            <input type="checkbox" v-model="selectedBlocks" :id="block._id" :value="block._id" class="h-4 w-4 mr-2 rounded-sm border dark:border-indigo-250 dark:bg-indigo-925 checked:text-yellow-450 focus:outline-indigo-325/30" />
          </div>
          <div class="dark:bg-submit-600 bg-white p-4 rounded-r-md flex flex-col flex-grow">
            <h3 class="font-display font-bold">{{ block.username }}</h3>
            <p class="h-full">{{ block.notes || "No notes." }}</p>
            <div class="flex flex-col gap-y-1 xs:flex-row xs:justify-between mt-2 dark:text-indigo-325">
              <small>Blocked {{ D(block.createdAt).fromNow() }}</small>
              <small tabindex="0" :title="`Unblock ${block.username}`" @click="removeBlock(block.userid)" class="hover:underline cursor-pointer">Remove Block</small>
            </div>
          </div>
        </div>
        <div class="flex flex-col xs:flex-row flex-wrap justify-center gap-y-2 xs:justify-between items-center lg:col-span-2" v-if="!working && blocks.data.length > 0">
          <div>{{ viewingRange }}</div>
          <div class="flex text-sm">
            <button :class="`text-sm bg-white dark:bg-indigo-925 border dark:border-transparent rounded-md py-1.5 px-3 mr-2 ${!hasPreviousPage ? 'text-neutral-500' : 'hover:border-neutral-400'}`" :disabled="!hasPreviousPage" @click="loadPreviousPage">
              &lt; <span class="hidden xs:inline">Previous</span>
            </button>
            <button :class="`text-sm bg-white dark:bg-indigo-925 border dark:border-transparent rounded-md py-1.5 px-3 mr-2 ${!hasNextPage ? 'text-neutral-500' : 'hover:border-neutral-400'}`" :disabled="!hasNextPage" @click="loadNextPage">
              <span class="hidden xs:inline">Next</span> &gt;
            </button>
            <Filter label="Show" :options="limitOptions" v-model="pagination.limit" />
          </div>
        </div>
      </div>
      <div class="text-sm font-bold" v-else>Loading...</div>
    </section>
  </main>
</template>

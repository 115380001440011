<script setup lang="ts">
import { reactive, onMounted, inject, computed } from "vue"
import Label from "@/components/input/Label.vue"

interface Props {
  initialData: any
  count: number
}

const props = defineProps<Props>()
const emits = defineEmits(['focus', 'blur'])
const addDataToArray = inject('addDataToArray') as (arrayObject: any, id: string) => void
const removeObjectFromArray = inject('removeObjectFromArray') as (id: string) => void
const countDisplay = computed(() => props.count + 1)

const value = reactive({
  id: props.initialData.id,
  title: props.initialData.title ? props.initialData.title : '',
  desc: props.initialData.desc ? props.initialData.desc : ''
})

onMounted(() => {
  addDataToArray(value, props.initialData.id)
})

function handleFocus() {
  emits('focus')
}

function handleBlur() {
  emits('blur')
}
</script>

<template>
  <div class="bg-white dark:bg-submit-925 rounded-md p-4 space-y-3">
    <h3 class="font-bold">Rule {{ countDisplay }}</h3>
    <div>
      <Label :field="`title_${value.id}`">Rule Title</Label>
      <input @focus="handleFocus" @blur="handleBlur" :name="`title_${value.id}`" class="w-full text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" v-model="value.title" type="text" placeholder="Rule Title" />
    </div>
    <div>
      <Label :field="`desc_${value.id}`">Rule Description</Label>
      <textarea @focus="handleFocus" @blur="handleBlur" class="w-full text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" :name="`desc_${value.id}`" v-model="value.desc"></textarea>
    </div>
    <button class="text-sm font-bold text-gray-400 dark:text-gray-500 hover:dark:text-gray-300 hover:text-black" @click="removeObjectFromArray(value.id)" type="button">Remove Rule</button>
  </div>
</template>

<script setup lang="ts">
import Field from "@/components/input/Field.vue"
import Label from "@/components/input/Label.vue"
import Form from "@/components/input/Form.vue"
import { ref, onBeforeMount } from "vue"
import { useRoute } from "vue-router"
import API from "@/api/api"
import * as zod from "zod"

const route = useRoute()

const eSchema = zod.object({
  email: zod.string()
    .email({ message: "Must be a valid email address." })
    .min(1, { message: "This is required." })
})

const pSchema = zod.object({
  password: zod.string()
    .min(8, { message: "Must be at least 8 characters." })
    .max(64, { message: "Must be less than 64 characters." }),
})

const debug = ref<boolean>(false)
const view = ref<string>("ready")
const error = ref<boolean>(false)
const working = ref<boolean>(false)
const token = ref<string>("")

onBeforeMount(() => {
  const t = route.query.code as string
  if (!t) {
    view.value = "ready"
    return
  }
  token.value = t
  view.value = "reset"
})

async function resetPassword(values: any) {
  if (working.value) return
  working.value = true
  try {
    await API().post("/reset", {
      email: values.email
    })
    view.value = "sent"
    working.value = false
  } catch (err: any) {
    error.value = true
    working.value = false
  }
}

async function changePassword(values: any) {
  if (working.value) return
  working.value = true
  try {
    await API(false).post(`/reset/${token.value}`, {
      password: values.password
    })
    view.value = "complete"
    working.value = false
  } catch (err: any) {
    error.value = true
    working.value = false
  }

}
</script>

<template>
  <section>
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">{{ $t('resetpassword') }}</h1>
    <template v-if="view === 'ready'">
      <Form :schema="eSchema" @on-submit="resetPassword" v-slot="{ fields, errors }" class="space-y-3">
        <div>
          <Label field="email">{{ $t("email") }}</Label>
          <Field type="input" subtype="email" name="email" placeholder="name@domain.com" />
        </div>
        <div>
          <button class="mt-6 font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black py-4 w-full" type="submit" :disabled="working">
            {{ working ? $t("processing") : $t("reset") }}
          </button>
        </div>
        <div v-if="debug">
          <pre>
{{ fields }}
          </pre>
          <pre>
{{ errors }}
          </pre>
        </div>
      </Form>
    </template>
    <template v-if="view === 'sent'">
      <p class="text-sm mb-8 leading-6">If there was an account with the email provided, a password reset email will be sent to it.</p>
      <p class="text-sm">Haven't received your email yet?
        <a href="/reset" class="underline hover:decoration-2">Retry</a>
      </p>
      <router-link to="/" class="block cursor-pointer text-center mt-12 font-display font-semibold rounded-md dark:bg-submit-600 dark:hover:bg-submit-500 py-4 w-full">Return to our website</router-link>
    </template>
    <template v-if="view === 'reset'">
      <Form :schema="pSchema" @on-submit="changePassword" v-slot="{ fields, errors }" class="space-y-3">
        <div>
          <Label field="password">{{ $t("newpassword") }}</Label>
          <Field type="input" subtype="password" name="password" />
        </div>
        <div>
          <button class="mt-6 font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black py-4 w-full" type="submit" :disabled="working">
            {{ working ? $t("processing") : $t("changepassword") }}
          </button>
        </div>
        <div v-if="debug">
          <pre>
{{ fields }}
          </pre>
          <pre>
{{ errors }}
          </pre>
        </div>
      </Form>
    </template>
    <template v-if="view === 'complete'">
      <p class="text-sm mb-8">You have reset your password.</p>
      <router-link to="/signin" class="block cursor-pointer text-center mt-12 font-display font-semibold rounded-md dark:bg-submit-600 dark:hover:bg-submit-500 py-4 w-full">{{ $t("signin") }}</router-link>
    </template>
  </section>
</template>

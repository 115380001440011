<script setup lang="ts">
import Checkbox from "@/components/input/Checkbox.vue"
import Field from "@/components/input/Field.vue"
import Label from "@/components/input/Label.vue"
import Form from "@/components/input/Form.vue"
import { ref, reactive } from "vue"
import API from "@/api/api"
import * as zod from "zod"

const schema = zod.object({
  email: zod.string()
    .email({ message: "Must be a valid email address." })
    .min(1, { message: "This is required." }),
  username: zod.string()
    .min(3, { message: "Can be between 3 and 24 characters." } )
    .max(24, { message: "Can be between 3 and 24 characters." } )
    .regex(/^[a-zA-Z0-9_-]+$/, { message: "Can only contain letters, numbers, underscores, and dashes." }),
  why: zod.string()
    .min(1, { message: "This is required." }),
  previous: zod.string()
    .min(1, { message: "This is required." }),
  context: zod.string().optional(),
  terms: zod.boolean()
    .refine(value => value === true, { message: "You must agree to the terms and guidelines." })
})

const buttonLabel = ref("Send Request")
const working = ref(false)
const completed = ref(false)
const error = ref(false)
const debug = ref(false)

const initialValues = reactive({
  email: "",
  username: "",
  why: "",
  previous: "",
  context: "",
  terms: false
})

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true
  buttonLabel.value = "Sending..."

  let path = `/appeal`
  let params: any = {
    email: values.email,
    username: values.username,
    why: values.why,
    previous: values.previous,
    context: values.context,
  }

  try {
    await API().post(path, params)
    completed.value = true
    working.value = false
  } catch (err: any) {
    error.value = true
    working.value = false
    buttonLabel.value = "Send Request"
    return
  }
}
</script>

<template>
  <Form class="grid grid-cols-2 content-start gap-3" :initialValues="initialValues" :schema="schema" @on-submit="handleSubmit" v-if="!completed" v-slot="{fields, errors}">
    <div class="row-start-1">
      <Label field="email">Email Address</Label>
      <Field name="email" type="input" subtype="email" helpText="This must match your account email address." />
    </div>
    <div class="row-start-2">
      <Label field="username">Username</Label>
      <Field name="username" />
    </div>
    <div class="row-start-3">
      <Label field="why">Why are you requesting reinstatement?</Label>
      <Field name="why" type="select">
        <option value="banned">My account was banned</option>
        <option value="timeout">My account was timed out</option>
        <option value="content">My content was hidden, disabled, or removed</option>
      </Field>
    </div>
    <div class="row-start-4">
      <Label field="previous">Have you previously contacted Submit about this claim?</Label>
      <Field name="previous" type="select" helpText="">
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </Field>
    </div>
    <div class="row-start-5 col-span-full">
      <Label field="context">Would you like to provide any additional information or context that would be helpful for our review of your reinstatement request?</Label>
      <Field name="context" type="textarea" />
    </div>
    <div class="row-start-6">
      <Checkbox name="terms">
        I have reviewed and understand the <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="/docs/terms" target="_blank">Terms of Service</a> and <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="/docs" target="_blank">Guidelines</a>
      </Checkbox>
    </div>
    <div class="row-start-7">
      <button type="submit" class="font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:bg-gold-500 text-black py-3 px-8 text-sm" :disabled="working">{{ buttonLabel }}</button>
    </div>
    <section class="row-start-8" v-if="debug">
      <pre>
{{ fields }}
      </pre>
      <pre>
{{ errors }}
      </pre>
    </section>
  </Form>
  <div v-else>
    <p class="font-bold text-green-500">Your request has been sent.</p>
    <p>If it's a valid request, response times can vary between 10-30 days depending on the nature of the request.</p>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, watchEffect, onBeforeUnmount, watch, isRef } from "vue"
import type { FormContext } from "@/components/input/Form.vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"

const theme = ref(localStorage.getItem("theme") || "dark")
const textDateInput = { enterSubmit: false, tabSubmit: true, openMenu: true }

// Define props with modelValue as type 'any' to accept all types of values
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  helpText: {
    type: String,
    default: ''
  },
  persist: {
    type: Boolean,
    default: false
  },
  locked: {
    type: Boolean,
    default: false
  },
  step: {
    type: Number,
    default: 1
  },
  showTime: {
    type: Boolean,
    default: false
  },
  timeOnly: {
    type: Boolean,
    default: false
  },
  alt: {
    type: Boolean,
    default: false
  },
  minDate: {
    type: Date,
    default: null
  },
  maxDate: {
    type: Date,
    default: null
  }
})
const emit = defineEmits(['newDate'])

const formContext = inject<FormContext>('formContext');
if (!formContext) {
  throw new Error(`Date: ${props.name} must be used within a Form component.`)
}

const { registerField, focusField, blurField, unregisterField, errors } = formContext
const value = ref<any>(null)
const isLocked = ref(false)

registerField(props.name, "date", value, props.persist, props.locked, props.step)

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

watchEffect(() => {
  error.value = errors.value[props.name]
})

watch(value, (newVal) => {
  emit('newDate', new Date(newVal))
})

watch(() => props.locked, (newVal) => {
  // if the date picker is locked and its timeOnly, set the time to 23:59
  if (newVal && props.timeOnly) {
    value.value = { hours: 23, minutes: 59 }
    isLocked.value = true
  } else {
    isLocked.value = newVal
  }
})

</script>

<template>
  <div>
    <VueDatePicker
      v-model="value"
      :name="name"
      :id="name"
      :enable-time-picker="showTime"
      hide-input-icon
      :dark="theme === 'dark'"
      auto-apply
      :text-input="textDateInput"
      :placeholder="timeOnly ? 'HH:mm' : showTime ? 'MM/DD/YYYY HH:mm' : 'MM/DD/YYYY'"
      utc="preserve"
      @focus="handleFocus"
      @blur="handleBlur"
      :input-class-name="alt ? 'forced-alt-bg' : 'bg-white dark:bg-submit-900 !important'"
      :min-date="minDate"
      :max-date="maxDate"
      :min-time="{ hours: 0, minutes: 1 }"
      :max-time="{ hours: 23, minutes: 59 }"
      :time-picker="timeOnly"
      :is24="false"
      :disabled="locked"
    >
    </VueDatePicker>
    <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>
  </div>
</template>

<style>
.forced-alt-bg {
  @apply bg-neutral-125 dark:bg-submit-950 !important;
}
.dp__theme_light {
  --dp-font-family: @apply font-sans;
  --dp-font-size: 0.875rem;
  --dp-border-radius: 6px;
  --dp-input-padding: 16px;
  --dp-background-color: #fff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: rgb(188 140 78 / 1);
  --dp-primary-disabled-color: #6bacea;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: rgb(107 114 128 / 1);
  --dp-menu-border-color: rgb(107 114 128 / 1);
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-marker-color: #ff6f60;
  --dp-tooltip-color: #fafafa;
  --dp-disabled-color-text: #8e8e8e;
  --dp-highlight-color: rgb(25 118 210 / 10%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
  --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}

.dp__theme_dark {
  --dp-font-family: @apply font-sans;
  --dp-font-size: 0.875rem;
  --dp-border-radius: 6px;
  --dp-input-padding: 16px;
  --dp-background-color: #161524;
  --dp-text-color: rgb(212 212 212 / 1);
  --dp-hover-color: hsl(46, 21%, 23%);
  --dp-hover-text-color: #fff;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: rgb(221 190 118 / 1);
  --dp-primary-disabled-color: #61a8ea;
  --dp-primary-text-color: rgb(41 38 59 / 1);
  --dp-secondary-color: #a9a9a9;
  --dp-border-color: transparent;
  --dp-menu-border-color: rgb(131 137 150 / 0.3);
  --dp-border-color-hover: transparent;
  --dp-disabled-color: #737373;
  --dp-disabled-color-text: #d0d0d0;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #959595;
  --dp-danger-color: #e53935;
  --dp-marker-color: #e53935;
  --dp-tooltip-color: #3e3e3e;
  --dp-highlight-color: rgb(0 92 178 / 20%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #484848);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #fff);
  --dp-range-between-border-color: var(--dp-hover-color, #fff);
}
</style>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from '@headlessui/vue'
import { computed } from "vue"

interface Props {
  title: string
  isOpen: boolean
  closeModal: any
  size?: string
}

const props = withDefaults(defineProps<Props>(), {
  isOpen: false,
  size: 'md'
})

const sizeClass = computed(() => {
  return {
    'sm': 'max-w-sm',
    'md': 'max-w-md',
    'lg': 'max-w-lg',
    'xl': 'max-w-xl'
  }[props.size]
})
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal()" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel :class="`w-full ${sizeClass} transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-925 px-2 xs:px-4 py-6 sm:px-6 sm:py-6 text-left align-middle shadow-xl transition-all`">
              <DialogTitle class="text-lg font-bold leading-6 font-display text-black dark:text-white mb-4">{{ title }}</DialogTitle>
              <slot>Dialog Body</slot>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { loadLocaleMessages } from '../../i18n'
import Modal from "./Modal.vue"
import { ref, watch } from 'vue'

const i18n = useI18n()

const languageModalOpen = ref(false)
function closeLanguageModal () {
  languageModalOpen.value = false
}

const setLocale = ref("en")
const locales = [
  {
    name: "Nederlands", // Dutch in Dutch
    code: "nl"
  },
  {
    name: "English", // English in English
    code: "en"
  },
  {
    name: "Español", // Spanish in Spanish
    code: "es"
  },
  {
    name: "Français", // French in French
    code: "fr"
  },
  {
    name: "Deutsch", // German in German
    code: "de"
  },
  {
    name: "Italiano", // Italian in Italian
    code: "it"
  },
  {
    name: "日本語", // Japanese in Japanese
    code: "ja"
  },
  {
    name: "한국어", // Korean in Korean
    code: "ko"
  },
  {
    name: "中文", // Mandarin Chinese in Chinese
    code: "zh"
  },
  {
    name: "Norsk", // Norwegian in Norwegian
    code: "no"
  },
  {
    name: "Polski", // Polish in Polish
    code: "pl"
  },
  {
    name: "Português", // Portuguese in Portuguese
    code: "pt"
  },
  {
    name: "Русский", // Russian in Russian
    code: "ru"
  },
  {
    name: "Kiswahili", // Swahili in Swahili
    code: "sw"
  },
  {
    name: "Svenska", // Swedish in Swedish
    code: "sv"
  },
  {
    name: "ไทย", // Thai in Thai
    code: "th"
  },
  {
    name: "Türkçe", // Turkish in Turkish
    code: "tr"
  },
  {
    name: "Українська", // Ukrainian in Ukrainian
    code: "uk"
  },
  {
    name: "Tiếng Việt", // Vietnamese in Vietnamese
    code: "vi"
  }
]

watch(
  () => setLocale.value,
  (newLocale) => {
    loadLocaleMessages(i18n, newLocale)
  }
)
</script>

<template>
  <div @click="languageModalOpen = true" class="flex gap-1 items-center hover:text-indigo-950 dark:hover:text-white hover:cursor-pointer hover:underline group">
    <svg xmlns="http://www.w3.org/2000/svg" class="fill-neutral-600 dark:fill-indigo-325 group-hover:fill-indigo-950 dark:group-hover:fill-white group-hover:cursor-pointer" width="16" height="14" viewBox="0 0 512 512"><path d="M256 464c7.4 0 27-7.2 47.6-48.4c8.8-17.7 16.4-39.2 22-63.6H186.4c5.6 24.4 13.2 45.9 22 63.6C229 456.8 248.6 464 256 464zM178.5 304h155c1.6-15.3 2.5-31.4 2.5-48s-.9-32.7-2.5-48h-155c-1.6 15.3-2.5 31.4-2.5 48s.9 32.7 2.5 48zm7.9-144H325.6c-5.6-24.4-13.2-45.9-22-63.6C283 55.2 263.4 48 256 48s-27 7.2-47.6 48.4c-8.8 17.7-16.4 39.2-22 63.6zm195.3 48c1.5 15.5 2.2 31.6 2.2 48s-.8 32.5-2.2 48h76.7c3.6-15.4 5.6-31.5 5.6-48s-1.9-32.6-5.6-48H381.8zm58.8-48c-21.4-41.1-56.1-74.1-98.4-93.4c14.1 25.6 25.3 57.5 32.6 93.4h65.9zm-303.3 0c7.3-35.9 18.5-67.7 32.6-93.4c-42.3 19.3-77 52.3-98.4 93.4h65.9zM53.6 208c-3.6 15.4-5.6 31.5-5.6 48s1.9 32.6 5.6 48h76.7c-1.5-15.5-2.2-31.6-2.2-48s.8-32.5 2.2-48H53.6zM342.1 445.4c42.3-19.3 77-52.3 98.4-93.4H374.7c-7.3 35.9-18.5 67.7-32.6 93.4zm-172.2 0c-14.1-25.6-25.3-57.5-32.6-93.4H71.4c21.4 41.1 56.1 74.1 98.4 93.4zM256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512z"/></svg>
    <span class="uppercase">{{$i18n.locale}}</span>
    <Modal
      :isOpen="languageModalOpen"
      :closeModal="closeLanguageModal"
      :title="$t('changelanguage')"
    >
      <p class="mt-1 mb-4">{{ $t('selectlanguage') }}.</p>
      <select class="bg-neutral-100 rounded-md dark:bg-indigo-950 py-2 px-2 w-full dark:text-neutral-200 outline-none border border-transparent focus:ring-0 focus:border-indigo-325/30 placeholder:text-indigo-250" v-model="setLocale">
        <option v-for="locale in locales" :key="`locale-${locale.code}`" :value="locale.code">
          {{ locale.name }}
        </option>
      </select>
      <p class="text-xs mt-4 text-neutral-600 dark:text-indigo-325"><span class="font-bold">{{ $t('pleasenote') }}: </span>{{ $t('languagehelp') }} <a class="underline hover:text-submit-950 dark:hover:text-white" href="https://discord.gg/N7nFueSy4H" target="_blank">Discord</a>.</p>
      <div class="flex justify-end">
        <button @click="closeLanguageModal" class="mt-2 text-sm font-semibold bg-gray-500 hover:bg-gray-600 dark:bg-submit-500 dark:hover:bg-submit-600 text-white dark:text-gray-400 rounded-md py-2 px-6 block first-letter:uppercase">{{ $t('close') }}</button>
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

interface Props {
  interest: any
}

const { interest } = defineProps<Props>()

const type = computed(() => {
  return interest.type === "official" ? "kinks" : "interests"
})
</script>

<template>
  <li :data-to="`/${type}/${interest.slug}`" class="bg-white rounded-md p-4 ListItem group dark:bg-submit-900 hover:dark:bg-submit-700 hover:cursor-pointer">
    <router-link class="font-semibold block" :to="`/${type}/${interest.slug}`">
      {{ interest.name }}
    </router-link>
    <ul class="inline dark:text-gray-500 text-gray-400 text-xs">
      <li class="inline">{{ interest.interestCounter }} user{{ interest.interestCounter === 1 ? '' : 's' }},&nbsp;</li>
      <li class="inline">{{ interest.associatedGroupCounter }} group{{ interest.associatedGroupCounter === 1 ? '' : 's' }}</li>
    </ul>
  </li>
</template>

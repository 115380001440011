<script setup lang="ts">
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Verification</h2>
        <p>Optional identity verification. If you choose to verify your identity, you can display a verified checkmark on your profile to let other users know that you've verified your identity with us.</p>
        <p>Verification is currently only possible by regularly participating in VC channels on our Discord server. Additional verification methods are coming soon.</p>
      </div>
    </section>
  </main>
</template>

<script setup lang="ts">
import { ref, computed, reactive, onBeforeMount, onMounted, watch, watchEffect } from "vue"
import ProfilePreview from "@/components/settings/ProfilePreview.vue"
import SelfSubmit from "@/components/input/SelfSubmit.vue"
import CombowRaw from "@/components/input/ComboRaw.vue"
import Fieldset from "@/components/input/Fieldset.vue"
import Label from "@/components/input/Label.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

const exploreSchema = zod.object({
  exploreContent: zod.array(zod.string()).optional()
})

const searchSchema = zod.object({
  searchContent: zod.array(zod.string()).optional()
})

const UserStore = useUserStore()
const { id, roles } = storeToRefs(UserStore)

const baseRoute = `/users/${id.value}`

const working = ref(false)
const metaData = ref<any>({})
const privacyData = ref<any>({})
const locationData = ref<any>({})
const ageData = ref<number>(0)
const initialLoadComplete = ref(false)
const settingCompleted = ref(false)

const profileVisibility = ref("")
const directMessaging = ref("")
const defaultVisibility = ref("public")
const exploreVisibility = ref("")
const mentionPrivacy = ref("")

const following = ref("enabled")
const followRequests = ref("")
const followVisibility = ref("")

const exploreContent = reactive({
  'privacy.exploreContent': []
})
const exploreButtonLabel = ref("Save Permissions")
const exploreSaved = ref(false)
const exploreError = ref(false)

const searchContent = reactive({
  'privacy.searchContent': []
})
const searchButtonLabel = ref("Save Permissions")
const searchSaved = ref(false)
const searchError = ref(false)

const cityPrivacy = ref("public")
const statePrivacy = ref("public")
const countryPrivacy = ref("public")
const anonymousLabel = ref("The Basement")

const agePrivacy = ref("public")
const rolesPrivacy = ref("public")
const pronounsPrivacy = ref("public")
const detailsLabel = ref("Human")

const blockAllPublicContent = ref("")

const messageRequests = ref("")
const messageRequestExceptions = ref<string[]>([])
const mreCount = ref(0)
const mreError = ref(false)
const mreSaved = ref(false)
const ddExclusions = ref<string[]>([])
const ddCount = ref(0)
const ddError = ref(false)
const ddSaved = ref(false)

const enabledOptions = [
  {text: "Enabled", value: "enabled"},
  {text: "Disabled", value: "disabled"}
]

const pfOptions = [
  {text: "Public", value: "public"},
  {text: "Followers", value: "followers"},
  {text: "Relationships", value: "relationships"},
  {text: "Only Me", value: "private"}
]

const ppOptions = [
  {text: "Public", value: "public"},
  {text: "Followers", value: "followers"},
  {text: "Close Friends", value: "friends"},
  {text: "Relationships", value: "relationships"},
  {text: "Only Me", value: "private"}
]

const contentList = [
  {label: "Photos", value: "photos", id: "photos"},
  {label: "Videos", value: "videos", id: "videos"},
  {label: "Writings", value: "writings", id: "writings"},
  {label: "Comments", value: "comments", id: "comments"},
  {label: "Educational Posts", value: "educational-posts", id: "educational-posts"},
  {label: "Tasks", value: "tasks", id: "tasks"},
  {label: "Events", value: "events", id: "events"},
]

const searchList = [
  {label: "Photos", value: "photos", id: "photos2"},
  {label: "Videos", value: "videos", id: "videos2"},
  {label: "Writings", value: "writings", id: "writings2"},
  {label: "Tasks", value: "tasks", id: "tasks2"}
]

const profileOptions = computed(() => {
  switch (profileVisibility.value) {
    case "public":
      return ppOptions
    case "private":
      return ppOptions.slice(1)
  }
})

const detailsContent = [
  {label: "Gender", value: "genderPrivacy"},
  {label: "Sexual Orientations", value: "sexualPrivacy"},
  {label: "Romantic Orientations", value: "romanticPrivacy"},
  {label: "Seeking", value: "seekingPrivacy"},
  {label: "Not Seeking", value: "notSeekingPrivacy"},
  {label: "Links", value: "linksPrivacy"}
]

const exceptionOptions = [
  {label: "Followers", value: "followers"},
  {label: "Following", value: "following"},
  {label: "Close Friends", value: "friends"},
  {label: "Relationships", value: "relationships"}
]

const accountAgeOptions = [
  {text: "1 Hour", value: "1h"},
  {text: "3 Hours", value: "3h"},
  {text: "6 Hours", value: "6h"},
  {text: "12 Hours", value: "12h"},
  {text: "24 Hours", value: "24h"},
  {text: "48 Hours", value: "48h"},
  {text: "72 Hours", value: "72h"},
  {text: "1 Week", value: "1w"}
]

onBeforeMount(async () => {
  await getMetaData()
  initialLoadComplete.value = true
  if (!privacyData.value.relationships) {
    privacyData.value.relationships = "enabled"
  }
  profileVisibility.value = privacyData.value.profileVisibility
  directMessaging.value = privacyData.value.directMessaging
  defaultVisibility.value = privacyData.value.defaultVisibility
  exploreVisibility.value = privacyData.value.exploreVisibility
  mentionPrivacy.value = privacyData.value.mentionPrivacy
  following.value = privacyData.value.following
  followRequests.value = privacyData.value.followRequests
  followVisibility.value = privacyData.value.followVisibility
  exploreContent['privacy.exploreContent'] = privacyData.value.exploreContent
  searchContent['privacy.searchContent'] = privacyData.value.searchContent
  cityPrivacy.value = privacyData.value.cityPrivacy
  statePrivacy.value = privacyData.value.statePrivacy
  countryPrivacy.value = privacyData.value.countryPrivacy
  agePrivacy.value = privacyData.value.agePrivacy
  rolesPrivacy.value = privacyData.value.rolesPrivacy
  pronounsPrivacy.value = privacyData.value.pronounsPrivacy
  blockAllPublicContent.value = privacyData.value.blockAllPublicContent
  messageRequests.value = privacyData.value.messageRequests
  messageRequestExceptions.value = privacyData.value.messageRequestExceptions
  ddExclusions.value = privacyData.value.ddExclusions
  settingCompleted.value = true
})

watch(following, (newValue) => {
  let booleanValue = newValue === "enabled" ? true : false
  UserStore.updateFollowingEnabled(booleanValue)
})

watch(directMessaging, (newValue) => {
  let booleanValue = newValue === "enabled" ? true : false
  UserStore.updateMessagesEnabled(booleanValue)
})

watch(defaultVisibility, (newValue) => {
  UserStore.updateDefaultVisibility(newValue)
})

async function getMetaData() {
  try {
    const response = await API().get(`/meta`)
    metaData.value = response.data.data.meta
    locationData.value = response.data.data.location || false
    ageData.value = response.data.data.age
    privacyData.value = response.data.data.privacy
  } catch (err) {
    console.error(err)
  }
}

watch(
  () => profileVisibility.value,
  (newProfile, prevProfile) => {
    if (newProfile === "private") {
      followRequests.value = "enabled"
      if (followVisibility.value === "public") {
        followVisibility.value = "followers"
      }
    }
  }
)

async function handleFieldsetSubmit(values: any) {
  if (working.value) return
  working.value = true

  let context: string = ""
  if (values['privacy.exploreContent']) {
    context = 'explore'
    exploreButtonLabel.value = "Saving..."
  }
  if (values['privacy.searchContent']) {
    context = 'search'
    searchButtonLabel.value = "Saving..."
  }

  try {
    await API().patch(baseRoute, values)
    if (context === 'explore') {
      exploreSaved.value = true
      exploreButtonLabel.value = "Save Permissions"
      working.value = false
      setTimeout(() => {
        exploreSaved.value = false
      }, 5000)
    }
    if (context === 'search') {
      searchSaved.value = true
      searchButtonLabel.value = "Save Permissions"
      working.value = false
      setTimeout(() => {
        searchSaved.value = false
      }, 5000)
    }
  } catch (err) {
    if (context === 'explore') {
      exploreError.value = true
      exploreButtonLabel.value = "Save Permissions"
      working.value = false
      setTimeout(() => {
        exploreError.value = false
      }, 5000)
    }
    if (context === 'search') {
      searchError.value = true
      searchButtonLabel.value = "Save Permissions"
      working.value = false
      setTimeout(() => {
        searchError.value = false
      }, 5000)
    }
  }
}

watch(
  () => cityPrivacy.value,
  (newCityPrivacy, prevCityPrivacy) => {
    switch (newCityPrivacy) {
      case "public":
        statePrivacy.value = "public"
        countryPrivacy.value = "public"
        break
      case "followers":
        if (statePrivacy.value === "relationships")
          statePrivacy.value = "followers"
      case "relationships":
        if (statePrivacy.value === "private") {
          statePrivacy.value = "relationships"
        }
        if (countryPrivacy.value === "private") {
          countryPrivacy.value = "relationships"
        }
        break
    }
  }
)

watch(
  () => statePrivacy.value,
  (newStatePrivacy, prevStatePrivacy) => {
    switch (newStatePrivacy) {
      case "public":
        if (countryPrivacy.value !== "public") {
          countryPrivacy.value = "public"
        }
        break
      case "followers":
        if (cityPrivacy.value === "public")
          cityPrivacy.value = "followers"
        if (countryPrivacy.value === "relationships" || countryPrivacy.value === "private")
          countryPrivacy.value = "followers"
        break
      case "relationships":
        if (cityPrivacy.value === "public" || cityPrivacy.value === "followers")
          cityPrivacy.value = "relationships"
        if (statePrivacy.value === "public" || statePrivacy.value === "followers")
          statePrivacy.value = "relationships"
        if (countryPrivacy.value === "private")
          countryPrivacy.value = "relationships"
        break
      case "private":
        if (cityPrivacy.value === "public" || cityPrivacy.value === "followers" || cityPrivacy.value === "relationships")
          cityPrivacy.value = "private"
        break
    }
  }
)

watch(
  () => countryPrivacy.value,
  (newCountryPrivacy, prevCountryPrivacy) => {
    switch (newCountryPrivacy) {
      case "followers":
        if (cityPrivacy.value === "public")
          cityPrivacy.value = "followers"
        if (statePrivacy.value === "public")
          statePrivacy.value = "followers"
        break
      case "relationships":
        if (cityPrivacy.value === "public")
          cityPrivacy.value = "relationships"
        if (statePrivacy.value === "public" || statePrivacy.value === "followers")
          statePrivacy.value = "relationships"
        break
      case "private":
        if (cityPrivacy.value === "public" || cityPrivacy.value === "followers" || cityPrivacy.value === "relationships")
          cityPrivacy.value = "private"
          statePrivacy.value = "private"
        break
    }
  }
)

async function comboHandler(field: string, value: any) {
  try {
    const dataToSend = {
      [field]: value
    }
    await API().patch(baseRoute, dataToSend)
    switch(field) {
      case 'privacy.messageRequestExceptions':
        mreSaved.value = true
        setTimeout(() => {
          mreSaved.value = false
        }, 5000)
        break
      case 'privacy.ddExclusions':
        ddSaved.value = true
        setTimeout(() => {
          ddSaved.value = false
        }, 5000)
        break
    }
  } catch (err) {
    switch(field) {
      case 'privacy.messageRequestExceptions':
        mreError.value = true
        setTimeout(() => {
          mreError.value = false
        }, 5000)
        break
      case 'privacy.ddExclusions':
        ddError.value = true
        setTimeout(() => {
          ddError.value = false
        }, 5000)
        break
    }
  }
}

watch(
  () => messageRequestExceptions.value,
  (newMRE) => {
    if (mreCount.value === 0) {
      mreCount.value++
      return
    }
    comboHandler("privacy.messageRequestExceptions", newMRE)
    mreCount.value++
  }
)

watch(
  () => ddExclusions.value,
  (newDD) => {
    if (ddCount.value === 0) {
      ddCount.value++
      return
    }
    comboHandler("privacy.ddExclusions", newDD)
    ddCount.value++
  }
)
</script>

<template>
  <main class="space-y-24" v-if="initialLoadComplete && settingCompleted">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Core Privacy</h2>
        <p>Major privacy settings that shape your experience on Submit.</p>
      </div>

      <div>
        <Label field="privacy.profileVisibility">Profile Visibility</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Set whether your profile is visible to all Submit users or only those that follow you. Setting your profile to private automatically enables follow requests (unless you turn following off), removes you from any starter packs you are a part of, and prevents you from being added to any starter packs. By default, all profiles are publicly visible.</p>
        <SelfSubmit v-model="profileVisibility" name="privacy.profileVisibility" :apiEndpoint="baseRoute" :options="[{ value: 'public', text: 'Public'}, { value: 'private', text: 'Private'}]" helpText="Private profiles do not completely hide your profile, there is no way to fully hide a profile from view." />
      </div>

      <div>
        <Label field="privacy.directMessaging">Direct Messaging</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Set whether users can send you messages. You can configure specific messaging privacy settings below. By default, messaging is enabled.</p>
        <SelfSubmit v-model="directMessaging" name="privacy.directMessaging" :apiEndpoint="baseRoute" :options="enabledOptions" helpText="Disabling user DM's doesn't prevent any Groups that you are a part of sending you a DM (for moderation purposes)." />
      </div>

      <div>
        <Label field="privacy.defaultVisibility">Default Visibility</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Set the default visibility of all content you post. By default, this is set to public.</p>
        <SelfSubmit v-model="defaultVisibility" name="privacy.defaultVisibility" :apiEndpoint="baseRoute" :options="pfOptions" />
      </div>

      <div>
        <Label field="privacy.exploreVisibility">Explore Participation</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Set whether or not your content can be featured in our publicly visible Explore section. By default, this is set to participate.</p>
        <SelfSubmit v-model="exploreVisibility" name="privacy.exploreVisibility" :apiEndpoint="baseRoute" :options="[{ value: 'enabled', text: 'Participate (Your content can be featured in Explore)'}, { value: 'disabled', text: 'Do Not Participate (Your content cannot be featured in Explore)'}]" helpText="If you choose to participate, you can control which public content is included in the Explore section below." />
      </div>

      <div>
        <Label field="privacy.mentionPrivacy">Mention Privacy</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Set which users can mention you in updates, posts, comments, etc. By default, this is set to Public (meaning anyone can mention you).</p>
        <SelfSubmit v-model="mentionPrivacy" name="privacy.mentionPrivacy" :apiEndpoint="baseRoute" :options="ppOptions" />
      </div>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Following Privacy</h2>
        <p>Set up your followers in a way that works for you, or turn off followers entirely.</p>
      </div>

      <div>
        <Label field="privacy.following">Following</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Enable or disable following. If you choose to disable following, <strong>your existing follows will be permanently removed and cannot be recovered</strong>. You will also be removed from any starter packs you are a part of and cannot be added to any starter packs. By default, following is enabled.</p>
        <SelfSubmit v-model="following" name="privacy.following" :apiEndpoint="baseRoute" :options="enabledOptions" />
      </div>

      <div v-if="following === 'enabled'">
        <Label field="privacy.followRequests">Follow Requests</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Enabling follow requests means every user who wants to follow you requires your approval first. If you have a private profile, follow requests are enabled automatically. If you enable follow requests, you will be removed from any starter packs you are a part of and cannot be added to any starter packs. By default, follow requests are disabled.</p>
        <SelfSubmit v-model="followRequests" name="privacy.followRequests" :apiEndpoint="baseRoute" :options="enabledOptions" :disabled="profileVisibility === 'private'" />
        <p v-if="profileVisibility === 'private'" class="text-xs mt-2 text-gray-500 dark:text-gray-4000"><span class="font-bold text-red-500">Locked:</span> When you have a private profile and following enabled, follow requests are required.</p>
      </div>

      <div v-if="following === 'enabled'">
        <Label field="privacy.followVisibility">Following/Followers Visibility</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Set what users can see your following/follower lists. By default, this is set to Public (meaning anyone on Submit can see them).</p>
        <SelfSubmit v-model="followVisibility" name="privacy.followVisibility" :apiEndpoint="baseRoute" :options="profileOptions" />
        <p v-if="profileVisibility === 'private'" class="text-xs mt-2 text-gray-500 dark:text-gray-400"><span class="font-bold dark:text-yellow-500">Limited:</span> When you have a private profile and following enabled, you cannot select public.</p>
      </div>
    </section>

    <section v-if="exploreVisibility === 'enabled'" class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Explore Privacy</h2>
        <p>Explore highlights content from around Submit with the entire community, control and limit its access to your content.</p>
      </div>

      <Form @on-submit="handleFieldsetSubmit" :schema="exploreSchema" :initialValues="exploreContent" class="space-y-6">
        <Fieldset name="privacy.exploreContent" :options="contentList"></Fieldset>
        <div>
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ exploreButtonLabel }}</button>
        </div>
        <div v-if="exploreError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="exploreSaved" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>
      </Form>
    </section>

    <section v-else>
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Explore Privacy</h2>
        <p>Explore highlights content from around Submit with the entire community, control and limit its access to your content.</p>
      </div>
      <p class="text-gray-500 dark:text-gray-400">You have opted out of participating in Explore. To configure explore privacy settings, you need to opt in to participating in explore.</p>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Search Privacy</h2>
        <p>You can't remove yourself from our limited search capability, but you can control and limit its access to your content.</p>
      </div>

      <Form @on-submit="handleFieldsetSubmit" :schema="searchSchema" :initialValues="searchContent" class="space-y-6">
        <Fieldset name="privacy.searchContent" :options="searchList"></Fieldset>
        <div>
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ searchButtonLabel }}</button>
        </div>
        <div v-if="searchError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="searchSaved" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>
      </Form>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Location Privacy</h2>
        <p>Control who can see your location details.</p>
      </div>

      <div>
        <div class="font-bold text-sm mb-1">Location Visibility</div>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Control who can see your location details. By default, these are set to public.</p>
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-3">
          <div>
            <Label field="privacy.cityPrivacy">City</Label>
            <SelfSubmit v-model="cityPrivacy" name="privacy.cityPrivacy" :apiEndpoint="baseRoute" :options="pfOptions" />
          </div>
          <div>
            <Label field="privacy.statePrivacy">State/Province</Label>
            <SelfSubmit v-model="statePrivacy" name="privacy.statePrivacy" :apiEndpoint="baseRoute" :options="pfOptions" />
          </div>
          <div>
            <Label field="privacy.countryPrivacy">Country</Label>
            <SelfSubmit v-model="countryPrivacy" name="privacy.countryPrivacy" :apiEndpoint="baseRoute" :options="pfOptions" />
          </div>
        </div>
      </div>

      <div>
        <label for="anonymousLabel" class="font-bold text-sm mb-1 block">Anonymous Label</label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">For users who cannot see your location, this is the location that they'll see. By default, this is set to "The Basement".</p>
        <input v-model="anonymousLabel" type="text" id="anonymousLabel" name="anonymousLabel" class="w-full p-4 rounded-md text-sm bg-white dark:bg-submit-900 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" disabled />
      </div>

      <div>
        <ProfilePreview :payload="{location: locationData, cityPrivacy, statePrivacy, countryPrivacy, anonymousLabel}" type="location" />
      </div>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Details Privacy</h2>
        <p>Control who can see your profile details. Your About section is always visible to all Submit users (except those you block), if you'd like to not have that publicly viewable, please change your profile visibility to private.</p>
      </div>

      <div>
        <div class="font-bold text-sm mb-1">Details Visibility</div>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Control who can see your profile details. By default, these are set to public.</p>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <div>
            <Label field="privacy.agePrivacy">Age</Label>
            <SelfSubmit v-model="agePrivacy" name="privacy.agePrivacy" :apiEndpoint="baseRoute" :options="pfOptions" />
          </div>
          <div>
            <Label field="privacy.rolesPrivacy">Roles</Label>
            <SelfSubmit v-model="rolesPrivacy" name="privacy.rolesPrivacy" :apiEndpoint="baseRoute" :options="pfOptions" />
          </div>
          <div>
            <Label field="privacy.pronounsPrivacy">Pronouns</Label>
            <SelfSubmit v-model="pronounsPrivacy" name="privacy.pronounsPrivacy" :apiEndpoint="baseRoute" :options="pfOptions" />
          </div>
          <div v-for="detail in detailsContent" :key="detail.value">
            <Label :field="`privacy.${detail.value}`">{{ detail.label }}</Label>
            <SelfSubmit :name="`privacy.${detail.value}`" :initialValue="privacyData[detail.value]" :apiEndpoint="baseRoute" :options="pfOptions" />
          </div>
        </div>
      </div>

      <div>
        <label for="privacy.detailsLabel" class="font-bold text-sm mb-1 block">No Details Label</label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">For users who cannot see your profile details, we show a single data point. By default, this is set to "Human". You cannot change it.</p>
        <input v-model="detailsLabel" type="text" id="privacy.detailsLabel" name="privacy.detailsLabel" class="w-full p-4 rounded-md text-sm bg-white dark:bg-submit-900 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" disabled />
      </div>

      <div>
        <ProfilePreview :payload="{agePrivacy, rolesPrivacy, pronounsPrivacy, detailsLabel, meta: metaData, age: ageData}" type="details" />
      </div>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Blocking Privacy</h2>
        <p>Control how public content of blocked users is displayed for you. This includes posts in groups, comments, and other public content where removing the blocked content would break your experience.</p>
      </div>

      <div>
        <Label field="privacy.blockAllPublicContent">Remove All Public Content</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Remove all public content from users you have blocked, even if it breaks your experience or removes context from conversations. By default, this is enabled.</p>
        <SelfSubmit v-model="blockAllPublicContent" name="privacy.blockAllPublicContent" :apiEndpoint="baseRoute" :options="enabledOptions" />
      </div>

      <div v-if="blockAllPublicContent === 'disabled'">
        <Label field="privacy.blockedUsernameMasking">Username Masking</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Mask the username of the blocked user with "Blocked User". By default, this is enabled.</p>
        <SelfSubmit name="privacy.blockedUsernameMasking" :initialValue="privacyData.blockedUsernameMasking" :apiEndpoint="baseRoute" :options="enabledOptions" />
      </div>

      <div v-if="blockAllPublicContent === 'disabled'">
        <Label field="blockedContentMasking">Content Masking</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Place a spoiler filter over the blocked user's content. By default, this is disabled.</p>
        <SelfSubmit name="privacy.blockedContentMasking" :initialValue="privacyData.blockedContentMasking" :apiEndpoint="baseRoute" :options="enabledOptions" />
      </div>
    </section>

    <section v-if="directMessaging === 'enabled'" class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Messaging Privacy</h2>
        <p>Control who can send you messages, message requests, and set up message scanning and filtering.</p>
      </div>

      <div>
        <Label field="privacy.messagesFrom">Message Requests</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Require a message request before someone can send you a message. By default, this is disabled.</p>
        <SelfSubmit v-model="messageRequests" name="privacy.messageRequests" :apiEndpoint="baseRoute" :options="enabledOptions" />
      </div>

      <div v-if="messageRequests === 'enabled'">
        <Label field="privacy.messageRequestExceptions">Message Request Exceptions</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">If you have Message Requsts enabled, add user types which can bypass them. By default, there are no exceptions set.</p>
        <div class="flex items-center gap-x-4">
          <CombowRaw class="flex-grow" name="privacy.messageRequestExceptions" :data="exceptionOptions" :limit="exceptionOptions.length" v-model="messageRequestExceptions" placeholder="selectusertype" multiple />
          <div v-if="mreError">
            <svg class="fill-red-500 w-6 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm97.9-320l-17 17-47 47 47 47 17 17L320 353.9l-17-17-47-47-47 47-17 17L158.1 320l17-17 47-47-47-47-17-17L192 158.1l17 17 47 47 47-47 17-17L353.9 192z"/></svg>
          </div>
          <div v-if="mreSaved">
            <svg class="fill-green-500 w-6 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337l-17 17-17-17-64-64-17-17L160 222.1l17 17 47 47L335 175l17-17L385.9 192l-17 17z"/></svg>
          </div>
        </div>
      </div>

      <div>
        <Label field="privacy.messageAccountAge">Reject Messages from New Accounts</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Set the minimum length of time an account needs to have existed on Submit before it can send you a message. By default, this is set to 6 hours.</p>
        <SelfSubmit name="privacy.messageAccountAge" :initialValue="privacyData.messageAccountAge" :apiEndpoint="baseRoute" :options="accountAgeOptions" />
      </div>

      <div>
        <Label field="privacy.ddScanning">DickDetective Scanning</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Scan incoming message attachments for unwanted nudity and block them. By default, this is enabled.</p>
        <SelfSubmit name="privacy.ddScanning" :initialValue="privacyData.ddScanning" :apiEndpoint="baseRoute" :options="enabledOptions" />
      </div>

      <div>
        <Label field="privacy.ddExclusions">DickDetective Exclusions</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">By default, DickDetective scans messages from all types of users, if you'd like to exclude a specific type of user, you can do so here.</p>
        <div class="flex items-center gap-x-4">
          <CombowRaw class="flex-grow" name="privacy.ddExclusions" :data="['Everyone', 'Followers', 'Close Friends', 'Relationships']" :limit="4" v-model="ddExclusions" placeholder="selectusertype" multiple />
          <div v-if="ddError">
            <svg class="fill-red-500 w-6 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm97.9-320l-17 17-47 47 47 47 17 17L320 353.9l-17-17-47-47-47 47-17 17L158.1 320l17-17 47-47-47-47-17-17L192 158.1l17 17 47 47 47-47 17-17L353.9 192z"/></svg>
          </div>
          <div v-if="ddSaved">
            <svg class="fill-green-500 w-6 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337l-17 17-17-17-64-64-17-17L160 222.1l17 17 47 47L335 175l17-17L385.9 192l-17 17z"/></svg>
          </div>
        </div>
      </div>
    </section>

    <section v-else class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Message Privacy</h2>
        <p>Control who can send you messages, who requires a message request, and set up message filtering.</p>
      </div>
      <p class="text-gray-500 dark:text-gray-400">You have disabled Direct Messaging. To configure message privacy settings, you need to enable Direct Messaging.</p>
    </section>

    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Relationship Privacy</h2>
        <p>Enable or disable receiving relationship requests from other users.</p>
      </div>

      <div>
        <Label field="privacy.relationships">Relationship Requests</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Allow other users to send you relationship requests. By default, this is enabled.</p>
        <SelfSubmit name="privacy.relationships" :initialValue="privacyData.relationships" :apiEndpoint="baseRoute" :options="enabledOptions" />
      </div>
    </section>

    <section class="space-y-6" v-if="roles.includes('admin')">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Advanced Privacy</h2>
        <p>You probably shouldn't touch these, we warned you...</p>
      </div>

      <div>
        <Label field="privacy.publicKeyOnly">Force Public Key Usage</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Force our platform and our users to refer to you only by your public key. By default, this is set to Disabled.</p>
        <SelfSubmit name="privacy.publicKeyOnly" :initialValue="privacyData.publicKeyOnly" :apiEndpoint="baseRoute" :options="enabledOptions" />
        <p class="text-xs mt-2 text-gray-500 dark:text-gray-4000"><strong>Note:</strong> You will experience visual bugs and glitches enabling this.</p>
      </div>

      <div>
        <Label field="privacy.fullSignatures">Show Full Crypto Signature with Signed Content</Label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Show the full cryptographic verification block when viewing Signed Content. By default, this is set to disabled.</p>
        <SelfSubmit name="privacy.fullSignatures" :initialValue="privacyData.fullSignatures" :apiEndpoint="baseRoute" :options="enabledOptions" />
      </div>

    </section>
  </main>
</template>

<script setup lang="ts">
import Reactions from "@/components/reactions/Reactions.vue"
import { usePlatformStore } from "@/stores/Platform"
import D from "@/composables/TimeDisplay"
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem
} from "@headlessui/vue"

const Platform = usePlatformStore()

interface Props {
  item: any
}

const props = defineProps<Props>()

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: props.item.author._id,
    contentId: props.item.post,
    contentType: props.item.type
  })
}
</script>

<template>
  <li>
    <article class="FeedItem" :class="item.type === 'comment' ? 'Action' : 'dark:bg-submit-900 bg-white'">
      <router-link
        class="PFP w-10 h-10"
        :class="item.type === 'comment' ? 'dark:bg-submit-900 bg-white' : 'dark:bg-submit-950 bg-gray-200'"
        :to="`/${item.author.username}`"
        :style="item.author.pfp ? `background-image: url('${item.author.pfp}')` : ''"
      >
        <span class="flex items-center justify-center w-full h-full text-2xl font-semibold text-gray-800 dark:text-gray-500" v-if="!item.author.pfp">{{ item.author.firstLetter }}</span>
      </router-link>
      <template v-if="item.type === 'post'">
        <header class="inline-wrap">
          <div class="flex items-center text-[15px] sm:text-base gap-x-1.5">
            <router-link :to="`/${item.author.username}`" class="truncate font-semibold">
              {{ item.author.username }}
            </router-link>
            <span class="inline" v-if="item.author.verified">
              <svg class="h-[.80rem] fill-[#00C2FF]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
            </span>
            <span v-if="(item.author.backer)" class="inline">
              <svg class="h-[.75rem] fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M160 0c17.7 0 32 14.3 32 32V191.9l0 .1v96c0 35.3 28.7 64 64 64c19.1 0 36.3-8.4 48-21.7c11.7 13.3 28.9 21.7 48 21.7c35.3 0 64-28.7 64-64V224.1l0-.1V96c0-17.7 14.3-32 32-32s32 14.3 32 32V336c0 97.2-78.8 176-176 176H284.8c-59.6 0-116.9-22.9-160-64L12.4 341c-16-15.2-16.6-40.6-1.4-56.6s40.6-16.6 56.6-1.4l60.5 57.6c0-1.5-.1-3.1-.1-4.6V32c0-17.7 14.3-32 32-32zm64 192c0-17.7 14.3-32 32-32s32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V192zm128 0c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"/></svg>
            </span>
            <span class="inline" v-if="(item.author.staff || item.author.volunteer)">
              <svg class="fill-gold-500" height="14" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
                <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
                <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
              </svg>
            </span>
          </div>
          <div class="flex relative items-center gap-x-4 dark:text-gray-400">
            <time class="text-sm" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).locale('submit').fromNow() }}</time>
            <Menu as="template" v-if="item.activityType !== 'action'">
              <MenuButton>
                <svg class="flex-none h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                  <title>Menu</title>
                  <path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"/>
                </svg>
              </MenuButton>
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems class="origin-top-right divide-y divide-indigo-350 absolute top-6 -right-1 w-32 rounded-md shadow-lg bg-neutral-125 dark:bg-submit-950 ring-1 ring-transparent ring-opacity-5 focus:outline-none z-50">
                  <div class="p-1">
                    <MenuItem v-slot="{ active }" v-if="!item.self">
                      <button
                        type="button"
                        @click="openReportDialog"
                        :class="[
                          active ? 'bg-yellow-400/10' : '',
                          'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                        ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                        Report
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="item.self">
                      <button type="button" class="text-xs px-4 py-2 w-full text-center">
                        No Actions
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </header>
      </template>
      <template v-if="item.type === 'comment'">
        <header class="V2">
          <div class="text-[15px] sm:text-base">
            <router-link :to="`/${item.author.username}`" class="truncate font-semibold">
              {{ item.author.username }}
            </router-link>
            <span> commented in {{ item.groupName }}</span>
          </div>
          <time class="text-sm dark:text-gray-400 break-keep" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).locale('submit').fromNow() }}</time>
        </header>
      </template>
      <div class="text-xs dark:text-gray-400" v-if="item.type === 'post'">
        <span>
          {{ item.postType !== 'personal' ? 'Post in ' : 'Personals Post in ' }}{{ item.groupName }}
        </span>
      </div>
      <ul
        :class="[
          'PostList ActionList'
        ]"
        v-if="item.type === 'comment'"
      >
        <li>
          <router-link :to="`/groups/${item.groupSlug}/${item.postShort}#${item.comment}`">
            <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold">
              <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm152 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
            </figure>
          </router-link>
          <router-link class="grid break-words text-sm sm:text-base" :to="`/groups/${item.groupSlug}/${item.postShort}#${item.comment}`">
            <div :title="item.content" class="relative whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ item.content }}</div>
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li>{{ item.title }}</li>
            </ul>
          </router-link>
        </li>
      </ul>
      <section class="Post" v-if="item.type === 'post'">
        <h2 class="bg-gold-500/30 inline-block px-4 p-0.5 rounded-xl font-semibold">
          <router-link :to="`/groups/${item.groupSlug}/${item.postShort}`">{{ item.title }}</router-link>
        </h2>
        <p>{{ item.content }}</p>
      </section>
      <footer v-if="item.type === 'post'">
        <ul class="Reactions text-xs text-gray-500 dark:text-gray-400">
          <li v-if="item.postType !== 'personal'">
            <Reactions :path="`/groups/${item.groupSlug}/${item.postShort}`" :reactions="item.reactionsCount" :reacted="item.hasReacted" :reactionData="item.reactionData" small />
          </li>
          <li v-if="item.postType !== 'personal'">
            <router-link class="group flex gap-x-2 items-center" :to="`/groups/${item.groupSlug}/${item.postShort}`">
              <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
              <span class="group-hover:dark:text-white">{{ item.commentCount }} Comment{{ item.commentCount === 1 ? '' : 's' }}</span>
            </router-link>
          </li>
        </ul>
      </footer>
    </article>
  </li>
</template>

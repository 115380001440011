<script setup lang="ts">
import { onMounted, onUnmounted, inject, ref, nextTick, watch, computed } from "vue"
import NewCollection from "@/components/collections/New.vue"
import Uploader from "@/components/media/Uploader.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import API from "@/api/api"

interface Props {
  collection: string
}

const Router = useRouter()
const Platform = usePlatformStore()
const User = useUserStore()
const { id } = storeToRefs(User)

const props = defineProps<Props>()

const profileData: any = inject("profileData")

const initialLoadComplete = ref(false)
const working = ref(false)
const totalItems = ref(0)
const context = ref("")
const collectionData = ref<any>({})

const photoList = ref<any[]>([])
const pageInfo = ref<any>({
  endCursor: undefined
})

const isOwner = computed(() => collectionData.value.user === id.value)

onMounted(async () => {
  context.value = props.collection === 'default' ? 'default' : 'collection'
  await fetchCollection()
  initialLoadComplete.value = true
  if (context.value === 'default') {
    document.title = `${profileData.value.username}'s Media - Submit`
  } else {
    document.title = `${profileData.value.username}'s Media Collection - Submit`
  }
  await nextTick()
  if (photoList.value.length > 0) {
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

onUnmounted(() => {
  if (photoList.value.length > 0) {
    observer.disconnect()
  }
})

// watch props collection for changes
watch(() => props.collection, async (newVal) => {
  initialLoadComplete.value = false
  photoList.value = []
  pageInfo.value = {
    endCursor: undefined
  }
  observer.disconnect()
  context.value = newVal === 'default' ? 'default' : 'collection'
  await fetchCollection()
  initialLoadComplete.value = true
  if (context.value === 'default') {
    document.title = `${profileData.value.username}'s Media - Submit`
  } else {
    document.title = `${profileData.value.username}'s Media Collection - Submit`
  }
  await nextTick()
  if (photoList.value.length > 0) {
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

function handleUpload(newItem: any) {
  photoList.value.unshift(newItem)
}

async function fetchCollection() {
  if (working.value) return
  working.value = true
  try {
    let path = `/users/${profileData.value._id}/media/collections/${props.collection}`
    if (pageInfo.value.endCursor) {
      path += `?${new URLSearchParams({
        cursor: pageInfo.value.endCursor
      })}`
    }
    const response = await API().get(path)
    photoList.value.push(...response.data.data)
    if (!pageInfo.value.endCursor) totalItems.value = response.data.pageInfo.totalItems
    pageInfo.value = response.data.pageInfo
    collectionData.value = response.data.collection
    working.value = false
  } catch (err: any) {
    console.error(err)
  }
}

async function updateCollection(data: any) {
  collectionData.value = {
    title: data.title,
    type: data.type,
    visibility: data.visibility,
    tags: data.tags,
    collectionOnly: data.collectionOnly
  }
  if (data.description && data.description.length > 0) {
    collectionData.value.description = data.description
  }
}

async function deleteCollection() {
  if (working.value) return
  working.value = true
  if (confirm("Are you sure you want to delete this collection?")) {
    try {
      await API().delete(`/collections/${collectionData.value._id}`)
      Router.push(`/${profileData.value.username}/media/collections`)
    } catch (err: any) {
      console.error(err)
      working.value = false
      alert("There was an error deleting this collection, please try again.")
    }
  } else {
    working.value = false
    return
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        fetchCollection()
      }
    }
  })
}, options)

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: collectionData.value.user,
    contentId: collectionData.value._id,
    contentType: "collection"
  })
}

</script>

<template>
  <section class="space-y-4" v-if="initialLoadComplete">
    <div v-if="collection !== 'default'" class="CollectionDetail gap-x-4">
      <div class="flex justify-center flex-grow min-h-28 md:aspect-[5/3] bg-white dark:bg-submit-925/50 rounded-md">
        <svg class="w-8 fill-gray-400 dark:fill-gray-500 group-hover:fill-gold-700 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 480H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H192c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64zM176 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm120 0c-8 0-15.5 4-20 10.7l-56 84L202.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H392c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144C311.5 228 304 224 296 224z"/></svg>
      </div>
      <div class="py-2">
        <h2 class="text-2xl">{{ collectionData.title }}</h2>
        <p class="text-sm">{{ collectionData.description && collectionData.description.length > 0 ? collectionData.description : 'No description found, how sad.' }}</p>
        <ul class="flex gap-4 mt-6 text-sm">
          <li v-if="isOwner">
            <NewCollection @updated="updateCollection" :type="collectionData.type" :collection="collectionData">Edit</NewCollection>
          </li>
          <li v-if="isOwner">
            <button @click="deleteCollection" type="button">Delete</button>
          </li>
          <li v-if="!isOwner">
            <button @click="openReportDialog" type="button">Report</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex justify-between text-sm">
      <span>{{ totalItems }} photos</span>
      <Uploader
        v-if="profileData.self"
        :collection="collection === 'default' ? 'none' : collection"
        @uploaded="handleUpload"
      >
        <button>Add Photo</button>
      </Uploader>
    </div>
    <ul class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3 sm:gap-6">
      <li v-for="item in photoList" :key="item._id">
        <router-link class="block w-full aspect-[4/3] bg-neutral-50 dark:bg-indigo-925/25 rounded-md" :to="`/${profileData.username}/media/${item._id}${context === 'collection' ? '?ctx=c' : ''}`" :title="item.description || 'No Caption Provided'">
          <img :id="item._id" class="object-cover aspect-[4/3] w-full rounded-md pointer-events-none select-none" :src="item.url" :alt="item.description || 'Media Thumbnail'" loading="lazy" />
        </router-link>
      </li>
    </ul>
    <div v-if="photoList.length > 0" id="loadMore"></div>
  </section>
  <section class="space-y-4" v-else>
    <div class="flex justify-between">
      <span class="bg-neutral-50 dark:bg-indigo-925/25 w-24 h-4 animate-pulse"></span>
    </div>
    <ul class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3 sm:gap-6">
      <li v-for="i in 6" :key="i">
        <div class="block w-full aspect-[4/3] bg-neutral-50 dark:bg-indigo-925/25 rounded-md animate-pulse"></div>
      </li>
    </ul>
  </section>
</template>

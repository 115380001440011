<script setup lang="ts">
import { ref, reactive, computed, inject, onBeforeMount, onUnmounted, type Ref } from "vue"
import { usePlatformStore } from "@/stores/Platform"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import { useRoute, useRouter } from "vue-router"
import Form from "@/components/input/Form.vue"
import Tags from "@/components/input/Tags.vue"
import API from "@/api/api"
import * as zod from "zod"

const Platform = usePlatformStore()

interface Props {
  post?: any
}

const schema = zod.object({
  title: zod.string()
    .min(3, { message: "Too short." }),
  tags: zod.array(zod.string())
    .optional(),
  html: zod.string()
    .min(50, { message: "Too short." })
})

const Route = useRoute()
const Router = useRouter()
const props = withDefaults(defineProps<Props>(), {
  post: undefined
})

const Group = inject("Group") as Ref<any>

const debug = ref(false)
const working = ref(false)
const error = ref(false)
const published = ref(props.post ? true : false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")
const raw = ref(props.post ? props.post.raw : "")
const attachments = ref<string[]>(props.post ? props.post.attachments || [] : [])
const initialValues = reactive({
  title: props.post ? props.post.title : "",
  tags: props.post ? (props.post.tags ? props.post.tags : []) : [],
  html: props.post ? props.post.html : ""
})

const editorOptions = {
  all: true,
  attachments: true,
  mentions: true
}

const mentionConfig = ref({
  group: Group.value._id
})

const postType = computed(() => {
  if ((Route.query.type && Route.query.type === "personal") || (props.post && props.post.type === "personal")) {
    return "personal"
  } else {
    return "post"
  }
})

onBeforeMount(() => {
  if (postType.value === "personal") {
    Platform.setCategoryOverride("personals")
  } else {
    Platform.setCategoryOverride("discussions")
  }
})

onUnmounted(() => {
  Platform.removeCategoryOverride()
  if (!published.value && attachments.value.length > 0) {
    attachments.value.forEach((attachment) => {
      try {
        API().delete(`/media/attachment/${attachment}`)
      } catch (err) {
        console.error(err)
      }
    })
  }
})

const header = computed(() => {
  if (postType.value === "personal") {
    if (props.post) {
      return "Edit Personal"
    } else {
      return "Share a New Personal"
    }
  } else {
    if (props.post) {
      return "Edit Discussion"
    } else {
      return "Create a New Discussion"
    }
  }
})

const rules = computed(() => {
  if (postType.value === "personal") {
    if (!props.post && Group.value.personalsRules) {
      return `<strong>New Personals Rules:</strong> ${Group.value.personalsRules}`
    }
    if (props.post && Group.value.personalsEditingRules) {
      return `<strong>Editing Rules:</strong> ${Group.value.personalsEditingRules}`
    }
    return undefined
  } else {
    if (!props.post && Group.value.postingRules) {
      return `<strong>New Discussion Rules:</strong> ${Group.value.postingRules}`
    }
    if (props.post && Group.value.discussionEditingRules) {
      return `<strong>Editing Rules:</strong> ${Group.value.editingRules}`
    }
    return undefined
  }
})

const buttonLabel = computed(() => {
  if (postType.value === "personal") {
    if (props.post) {
      if (working.value) return "Updating..."
      return "Update Personal"
    } else {
      if (working.value) return "Posting..."
      return "Share Personal"
    }
  } else {
    if (props.post) {
      if (working.value) return "Updating..."
      return "Update Discussion"
    } else {
      if (working.value) return "Posting..."
      return "Start Discussion"
    }
  }
})

function handleCancel() {
  if (working.value) return
  if (postType.value === "personal") {
    if (props.post) {
      return Router.push(`/groups/${Group.value.slug}/${props.post.short}`)
    } else {
      return Router.push(`/groups/${Group.value.slug}/personals`)
    }
  } else {
    if (props.post) {
      return Router.push(`/groups/${Group.value.slug}/${props.post.short}`)
    } else {
      return Router.push(`/groups/${Group.value.slug}`)
    }
  }
}

function updateRaw(newRaw: string) {
  raw.value = newRaw
}

function updateAttachments(newAttachments: string[]) {
  attachments.value = newAttachments
}

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  let body: any = { post: {
    title: values.title,
    group: Group.value._id,
    type: postType.value === 'personal' ? 'personal' : 'text',
    tags: values.tags,
    html: values.html,
    raw: raw.value.slice(0, 264),
  } }

  if (attachments.value.length > 0) {
    body.post.attachments = attachments.value
  }

  try {
    if (props.post) {
      await API().put(`/groups/g/${Group.value.slug}/posts/${props.post.short}`, body)
      return Router.push(`/groups/${Group.value.slug}/${props.post.short}`)
    } else {
      const response = await API().post(`/groups/g/${Group.value.slug}/posts`, body)
      const data = response.data.data
      published.value = true
      return Router.push(`/groups/${Group.value.slug}/${data.short}`)
    }
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorMessage.value = response.data.message
        errorCode.value = response.data.code
      }
    }
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <div>
    <header class="mb-3">
      <h2 class="font-display font-bold text-xl">{{ header }}</h2>
    </header>
    <Form @on-submit="handleSubmit" :initial-values="initialValues" :schema="schema" v-slot="{fields, errors}" class="grid grid-cols-1 gap-4">
      <div>
        <Label field="title">Title</Label>
        <Field name="title" />
      </div>
      <div>
        <Label field="tags">Tags</Label>
        <Tags name="tags" :limit="6" :help-text="`Max 6. Hit enter or comma to add a tag.`" />
      </div>
      <div>
        <Label field="html">Content</Label>
        <Editor name="html" @raw="updateRaw" @attachments="updateAttachments" :attachments="attachments" :options="editorOptions" :mention-config="mentionConfig" />
      </div>
      <div class="text-sm" v-if="rules" v-html="rules"></div>
      <div class="flex justify-end gap-4">
        <button @click="handleCancel" type="button" class="text-sm font-bold px-4 py-1.5 dark:text-gray-400 text-gray-500">Cancel</button>
        <button type="submit" class="bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 text-sm font-bold" :disabled="working">{{ buttonLabel }}</button>
      </div>

      <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
      </div>

      <section v-if="debug">
        <pre class="text-sm">
{{ fields }}
        </pre>
        <pre class="text-sm">
{{ errors }}
        </pre>
      </section>
    </Form>
  </div>
</template>

<script setup lang="ts">
import Checkbox from "@/components/input/Checkbox.vue"
import Field from "@/components/input/Field.vue"
import Label from "@/components/input/Label.vue"
import Form from "@/components/input/Form.vue"
import Tags from "@/components/input/Tags.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import { ref } from "vue"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const User = useUserStore()
const { defaultVisibility, circles } = storeToRefs(User)

const schema = zod.object({
  title: zod.string()
    .min(3, { message: "Too short." })
    .max(64, { message: "Too long." }),
  description: zod.string()
    .optional(),
  visibility: zod.string()
    .min(1, { message: "You must select a visibility option."}),
  tags: zod.array(zod.string())
    .optional(),
  collectionOnly: zod.boolean()
})

interface Props {
  type: string
  collection?: any
}

const debug = ref(false)

const props = defineProps<Props>()
const emit = defineEmits(["created", "updated"])

const initialValues = {
  title: props.collection ? props.collection.title : "",
  description: props.collection ? props.collection.description || "" : "",
  visibility: props.collection ? props.collection.visibility : defaultAudience(defaultVisibility.value),
  tags: props.collection ? props.collection.tags || [] : [],
  collectionOnly: props.collection ? props.collection.collectionOnly || false : false
}

const collectionsOpen = ref(false)

const working = ref(false)
const error = ref(false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")

const toggleCollectionsOpen = async () => {
  collectionsOpen.value = !collectionsOpen.value
}

function defaultAudience(audience: string) {
  if (audience === "public") return "public"
  if (audience === "followers") return "followers"
  if (audience === "relationships") return "relationships"
}

function audienceDisplay(audience: string) {
  if (audience === "public") return "Everyone"
  if (audience === "followers") return "Followers"
  if (audience === "relationships") return "Relationships"
  // if audience isn't any of the above, check circles and return the matching name
  const circle = circles.value.find((circle: any) => circle.code === audience)
  if (circle) return circle.name
}

async function createCollection(values: any) {
  if (working.value) return
  working.value = true
  let payload: any = {
    title: values.title,
    type: props.type,
    visibility: values.visibility,
    tags: values.tags,
    collectionOnly: values.collectionOnly
  }
  if (values.description && values.description.length > 3) {
    payload.description = values.description
  }

  try {
    let response: any
    if (props.collection) {
      response = await API().put(`/collections/${props.collection._id}`, payload)
      emit("updated", payload)
    } else {
      response = await API().post("/collections", payload)
      emit("created", response.data.data)
    }
    working.value = false
    toggleCollectionsOpen()
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorMessage.value = response.data.message
        errorCode.value = response.data.code
      }
    } else {
      console.error(err)
    }
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <button @click="toggleCollectionsOpen">
    <slot></slot>
  </button>
  <TransitionRoot as="template" :show="collectionsOpen">
    <Dialog as="div" class="relative z-[100]" @close="toggleCollectionsOpen">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                <Form @on-submit="createCollection" :initial-values="initialValues" :schema="schema" v-slot="{fields, errors}" class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl">
                  <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                    <div class="flex flex-grow flex-col">
                      <DialogTitle class="text-base font-semibold leading-6 dark:text-white">{{ collection ? 'Edit' : 'New' }} Collection</DialogTitle>
                      <p class="text-sm">{{ collection ? 'Edit an existing ' : 'Create a new ' }}collection.</p>
                    </div>
                    <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="toggleCollectionsOpen">
                      <span class="sr-only">Close panel</span>
                      <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                    </button>
                  </div>
                  <div class="flex flex-col mt-10 flex-1 px-4 sm:px-6 space-y-6">
                    <div>
                      <Label field="type">Type</label>
                      <div class="flex items-center justify-between capitalize p-4 w-full text-sm rounded-md bg-white dark:bg-submit-900 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50">
                        {{ type }}
                        <svg class="flex-none h-3 dark:fill-gray-400 fill-gray-500 pr-1.5" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
                      </div>
                      <p class="mt-2 text-xs text-gray-500 dark:text-gray-400">
                        Type settings are locked to the collection context.
                      </p>
                    </div>
                    <div>
                      <Label field="title">Title</Label>
                      <Field name="title" placeholder="Collection Title" />
                    </div>
                    <div>
                      <Label field="description">Description (Optional)</Label>
                      <Field name="description" type="textarea" rows="3" placeholder="Collection Description" />
                    </div>
                    <div>
                      <Label field="visibility">Audience</Label>
                      <Field name="visibility" type="select" v-if="!collection">
                        <option value="public">Everyone</option>
                        <option value="followers">Followers</option>
                        <option value="relationships">Relationships</option>
                        <optgroup label="Circles" v-if="circles.length > 0">
                          <option v-for="circle in circles" :key="circle._id" :value="circle.code">{{ circle.name }}</option>
                        </optgroup>
                      </Field>
                      <Field name="visibility" type="select" help-text="Collection audience cannot be edited currently." :locked="true" :locked-value="collection.visibility" :locked-display="audienceDisplay(collection.visibility)" v-else>
                        <option value="public">Everyone</option>
                        <option value="followers">Followers</option>
                        <option value="relationships">Relationships</option>
                        <optgroup label="Circles" v-if="circles.length > 0">
                          <option v-for="circle in circles" :key="circle._id" :value="circle.code">{{ circle.name }}</option>
                        </optgroup>
                      </Field>
                    </div>
                    <div>
                      <Label field="tags">Tags</Label>
                      <Tags name="tags" :limit="6" :help-text="`Max 6. Hit enter or comma to add a tag.`" />
                    </div>
                    <Checkbox name="collectionOnly" :help-text="`If checked, the content in this collection will not show up in your primary ${type} collection, and will only be visible in this collection.`">
                      Content should only show in this collection
                    </Checkbox>
                    <div v-if="debug">
                      <pre>
{{  fields }}
                      </pre>
                      <pre>
{{ errors }}
                      </pre>
                    </div>
                  </div>
                  <div class="mx-4 sm:mx-6 mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                    <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                  </div>
                  <div class="flex border-t border-t-indigo-350 mt-6 pt-6 px-4 sm:px-6">
                    <div class="flex justify-end w-full gap-4">
                      <button type="button" @click="toggleCollectionsOpen" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Cancel</button>
                      <button type="submit" :class="`py-2 px-4 text-sm border border-transparent rounded-md font-semibold bg-gold-700 dark:bg-gold-500 text-black ${working ? '' : 'bg-opacity-75'}`" :disabled="working" v-if="!collection">{{ working ? 'Creating...' : 'Create' }}</button>
                      <button type="submit" :class="`py-2 px-4 text-sm border border-transparent rounded-md font-semibold bg-gold-700 dark:bg-gold-500 text-black ${working ? '' : 'bg-opacity-75'}`" :disabled="working" v-else>{{ working ? 'Updating...' : 'Update' }}</button>
                    </div>
                  </div>
                </Form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import Checkbox from "@/components/input/Checkbox.vue"
import Field from "@/components/input/Field.vue"
import Label from "@/components/input/Label.vue"
import Form from "@/components/input/Form.vue"
import API from "@/api/api"
import * as zod from "zod"
import { ref } from "vue"

const initialValues = {
  email: "",
  username: "",
  age: false,
  terms: false
}

const schema = zod.object({
  email: zod.string()
    .email({ message: "Must be a valid email address." })
    .min(1, { message: "This is required." }),
  username: zod.string()
    .min(4, { message: "Can be between 4 and 24 characters." } )
    .max(24, { message: "Can be between 4 and 24 characters." } )
    .regex(/^[a-zA-Z0-9_-]+$/, { message: "Can only contain letters, numbers, underscores, and dashes." }),
  age: zod.boolean()
    .refine(value => value === true, { message: "You must be at least 18 years of age." }),
  terms: zod.boolean()
    .refine(value => value === true, { message: "You must agree to the Terms of Service." })
})

const working = ref(false)
const debug = ref(false)
const joined = ref(false)
const signUpError = ref(false)
const errorType = ref("")

async function handleSubmit(values: any) {
  if (working.value) return
  if (joined.value) return
  working.value = true
  try {
    const response = await API(false).post("/waitlist", {
      email: values.email,
      username: values.username
    })
    setTimeout(() => {
      joined.value = true
      working.value = false
    }, 1000)
  } catch (err: any) {
    signUpError.value = true
    if (err.response.status === 400) {
      errorType.value = "username"
    } else {
      errorType.value = "general"
    }
    working.value = false
  }
}

</script>

<template>
  <section v-if="!joined">
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">{{ $t("jointhe") }} {{ $t("waitlist") }}</h1>
    <Form :schema="schema" :initial-values="initialValues" @on-submit="handleSubmit" v-slot="{ fields, errors }" class="space-y-3">
      <div>
        <Label field="email">{{ $t("email") }}</Label>
        <Field type="input" subtype="email" name="email" placeholder="name@domain.com" />
      </div>
      <div>
        <Label field="username">{{ $t("username") }}</Label>
        <Field name="username" placeholder="Username" />
      </div>
      <div>
        <Checkbox name="age">
          I confirm I am at least 18 years of age.
        </Checkbox>
      </div>
      <div>
        <Checkbox name="terms">
          I agree to the <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" target="_blank" href="https://docs.submit.gg/terms">Terms of Service</a>.
        </Checkbox>
      </div>
      <div>
        <button class="mt-6 font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black py-4 w-full" type="submit" :disabled="working">
          {{ working ? $t("processing") : $t("jointhe") + " " + $t("waitlist") }}
        </button>
      </div>
      <div v-if="debug">
        <pre>
{{ fields }}
        </pre>
        <pre>
{{ errors }}
        </pre>
      </div>
    </Form>
    <router-link class="text-sm inline-block mt-6 hover:underline" to="/signup/early">Have an early access code? Click here to get started!</router-link>
    <div class="mt-4 text-sm text-red-600" v-if="signUpError">
      <strong>Error:</strong>&nbsp;
      <span v-if="errorType === 'general'">There was an error signing you up for the waitlist.</span>
      <span v-if="errorType === 'username'">The username requested is invalid or taken.</span>
    </div>
  </section>
  <section v-else>
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">{{ $t("checkinbox") }}</h1>
    <p class="text-sm mb-8 leading-6">Verify your email by clicking the link in the confirmation email we've just sent you (it expires in 24 hours!).</p>
    <router-link to="/" class="block cursor-pointer text-center mt-12 font-display font-semibold rounded-md dark:bg-submit-600 dark:hover:bg-submit-500 py-4 w-full">Return to our website</router-link>
  </section>
</template>

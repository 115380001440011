<script setup lang="ts">
import { usePlatformStore } from '../../stores/Platform'
import { storeToRefs } from "pinia"

const platformStore = usePlatformStore()
const { theme } = storeToRefs(platformStore)
const { toggleTheme } = platformStore
</script>

<template>
  <div class="inline-flex gap-1 items-center hover:text-indigo-950 dark:hover:text-white hover:cursor-pointer hover:underline group" @click="toggleTheme" @keyup.enter.space="toggleTheme" v-if="theme === 'dark'">
    <svg xmlns="http://www.w3.org/2000/svg" class="fill-neutral-600 dark:fill-indigo-325 group-hover:fill-indigo-950 dark:group-hover:fill-white group-hover:cursor-pointer" width="16" height="14" viewBox="0 0 512 512"><path d="M512 256c0 7.8-.3 15.5-1 23.1C438.8 304.7 351.2 320 256 320s-182.8-15.3-255-40.9c-.7-7.6-1-15.3-1-23.1C0 114.6 114.6 0 256 0S512 114.6 512 256zM256 512C134.9 512 33.4 427.9 6.8 314.8C79.4 338.4 164.7 352 256 352s176.6-13.6 249.2-37.2C478.6 427.9 377.1 512 256 512zM224 160c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32zm48 0c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80s80-35.8 80-80z"/></svg>
    <span class="capitalize">{{ $t("dark") }}</span>
  </div>
  <div class="flex gap-1 items-center hover:text-indigo-950 dark:hover:text-white hover:cursor-pointer hover:underline group" @click="toggleTheme" @keyup.enter.space="toggleTheme" v-if="theme === 'light'">
    <svg xmlns="http://www.w3.org/2000/svg" class="fill-neutral-600 dark:fill-indigo-325 group-hover:fill-indigo-950 dark:group-hover:fill-white group-hover:cursor-pointer" width="16" height="14" viewBox="0 0 576 512"><path d="M245.2 318.3l-25.1-50.2 50.1 25.1L280 0h16l9.8 293.2 50.1-25.1-25.1 50.2L384 336l-53.2 17.7 25.1 50.2-47.2-23.6 2.2 65.7C370.6 435.2 416 382.9 416 320c0-38.3-16.8-72.7-43.5-96.1C417.8 195.6 448 145.3 448 88c0-30.5-8.5-59-23.3-83.2c64.9 37.8 112.8 101.7 129.2 177.4L512 224h47.5c.3 5.3 .5 10.6 .5 16c0 18.2-1.8 36-5.2 53.2L496 352h39.9C493.3 446.4 398.3 512 288 512S82.7 446.4 40.1 352H80L21.2 293.2C17.8 276 16 258.2 16 240c0-5.4 .2-10.7 .5-16H64L22.2 182.2C38.5 106.5 86.4 42.6 151.3 4.8C136.5 29 128 57.5 128 88c0 57.3 30.2 107.6 75.5 135.9C176.8 247.3 160 281.7 160 320c0 62.9 45.4 115.2 105.1 126l2.2-65.7-47.2 23.6 25.1-50.2L192 336l53.2-17.7z"/></svg>
    <span class="capitalize">{{ $t("light") }}</span>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import { useRouter } from "vue-router"
import { ref, onMounted } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const ExploreAPI = import.meta.env.VITE_EXPLORE_API

const Router = useRouter()
const UserStore = useUserStore()
const { roles } = storeToRefs(UserStore)

const initialLoadComplete = ref(false)
const working = ref(false)
const isAdmin = ref(roles.value.includes("admin"))
const feedData = ref<any>(undefined)
const error = ref(false)

onMounted(async () => {
  await fetchExploreFeed()
  initialLoadComplete.value = true
})

async function fetchExploreFeed() {
  try {
    const response = await fetch(`${ExploreAPI}/feed`, {
      method: 'GET',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    feedData.value = data.map((item: any) => ({ ...item, loading: true }))
  } catch (err) {
    console.error(err)
    error.value = true
  }
}

async function replaceFeedItem(id: string) {
  working.value = true
  try {
    const response = await fetch(`${ExploreAPI}/replace`, {
      method: 'POST',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ OldItem: id })
    })
    const data = await response.json()
    feedData.value = feedData.value.map((item: any) => {
      if (item._id === id) {
        return { ...data, loading: true }
      }
      return item
    })
  } catch (err) {
    console.error(err)
    error.value = true
  }
  working.value = false
}

async function markItemAsIneligible(id: string) {
  working.value = true
  try {
    await API().patch(`/media/${id}/ineligibility`, {
      type: "explore",
      bool: true
    })
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    working.value = false
  }
}

function handleImageLoad(item: any) {
  item.loading = false
}

function getAspectRatioClass(item: any) {
  const ratio = item.width / item.height
  const formattedRatio = ratio.toFixed(2)
  return `aspect-ratio: ${formattedRatio}`
}

function actionHandler(e: any) {
  if (e.target.nodeName === 'A' || e.target.nodeName === 'SVG' || e.target.nodeName === 'svg' || e.target.nodeName === 'PATH' || e.target.nodeName === 'BUTTON' || e.target.closest('a') || e.target.classList.contains('TheEnd'))
    return
  Router.push(e.target.getAttribute('data-to'))
}
</script>

<template>
  <masonry-wall :items="feedData" :column-width="224" :min-columns="2" :max-columns="6" :gap="0" v-if="initialLoadComplete">
    <template #default="{ item, index }: { item: any, index: number }" v-if="feedData && isAdmin">
      <div class="ExploreItem relative cursor-pointer explore-photo-container" :style="getAspectRatioClass(item)">
        <div class="absolute flex items-center bottom-0 right-0 w-full dark:bg-submit-500 bg-white px-1 py-1 text-sm font-bold">
          <div class="flex">{{ index + 1 }}</div>
          <ul class="flex flex-1 justify-end gap-2">
            <li class="flex">
              <button v-if="isAdmin" class="flex group" type="button">
                <svg class="w-4 fill-gray-400 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7l388.6 0c3.9 0 7.6-.7 11-2.1l-261-205.6z"/></svg>
              </button>
            </li>
            <li class="flex group">
              <button v-if="isAdmin" @click="markItemAsIneligible(item._id)" class="flex group" type="button">
                <svg class="w-4 fill-gray-400 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7l-55.5-43.5c.5-3.1 .7-6.3 .7-9.6l0-320c0-35.3-28.7-64-64-64L128 32c-14.4 0-27.8 4.8-38.5 12.9L38.8 5.1zm120 94.1c5.3-2 11.1-3.2 17.2-3.2c26.5 0 48 21.5 48 48c0 2-.1 4-.4 6L158.8 99.2zM328.1 231.9l20-29.4c4.5-6.6 11.9-10.5 19.8-10.5s15.4 3.9 19.8 10.5L499.4 366.1 328.1 231.9zM221.7 272.5c.3-.4 .6-.7 1-1.1L64 146.4 64 416c0 35.3 28.7 64 64 64l359.4 0-81.2-64L320 416l-72 0-96 0c-9.3 0-17.8-5.4-21.8-13.9s-2.6-18.5 3.5-25.6l88-104z"/></svg>
              </button>
            </li>
            <li class="flex group">
              <button v-if="isAdmin" @click="replaceFeedItem(item._id)" type="button">
                <svg class="w-4 fill-gray-400 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M480 256c17.7 0 32 14.3 32 32c0 88.4-71.6 160-160 160H192l0 32c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-64-64c-12.5-12.5-12.5-32.8 0-45.3l64-64c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6v32H352c53 0 96-43 96-96c0-17.7 14.3-32 32-32z"/><path class="fa-primary" d="M32 256c-17.7 0-32-14.3-32-32C0 135.6 71.6 64 160 64H320V32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c12.5 12.5 12.5 32.8 0 45.3l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V128H160c-53 0-96 43-96 96c0 17.7-14.3 32-32 32z"/></svg>
              </button>
            </li>
          </ul>
        </div>
        <div class="loading-spinner" v-if="item.loading">Loading...</div>
        <router-link :to="`/${item.user}/media/${item._id}`">
          <img loading="lazy" class="block pointer-events-none" :src="item.url" @load="handleImageLoad(item)" />
        </router-link>
      </div>
    </template>
    <template #default="{ item, index }: { item: any, index: number }" v-if="feedData && !isAdmin">
      <div class="ExploreItem relative block explore-photo-container" :style="getAspectRatioClass(item)">
        <div class="loading-spinner" v-if="item.loading">Loading...</div>
        <router-link :to="`/${item.user}/media/${item._id}`">
          <img :height="item.height" :width="item.width" loading="lazy" class="w-full h-auto block pointer-events-none" :src="item.url" @load="handleImageLoad(item)" />
        </router-link>
      </div>
    </template>
  </masonry-wall>
  <p v-if="error">There was an error fetching the explore feed.</p>
  <p v-if="!initialLoadComplete">Loading...</p>
</template>

<style>
.ExploreFeed {
  display: block;
  columns: 10rem;
  column-gap: 0;
}

@media (min-width: 768px) {
  .ExploreFeed {
    columns: 16rem;
  }
}

.explore-photo-container {
  transition: transform 0.2s;
}

.explore-photo-container:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transform: scale(1.1);
  z-index: 10;
}
</style>

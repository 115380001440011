<script setup lang="ts">
import { computed } from "vue"
import { useRoute } from "vue-router"

interface Props {
  to: any
  exact?: boolean
}

const { to, exact } = defineProps<Props>()
const route = useRoute()

const isActive = computed(() => {
  //check if to is a string
  if (typeof to === "string") {
    if (exact) {
      return route.path === to
    } else {
      return route.path.startsWith(to)
    }
  } else {
    if (exact) {
      return route.name === to.name
    } else {
      const routeName = route.name as string
      return routeName.includes(to.name)
    }
  }
})
</script>

<template>
  <li class="inline-flex text-sm">
    <router-link
      :class="[
        isActive ? 'text-gold-700 dark:text-gold-500' : '',
        'NavItem flex items-center gap-2 md:gap-4 group hover:text-black dark:hover:text-white'
      ]"
      :to="to"
    >
      <figure
        :class="[
          isActive ? 'fill-gold-700 dark:fill-gold-500' : 'fill-gray-500 dark:fill-gray-400',
          'flex flex-none w-4 h-4 justify-center items-center *:group-hover:fill-black *:dark:group-hover:fill-white'
        ]"
      >
        <slot name="icon"></slot>
      </figure>
      <span>
        <slot name="label"></slot>
      </span>
    </router-link>
  </li>
</template>

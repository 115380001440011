<script setup lang="ts">
import { ref, onBeforeMount } from "vue"
import { useRoute } from "vue-router"
import API from "@/api/api"

const route = useRoute()

const working = ref(true)
const error = ref(false)
const verified = ref(false)

onBeforeMount(async () => {
  const token = route.query.code as string
  if (!token) {
    error.value = true
    working.value = false
    return
  }
  try {
    await API(false).post("/waitlist/verify", {
      code: token
    })
    verified.value = true
    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
  }
})
</script>

<template>
  <section>
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">{{ $t('verification') }}</h1>
    <template v-if="!working && !error && verified">
      <p class="text-sm mb-8 leading-6">Thank you for verifying your email address, your username has been reserved and you are on the waitlist.</p>
      <p class="text-sm mb-8 leading-6">Generally you can expect an invite within 24-48 hours of joining the waitlist, but it can take a bit longer too.</p>
    </template>
    <template v-if="!working && error">
      <p class="text-sm mb-8 leading-6">Verification code not found or expired. If it's been more than 24 hours since you signed up for the waitlist, you'll need to sign up again.</p>
    </template>
    <template v-if="working && !error">
      <p class="text-sm mb-8 leading-6 animate-pulse">Verifying&hellip;</p>
    </template>
  </section>
</template>

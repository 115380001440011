import MentionList from "@/components/editor/MentionList.vue"
import type { Editor } from "@tiptap/vue-3"
import { VueRenderer } from "@tiptap/vue-3"
import tippy from "tippy.js"
import API from "@/api/api"

export default {
  items: async ({ query, editor }: { query: string, editor: Editor }) => {
    // Get the mention configuration from editor storage
    const mentionConfig = editor.storage.mentionConfiguration.config

    // Ensure we have a valid object before destructuring
    const { contentId, contentType, contentAuthorId, group } = mentionConfig || {}

    // If query is too short, show a helpful message
    if (query.length < 2) {
      return [{
        id: 'helper',
        label: 'Keep typing to search users...',
        isHelper: true // We'll use this flag in MentionList
      }]
    }

    // Only make API call if query is 2 or more characters
    if (query.length >= 2) {
      try {
        // Include additional parameters in API call
        const payload = {
          query,
          ...(contentId && { contentId }),
          ...(contentType && { contentType }),
          ...(contentAuthorId && { contentAuthorId }),
          ...(group && { group })
        }

        const response = await API().post('/mentions', payload)
        const users = response.data.data
        return users.slice(0, 5).map((user: any) => ({
          id: user._id,
          label: user.username,
        }))
      } catch (error) {
        console.error('Error fetching mentions:', error)
        return []
      }
    }

    return []
  },

  render: () => {
    let component: VueRenderer
    let popup: any[]

    return {
      onStart: (props: any) => {
        component = new VueRenderer(MentionList, {
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props: any) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown({ event: props.event })
      },

      onExit() {
        if (popup?.[0]?.state?.isDestroyed === false) {
          popup[0].destroy()
        }
        component.destroy()
      },
    }
  },
} as any

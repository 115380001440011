<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const UserStore = useUserStore()
const { unseenFeaturedAnnouncements } = storeToRefs(UserStore)

interface Announcement {
  _id: string,
  slug: string,
  featured: boolean,
  featuredType: string,
  featuredTitle: string,
  featuredIcon: string,
  featuredSummary: string,
  publishedAt: string
}

const working = ref(false)
const error = ref<boolean>(false)
const fetchedAnnouncements = ref<Record<string, Announcement>>({})
const currentIndex = ref(0)

const sliderRef = ref<HTMLElement | null>(null)

const scrollToIndex = (index: number) => {
  if (!sliderRef.value) return
  const slideWidth = sliderRef.value.offsetWidth
  sliderRef.value.scrollTo({
    left: slideWidth * index,
    behavior: 'smooth'
  })
}

const fetchAnnouncement = async (id: string) => {
  if (working.value) return
  if (fetchedAnnouncements.value[id]) return // Already fetched

  try {
    working.value = true
    const announcement = await API().get(`/announcements/featured/${id}`)
    fetchedAnnouncements.value[id] = announcement.data.data

    // Pre-fetch next announcement if it exists
    const nextIndex = unseenFeaturedAnnouncements.value.indexOf(id) + 1
    if (nextIndex < unseenFeaturedAnnouncements.value.length) {
      const nextId = unseenFeaturedAnnouncements.value[nextIndex]
      if (!fetchedAnnouncements.value[nextId]) {
        const nextAnnouncement = await API().get(`/announcements/featured/${nextId}`)
        fetchedAnnouncements.value[nextId] = nextAnnouncement.data.data
      }
    }
  } catch (e) {
    console.error('Failed to fetch announcement:', e)
    error.value = true
  } finally {
    working.value = false
  }
}

onMounted(() => {
  if (unseenFeaturedAnnouncements.value.length > 0) {
    fetchAnnouncement(unseenFeaturedAnnouncements.value[0])
  }

  // Add scroll listener
  if (sliderRef.value) {
    sliderRef.value.addEventListener('scroll', handleScroll)
  }
})

onUnmounted(() => {
  // Remove scroll listener
  if (sliderRef.value) {
    sliderRef.value.removeEventListener('scroll', handleScroll)
  }
})

// Touch handling
const touchStart = ref(0)
const touchEnd = ref(0)

const handleTouchStart = (e: TouchEvent) => {
  touchStart.value = e.changedTouches[0].screenX
}

const handleTouchEnd = (e: TouchEvent) => {
  touchEnd.value = e.changedTouches[0].screenX
  handleSwipe()
}

const handleSwipe = () => {
  const swipeDistance = touchEnd.value - touchStart.value
  if (Math.abs(swipeDistance) > 50) { // Minimum swipe distance
    if (swipeDistance > 0 && currentIndex.value > 0) {
      currentIndex.value--
    } else if (swipeDistance < 0 && currentIndex.value < unseenFeaturedAnnouncements.value.length - 1) {
      currentIndex.value++
      fetchAnnouncement(unseenFeaturedAnnouncements.value[currentIndex.value])
    }
  }
}

const handleNavigation = async (index: number) => {
  if (index === currentIndex.value) return

  const id = unseenFeaturedAnnouncements.value[index]
  if (!fetchedAnnouncements.value[id]) {
    await fetchAnnouncement(id)
  }

  currentIndex.value = index
  scrollToIndex(index)
}

const handleScroll = () => {
  if (!sliderRef.value) return
  const slideWidth = sliderRef.value.offsetWidth
  const scrollPosition = sliderRef.value.scrollLeft
  const newIndex = Math.round(scrollPosition / slideWidth)

  if (newIndex !== currentIndex.value) {
    currentIndex.value = newIndex
    const id = unseenFeaturedAnnouncements.value[newIndex]
    if (id && !fetchedAnnouncements.value[id]) {
      fetchAnnouncement(id)
    }
  }
}

const dismissAnnouncement = async (id: string) => {
  try {
    if (working.value) return
    await API().get(`/announcements/featured/${id}/dismiss`)
    UserStore.addAnnouncementToList(id)
  } catch (e) {
    console.error('Failed to dismiss announcement:', e)
  }
}

const handleAnnouncementClick = (event: MouseEvent) => {
  // If we're swiping, the slider's touch handlers will take care of it
  if (Math.abs(touchEnd.value - touchStart.value) > 50) {
    return
  }

  // Otherwise, let the router-link handle the navigation
  event.preventDefault()
}
</script>

<template>
  <div v-if="unseenFeaturedAnnouncements.length === 0" class="h-[160px] flex items-center justify-center text-gray-500">
    No announcements available
  </div>

  <div v-else class="relative h-[160px] w-full overflow-hidden">
    <div class="-mx-2 w-[calc(100%+16px)] h-full">
      <div
        ref="sliderRef"
      class="flex h-full w-full snap-x snap-mandatory overflow-x-auto scrollbar-hide"
      @touchstart="handleTouchStart"
      @touchend="handleTouchEnd"
    >
      <div
        v-for="(id, index) in unseenFeaturedAnnouncements"
        :key="id"
        class="flex-none w-full snap-center"
      >
        <div class="h-full mx-2">
          <div v-if="!fetchedAnnouncements[id] && working"
               class="h-full relative bg-white rounded-lg shadow-lg p-4">
            <div class="animate-pulse">
              <div class="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div class="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>
          </div>

          <div v-else-if="!fetchedAnnouncements[id] && error"
               class="h-full relative bg-white rounded-lg shadow-lg p-4">
            <p class="text-red-500">{{ error }}</p>
          </div>

          <div v-else-if="fetchedAnnouncements[id]"
               class="h-full relative bg-white dark:bg-submit-900 rounded-2xl p-4 group">
            <button
              @click="dismissAnnouncement(id)"
              class="absolute top-4 right-4 text-gray-500 dark:hover:text-white hover:text-black transition-colors"
            >
              <svg
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <!-- Content wrapper with overflow handling -->
            <router-link
              :to="`/announcements/${fetchedAnnouncements[id].slug}`"
              @click.native.stop="handleAnnouncementClick"
            >
              <div
                class="h-full flex cursor-pointer items-center gap-2 sm:gap-4"
              >
                <div class="px-4 hidden sm:block">
                  <svg v-if="fetchedAnnouncements[id].featuredIcon === 'alert'" class="h-10 sm:h-16 md:h-20 lg:h-24 dark:fill-gold-500 fill-gold-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                  <svg v-if="fetchedAnnouncements[id].featuredIcon === 'calendar'" class="h-10 sm:h-16 md:h-20 lg:h-24 dark:fill-gold-500 fill-gold-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>
                  <svg v-if="fetchedAnnouncements[id].featuredIcon === 'celebrate'" class="h-10 sm:h-16 md:h-20 lg:h-24 dark:fill-gold-500 fill-gold-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M32 32a32 32 0 1 1 64 0A32 32 0 1 1 32 32zM448 160a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32 256a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM167 153c-9.4-9.4-9.4-24.6 0-33.9l8.3-8.3c16.7-16.7 27.2-38.6 29.8-62.1l3-27.4C209.6 8.2 221.5-1.3 234.7 .1s22.7 13.3 21.2 26.5l-3 27.4c-3.8 34.3-19.2 66.3-43.6 90.7L201 153c-9.4 9.4-24.6 9.4-33.9 0zM359 311l8.2-8.3c24.4-24.4 56.4-39.8 90.7-43.6l27.4-3c13.2-1.5 25 8 26.5 21.2s-8 25-21.2 26.5l-27.4 3c-23.5 2.6-45.4 13.1-62.1 29.8L393 345c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9zM506.3 8.5c8.6 10.1 7.3 25.3-2.8 33.8l-10 8.5c-14.8 12.5-33.7 19.1-53 18.6c-16.6-.4-30.6 12.4-31.6 29l-1.8 30c-2.5 42.5-38.3 75.3-80.8 74.2c-7.6-.2-15 2.4-20.7 7.3l-10 8.5c-10.1 8.6-25.3 7.3-33.8-2.8s-7.3-25.3 2.8-33.8l10-8.5c14.8-12.5 33.7-19.1 53-18.6c16.6 .4 30.6-12.4 31.6-29l1.8-30c2.5-42.5 38.3-75.3 80.8-74.2c7.6 .2 15-2.4 20.7-7.3l10-8.5c10.1-8.6 25.3-7.3 33.8 2.8zM150.6 201.4l160 160c7.7 7.7 10.9 18.8 8.6 29.4s-9.9 19.4-20 23.2l-39.7 14.9L83.1 252.5 98 212.8c3.8-10.2 12.6-17.7 23.2-20s21.7 1 29.4 8.6zM48.2 345.6l22.6-60.2L226.6 441.2l-60.2 22.6L48.2 345.6zM35.9 378.5l97.6 97.6L43.2 510c-11.7 4.4-25 1.5-33.9-7.3S-2.4 480.5 2 468.8l33.8-90.3z"/></svg>
                  <svg v-if="fetchedAnnouncements[id].featuredIcon === 'launch'" class="h-10 sm:h-16 md:h-20 lg:h-24 dark:fill-gold-500 fill-gold-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M117.8 128l89.3 0C286.9-3.7 409.5-8.5 483.9 5.3c11.6 2.2 20.7 11.2 22.8 22.8c13.8 74.4 9 197-122.7 276.9l0 89.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9l0-114.7c0-22.6-9-44.3-25-60.3s-37.7-25-60.3-25L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3zM424 128a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zM166.5 470C132.3 504.3 66 511 28.3 511.9c-16 .4-28.6-12.2-28.2-28.2C1 446 7.7 379.7 42 345.5c34.4-34.4 90.1-34.4 124.5 0s34.4 90.1 0 124.5zm-46.7-36.4c11.4-11.4 11.4-30 0-41.4s-30-11.4-41.4 0c-10.1 10.1-13 28.5-13.7 41.3c-.5 8 5.9 14.3 13.9 13.9c12.8-.7 31.2-3.7 41.3-13.7z"/></svg>
                </div>
                <div>
                  <h3 class="flex gap-2 items-center sm:block text-base sm:text-lg font-bold">
                    <svg v-if="fetchedAnnouncements[id].featuredIcon === 'alert'" class="sm:hidden h-4 dark:fill-gold-500 fill-gold-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                    <svg v-if="fetchedAnnouncements[id].featuredIcon === 'calendar'" class="sm:hidden h-4 dark:fill-gold-500 fill-gold-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>
                    <svg v-if="fetchedAnnouncements[id].featuredIcon === 'celebrate'" class="sm:hidden h-4 dark:fill-gold-500 fill-gold-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M32 32a32 32 0 1 1 64 0A32 32 0 1 1 32 32zM448 160a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32 256a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM167 153c-9.4-9.4-9.4-24.6 0-33.9l8.3-8.3c16.7-16.7 27.2-38.6 29.8-62.1l3-27.4C209.6 8.2 221.5-1.3 234.7 .1s22.7 13.3 21.2 26.5l-3 27.4c-3.8 34.3-19.2 66.3-43.6 90.7L201 153c-9.4 9.4-24.6 9.4-33.9 0zM359 311l8.2-8.3c24.4-24.4 56.4-39.8 90.7-43.6l27.4-3c13.2-1.5 25 8 26.5 21.2s-8 25-21.2 26.5l-27.4 3c-23.5 2.6-45.4 13.1-62.1 29.8L393 345c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9zM506.3 8.5c8.6 10.1 7.3 25.3-2.8 33.8l-10 8.5c-14.8 12.5-33.7 19.1-53 18.6c-16.6-.4-30.6 12.4-31.6 29l-1.8 30c-2.5 42.5-38.3 75.3-80.8 74.2c-7.6-.2-15 2.4-20.7 7.3l-10 8.5c-10.1 8.6-25.3 7.3-33.8-2.8s-7.3-25.3 2.8-33.8l10-8.5c14.8-12.5 33.7-19.1 53-18.6c16.6 .4 30.6-12.4 31.6-29l1.8-30c2.5-42.5 38.3-75.3 80.8-74.2c7.6 .2 15-2.4 20.7-7.3l10-8.5c10.1-8.6 25.3-7.3 33.8 2.8zM150.6 201.4l160 160c7.7 7.7 10.9 18.8 8.6 29.4s-9.9 19.4-20 23.2l-39.7 14.9L83.1 252.5 98 212.8c3.8-10.2 12.6-17.7 23.2-20s21.7 1 29.4 8.6zM48.2 345.6l22.6-60.2L226.6 441.2l-60.2 22.6L48.2 345.6zM35.9 378.5l97.6 97.6L43.2 510c-11.7 4.4-25 1.5-33.9-7.3S-2.4 480.5 2 468.8l33.8-90.3z"/></svg>
                    <svg v-if="fetchedAnnouncements[id].featuredIcon === 'launch'" class="sm:hidden h-4 dark:fill-gold-500 fill-gold-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M117.8 128l89.3 0C286.9-3.7 409.5-8.5 483.9 5.3c11.6 2.2 20.7 11.2 22.8 22.8c13.8 74.4 9 197-122.7 276.9l0 89.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9l0-114.7c0-22.6-9-44.3-25-60.3s-37.7-25-60.3-25L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3zM424 128a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zM166.5 470C132.3 504.3 66 511 28.3 511.9c-16 .4-28.6-12.2-28.2-28.2C1 446 7.7 379.7 42 345.5c34.4-34.4 90.1-34.4 124.5 0s34.4 90.1 0 124.5zm-46.7-36.4c11.4-11.4 11.4-30 0-41.4s-30-11.4-41.4 0c-10.1 10.1-13 28.5-13.7 41.3c-.5 8 5.9 14.3 13.9 13.9c12.8-.7 31.2-3.7 41.3-13.7z"/></svg>
                    {{ fetchedAnnouncements[id].featuredTitle }}
                  </h3>
                  <p class="text-sm dark:text-gray-400 pr-4">{{ fetchedAnnouncements[id].featuredSummary }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Navigation dots -->
    <div class="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2" v-if="unseenFeaturedAnnouncements.length > 1">
      <button
        v-for="(id, index) in unseenFeaturedAnnouncements"
        :key="id"
        @click="handleNavigation(index)"
        class="w-2 h-2 rounded-full transition-colors"
        :class="index === currentIndex ? 'bg-[#E177A4]' : 'dark:bg-gray-400'"
      />
    </div>
  </div>
</div>
</template>

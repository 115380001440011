<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import { computed } from "vue"
import API from "@/api/api"

const UserStore = useUserStore()
const { username } = storeToRefs(UserStore)

interface Props {
  item: any
}

const props = defineProps<Props>()
const emit = defineEmits(["remove"])

const action = computed(() => {
  switch (props.item.type) {
    case "follow":
      return "followed"
    case "reaction":
      return "reacted to"
    case "request":
      return "requested"
    case "comment":
      return "commented"
    case "starterpack:added":
      return "added you to a"
    case "starterpack:removed":
      return "removed themselves from your"
    case "mention":
      return "mentioned you"
  }
})

const mediaType = computed(() => {
  if (props.item.reference && props.item.referenceType === "status") {
    return "status"
  }
  if (props.item.reference && props.item.referenceType === "media") {
    return "photo"
  }
  if (props.item.reference && props.item.referenceType === "writing") {
    return "writing"
  }
  if (props.item.reference && props.item.referenceType === "post") {
    return "group post"
  }
  if (props.item.reference && (props.item.referenceType === "comment" || props.item.referenceType === "comments")) {
    return "comment"
  }
  if (props.item.reference && props.item.referenceType === "event") {
    return "event"
  }
  if (props.item.reference && props.item.referenceType === "starterpack") {
    return "starter pack"
  }
  return ""
})

async function handleRequest(action: string, id: string) {
  try {
    await API().post(`/requests/${id}`, {
      action
    })
    emit("remove", props.item._id)
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <li class="flex flex-row gap-4 items-center" v-if="!item.system">
    <router-link class="relative" :title="`Go to ${item.fromUserGroup[0].username}'s profile`" :to="`/${item.fromUserGroup[0].username}`">
      <span class="absolute -top-1 -right-1 rounded-full w-2 h-2 bg-red-500" v-if="!item.read"></span>
      <figure class="block w-12 h-12 bg-neutral-50 dark:bg-indigo-925/50 rounded-full bg-cover" :style="item.fromUserGroup[0].pfp ? `background-image: url('${item.fromUserGroup[0].pfp}')` : ''">
        <span class="flex items-center justify-center w-full h-full text-2xl font-semibold text-neutral-600 dark:text-indigo-250" v-if="!item.fromUserGroup[0].pfp">{{ item.fromUserGroup[0].firstLetter }}</span>
      </figure>
    </router-link>
    <div class="flex flex-col" v-if="item.type === 'mention'">
      <template v-if="item.subtype !== 'comment'">
        <div><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> {{ action }} in a <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="item.referenceURL ? `${item.referenceURL}` : `/${item.fromUserGroup[0].username}/${item.referenceType}/${item.referenceId}`">{{ mediaType }}</router-link>.</div>
      </template>
      <template v-if="item.subtype === 'comment'">
        <div><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> {{ action }} in a <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="item.referenceURL ? `${item.referenceURL}` : `/${item.fromUserGroup[0].username}/${item.referenceType}/${item.referenceId}`">comment</router-link>.</div>
      </template>
      <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
    </div>
    <div class="flex flex-col" v-if="item.type === 'starterpack:added'">
      <div><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has {{ action }} <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="item.referenceURL">Starter Pack ({{ item.referenceTitle }})</router-link>.</div>
      <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
    </div>
    <div class="flex flex-col" v-if="item.type === 'starterpack:removed'">
      <div><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has {{ action }} <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="item.referenceURL">Starter Pack ({{ item.referenceTitle }})</router-link>.</div>
      <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
    </div>
    <div class="flex flex-col" v-if="item.type === 'follow'">
      <div><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has {{ action }} you.</div>
      <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
    </div>
    <div class="flex flex-col" v-if="item.type === 'message'">
      <div><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has approved your message request.</div>
      <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
    </div>
    <div class="flex flex-col" v-if="item.type === 'comment'">
      <template v-if="item.referenceType !== 'post' && item.referenceType !== 'event'">
        <div v-if="item.subtype === 'parent'"><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${username}/${item.referenceType}/${item.subReferenceId}#${item.referenceId}`">{{ action }}</router-link> on your <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${username}/${item.referenceType}/${item.subReferenceId}`">{{ item.referenceType }}</router-link>.</div>
        <div v-if="item.subtype === 'thread'"><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.subReferenceAuthor}/${item.referenceType}/${item.subReferenceId}#${item.referenceId}`">replied</router-link> to a comment thread you're participating in or following.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </template>
      <template v-if="item.referenceType === 'post'">
        <div v-if="item.subtype === 'parent'"><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/groups/${item.forPostGroupSlug}/${item.forPostShort}/#${item.referenceId}`">{{ action }}</router-link> on your <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/groups/${item.forPostGroupSlug}/${item.forPostShort}`">group post ({{ item.forPostTitle }})</router-link>.</div>
        <div v-if="item.subtype === 'thread'"><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/groups/${item.forPostGroupSlug}/${item.forPostShort}/#${item.referenceId}`">replied</router-link> to a thread on a <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/groups/${item.forPostGroupSlug}/${item.forPostShort}`">group post ({{ item.forPostTitle }})</router-link> that you're participating in.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </template>
      <template v-if="item.referenceType === 'event'">
        <div v-if="item.subtype === 'parent'"><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`${item.subReferenceURL}#${item.referenceId}`">{{ action }}</router-link> on your <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`${item.subReferenceURL}`">event ({{ item.subReferenceTitle }})</router-link>.</div>
        <div v-if="item.subtype === 'thread'"><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`${item.subReferenceURL}#${item.referenceId}`">replied</router-link> to a thread on your <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`${item.subReferenceURL}`">event ({{ item.subReferenceTitle }})</router-link>.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </template>
    </div>
    <div class="flex justify-between items-center w-full flex-wrap gap-y-1.5 gap-x-4" v-if="item.type === 'request'">
      <div class="flex flex-col" v-if="item.subtype === 'follow'">
        <div><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has {{ action }} to follow you.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
      <div class="flex flex-col" v-if="item.subtype === 'message'">
        <div><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has {{ action }} to message you.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
      <div class="flex flex-col" v-if="item.subtype === 'relationship'">
        <div><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> would like to enter into a relationship with you: {{ item.relationshipName }}.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
      <div class="flex gap-4" v-if="item.subtype !== 'relationship'">
        <button class="bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 inline-flex justify-center py-1 px-6 border-0 text-sm font-bold rounded-md text-black" type="button" @click="handleRequest('accept', item.referenceId)">Accept</button>
        <button class="inline-flex justify-center py-1 px-6 text-sm dark:hover:text-white dark:text-gray-400 text-gray-800" type="button" v-if="!item.ignored" @click="handleRequest('ignore', item.referenceId)">Ignore</button>
      </div>
      <div class="flex gap-4" v-if="item.subtype === 'relationship'">
        <button class="bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 inline-flex justify-center py-1 px-6 border-0 text-sm font-bold rounded-md text-black" type="button" @click="handleRequest('accept', item.referenceId)">Accept</button>
        <button class="inline-flex justify-center py-1 px-6 text-sm dark:hover:text-white dark:text-gray-400 text-gray-800" type="button" @click="handleRequest('reject', item.referenceId)">Reject</button>
      </div>
    </div>
    <div class="flex flex-col" v-if="item.type === 'reaction'">
      <div v-if="item.fromUserGroup.length === 1 && item.fromUserCount === 1">
        <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> has {{ action }} your <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="item.referenceURL ? `${item.referenceURL}` : `/${username}/${item.referenceType}/${item.referenceId}`">{{ mediaType }}<span v-if="item.referenceTitle">&nbsp;({{ item.referenceTitle }})</span></router-link>.
      </div>
      <div v-if="item.fromUserGroup.length === 2 && item.fromUserCount === 2">
        <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> and <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[1]}`">{{ item.fromUserGroup[1] }}</router-link> have {{ action }} your <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="item.referenceURL ? `${item.referenceURL}` : `/${username}/${item.referenceType}/${item.referenceId}`">{{ mediaType }}</router-link>.
      </div>
      <div v-if="item.fromUserGroup.length === 3 && item.fromUserCount === 3">
        <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link>, <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[1]}`">{{ item.fromUserGroup[1] }}</router-link> and <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[2]}`">{{ item.fromUserGroup[2] }}</router-link> have {{ action }} your <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="item.referenceURL ? `${item.referenceURL}` : `/${username}/${item.referenceType}/${item.referenceId}`">{{ mediaType }}</router-link>.
      </div>
      <div v-if="item.fromUserGroup.length === 1 && item.fromUserCount > 1">
        <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link> and {{ item.fromUserCount - 1 }} others have {{ action }} your <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="item.referenceURL ? `${item.referenceURL}` : `/${username}/${item.referenceType}/${item.referenceId}`">{{ mediaType }}</router-link>.
      </div>
      <div v-if="item.fromUserGroup.length === 2 && item.fromUserCount > 2">
        <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link>, <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[1]}`">{{ item.fromUserGroup[1] }}</router-link> and {{ item.fromUserCount - 2 }} others have {{ action }} your <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="item.referenceURL ? `${item.referenceURL}` : `/${username}/${item.referenceType}/${item.referenceId}`">{{ mediaType }}</router-link>.
      </div>
      <div v-if="(item.fromUserGroup.length > 3) && item.fromUserCount > 3">
        <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[0].username}`">{{ item.fromUserGroup[0].username }}</router-link>, <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[1]}`">{{ item.fromUserGroup[1] }}</router-link>, <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/${item.fromUserGroup[2]}`">{{ item.fromUserGroup[2] }}</router-link> and {{ item.fromUserCount - 3 }} others have {{ action }} your <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="item.referenceURL ? `${item.referenceURL}` : `/${username}/${item.referenceType}/${item.referenceId}`">{{ mediaType }}</router-link>.
      </div>
      <div v-if="item.fromUserGroup.length === 0 && item.fromUserCount > 0">
        {{ item.fromUserCount }} humans have {{ action }} your <router-link class="font-semibold dark:hover:text-white" :to="item.referenceURL ? `${item.referenceURL}` : `/${username}/${item.referenceType}/${item.referenceId}`">{{ mediaType }}</router-link>.
      </div>
      <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
    </div>
    <div v-if="item.type === 'reaction' && item.thumbnail">
      <router-link :to="item.referenceURL ? `${item.referenceURL}` : `/${username}/${item.referenceType}/${item.referenceId}`">
        <img width="48" :src="item.thumbnail" />
      </router-link>
    </div>
  </li>
  <li
    :class="[
      item.reason && item.reason.length > 0 ? '' : 'items-center',
      'flex flex-row gap-4'
    ]"
    v-else
  >
    <div class="relative">
      <span class="absolute -top-1 -right-1 rounded-full w-2 h-2 bg-red-500" v-if="!item.read"></span>
      <figure class="flex items-center justify-center w-12 h-12 bg-neutral-50 dark:bg-indigo-925/50 rounded-full bg-cover">
        <svg class="dark:fill-gold-500 fill-gold-700 h-6 w-6 origin-center" width="24" height="28" viewBox="-20 -20 158 183" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z"></path>
          <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z"></path>
          <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z"></path>
        </svg>
      </figure>
    </div>
    <template v-if="item.type === 'event' && item.subtype === 'rsvp:new'">
      <div class="flex flex-col">
        <div>
          Your event,
          <router-link
            class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline"
            :to="`/e/${item.forEventShort}`"
          >
            {{ item.forEventName }}
          </router-link>,
          has a new attendee{{ item.subReferenceUsername ? `: ` : '.' }}
          <template v-if="item.subReferenceUsername">
            <router-link
              class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline"
              :to="`/${item.subReferenceUsername}`"
            >
              {{ item.subReferenceUsername }}
            </router-link>
          </template>
        </div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'req:new'">
      <div class="flex flex-col">
        <div>Your event, <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link>, has received a <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}/requests`">new request to attend</router-link>.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'req:accepted'">
      <div class="flex flex-col">
        <div>Your request to attend <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link> was approved.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'req:declined'">
      <div class="flex flex-col">
        <div>Your request to attend <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link> was declined.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'organizer:added'">
      <div class="flex flex-col">
        <div>You have been added as an organizer for the <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link> event.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'organizer:removed'">
      <div class="flex flex-col">
        <div>You have been removed as an organizer for the <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link> event.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'removed'">
      <div class="flex flex-col">
        <div>Your RSVP or consideration has been removed from the <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link> event.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'banned'">
      <div class="flex flex-col">
        <div>You've been banned from the <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link> event.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'unbanned'">
      <div class="flex flex-col">
        <div>You've been unbanned from the <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link> event.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'update'">
      <div class="flex flex-col">
        <div>Organizers for <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link> have posted an <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}/updates`">update</router-link>.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'cancelled'">
      <div class="flex flex-col">
        <div><router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link> has been cancelled.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'event' && item.subtype === 'deleted'">
      <div class="flex flex-col">
        <div>The <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/e/${item.forEventShort}`">{{ item.forEventName }}</router-link> event has been deleted.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'message:new'">
      <div class="flex flex-col">
        <div>A user has sent a new message to <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link>'s moderation team.</div>
        <div class="text-sm dark:text-gray-400 text-gray-600 mb-1"><router-link class="hover:underline" :to="`/groups/${item.forGroupSlug}/moderation/messages/${item.referenceId}`">Click here to view the message</router-link></div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'message:reply'">
      <div class="flex flex-col">
        <div>A user has sent a reply to a message to <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link>'s moderation team.</div>
        <div class="text-sm dark:text-gray-400 text-gray-600 mb-1"><router-link class="hover:underline" :to="`/groups/${item.forGroupSlug}/moderation/messages/${item.referenceId}`">Click here to view the message</router-link></div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'mq:requested'">
      <div class="flex flex-col">
        <div>A user has requested to join <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link></div>
        <div class="text-sm dark:text-gray-400 text-gray-600 mb-1"><router-link class="hover:underline" :to="`/groups/${item.forGroupSlug}/moderation/memberqueue`">Click here to view the group's membership queue</router-link></div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'mq:approved'">
      <div class="flex flex-col">
        <div>Your request to join <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link> was approved.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'mq:rejected'">
      <div class="flex flex-col">
        <div>Your request to join <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link> was rejected.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'mod:warning'">
      <div class="flex flex-col">
        <div>You have received a warning in <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link>.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'mod:timeout'">
      <div class="flex flex-col">
        <div>You have been timed out in <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link>.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'mod:kick'">
      <div class="flex flex-col">
        <div>You have been kicked from <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link>.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'mod:ban'">
      <div class="flex flex-col">
        <div>You have been banned from <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link>.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'mod:added'">
      <div class="flex flex-col">
        <div>You have been added as a moderator in the <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link> group.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'mod:removed'">
      <div class="flex flex-col">
        <div>You have been removed as a moderator in the <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link> group.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && (item.subtype === 'mod:deleted' || item.subtype === 'moderation:deleted')">
      <div class="flex flex-col">
        <div>Your post, {{ item.forPostTitle }}, in <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link>, has been removed by group moderation.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Reason: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'report:filed'">
      <div class="flex flex-col">
        <div>A user has filed a new report in <router-link :to="`/groups/${item.forGroupSlug}`" class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline">{{ item.forGroupName }}</router-link></div>
        <div class="text-sm dark:text-gray-400 text-gray-600 mb-1"><router-link class="hover:underline" :to="`/groups/${item.forGroupSlug}/moderation/reports`">Click here to view the group's moderation reports</router-link></div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'group' && item.subtype === 'report:action'">
      <div class="flex flex-col">
        <div>Your <strong>report</strong> in <router-link class="font-semibold dark:hover:text-white dark:hover:no-underline hover:underline" :to="`/groups/${item.forGroupSlug}`">{{ item.forGroupName }}</router-link> has been actioned upon.</div>
        <div v-if="item.reason && item.reason.length > 0" class="text-sm dark:text-gray-400 text-gray-600 mb-1">Response: {{ item.reason }}</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'reservation' && item.subtype === 'group:approved'">
      <div class="flex flex-col">
        <div>Your group reservation, <strong>{{ item.forGroupName }}</strong>, has been approved.</div>
        <div class="text-sm dark:text-gray-400 text-gray-600 mb-1"><router-link class="hover:underline" to="/groups/create">Click here to get started creating your new group</router-link></div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
    <template v-if="item.type === 'reservation' && item.subtype === 'group:rejected'">
      <div class="flex flex-col">
        <div>Your group reservation, <strong>{{ item.forGroupName }}</strong>, has been rejected.</div>
        <time class="text-xs" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).fromNow() }}</time>
      </div>
    </template>
  </li>
</template>

<script setup lang="ts">
import { ref, watch, provide, onUnmounted, reactive } from "vue"
import type { Ref, WatchStopHandle } from "vue"
import { ZodObject, ZodEffects } from "zod"

export interface Field {
  value: Ref<any>
  type: string
  raw?: Ref<string> | undefined // only for the editor
  watcher: WatchStopHandle
  persist: boolean
  persistedValue?: any
  initialValue?: any
  touched: boolean
  dirty: boolean
  locked: boolean
  step?: number
}

interface InitialValues {
  [key: string]: any
}

export interface FormContext {
  registerField: (name: string, type: string, value: Ref<any>, persist: boolean, locked?: boolean, step?: number, raw?: Ref<string>, headless?: boolean) => void
  unregisterField: (name: string) => void
  focusField: (name: string) => void
  blurField: (name: string) => void
  fields: Ref<Map<string, Field>>
  errors: Ref<Record<string, string | null>>
  steps: number
  currentStep: Ref<number>
}

const fields = ref(new Map<string, Field>()) as Ref<Map<string, Field>>
const errors = ref<Record<string, string | null>>({})
const currentStep = ref<number>(1)
const activeField = ref<string | null>(null)

interface Props {
  initialValues?: InitialValues
  schema: ZodObject<any> | ZodEffects<ZodObject<any>>
  steps?: number,
  customValidation?: (
    fields: Map<string, Field>,
    errors: Record<string, string | null>,
    validateOnSubmit: boolean
  ) => void
}

const props = withDefaults(defineProps<Props>(), {
  steps: 1
})

const emit = defineEmits(['onSubmit', 'step-changed'])

// Register a field with the form, using initial values from props if provided
const registerField = (name: string, type: string, value: Ref<any>, persist: boolean = false, locked: boolean = false, step: number = 1, raw: Ref<string> | undefined = undefined, headless: boolean = false) => {

  let initialValue: any = undefined
  let persistValue: any = undefined

  if (!fields.value.has(name)) {
    if (props.initialValues && props.initialValues[name] !== undefined) {
      // Check if the initial value is an object
      if (typeof props.initialValues[name] === 'object' && props.initialValues[name] !== null) {
        // For an array
        if (Array.isArray(props.initialValues[name])) {
          value.value = [...props.initialValues[name]]
          initialValue = [...props.initialValues[name]]
          if (locked && persist) {
            persistValue = [...props.initialValues[name]]
          }
        } else { // For an object
          value.value = { ...props.initialValues[name] }
          initialValue = { ...props.initialValues[name] }
          if (locked && persist) {
            persistValue = { ...props.initialValues[name] }
          }
        }
      } else { // For primitive types
        value.value = props.initialValues[name]
        initialValue = props.initialValues[name]
        if (locked && persist) {
          persistValue = props.initialValues[name]
        }
      }
    }

    const watcher = watch(value, (newValue) => {
      if (name === activeField.value || headless) {
        if (raw) {
          validateField(name, raw.value, type)
        } else {
          validateField(name, newValue, type)
        }
        if (persist) {
          fields.value.get(name)!.persistedValue = newValue
        }
        if (headless) {
          fields.value.get(name)!.touched = true
        }
        if (initialValue === newValue) {
          fields.value.get(name)!.dirty = false
        } else {
          fields.value.get(name)!.dirty = true
        }
      }
    })

    fields.value.set(name, { value: value, type: type, watcher, persist: persist, raw, initialValue, touched: false, dirty: false, locked, step })
    if (persist && persistValue !== undefined) {
      fields.value.get(name)!.persistedValue = persistValue
    }
  } else {
    const field = fields.value.get(name)
    if (field && field.persist) {
      initialValue = field.initialValue
      const watcher = watch(value, (newValue) => {
        if (name === activeField.value || headless) {
          if (raw) {
            validateField(name, raw.value, field.type)
          } else {
            validateField(name, newValue, field.type)
          }
          if (persist) {
            fields.value.get(name)!.persistedValue = newValue
          }
          if (headless) {
            fields.value.get(name)!.touched = true
          }
          if (initialValue === newValue) {
            fields.value.get(name)!.dirty = false
          } else {
            fields.value.get(name)!.dirty = true
          }
        }
      })
      field.watcher()
      field.watcher = watcher
      value.value = field.persistedValue || field.initialValue
      field.value = value
    }
  }
}

const unregisterField = (name: string) => {
  const field = fields.value.get(name)
  if (field) {
    field.watcher()
    fields.value.delete(name)
  }
}

// Type guard to check if schema is ZodObject
function isZodObject(schema: any): schema is ZodObject<any> {
  return schema instanceof ZodObject
}

const validateField = (name: string, value: any, type: string) => {
  // check if a string, if a string, check if a valid date
  if (typeof value === 'string' && type === 'date') {
    const date = new Date(value)
    if (!isNaN(date.getTime())) {
      value = date
    }
  }

  let fieldSchema
  if (isZodObject(props.schema)) {
    fieldSchema = props.schema.pick({ [name]: true })
  } else {
    fieldSchema = (props.schema as ZodEffects<ZodObject<any>>)._def.schema.pick({ [name]: true })
  }

  const result = fieldSchema.safeParse({ [name]: value })
  if (!result.success) {
    errors.value[name] = result.error.issues[0].message
  } else {
    if (name === 'endDateTime') {
      const durationFrequency = fields.value.get('durationFrequency') as any
      const startDate = fields.value.get('startDateTime')
      if (startDate) {
        const startTime = new Date(startDate.value as any).getTime()
        const endTime = new Date(value).getTime()
        if (durationFrequency && durationFrequency.value === 'one-time') {
          // check to make sure the end date is no more than 1 day after the start date
          if (endTime - startTime > 93600000) {
            errors.value[name] = "End date must be no more than 1 day after the start date."
          } else {
            errors.value[name] = null
          }
        } else if (endTime - startTime > 1036800000) {
          errors.value[name] = "End date must be less than 12 days after the start date."
        } else if (endTime < startTime) {
          errors.value[name] = "End date must be after the start date."
        } else {
          errors.value[name] = null
        }
      }
    } else {
      errors.value[name] = null
    }
  }
  return result.success
}

const focusField = (name: string) => {
  activeField.value = name
  const field = fields.value.get(name)
  if (field) {
    field.touched = true
  }
}

const blurField = (name: string) => {
  activeField.value = null
  const field = fields.value.get(name)
  if (field) {
    if (field.hasOwnProperty('raw') && field.raw !== undefined) {
      validateField(name, field.raw, field.type)
    } else {
      if (field.persist) {
        field.persistedValue = field.value
      }
      validateField(name, field.value, field.type)
    }
  }
}

function validateStep() {
  const stepFields = Array.from(fields.value.entries()).filter(([_, field]) => field.step === currentStep.value)
  let pickedSchema
  if (isZodObject(props.schema)) {
    pickedSchema = props.schema.pick(Object.fromEntries(stepFields.map(([name, _]) => [name, true])))
  } else {
    pickedSchema = (props.schema as ZodEffects<ZodObject<any>>)._def.schema.pick(Object.fromEntries(stepFields.map(([name, _]) => [name, true])))
  }

  const stepData = Object.fromEntries(
    stepFields.map(([name, field]) => {
      let valueToValidate: any = field.raw !== undefined ? field.raw : field.value
      // check if a string, if a string, check if a valid date
      if (typeof valueToValidate === 'string' && field.type === 'date') {
        const date = new Date(valueToValidate)
        if (!isNaN(date.getTime())) {
          valueToValidate = date
        }
      }
      return [name, valueToValidate]
    })
  )

  const result = pickedSchema.safeParse(stepData)
  if (!result.success) {
    for (const issue of result.error.issues) {
      if (typeof issue.path[0] === 'string') {
        errors.value[issue.path[0]] = issue.message
      }
    }
    return false
  } else {
    // check if stepFields has a field with name 'endDateTime'
    const durationFrequency = stepFields.find(([name, _]) => name === 'durationFrequency') as any
    const endDateTimeField = stepFields.find(([name, _]) => name === 'endDateTime')
    if (endDateTimeField) {
      const startDateTimeField = stepFields.find(([name, _]) => name === 'startDateTime')
      if (startDateTimeField) {
        const startTime = new Date(startDateTimeField[1].value as any).getTime()
        const endTime = new Date(endDateTimeField[1].value as any).getTime()
        if (durationFrequency && durationFrequency[1].value === 'one-time') {
          // check to make sure the end date is no more than 1 day after the start date
          if (endTime - startTime > 93600000) {
            errors.value['endDateTime'] = "End date must be no more than 1 day after the start date."
            return false
          }
        }
        if (endTime - startTime > 1036800000) {
          errors.value['endDateTime'] = "End date must be less than 12 days after the start date."
          return false
        }
        if (endTime < startTime) {
          errors.value['endDateTime'] = "End date must be after the start date."
          return false
        }
        errors.value['endDateTime'] = null
        return true
      }
    }
  }
  return true
}

const validateForm = () => {
  const formData = Object.fromEntries(
    Array.from(fields.value.entries()).map(([name, field]) => {
      let valueToValidate: any = field.hasOwnProperty('raw') && field.raw !== undefined ? field.raw : field.value
      // check if a string, if a string, check if a valid date
      if (typeof valueToValidate === 'string' && field.type === 'date') {
        const date = new Date(valueToValidate);
        if (!isNaN(date.getTime())) {
          valueToValidate = date;
        }
      }
      return [name, valueToValidate]
    })
  )

  const result = props.schema.safeParse(formData);
  if (!result.success) {
    for (const issue of result.error.issues) {
      // Ensure the error path is a string for key access
      if (typeof issue.path[0] === 'string') {
        errors.value[issue.path[0]] = issue.message
      }
    }
    return false
  } else {
    // check if formData has a field with name 'endDateTime'
    if (formData.endDateTime) {
      const durationFrequency = fields.value.get('durationFrequency') as any
      const startDateTimeField = fields.value.get('startDateTime')
      if (startDateTimeField) {
        const startTime = new Date(startDateTimeField.value as any).getTime()
        const endTime = new Date(formData.endDateTime).getTime()
        if (endTime < startTime) {
          errors.value['endDateTime'] = "End date must be after the start date."
          return false
        }
        if (durationFrequency && durationFrequency.value === 'one-time') {
          if (endTime - startTime > 93600000) {
            errors.value['endDateTime'] = "End date must be no more than 1 day after the start date."
            return false
          }
        }
        if (durationFrequency && durationFrequency.value !== 'one-time' && endTime - startTime > 1036800000) {
          errors.value['endDateTime'] = "End date must be less than 12 days after the start date."
          return false
        }
        errors.value['endDateTime'] = null
        return true
      }
    }
  }
  return true
}

function getFieldValue(name: string) {
  const field = fields.value.get(name)
  return field ? field.value : null
}

function resetFields() {
  for (const [name, field] of fields.value.entries()) {
    field.value = props.initialValues ? props.initialValues[name] : ''
  }
}

function clearField(name: string) {
  const field = fields.value.get(name)
  if (field) {
    field.value.value = ''
  }
}

const stepData = reactive({
  steps: props.steps || 0,
  currentStep,
})

const previousStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--
  }
}

// watch the currentStep and emit a custom event when it changes
watch(currentStep, (newVal) => {
  emit('step-changed', newVal)
})

watch(
  () => fields.value,
  (newFields) => {
    if (props.customValidation) {
      props.customValidation(newFields, errors.value, false)
    }
  },
  { deep: true }
)

const tools = {
  validateForm,
  validateField,
  getFieldValue,
  resetFields,
  clearField,
  previousStep,
  unregisterField
}

onUnmounted(() => {
  for (const { watcher } of fields.value.values()) {
    watcher()
  }
  resetFields()
})

const handleSubmit = () => {
  if (props.steps > 1 && currentStep.value < props.steps) {
    if (!validateStep()) {
      console.error("Step validation failed", errors.value)
      return
    }
    currentStep.value++
    return
  } else if (props.steps > 1 && currentStep.value === props.steps) {
    if (!validateStep()) {
      console.error("Step validation failed", errors.value)
      return
    }
    emit('onSubmit', Object.fromEntries(Array.from(fields.value.entries()).map(([name, { value }]) => [name, value])), resetFields)
  } else {
    if (props.customValidation) {
      props.customValidation(fields.value, errors.value, true)
    }
    if (validateForm()) {
      emit('onSubmit', Object.fromEntries(Array.from(fields.value.entries()).map(([name, { value }]) => [name, value])), resetFields)
    } else {
      console.error("Form validation failed", errors.value)
    }
  }
}

const formContext: FormContext = {
  registerField,
  unregisterField,
  focusField,
  blurField,
  fields,
  errors,
  steps: props.steps,
  currentStep
}

provide('formContext', formContext)
</script>

<template>
  <form @submit.prevent="handleSubmit" @keydown.enter.meta.prevent="handleSubmit">
    <slot :fields="fields" :errors="errors" :steps="stepData" :tools="tools"></slot>
  </form>
</template>

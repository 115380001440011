<script setup lang="ts">
</script>

<template>
  <svg class="dark:fill-gold-500 fill-gold-700 h-12 w-12" width="24" height="28" viewBox="-20 -20 158 183" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="scale-center" d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z">
      <animateTransform
      attributeName="transform"
      type="scale"
      values="1;0.8;1"
      dur=".6s"
      repeatCount="indefinite"/>
    </path>
    <path class="scale-center" d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z">
      <animateTransform
      attributeName="transform"
      type="scale"
      values="1;0.8;1"
      dur=".6s"
      repeatCount="indefinite"/>
    </path>
    <path class="scale-center" d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z">
      <animateTransform
      attributeName="transform"
      type="scale"
      values="1;0.8;1"
      dur=".6s"
      repeatCount="indefinite"/>
    </path>
  </svg>
</template>

<script setup lang="ts">
import InboxItem from "@/components/messages/InboxItem.vue"
import { ref, onMounted, inject, type Ref } from "vue"
import setupCommons from "@/composables/Common"
import API from "@/api/api"

const { actionHandler, scrollToTop } = setupCommons()

interface Props {
  context: string
}

const groupSlug = inject("slug") as Ref<string>
const groupId = inject("groupId") as Ref<string>

const props = withDefaults(defineProps<Props>(), {
  context: "inbox"
})

const initialLoadComplete = ref(false)
const messages = ref<any[]>([])
const working = ref(false)
const error = ref(false)
const pageInfo = ref<any>({
  endCursor: undefined
})
const markAllLabel = ref("Mark All as Read")

onMounted(async () => {
  await getThreads()
  initialLoadComplete.value = true
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
})

async function markAllAsRead() {
  if (working.value) return
  markAllLabel.value = "Marking..."
  working.value = true

  let path = `/groups/g/${groupSlug.value}/inbox/read`

  try {
    await API().get(path)
    // iterate through messages and mark them as read
    messages.value.forEach((message) => {
      message.toRead = true
      message.fromRead = true
    })
  } catch (err) {
    error.value = true
  } finally {
    markAllLabel.value = "Mark All as Read"
    working.value = false
  }
}

async function getThreads() {
  if (working.value) return
  working.value = true

  let path = `/groups/g/${groupSlug.value}/inbox`
  let params: any = {
    context: props.context
  }
  if (pageInfo.value.endCursor) {
    params = {
      ...params,
      cursor: pageInfo.value.endCursor
    }
  }

  path = `${path}?${new URLSearchParams(params)}`

  try {
    const response = await API().get(path)
    let data = response.data
    messages.value.push(...data.data)
    pageInfo.value = data.pageInfo
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    working.value = false
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getThreads()
      }
    }
  })
}, options)
</script>

<template>
  <div>
    <main>
      <ul class="text-sm flex justify-end gap-4 mb-2">
        <li>
          <router-link :class="`hover:underline ${context === 'sent' ? 'text-gold-700 dark:text-gold-500' : ''}`" :to="`/groups/${groupSlug}/moderation/messages/sent`">Sent</router-link>
        </li>
        <li>
          <router-link :class="`hover:underline ${context === 'archives' ? 'text-gold-700 dark:text-gold-500' : ''}`" :to="`/groups/${groupSlug}/moderation/messages/archives`">Archives</router-link>
        </li>
        <li class="hover:underline cursor-pointer" @click="markAllAsRead" v-if="initialLoadComplete && messages.length > 0 && (context === 'inbox' || context === 'sent')">{{ markAllLabel }}</li>
      </ul>
      <ul @click="actionHandler" class="ItemList grid grid-cols-1 gap-3" v-if="initialLoadComplete && messages.length > 0">
        <InboxItem v-for="message in messages" :thread="message" :key="message._id" :group="groupId" />
      </ul>
      <p v-if="initialLoadComplete && messages.length === 0" class="text-sm">No messages.</p>
      <p v-if="!initialLoadComplete" class="text-sm">Loading...</p>
    </main>
    <div id="loadMore"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, onBeforeMount, type Ref } from "vue"
import SelfSubmit from "@/components/input/SelfSubmit.vue"
import Label from "@/components/input/Label.vue"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
  Combobox,
	ComboboxInput,
	ComboboxOptions,
	ComboboxOption,
} from "@headlessui/vue"

const Group = inject("Group") as Ref<any>

const baseRoute = `/groups/g/${Group.value._id}`

const ageOptions = [
  {text: "No Minimum", value: "aa:0"},
  {text: "6 Hours", value: "aa:6h"},
  {text: "1 Day", value: "aa:1d"},
  {text: "1 Week", value: "aa:1w"},
  {text: "1 Month", value: "aa:1m"},
  {text: "3 Months", value: "aa:3m"},
  {text: "6 Months", value: "aa:6m"},
  {text: "1 Year", value: "aa:1y"},
]

const postOptions = [
  {text: "No Limit", value: "pf:0"},
  {text: "1 Hour", value: "pf:1h"},
  {text: "3 Hours", value: "pf:3h"},
  {text: "6 Hours", value: "pf:6h"},
  {text: "12 Hours", value: "pf:12h"},
  {text: "1 Day", value: "pf:1d"},
  {text: "3 Days", value: "pf:3d"},
  {text: "9 Days", value: "pf:9d"}
]

const personalsOptions = [
  {text: "1 Day", value: "pf:0"},
  {text: "3 Days", value: "pf:3d"},
  {text: "6 Days", value: "pf:6d"},
  {text: "9 Days", value: "pf:9d"},
  {text: "12 Days", value: "pf:12d"},
  {text: "15 Days", value: "pf:15d"},
  {text: "30 Days", value: "pf:30d"}
]

const attachmentOptions = [
  { text: "Enabled", value: "true" },
  { text: "Disabled", value: "false" }
]

const accountAgeRequirement = ref("aa:0")
const postFrequencyRequirement = ref("pf:0")
const personalFrequencyRequirement = ref("pf:6d")
const attachmentsEnabled = ref("true")
const moderatorList = ref(<any>[])
const modalOpen = ref(false)
const error = ref(false)
const working = ref(false)
const members = ref(<any>[])
const member = ref(<any>{})

onBeforeMount(() => {
  setupInitialValues(Group.value)
})

function setupInitialValues(group: any) {
  if (group.moderation.accountAgeRequirement) {
    accountAgeRequirement.value = group.moderation.accountAgeRequirement
  }

  if (group.moderation.postFrequencyRequirement) {
    postFrequencyRequirement.value = group.moderation.postFrequencyRequirement
  }

  if (group.moderation.personalFrequencyRequirement) {
    personalFrequencyRequirement.value = group.moderation.personalFrequencyRequirement
  }

  if (group.moderation.hasOwnProperty('attachmentsEnabled')) {
    attachmentsEnabled.value = group.moderation.attachmentsEnabled ? "true" : "false"
  }

  if (group.moderators) {
    moderatorList.value = group.moderators
  }
}

function updateGroupData() {
  Group.value.moderation = {
    accountAgeRequirement: accountAgeRequirement.value,
    postFrequencyRequirement: postFrequencyRequirement.value,
    personalFrequencyRequirement: personalFrequencyRequirement.value,
    attachmentsEnabled: attachmentsEnabled.value === "true"
  }
}

function toggleModal() {
  error.value = false
  if (modalOpen.value) {
    members.value = []
    member.value = {}
  }
  modalOpen.value = !modalOpen.value
}

async function addModerator() {
  if (working.value) return
  working.value = true

  try {
    await API().post(`${baseRoute}/moderators`, {
      user: member.value._id
    })
    working.value = false
    moderatorList.value.push({
      _id: member.value.user,
      username: member.value.username,
      createdAt: new Date()
    })
    toggleModal()
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
  }
}

async function removeModerator(id: string) {
  if (working.value) return
  working.value = true

  try {
    await API().delete(`${baseRoute}/moderators`, {
      data: { user: id }
    })
    working.value = false
    moderatorList.value = moderatorList.value.filter((m: any) => m._id !== id)
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
  }
}

async function memberSearch(search: string) {
  try {
    if(search.length > 2) {
      const response = await API().post(`${baseRoute}/members/lookup`, {
        username: search
      })

      members.value = []
      return members.value = response.data.data
    }
    return members.value = []
  } catch (err) {
    if (err) console.error(err)
  }
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6" v-if="Group.isOwner">
      <section class="space-y-6">
        <h3 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Moderators</h3>
        <p>Add or remove moderators for the group.</p>
      </section>
      <section class="space-y-6">
        <ul>
          <li
            class="text-sm"
            v-if="moderatorList.length > 0"
            v-for="moderator in moderatorList"
          >
            {{ moderator.username }} (<span class="cursor-pointer hover:underline" @click="removeModerator(moderator._id)">Remove</span>)
          </li>
          <li class="text-sm" v-if="moderatorList.length === 0">No moderators found.</li>
          <li class="text-sm mt-6">
            <button @click="toggleModal()" class="underline">Add a Moderator</button>
          </li>
        </ul>
        <TransitionRoot appear :show="modalOpen" as="template">
          <Dialog as="div" @close="toggleModal()" class="relative z-[100]">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>
            <div class="fixed inset-0 overflow-y-auto">
              <div
                class="flex min-h-full items-center justify-center p-4 text-center"
              >
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0 scale-95"
                  enter-to="opacity-100 scale-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100 scale-100"
                  leave-to="opacity-0 scale-95"
                >
                  <DialogPanel
                    class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                  >
                    <DialogTitle
                      as="h3"
                      class="text-xl font-bold leading-6 text-black dark:text-white mb-4"
                    >
                      Add a Moderator
                    </DialogTitle>
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">Search for a group member you'd like to promote to moderator.</p>
                      <div>
                        <Label field="reason">Member</Label>
                        <Combobox v-model="member">
                          <div class="relative">
                            <ComboboxInput
                              placeholder="Search for a group member"
                              @change="memberSearch($event.target.value)"
                              :displayValue="() => (member.username ? member.username : '')"
                              class="w-full py-4 px-4 rounded-md border dark:border-transparent border-gray-700 bg-white dark:bg-submit-600 dark:text-gray-300 ring-0 focus:ring-0"
                            />
                            <TransitionRoot
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <ComboboxOptions class="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div
                                  v-if="members.length === 0"
                                  class="relative cursor-default select-none py-2 px-4"
                                >
                                  Nothing found.
                                </div>
                                <ComboboxOption
                                  v-for="member in members"
                                  :value="member"
                                  v-slot="{ selected, active }"
                                  as="template"
                                >
                                  <li class="relative cursor-default select-none py-2 px-4"
                                  :class="{
                                    'dark:bg-gold-500 bg-gold-700 text-black': active,
                                    'dark:text-gray-300': !active,
                                  }">
                                    {{ member.username }}
                                  </li>
                                </ComboboxOption>
                              </ComboboxOptions>
                            </TransitionRoot>
                          </div>
                        </Combobox>
                      </div>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleModal()"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                          @click="addModerator"
                        >
                          {{ working ? 'Working...' : 'Promote' }}
                        </button>
                      </div>
                      <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                        <p>There was an error promoting this member, please try again.</p>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </section>
    </section>
    <section class="space-y-6">

      <section class="space-y-6">
        <h3 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Membership</h3>
        <p>Moderation controls for users that wish to join the group.</p>
      </section>

      <div>
        <Label field="moderation.accountAgeRequirement">Account Age</label>
        <p class="text-sm text-gray-500 dark:text-gray-400 mb-4">Set the minimum amount of time a user account must have existed on Submit before being able to join the group.</p>
        <SelfSubmit @update="updateGroupData" name="moderation.accountAgeRequirement" v-model="accountAgeRequirement" :apiEndpoint="baseRoute" :options="ageOptions" method="PUT" help-text="Anything other than No Minimum will disqualify this group from being included in Starter Packs." />
      </div>

      </section>
      <section class="space-y-6">

        <section class="space-y-6">
          <h3 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Posting</h3>
          <p>Moderation controls for posts.</p>
        </section>

        <div>
          <Label field="moderation.postFrequencyRequirement">Post Frequency</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-4">Set the time between posts for all group members.</p>
          <SelfSubmit @update="updateGroupData" name="moderation.postFrequencyRequirement" v-model="postFrequencyRequirement" :apiEndpoint="baseRoute" :options="postOptions" method="PUT" />
        </div>

        <div>
          <Label field="moderation.personalFrequencyRequirement">Personal Frequency</label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-4">Set the time between personals for all group members. By default this is set to 6 days.</p>
          <SelfSubmit @update="updateGroupData" name="moderation.personalFrequencyRequirement" v-model="personalFrequencyRequirement" :apiEndpoint="baseRoute" :options="personalsOptions" method="PUT" />
        </div>

        <div>
          <Label field="moderation.attachmentsEnabled">Media Attachments</label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-4">Allow group members to attach media to their posts. By default this is enabled.</p>
          <SelfSubmit @update="updateGroupData" name="moderation.attachmentsEnabled" v-model="attachmentsEnabled" :apiEndpoint="baseRoute" :options="attachmentOptions" method="PUT" />
        </div>

      </section>
  </main>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, watchEffect } from "vue"
import { usePlatformStore } from "@/stores/Platform"
import { useRoute, useRouter } from "vue-router"
import { storeToRefs } from "pinia"

const Platform = usePlatformStore()
const { categoryOverride, subSectionOverride } = storeToRefs(Platform)

interface Props {
  navItems: any[]
  slice?: number
}

const props = withDefaults(defineProps<Props>(), {
  slice: 2
})

const route = useRoute()
const router = useRouter()

const category = ref("")
const subsection = ref("")
const activeItem = ref<any>(undefined)

function updateRouteInfo() {
  const [categoryValue, subsectionValue] = route.path.split("/").slice(props.slice)
  category.value = categoryValue
  subsection.value = subsectionValue

  const findItem = (predicate: any) => props.navItems.find(predicate)
  const findSubsection = (item: any) => item.subsections.find((sub: any) => sub.subsection === subsection.value)

  let active = findItem((item: any) => item.category === category.value && item.subsections) && findSubsection(findItem((item: any) => item.category === category.value))
  active ||= findItem((item: any) => item.to === route.path)

  if (active) {
    activeItem.value = active
    category.value = active.category || category.value
  } else {
    // check how many items are in the path
    const pathItems = route.path.split("/").slice(props.slice)
    if (pathItems.length === 1) {
      active = findItem((item: any) => item.category === pathItems[0])
      activeItem.value = active
    } else if (pathItems.length === 2) {
      active = findItem((item: any) => item.category === pathItems[0] && item.subsections)
      activeItem.value = active
      if (active) category.value = active.category || category.value
    }
  }

  category.value = categoryOverride.value || category.value
  subsection.value = subSectionOverride.value || subsection.value

  if (categoryOverride.value) {
    const active = findItem((item: any) => item.category === categoryOverride.value)
    activeItem.value = active
  }
}

onBeforeMount(updateRouteInfo)
watchEffect(updateRouteInfo)

function handleSelectChange(e: any) {
  const to = e.target.value
  router.push(to)
}
</script>

<template>
<nav>
  <select @change="handleSelectChange" :value="activeItem ? activeItem.to : ''" class="block md:hidden bg-white dark:bg-submit-900 w-full rounded-md text-sm outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30">
    <template v-for="item in navItems" :key="item.category">
      <optgroup :label="item.name" v-if="item.subsections.length > 0">
        <option v-for="subitem in item.subsections" :key="subitem.subsection" :value="subitem.to">
          {{ subitem.name }}
        </option>
      </optgroup>
      <option :value="item.to" v-else>
        {{ item.name }}
      </option>
    </template>
  </select>
  <ul class="hidden md:block space-y-6 text-sm dark:text-gray-400 text-gray-500">
    <li class="flex" v-for="item in navItems" :key="item.category">
      <template v-if="item.subsections.length > 0">
        <details class="flex flex-col w-full group" :open="category === item.category">
          <summary class="flex justify-between items-center hover:cursor-pointer">
            <div class="flex items-center gap-3">
              <figure
                :class="[
                  category === item.category ? 'fill-gold-700 dark:fill-gold-500' : '*:fill-gray-500 *:dark:fill-gray-400',
                  'flex flex-none w-4 h-4 justify-center items-center *:group-hover:fill-black *:dark:group-hover:fill-white'
                ]"
                v-html="item.icon"
              />
              <span :class="`${category === item.category ? 'dark:text-white text-black' : ''} dark:group-hover:text-white group-hover:text-black`">{{ item.name }}</span>
            </div>
            <div>
              <svg class="fill-gray-500 dark:fill-gray-400 ml-1 h-3 group-open:rotate-180 transition-all" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
            </div>
          </summary>
          <ul class="mt-3 ml-7 space-y-2">
            <li v-for="subitem in item.subsections" :key="subitem.subsection">
              <router-link :to="subitem.to" :class="`hover:underline ${subsection === subitem.subsection ? 'dark:text-gold-500 text-gold-700' : ''}`">
                {{ subitem.name }}
              </router-link>
            </li>
          </ul>
        </details>
      </template>
      <template v-if="item.subsections.length === 0">
        <li class="flex">
          <router-link :to="item.to as any" class="flex items-center gap-3 group">
            <figure
              :class="[
                category === item.category ? 'fill-gold-700 dark:fill-gold-500' : 'fill-gray-500 dark:fill-gray-400',
                'flex flex-none w-4 h-4 justify-center items-center *:group-hover:fill-black *:dark:group-hover:fill-white'
              ]"
              v-html="item.icon"
            />
            <span :class="`${category === item.category ? 'dark:text-white text-black' : ''} dark:group-hover:text-white group-hover:text-black`">{{ item.name }}</span>
          </router-link>
        </li>
      </template>
    </li>
  </ul>
</nav>
</template>

<script setup lang="ts">
import type { FormContext } from "@/components/input/Form.vue"
import { inject, ref, watchEffect, onBeforeUnmount } from "vue"
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  RadioGroupDescription
} from "@headlessui/vue"

interface Props {
  name: string
  options: any[]
  helpText?: string
  persist?: boolean
  locked?: boolean
  step?: number
  alt?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  persist: false,
  locked: false,
  step: 1,
  alt: false
})

const formContext = inject<FormContext>('formContext');
if (!formContext) {
  throw new Error(`Radio Group: ${props.name} must be used within a Form component.`)
}

const { registerField, focusField, blurField, unregisterField, errors } = formContext
const value = ref<string>("")

registerField(props.name, "radio", value, props.persist, props.locked, props.step, undefined, true)

onBeforeUnmount(() => {
  if (props.persist) return
  unregisterField(props.name)
})

const handleFocus = () => focusField(props.name)
const handleBlur = () => blurField(props.name)

const error = ref<string | null>(null)

watchEffect(() => {
  error.value = errors.value[props.name]
})
</script>

<template>
  <div>
    <RadioGroup :name="name" v-model="value" @on-click="handleFocus" @on-focus="handleFocus" @on-blur="handleBlur" class="space-y-2">
      <RadioGroupOption
        as="template"
        v-slot="{ checked }"
        v-for="option in options"
        :value="option.value"
        :key="option.value"
      >
        <div class="relative flex cursor-pointer rounded-lg p-4 focus:outline-none" :class="alt ? 'dark:bg-submit-950 bg-neutral-125' : 'bg-white dark:bg-submit-900'">
          <div class="flex w-full items-center">
            <div class="shrink-0">
              <svg class="h-6 w-6 mr-6" viewBox="0 0 24 24" fill="none">
                <circle
                  class="fill-white dark:fill-submit-900"
                  cx="12"
                  cy="12"
                  r="12"
                  fill-opacity="1"
                />
                <path
                  v-show="checked"
                  d="M7 13l3 3 7-7"
                  class="stroke-black dark:stroke-white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="flex items-center">
              <div class="text-sm">
                <RadioGroupLabel
                  as="h3"
                  :class="checked ? 'text-gold-700 dark:text-gold-500' : ''"
                  class="font-semibold"
                >
                  {{ option.label }}
                </RadioGroupLabel>
                <RadioGroupDescription
                  as="p"
                >
                  {{ option.description }}
                </RadioGroupDescription>
              </div>
            </div>
          </div>
        </div>
      </RadioGroupOption>
    </RadioGroup>
    <p v-if="helpText" class="mt-2 text-xs text-gray-500 dark:text-gray-400">{{ helpText }}</p>
    <small class="mt-1 block text-red-600 font-semibold" v-if="error">{{ error }}</small>
  </div>
</template>

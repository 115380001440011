<script setup lang="ts">
import { onMounted, onUnmounted, inject, ref, nextTick, watch, computed } from "vue"
import NewCollection from "@/components/collections/New.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"

import API from "@/api/api"

interface Props {
  collection: string
}

const Router = useRouter()
const Platform = usePlatformStore()
const User = useUserStore()
const { id } = storeToRefs(User)

const props = defineProps<Props>()

const profileData: any = inject("profileData")

const initialLoadComplete = ref(false)
const working = ref(false)
const totalItems = ref(0)
const collectionData = ref<any>({})

const writingsList = ref<any[]>([])
const pageInfo = ref<any>({
  endCursor: undefined
})

const isOwner = computed(() => collectionData.value.user === id.value)

onMounted(async () => {
  await fetchCollection()
  initialLoadComplete.value = true
  if (props.collection === 'default') {
    document.title = `${profileData.value.username}'s Writing - Submit`
  } else {
    document.title = `${profileData.value.username}'s Writing Collection - Submit`
  }
  await nextTick()
  if (writingsList.value.length > 0) {
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

watch(() => props.collection, async (newVal) => {
  initialLoadComplete.value = false
  writingsList.value = []
  pageInfo.value = {
    endCursor: undefined
  }
  observer.disconnect()
  await fetchCollection()
  initialLoadComplete.value = true
  if (newVal === 'default') {
    document.title = `${profileData.value.username}'s Writing - Submit`
  } else {
    document.title = `${profileData.value.username}'s Writing Collection - Submit`
  }
  await nextTick()
  if (writingsList.value.length > 0) {
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

onUnmounted(() => {
  if (writingsList.value.length > 0) {
    observer.disconnect()
  }
})

async function fetchCollection() {
  if (working.value) return
  working.value = true
  try {
    let path = `/users/${profileData.value._id}/writing/`
    if (props.collection === "drafts") {
      path += "drafts"
    } else {
      path += `collections/${props.collection}`
    }
    if (pageInfo.value.endCursor) {
      path += `?${new URLSearchParams({
        cursor: pageInfo.value.endCursor
      })}`
    }
    const response = await API().get(path)
    writingsList.value.push(...response.data.data)
    if (props.collection === "drafts") totalItems.value = response.data.data.length
    if (!pageInfo.value.endCursor && props.collection !== "drafts") totalItems.value = response.data.pageInfo.totalItems
    if (props.collection !== "drafts") pageInfo.value = response.data.pageInfo
    if (props.collection !== "drafts" && props.collection !== "default") collectionData.value = response.data.collection
    working.value = false
  } catch (err: any) {
    console.error(err)
  }
}

async function updateCollection(data: any) {
  collectionData.value = {
    title: data.title,
    type: data.type,
    visibility: data.visibility,
    tags: data.tags,
    collectionOnly: data.collectionOnly
  }
  if (data.description && data.description.length > 0) {
    collectionData.value.description = data.description
  }
}

async function deleteCollection() {
  if (working.value) return
  working.value = true
  if (confirm("Are you sure you want to delete this collection?")) {
    try {
      await API().delete(`/collections/${collectionData.value._id}`)
      Router.push(`/${profileData.value.username}/writing/collections`)
    } catch (err: any) {
      console.error(err)
      working.value = false
      alert("There was an error deleting this collection, please try again.")
    }
  } else {
    working.value = false
    return
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        fetchCollection()
      }
    }
  })
}, options)

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: collectionData.value.user,
    contentId: collectionData.value._id,
    contentType: "collection"
  })
}

</script>

<template>
  <section class="space-y-4" v-if="initialLoadComplete">
    <div v-if="props.collection !== 'drafts' && props.collection !== 'default'" class="CollectionDetail gap-x-4">
      <div class="flex justify-center flex-grow aspect-[5/3] bg-white dark:bg-submit-925/50 rounded-md">
        <svg class="w-8 fill-gray-400 dark:fill-gray-500 group-hover:fill-gold-700 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
      </div>
      <div class="py-2">
        <h2 class="text-2xl">{{ collectionData.title }}</h2>
        <p class="text-sm">{{ collectionData.description && collectionData.description.length > 0 ? collectionData.description : 'No description found, how sad.' }}</p>
        <ul class="flex gap-4 mt-6 text-sm">
          <li v-if="isOwner">
            <NewCollection @updated="updateCollection" :type="collectionData.type" :collection="collectionData">Edit</NewCollection>
          </li>
          <li v-if="isOwner">
            <button @click="deleteCollection" type="button">Delete</button>
          </li>
          <li v-if="!isOwner">
            <button @click="openReportDialog" type="button">Report</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex justify-between text-sm font-bold">
      <span>{{ totalItems }} {{ collection === 'drafts' ? 'draft' : 'writing' }}{{ writingsList.length === 1 ? '' : 's' }}</span>
      <router-link :to="`/${profileData.username}/writing/new`" v-if="profileData.self">Add Writing</router-link>
    </div>
    <ul class="grid grid-cols-1 divide-y dark:divide-gray-800">
      <li class="first-of-type:pt-0 pt-5 pb-5" v-for="item in writingsList" :key="item._id">
        <time class="mb-1 block">{{ item.status === 'draft' ? D(item.createdAt).format('LL') : D(item.publishedAt).format('LL') }}</time>
        <router-link class="block w-full text-xl font-bold" :to="`/${profileData.username}/writing/${item.slug}${collection === 'drafts' ? '/edit' : ''}`">
          {{ item.title }}
        </router-link>
        <p>{{ item.description ? item.description : 'This writing has no description, how sad.' }}</p>
        <footer class="mt-4">
          <ul class="inline text-sm" v-if="item.tags && item.tags.length > 0">
            <li class="font-bold inline">Tags: </li>
            <li v-for="(tag, i) in item.tags" :key="tag" class="inline" v-if="item.tags && item.tags.length > 0">
              {{ tag }}{{ i < item.tags.length - 1 ? ', ' : '' }}
            </li>
            <li class="inline" v-else>
              None
            </li>
          </ul>
          <ul class="flex text-sm" v-else>
            <li>No Tags</li>
          </ul>
        </footer>
      </li>
    </ul>
    <div v-if="writingsList.length > 0" id="loadMore"></div>
  </section>
  <section class="space-y-4" v-else>
    <div class="flex justify-between">
      <span class="bg-neutral-50 dark:bg-indigo-925/25 w-24 h-4 animate-pulse"></span>
    </div>
    <ul class="grid grid-cols-1 divide-y dark:divide-gray-800">
      <li class="first-of-type:pt-0 pt-5 pb-5" v-for="i in 3" :key="i">
        <div class="block w-full h-28 bg-neutral-50 dark:bg-indigo-925/25 rounded-md animate-pulse"></div>
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import D from "@/composables/TimeDisplay"
import { ref, computed } from "vue"

const props = defineProps(["message", "thread"])
const fromContext = ref("")
const firstLetter = ref("")
const pfp = ref<string | boolean>(false)

// compare message.from to thread.from and thread.to to determine what user data to display
const fromLabel = computed(() => {
  if (typeof props.thread.from === "string") {
    if (props.message.from === props.thread.from) {
      fromContext.value = "group"
      firstLetter.value = "G"
      return props.thread.fromGroupName
    } else if (props.message.fromGroup && (props.message.fromGroup === props.thread.from)) {
      fromContext.value = "group"
      firstLetter.value = "G"
      return props.thread.fromGroupName
    }else {
      if (props.thread.toType === "group") {
        fromContext.value = "group"
        firstLetter.value = "G"
        return props.thread.toGroupName
      } else {
        fromContext.value = "user"
        firstLetter.value = props.thread.to.firstLetter
        if (props.thread.to.pfp) pfp.value = props.thread.to.pfp
        return props.thread.to.username
      }
    }
  } else {
    if (props.message.from === props.thread.from._id) {
      fromContext.value = "user"
      firstLetter.value = props.thread.from.firstLetter
      if (props.thread.from.pfp) pfp.value = props.thread.from.pfp
      return props.thread.from.username
    } else {
      if (props.thread.toType === "group") {
        fromContext.value = "group"
        firstLetter.value = "G"
        return props.thread.toGroupName
      } else {
        fromContext.value = "user"
        firstLetter.value = props.thread.to.firstLetter
        if (props.thread.to.pfp) pfp.value = props.thread.to.pfp
        return props.thread.to.username
      }
    }
  }
})
</script>

<template>
  <article class="thread pt-6">
    <figure class="flex items-center justify-center bg-white dark:bg-submit-900 rounded-full bg-cover w-10 h-10" :style="pfp ? `background-image: url('${pfp}')` : ''">
      <div v-if="!pfp" class="flex items-center justify-center w-full h-full font-semibold text-black dark:text-gray-400 select-none">
        {{ firstLetter }}
      </div>
    </figure>
    <main>
      <header>
        <h1 class="text-xl">{{ fromLabel }}</h1>
        <time class="text-sm dark:text-gray-500" :title="D(message.createdAt).format('LLLL')">{{ D(message.createdAt).fromNow() }}</time>
      </header>
      <section class="mt-6 CommonBody" v-html="message.message"></section>
    </main>
  </article>
</template>

<script setup lang="ts">
import Marked from "@/components/utility/Marked.vue"
import { ref, computed } from "vue"

const defaultSection = "editor"
const currentSection = ref(defaultSection)

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>

<template>
  <section class="grid grid-cols-1 w-full">
    <ul class="flex space-x-12 text-sm mb-1">
      <li @click="currentSection = 'editor'" :class="`hover:cursor-pointer hover:underline ${currentSection === 'editor' ? 'font-bold' : ''}`">Editor</li>
      <li @click="currentSection = 'preview'" :class="`hover:cursor-pointer hover:underline ${currentSection === 'preview' ? 'font-bold' : ''}`">Preview</li>
    </ul>
    <div class="space-y-4" v-if="currentSection === 'editor'">
      <textarea
        rows="10"
        class="w-full h-full rounded-md bg-white dark:bg-submit-600 dark:text-gray-300 outline-none py-4 px-4 border border-transparent focus:ring-0 focus:border-indigo-325/30 placeholder:text-indigo-250"
        v-model="value"
      >
      </textarea>
    </div>
    <div class="mt-6 block" v-if="currentSection === 'preview'">
      <Marked :markdown="modelValue" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"
import API from "@/api/api"

interface Props {
  content: any
  open: boolean
  toggle: any
}

const props = defineProps<Props>()

const reactionList = ref<any[]>([])
const initialLoadComplete = ref(false)
const isFetching = ref(false)
const pageInfo = ref<any>({
  endCursor: undefined
})

onMounted(async () => {
  await fetchReactions()
  initialLoadComplete.value = true
})

const fetchReactions = async () => {
  if (isFetching.value) return
  isFetching.value = true

  let path = `/reactions/${props.content.contentType}/${props.content.contentId}`
  if (pageInfo.value.endCursor) {
    path = `/reactions/${props.content.contentType}/${props.content.contentId}?${new URLSearchParams({
      cursor: pageInfo.value.endCursor
    })}`
  }

  try {
    const response = await API().get(path)
    reactionList.value = [...reactionList.value, ...response.data.data]
    pageInfo.value = response.data.pageInfo
    isFetching.value = false
  } catch (err) {
    console.error(err)
    return
  }
}

function getEmojiForType(type: string) {
  return type === "like" ? "👍" : type === "dislike" ? "👎" : type === "love" ? "❤️" : type === "hot" ? "🥵" : type === "laugh" ? "😂" : type === "cry" ? "😢" : type === "angry" ? "😠" : type === "celebrate" ? "🥳" : ""
}
</script>

<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" @close="toggle" class="relative">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25 z-20" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto z-20">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center z-20"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all z-20"
            >
              <DialogTitle
                as="div"
                class="flex justify-between mb-6"
              >
                <h3 class="text-lg font-medium leading-6 text-gold-700 dark:text-gold-500">Reactions</h3>
                <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="toggle">
                  <span class="sr-only">Close panel</span>
                  <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                </button>
              </DialogTitle>
              <div class="flex max-h-96 overflow-y-scroll">
                <ul class="space-y-2">
                  <li v-if="!initialLoadComplete">Loading...</li>
                  <li v-if="initialLoadComplete && reactionList.length === 0">No reactions yet.</li>
                  <li v-for="reaction in reactionList" :key="reaction._id" v-if="initialLoadComplete && reactionList.length > 0">
                    <a class="flex gap-2 items-center" :href="`/${reaction.user.username}`">
                      <figure class="flex items-center justify-center bg-gray-200 dark:bg-submit-950 rounded-full bg-cover w-10 h-10" :style="reaction.user.pfp ? `background-image: url('${reaction.user.pfp}')` : ''">
                        <span v-if="!reaction.user.pfp" class="flex items-center justify-center w-full h-full font-semibold text-black dark:text-gray-400 dark:hover:text-white text-xl">{{ reaction.user.firstLetter }}</span>
                      </figure>
                      {{ reaction.user.username }}
                      {{ getEmojiForType(reaction.type) }}
                    </a>
                  </li>
                  <li v-if="pageInfo.hasNextPage && !isFetching" @click="fetchReactions">Load More...</li>
                  <li class="flex justify-center" v-if="initialLoadComplete && isFetching">
                    <svg class="dark:fill-gold-500 fill-gold-700 h-12 w-12 origin-center" width="24" height="28" viewBox="-20 -20 158 183" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z">
                        <animateTransform
                        attributeName="transform"
                        type="scale"
                        values="1;0.8;1"
                        dur=".6s"
                        repeatCount="indefinite"/>
                      </path>
                      <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z">
                        <animateTransform
                        attributeName="transform"
                        type="scale"
                        values="1;0.8;1"
                        dur=".6s"
                        repeatCount="indefinite"/>
                      </path>
                      <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z">
                        <animateTransform
                        attributeName="transform"
                        type="scale"
                        values="1;0.8;1"
                        dur=".6s"
                        repeatCount="indefinite"/>
                      </path>
                    </svg>
                  </li>
                </ul>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

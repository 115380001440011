<script setup lang="ts">
import API from "@/api/api"
import { useUserStore } from "@/stores/User"
import ViewCircle from "@/components/circles/ViewCircle.vue"
import { ref } from "vue"

const props = defineProps({
  circle: {
    type: Object,
    required: true
  }
})
const emits = defineEmits(["deleted", "removedUserFromCircle"])

const UserStore = useUserStore()

const working = ref(false)

async function deleteCircle() {
  if (working.value) return
  working.value = true
  try {
    await API().delete(`/circles/${props.circle.code}`)
    emits("deleted", props.circle.code)
    UserStore.removeCircle(props.circle.code)
  } catch (err) {
    console.error(err)
  } finally {
    working.value = false
  }
}

function handleRemovedUserFromCircle(data: any) {
  emits("removedUserFromCircle", data)
}
</script>

<template>
  <li class="text-sm bg-white dark:bg-submit-700 dark:hover:bg-submit-500 rounded-md p-4" :key="circle._id">
    <h3 class="font-bold">{{ circle.name }}</h3>
    <p class="text-xs text-gray-500 dark:text-gray-400">{{ circle.count }}/{{ circle.type === "xl" ? 256 : 64 }} users</p>
    <div class="mt-2 flex justify-between">
      <ViewCircle :circle="circle" label="View" @remove-from-circle="handleRemovedUserFromCircle" />
      <button @click="deleteCircle" type="button" class="text-red-500 dark:text-red-400">Delete</button>
    </div>
  </li>
</template>

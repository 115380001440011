<script setup lang="ts">
import { ref, inject, computed, onBeforeMount, type Ref } from "vue"
import D from "@/composables/TimeDisplay"
import { useRoute } from "vue-router"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const slug = inject("slug") as Ref<string>
const Route = useRoute()

const report = ref<any>({})
const reportType = ref("")

const initialLoadComplete = ref(false)

const error = ref(false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")

const modalOpen = ref(false)
const modalType = ref("")
const details = ref("")
const reason = ref("")
const response = ref("")
const status = ref("")
const actionType = ref("")
const actionResponse = ref("")
const actionReason = ref("")
const actionDetails = ref("")

const working = ref(false)

onBeforeMount(async () => {
  await getReport()
  reportType.value = report.value.reportType
  status.value = report.value.status
  actionType.value = report.value.actionType || ""
  actionResponse.value = report.value.actionResponse || ""
  actionReason.value = report.value.actionReason || ""
  actionDetails.value = report.value.actionDetails || ""
  initialLoadComplete.value = true
})

const buttonLabel = computed(() => {
  if (working.value) return "Processing..."
  if (modalType.value === "remove") return "Remove Content"
  if (modalType.value === "close") return "Close Report"
})

const reportTypeLabel = computed(() => {
  switch (reportType.value) {
    case "prohibited":
      return "Prohibited Content"
    case "harassment":
      return "Harassment"
    case "spam":
      return "Spam"
    case "Underage":
      return "Underage"
    case "dmca":
      return "DMCA"
    case "other":
      return "Other"
    default:
      return "Unknown"
  }
})

function toggleModal(type: string) {
  if (modalOpen.value) {
    error.value = false
    errorMessage.value = "There was an error performing this action, please try again."
    errorCode.value = "NEC"
    reason.value = ""
    details.value = ""
    response.value = ""
    setTimeout(() => {
      modalType.value = type
    }, 200)
  } else {
    modalType.value = type
  }
  modalOpen.value = !modalOpen.value
}

async function getReport() {
  try {
    let path = `/groups/g/${slug.value}/reports/${Route.params.id}`
    const response = await API().get(path)
    report.value = response.data.data
  } catch (err) {
    console.error(err)
    error.value = true
  }
}

async function reportAction() {
  if (working.value) return
  working.value = true

  const data: any = {
    actionType: modalType.value,
    actionResponse: response.value,
    actionReason: reason.value
  }

  if (details.value) data.actionDetails = details.value

  try {
    await API().post(`/groups/g/${slug.value}/reports/${report.value._id}/action`, data)

    working.value = false
    status.value = "closed"
    actionType.value = modalType.value
    actionResponse.value = response.value
    actionReason.value = reason.value
    actionDetails.value = details.value
    toggleModal("close")
  } catch (e) {
    error.value = true
    errorMessage.value = "There was an error performing this action, please try again."
    errorCode.value = "NEC"
    working.value = false
  }
}
</script>

<template>
  <main class="space-y-6" v-if="initialLoadComplete">
    <header>
      <h2 class="font-display font-semibold text-lg">
        {{ reportTypeLabel }} Report
      </h2>
      <div class="text-xs text-gray-400">
        <span class="capitalize">{{ status }}</span>
        &nbsp;&middot;&nbsp;
        <span class="capitalize">{{ report.reports.length }} report{{ report.reports.length === 1 ? '' : 's' }}</span>
        &nbsp;&middot;&nbsp;
        <time :title="D(report.createdAt).format('LLLL')">First reported {{ D(report.createdAt).fromNow() }}</time>
        <span v-if="report.isTest">
          &nbsp;&middot;&nbsp;
          TEST
        </span>
      </div>
    </header>
    <section v-if="status === 'pending'">
      <ul class="flex flex-col md:flex-row text-sm gap-6">
        <li v-if="report.contentStatus === 'active'" tabindex="0" class="underline decoration-dotted cursor-pointer hover:decoration-solid" @click="toggleModal('remove')">Remove Content</li>
        <li tabindex="0" class="underline decoration-dotted cursor-pointer hover:decoration-solid" @click="toggleModal('close')">Close Report</li>
      </ul>
      <TransitionRoot appear :show="modalOpen" as="template">
        <Dialog as="div" @close="toggleModal('close')" class="relative z-[100]">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div class="fixed inset-0 overflow-y-auto">
            <div
              class="flex min-h-full items-center justify-center p-4 text-center"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                >
                  <DialogTitle
                    as="h3"
                    class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                  >
                    <template v-if="modalType === 'remove'">
                      Remove Content
                    </template>
                    <template v-if="modalType === 'close'">
                      Close Report
                    </template>
                  </DialogTitle>
                  <template v-if="modalType === 'remove' && report.reportContentType === 'group:post'">
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">Remove the reported content and close the report(s).</p>
                      <div>
                        <label for="details" class="text-sm block font-bold mb-1">Moderation Notes</label>
                        <textarea v-model="details" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="details" rows="2" placeholder="Notes" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is only shared with other group moderators.</p>
                      </div>
                      <div>
                        <label for="reason" class="text-sm block font-bold mb-1">Removal Reason</label>
                        <textarea v-model="reason" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is shared with the member that posted the content.</p>
                      </div>
                      <div>
                        <label for="response" class="text-sm block font-bold mb-1">Report Response</label>
                        <textarea v-model="response" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="response" rows="3" placeholder="Response" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is shared with all members that reported the content.</p>
                      </div>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleModal('close')"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                          @click="reportAction"
                        >
                          {{ buttonLabel }}
                        </button>
                      </div>
                      <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                      </div>
                    </div>
                  </template>
                  <template v-if="modalType === 'remove' && report.reportContentType === 'group:comment'">
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">Comments can not be currently deleted directly from a report. Please go to the post and remove the comment and then come back and close this ticket.</p>
                      <p class="text-xs">This will be fixed in a later update to the reporting system.</p>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleModal('close')"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </template>
                  <template v-if="modalType === 'close'">
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">Close the report(s) with taking no action on the content.</p>
                      <div>
                        <label for="details" class="text-sm block font-bold mb-1">Moderation Notes</label>
                        <textarea v-model="details" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="details" rows="2" placeholder="Notes" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is only shared with other group moderators.</p>
                      </div>
                      <div>
                        <label for="response" class="text-sm block font-bold mb-1">Report Response</label>
                        <textarea v-model="response" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="response" rows="3" placeholder="Response" />
                        <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> This is shared with all members that reported the content.</p>
                      </div>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleModal('close')"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                          @click="reportAction"
                        >
                          {{ buttonLabel }}
                        </button>
                      </div>
                      <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                        <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                      </div>
                    </div>
                  </template>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </section>
    <section v-else>
      <p class="text-sm">You cannot take action on a closed report.</p>
    </section>
    <ul class="text-sm space-y-1">
      <li><strong>Reported Content:</strong> <a class="underline decoration-dotted hover:decoration-solid" :href="report.reportURL">{{ report.reportContentType }}</a> (Status: {{ report.contentStatus }})</li>
      <li><strong>Reported Member:</strong> <a class="underline decoration-dotted hover:decoration-solid" :href="`/groups/${report.group}/moderation/members/${report.reportedUser._id}`">{{ report.reportedUser.username }}</a> (Status: {{ report.reporetedUserIsCurrentMember ? 'Current Member' : 'Not a Current Member' }})</li>
      <li><strong>First Reported By:</strong> <a class="underline decoration-dotted hover:decoration-solid" :href="`/${report.firstReportingUser.username}`">{{ report.firstReportingUser.username }}</a></li>
    </ul>
    <section class="text-sm">
      <h3 class="font-bold mb-1">Action</h3>
      <p v-if="status === 'pending'">No action taken.</p>
      <div class="text-xs" v-if="status === 'closed'">
        <p><strong>Action Taken:</strong> {{ actionType }}</p>
        <p><strong>Response:</strong> {{ actionResponse }}</p>
        <p><strong>Reason:</strong> {{ actionReason }}</p>
        <p><strong>Moderation Details:</strong> {{ actionDetails }}</p>
      </div>
    </section>
    <section class="text-sm">
      <h3 class="font-bold mb-1">Notes</h3>
      <ul class="space-y-3">
        <li v-if="!report.notes || report.notes.length === 0">No notes.</li>
        <li class="underline decoration-dotted hover:decoration-solid cursor-pointer">Add a note</li>
      </ul>
    </section>
    <section>
      <h3 class="font-bold text-sm mb-1">Report(s)</h3>
      <p class="mb-6 text-sm">Multiple reports of the same type for this piece of content will stack here. When action is taken on this report, it will close each of these individual reports along with notifying each reporting user.</p>
      <ul>
        <li v-for="r in report.reports" class="dark:bg-submit-900 bg-white p-4 rounded-md">
          <div class="text-sm dark:text-gray-400">Report from {{ r.reportingUser.username }}, {{ D(r.createdAt).format('LLLL') }}</div>
          <p>{{r.details}}</p>
        </li>
      </ul>
    </section>
  </main>
</template>

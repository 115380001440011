<script setup lang="ts">
import StatusEditor from "@/components/status/StatusEditor.vue"
import MediaEditor from "@/components/status/MediaEditor.vue"
import { useUserStore } from "@/stores/User"
import { ref, onMounted } from "vue"
import { storeToRefs } from "pinia"

const UserStore = useUserStore()
const { username } = storeToRefs(UserStore)

const open = ref(false)
const statusType = ref<string | null>(null)

function openEditor(type: string) {
  open.value = true
  statusType.value = type
}

function close() {
  open.value = false
}

onMounted(() => {
  // check query string for new=status
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.get('new') === 'status') {
    open.value = true
  }
})
</script>

<template>
  <section class="dark:bg-submit-900 bg-white rounded-2xl break-modern p-0 h-fit" :class="{ 'p-4': open }">
    <div class="dark:text-gray-500 text-[15px] md:text-base flex items-center" v-if="!open">
      <div class="py-4 px-4 flex-1 cursor-pointer border-r-4 border-r-neutral-125 dark:border-r-submit-950 hover:dark:text-white" @click="openEditor('text')">What would you like to share, {{ username }}?</div>
      <div @click="openEditor('media')" class="px-8 py-4 cursor-pointer group">
        <svg class="w-4 dark:fill-gray-500 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
      </div>
    </div>
    <header v-if="open">
      <h1 class="font-bold mb-3">New {{ statusType === 'text' ? 'Status Update' : 'Media Update' }}</h1>
    </header>
    <StatusEditor v-if="open && statusType === 'text'" @close="close" />
    <MediaEditor v-if="open && statusType === 'media'" @close="close" />
  </section>
</template>

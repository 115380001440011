<script setup lang="ts">
import SelfSubmit from "@/components/input/SelfSubmit.vue"
import { ref, computed, onBeforeMount, watchEffect } from "vue"
import Label from "@/components/input/Label.vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import confetti from "canvas-confetti"
import { storeToRefs } from "pinia"
import { validate } from "uuid"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from "@headlessui/vue"

const UserStore = useUserStore()
const { id, preferences, flags } = storeToRefs(UserStore)

const baseRoute = `/users/${id.value}`

const isEnabled = computed(() => flags.value.includes("backer"))
const loading = ref(false)
const backerCode = ref("")
const error = ref(false)
const backerData = ref<any>({})
const open = ref(false)
const validating = ref(false)
const attempted = ref(false)
const validated = ref(false)
const showBadge = ref<string>(preferences.value.backerDisplay || "enabled")
const badgeType = ref<string>(preferences.value.backerDisplayType || "default")

const closeValidator = () => {
  open.value = false
  // delay to allow the dialog to close
  setTimeout(() => {
    validating.value = false
  }, 500)
}

function launchConfetti() {
  confetti({
    particleCount: 150,
    spread: 100,
    disableForReducedMotion: true
  })
}

const isDirty = computed(() => {
  if (backerCode.value.length > 0) {
    return true
  }
  return false
})

const fieldError = computed(() => {
  if (!validate(backerCode.value)) {
    return true
  }
  return false
})

const category = computed(() => {
  if (backerData.value.subType === "thefew") {
    return "The Few"
  }
  if (backerData.value.subType === "reallyearly") {
    return "Really Early Adopters"
  }
  if (backerData.value.subType === "early") {
    return "Early Adopters"
  }
  if (backerData.value.subType === "general") {
    return "General"
  }
  return "Unknown"
})

const shouldDisplayCode = computed(() => {
  if (!backerData.value.backer) {
    return true
  } else {
    switch (backerData.value.type) {
      case "benefactor":
        return false
      case "ultra":
        return false
      case "lifetime":
        return false
      case "monthly":
        return true
      default:
        return true
    }
  }
})

async function fetchBackerData() {
  try {
    const response = await API().get(`/backer`)
    backerData.value = response.data.data
  } catch (err) {
    console.error(err)
  }
}

onBeforeMount(async () => {
  if (!isEnabled.value) {
    return
  }
  await fetchBackerData()
  loading.value = false
})

async function validateCode() {
  open.value = true
  validating.value = true
  attempted.value = true
  let data: any = {
    code: backerCode.value
  }

  try {
    await API().post(`/backer/redeem`, data)
    validated.value = true
    backerCode.value = ""
    launchConfetti()
    await fetchBackerData()
  } catch (err) {
    console.error(err)
    error.value = true
    validating.value = false
    return
  }
}

const badgeTypes = [
  { value: 'default', text: 'Standard (Gold)' },
  { value: 'green', text: 'Standard (Green)' },
  { value: 'pink', text: 'Standard (Pink)' }
]

// watch for changes to the backer preferences
watchEffect(() => {
  if (showBadge.value !== preferences.value.backerDisplay) {
    UserStore.updatePreferences({
      backerDisplay: showBadge.value
    })
  }
  if (badgeType.value !== preferences.value.backerDisplayType) {
    UserStore.updatePreferences({
      backerDisplayType: badgeType.value
    })
  }
})
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6" v-if="!isEnabled">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Backer Support</h2>
        <p>If you've decided to become a backer, you can view your backer details here as well as enter backer codes, and manage backer-specific settings.</p>
        <p class="text-gray-500 dark:text-gray-400 text-sm font-bold" v-if="!isEnabled">This feature is currently disabled, check your <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="/early-access/features">EAP Features</a> to see if you have backer support enabled.</p>
      </div>
    </section>
    <section class="space-y-6" v-if="isEnabled">
      <div class="space-y-6" v-if="backerData.backer && !loading">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Your Backer Status</h2>
        <p>You are a backer, thank you for your support! The details of your backer support are as follows:</p>
        <ul class="space-y-1">
          <li class="capitalize"><strong>Type:</strong> {{ backerData.type }}</li>
          <li><strong>Category:</strong> {{ category }}</li>
          <li v-if="backerData.type === 'monthly'"><strong>Term:</strong> {{ backerData.term }} Months</li>
          <li v-if="backerData.type === 'monthly'"><strong>Expires:</strong> {{ D(backerData.expiresAt).format("LL") }}</li>
          <li><strong>Backer Feature Group:</strong> Sentinel</li>
        </ul>
        <p><strong>Note:</strong> If you are a monthly backer, your expiry date may look a little confusing. Expiry countdowns will not commence until all major features are available.</p>
      </div>
      <div class="space-y-6" v-if="!backerData.backer && !loading">
        <h2 class="font-display font-semibold dark:text-gold-500 text-2xl">Your Backer Status</h2>
        <p>You are not a backer 😢! You can learn more about becoming a backer by visiting the <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="/backer">Backer Details</a> page.</p>
      </div>
    </section>
    <section class="space-y-6" v-if="isEnabled && shouldDisplayCode">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-2xl">Backer Code</h2>
        <p>If you have a backer code you'd like to redeem, you can do so below.</p>
        <div>
          <Label field="code">Code</Label>
          <input name="code" id="code" type="text" v-model="backerCode" class="w-full p-4 text-sm rounded-md bg-white dark:bg-submit-900 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" placeholder="put-backer-code-here" />
          <small class="block text-red-500 font-semibold mt-1" v-if="fieldError && isDirty">Error: Invalid Code Format</small>
          <button @click="validateCode" class="mt-6 text-sm font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500 dark:hover:bg-gold-500 text-black py-2 px-12" type="submit" :disabled="fieldError || !isDirty">Validate</button>
        </div>
      </div>
    </section>
    <section class="space-y-6" v-if="isEnabled && backerData.backer">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Backer Settings</h2>
        <p>Choose if you'd like to display your backer badge and what type of badge you'd like to display.</p>
        <div>
          <Label field="preferences.backerDisplay">Backer Badge Visibility</Label>
          <SelfSubmit v-model="showBadge" name="preferences.backerDisplay" :apiEndpoint="baseRoute" :options="[{ value: 'enabled', text: 'Enabled'}, { value: 'disabled', text: 'Disabled'}]" />
        </div>
        <div>
          <Label field="preferences.backerDisplayType">Backer Badge Type</Label>
          <SelfSubmit v-model="badgeType" name="preferences.backerDisplayType" :apiEndpoint="baseRoute" :options="badgeTypes" />
          <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> Additional badges will be available soon.</p>
        </div>
      </div>
    </section>
    <TransitionRoot appear :show="open" as="template">
      <Dialog as="div" @close="closeValidator" class="relative">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
            <DialogPanel
                class="w-full max-w-md min-h-[204px] transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
              >
                <div class="flex h-[204px] space-y-4 align-middle items-center justify-center" v-if="validating && (!validated || error)">
                  <div>
                    <div class="flex justify-center">
                      <div class="w-6 h-6 border-2 border-t-2 border-gold-700 dark:border-gold-500 rounded-full animate-ping"></div>
                    </div>
                    <p class="text-sm font-bold mt-6">Validating your backer code, please wait...</p>
                  </div>
                </div>
                <div class="flex h-[204px] space-y-4 align-middle items-center justify-center" v-if="validating && error">
                  <div>
                    <p class="text-sm font-bold text-red-500">The code provided is invalid or has already been used.</p>
                  </div>
                </div>
                <div class="flex h-[204px] space-y-4 align-middle items-center justify-center" v-if="validating && validated">
                  <div class="text-center">
                    <h2 class="font-extrabold dark:text-yellow-450">Thank you for your support!</h2>
                    <p class="text-sm font-bold">You have successfully redeemed your backer code.</p>
                    <button @click="closeValidator" class="mt-6 text-sm hover:cursor-pointer">Close</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </main>
</template>
